import React, { useState, useEffect } from "react";
import style from "../styles/pricing.module.css";
import FAQsection from "../components/financialComponent/FAQsection";
import { PRICINGTABLECOR, PRICINGTABLECORONCE } from "../components/financialComponent/PricingTable";
import { useSelector } from "react-redux";
import CookiesModal from "../components/CookiesModal";
import CookiesModalTest from "../components/CookiesModalTest";
import CookiesPolicyModal from "../components/CookiesPolicyModal";
import { useTranslation } from "react-multi-lang";
import { Link, useHistory } from "react-router-dom";


export default function Pricing() {
  const [show, setShow] = useState(false);
  const daynight = useSelector((state) => state.data.dayNight);
  const cookiesModal = useSelector((state) => state.data.cookiesModal);
  const history = useHistory();
  const d = useTranslation();

  useEffect(() => {
		document.title = `${d("tab.pricing")} - dioptify.com`;
	});

  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const handleCallback = (e) =>{
    setShowCheckoutModal(false)
  }

  const handleCallbackParent = (e) =>{
    // TODO: add here some alert why the modal is not showing
    if(chips === "-") {
      let path = `/enterprise`; 
      history.push(path);
    } else if(chips.trim() !== ""){
      setShowCheckoutModal(true);
    }
  }

  const handleCallbackParentSubPlan = (e, plan) =>{
    setChips(plan);
    setShowCheckoutModal(true);
  }

  // https://surajsharma.net/blog/react-handle-radio-buttons
  const [chips, setChips] = useState(""); //same as creating your state variable where "Next" is the default value for buttonText and setButtonText is the setter function for your state variable instead of setState
  const changeChips = (text) => setChips(text);

  const handleChange = (event) => {
    var prcingArray = (event.target.value).split(","); // this fuck step is neccessary because JS is casting automatically array to string WTF
    
    changeChips(prcingArray[2]);
    changeTextOnce(prcingArray[0]);
  }

  const handleChangeMonth = (event) => {
    var prcingArray = (event.target.value).split(","); // this fuck step is neccessary because JS is casting automatically array to string WTF
    
    changeChips(prcingArray[2]);
    changeText(prcingArray[1])
  }

  /*
  const resetRadioState = () => {
    setPoints('');
  }
  */

  const [buttonText, setButtonText] = useState("0,00"); //same as creating your state variable where "Next" is the default value for buttonText and setButtonText is the setter function for your state variable instead of setState
  const [buttonTextOnce, setButtonTextOnce] = useState("0,00");
  const changeText = (text) => setButtonText(text);
  const changeTextOnce = (text) => setButtonTextOnce(text);

  // https://reactjs.org/docs/conditional-rendering.html
  const [loggedIn, setLoggedIn] = useState(false);
  const changeLoggedIn = (logged) => setLoggedIn(logged);
  useEffect(() => {
    const fetchUser = async () => {
      if(localStorage.getItem('user') !== null) {
        changeLoggedIn(true);
      } else {
        changeLoggedIn(false);
      }
    };
    fetchUser();
  }, []);

  return (
    <>
      {cookiesModal ? <CookiesModal /> : ""}
      <div className={`${style.cookiesSection} ${daynight ? style.nightMode : ""}`}>
        <div class="col-lg-12 mx-auto">
          <div className={style.heroSection}>
            <h1 class="mainHeading mb-1">{d("price.title")}</h1>
            
            <br/>
            <h4 className={`${style.commonTextPricing} ${style.tooltipnew}`}>{d("price.tooltipimage")}
              <>&nbsp;</><i class="fas fa-info-circle"></i>
              <br/>
              <span className={`${style.tooltiptextnew}`} >{d("price.tooltipimagetext")}</span>
            </h4>
            {/*
            <br/>
            <h4 className={`${style.commonTextPricing} ${style.tooltipnew}`}>{d("price.tooltipvideo")}
              <>&nbsp;</><i class="fas fa-info-circle"></i>
              <br/>
              <span className={`${style.tooltiptextnew}`} >{d("price.tooltipvideotext")}</span>
            </h4>
            */}
            <br/>
            <h4 className={`${style.commonTextPricing} ${style.tooltipnew}`}>{d("price.points")}
              <>&nbsp;</><i class="fas fa-question-circle"></i>
              <br/>
              <span className={`${style.tooltiptextnew}`} >{d("price.tooltippointstext")}</span>
            </h4>

              {/*
                <>
                  <div>
                    <div className={`${style.switchesContainer}`}>
                      <input type="radio" id="switchMonthly" name="switchPlan" value="Monthly" checked="checked" />
                      <input type="radio" id="switchYearly" name="switchPlan" value="Yearly" />
                      <label for="switchMonthly">Monthly</label>
                      <label for="switchYearly">Yearly</label>
                      <div className={`${style.switchWrapper}`}>
                        <div className={`${style.switch}`}>
                          <div>Monthly</div>
                          <div>Yearly</div>
                        </div>
                      </div>
                    </div>
                    <p><small>NB: Input radios are used toggle the switch state.</small></p>
                  </div>
                </>
              */}
          </div>
          
          <section>
            <div class="container-fluid">
              {/*<div class="container"> Old for bottom config*/}
              <div style={{maxWidth:'1500px', margin:"auto"}}>
                {/*<div class="row">*/}
                  {loggedIn
                    ?
                    <>
                    <div class="row">
                      <div className={`col-xl-4`}>
                        <div className={`${style.cardPricing} text-center`}>
                          {/*https://codepen.io/nxworld/pen/oLdoWb*/}
                          <div className={`${style.ribbon} ${style.ribbonTopLeft}`}>
                            <span>{d("price.price")}</span>
                          </div>

                          <div className={style.logo}>
                            <i class="fa fa-crow fa-5x" aria-hidden="true"></i>
                            <h2>{d("price.standard")}</h2>
                          </div>
                          <div className={style.price}>
                            <h4>4.99 € / {d("price.month")}</h4>
                          </div>

                          <div className={style.option}>
                            <ul>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.standardDescription.perk1")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.standardDescription.perk2")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.standardDescription.perk3")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.standardDescription.perk4")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.standardDescription.perk5")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.standardDescription.perk6")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.standardDescription.perk7")} </li>
                            </ul>
                          </div>

                          <Link
                            to="#"
                            className={`btn-outline-success ${style.buttonAdventure}`}
                            onClick={(e) => {
                              handleCallbackParentSubPlan(e, "sp1");
                            }}
                          >
                              {chips === "-"
                                ? <>{d("price.contact")}</>
                                : <>{d("price.subscribe")}</>
                              }
                          </Link>
                        </div>
                      </div>
                      <div className={`${style.divMobile} col-xl-4`}>
                        <div className={`${style.cardPricing} text-center`}>

                          <div className={style.logo}>
                            <i class="fa fa-dove fa-5x" aria-hidden="true"></i>
                            <h2>{d("price.unlimitedPlan")}</h2>
                          </div>
                          <div className={style.price}>
                            <h4>24.99 € / {d("price.month")}</h4>
                          </div>

                          <div className={style.option}>
                            <ul>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.unlimitedDescription.perk1")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.unlimitedDescription.perk2")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.unlimitedDescription.perk3")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.unlimitedDescription.perk4")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.unlimitedDescription.perk5")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.unlimitedDescription.perk6")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.unlimitedDescription.perk7")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.unlimitedDescription.perk8")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.unlimitedDescription.perk9")} </li>
                            </ul>
                          </div>

                          <Link
                            to="#"
                            className={`btn-outline-success ${style.buttonAdventure}`}
                            onClick={(e) => {
                              handleCallbackParentSubPlan(e, "sp2");
                            }}
                          >
                              {chips === "-"
                                ? <>{d("price.contact")}</>
                                : <>{d("price.subscribe")}</>
                              }
                          </Link>
                        </div>
                      </div>
                      <div className={`${style.divMobile} col-xl-4`}>
                        <div className={`${style.cardPricing} text-center`}>
                          <div className={style.logo}>
                            <i class="fa fa-kiwi-bird fa-5x" aria-hidden="true"></i>
                            <h2>{d("price.once")}</h2>
                          </div>
                          <div className={style.price}>
                            <h4>{buttonTextOnce} € / {d("price.month")}</h4>
                          </div>
                          <div className={style.containers}>
                              <ul>
                              <li >
                                <input type="radio" id="fs-option" name="selector" value={PRICINGTABLECORONCE[1]} onChange={handleChange}/>
                                <label for="fs-option"><strong>100</strong> {d("price.points")} <i class="fa fa-tag" aria-hidden="true"></i></label>
                                
                                <div className={style.check}></div>
                              </li>
                              <li >
                                <input type="radio" id="fss-option" name="selector" value={PRICINGTABLECORONCE[10]} onChange={handleChange}/>
                                <label for="fss-option"><strong>500</strong> {d("price.points")} <i class="fa fa-tags" aria-hidden="true"></i></label>
                                
                                <div className={style.check}></div>
                              </li>
                            </ul>
                          </div>

                          <br/>
                          <br/>
                          
                          <div className={style.option}>
                            <ul>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.payOnceDescription.perk1")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.payOnceDescription.perk2")} </li>
                            <hr/>
                            <li> <i class="fas fa-times-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.payOnceDescription.perk3")} </li>
                            </ul>
                          </div>

                          {/*<a href="#">{d("price.buy")}</a>*/}
                          <Link
                            to="#"
                            className={`btn-outline-success ${style.buttonAdventure}`}
                            onClick={(e) => {
                              handleCallbackParent(e);
                            }}
                          >
                            {chips === "-"
                              ? <>{d("price.contact")}</>
                              : <>{d("price.buy")}</>
                            }
                          </Link>
                        </div>
                      </div>
                    </div>
                    
                    <br />
                    <br />
                    <br />
                    <br />

                    <div class="row">
                      <div className={`${style.divMobile} col-xl-12`}>
                        <div className={`${style.cardPricing} text-center`}>
                          <div className={style.logo}>
                            <i class="fa fa-building fa-5x" aria-hidden="true"></i>
                            <h2>{d("price.enterprise")}</h2>
                          </div>
                          <div className={style.price}>
                            <h4>{d("price.enterpriseContact1")}</h4>
                          </div>

                          <div className={style.option}>
                            <ul>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.enterpriseDescription.perk1")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.enterpriseDescription.perk2")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.enterpriseDescription.perk3")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.enterpriseDescription.perk4")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.enterpriseDescription.perk5")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.enterpriseDescription.perk6")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.enterpriseDescription.perk7")} </li>
                            </ul>
                          </div>

                          <Link
                            to="/enterprise"
                            className={`btn-outline-success ${style.buttonAdventure}`}
                          >
                            {d("price.enterpriseContact2")}
                          </Link>
                        </div>
                      </div>
                    </div>
                    </>
                    :
                    <>
                    <div class="row">
                      <div className={`col-xl-3`} >
                        <div className={`${style.cardPricing} text-center`}>
                          <div className={style.logo}>
                            {/* TODO: add here the correct css fa class */}
                            <i class="fa fa-feather-alt fa-5x" aria-hidden="true"></i>
                            <h2>{d("price.free")}</h2>
                          </div>
                          <div className={style.price}>
                            <h4>{d("price.forever")} {d("price.free")}</h4>
                          </div>
                          <div className={style.option}>
                            <ul>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.creds")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.unlimited")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.api")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.psext")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.refer")} </li>
                            </ul>
                          </div>

                          <Link
                            to="/authentication"
                            className={`btn-outline-success ${style.buttonAdventure}`}
                          >
                            {d("price.login")}
                          </Link>
                        </div>
                      </div>
                      <div className={`${style.divMobile} col-xl-3`}>
                        <div className={`${style.cardPricing} text-center`}>
                          <div className={`${style.ribbon} ${style.ribbonTopLeft}`}>
                            <span>{d("price.price")}</span>
                          </div>

                          <div className={style.logo}>
                            <i class="fa fa-crow fa-5x" aria-hidden="true"></i>
                            <h2>{d("price.standard")}</h2>
                          </div>
                          <div className={style.price}>
                            <h4>4.99 € / {d("price.month")}</h4>
                          </div>

                          <div className={style.option}>
                            <ul>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.standardDescription.perk1")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.standardDescription.perk2")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.standardDescription.perk3")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.standardDescription.perk4")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.standardDescription.perk5")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.standardDescription.perk6")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.standardDescription.perk7")} </li>
                            </ul>
                          </div>

                          <Link
                            to="/authentication"
                            className={`btn-outline-success ${style.buttonAdventure}`}
                          >
                            {d("price.subscribe")}
                          </Link>
                        </div>
                      </div>
                      <div className={`${style.divMobile} col-xl-3`}>
                        <div className={`${style.cardPricing} text-center`}>

                          <div className={style.logo}>
                            <i class="fa fa-dove fa-5x" aria-hidden="true"></i>
                            <h2>{d("price.unlimitedPlan")}</h2>
                          </div>
                          <div className={style.price}>
                            <h4>24.99 € / {d("price.month")}</h4>
                          </div>

                          <div className={style.option}>
                            <ul>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.unlimitedDescription.perk1")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.unlimitedDescription.perk2")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.unlimitedDescription.perk3")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.unlimitedDescription.perk4")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.unlimitedDescription.perk5")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.unlimitedDescription.perk6")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.unlimitedDescription.perk7")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.unlimitedDescription.perk8")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.unlimitedDescription.perk9")} </li>
                            </ul>
                          </div>

                          <Link
                            to="/authentication"
                            className={`btn-outline-success ${style.buttonAdventure}`}
                          >
                            {d("price.subscribe")}
                          </Link>
                        </div>
                      </div>
                      <div className={`${style.divMobile} col-xl-3`}>
                        <div className={`${style.cardPricing} text-center`}>
                          <div className={style.logo}>
                            <i class="fa fa-kiwi-bird fa-5x" aria-hidden="true"></i>
                            <h2>{d("price.once")}</h2>
                          </div>
                          <div className={style.price}>
                            <h4>{buttonTextOnce} € / {d("price.month")}</h4>
                          </div>
                          <div className={style.containers}>
                              <ul>
                              <li >
                                <input type="radio" id="fs-option" name="selector" value={PRICINGTABLECORONCE[1]} onChange={handleChange}/>
                                <label for="fs-option"><strong>100</strong> {d("price.points")} <i class="fa fa-tag" aria-hidden="true"></i></label>
                                
                                <div className={style.check}></div>
                              </li>
                              <li >
                                <input type="radio" id="fss-option" name="selector" value={PRICINGTABLECORONCE[10]} onChange={handleChange}/>
                                <label for="fss-option"><strong>500</strong> {d("price.points")} <i class="fa fa-tags" aria-hidden="true"></i></label>
                                
                                <div className={style.check}></div>
                              </li>
                            </ul>
                          </div>

                          <br/>
                          <br/>
                          
                          <div className={style.option}>
                            <ul>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.payOnceDescription.perk1")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.payOnceDescription.perk2")} </li>
                            <hr/>
                            <li> <i class="fas fa-times-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.payOnceDescription.perk3")} </li>
                            </ul>
                          </div>

                          <Link
                            to="/authentication"
                            className={`btn-outline-success ${style.buttonAdventure}`}
                          >
                            {d("price.buy")}
                          </Link>
                        </div>
                      </div>
                    </div>
                    
                    <br />
                    <br />
                    <br />
                    <br />

                    <div class="row">
                      <div className={`${style.divMobile} col-xl-12`}>
                        <div className={`${style.cardPricing} text-center`}>
                          <div className={style.logo}>
                            <i class="fa fa-building fa-5x" aria-hidden="true"></i>
                            <h2>{d("price.enterprise")}</h2>
                          </div>
                          <div className={style.price}>
                            <h4>{d("price.enterpriseContact1")}</h4>
                          </div>

                          <div className={style.option}>
                            <ul>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.enterpriseDescription.perk1")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.enterpriseDescription.perk2")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.enterpriseDescription.perk3")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.enterpriseDescription.perk4")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.enterpriseDescription.perk5")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.enterpriseDescription.perk6")} </li>
                            <hr/>
                            <li> <i class="fas fa-check-circle fa-1x" aria-hidden="true"></i> <>&nbsp;</> {d("price.enterpriseDescription.perk7")} </li>
                            </ul>
                          </div>

                          <Link
                            to="/enterprise"
                            className={`btn-outline-success ${style.buttonAdventure}`}
                          >
                            {d("price.enterpriseContact2")}
                          </Link>
                        </div>
                      </div>
                    </div>
                    </>
                  }
                {/*</div>*/}
              </div>
            </div>
          </section>
        </div>
      </div>

      {showCheckoutModal
        ?
        <CookiesModalTest parentCallback={handleCallback} plan={chips}/>
        :
        ""
      }

      <FAQsection data={d}/>
      <CookiesPolicyModal show={show} setShow={setShow} />
    </>
  );
}
