import Types from "../constant/image";

export const setImage = (data) => ({
  type: Types.SET_IMAGE,
  payload: data,
});

export const setVideo = (data) => ({
  type: Types.SET_VIDEO,
  payload: data,
});

export const deleteVideo = (id) => ({
  type: Types.DELETE_VIDEO,
  payload: id,
});

export const deleteImage = (id) => ({
  type: Types.DELETE_IMAGE,
  payload: id,
});

export const setDayNight = (data) => ({
  type: Types.SET_DAYNIGHT,
  payload: data,
});

export const setEditorData = (data) => ({
  type: Types.SET_EDITOR_DATA,
  payload: data,
});

export const setCookiesModal = (data) => ({
  type: Types.SET_COOKIES_MODAL,
  payload: data,
});
export const setFunctionalCookies = (data) => ({
  type: Types.SET_FUNCTIONAL_COOKIES,
  payload: data,
});
export const setAnalyticsCookies = (data) => ({
  type: Types.SET_ANALYTICS_COOKIES,
  payload: data,
});

export const setLogoutStates = () => ({
  type: Types.LOGOUT,
});
