import React, { useState, useEffect } from "react";
import BlogSection from "../components/BlogSection";
import { useTranslation } from "react-multi-lang";
import style from "../styles/multiselection.module.css";
import { useSelector } from "react-redux";
import { Alert } from "react-bootstrap";

import CookiesModal from "../components/CookiesModal";
import MultiSelectionUploadSection from "../components/services/MultiService/MultiSelectionUploadSection";
import UploadMultiServiceSectionTasks from "../components/services/MultiService/UploadMultiServiceSectionTasks";
import UploadMultiService from "../components/services/MultiService/UploadMultiService";

export default function MultiSelection() {
  const data = useTranslation();
  // TODO: change this when I found a good solution for naming problems of other services
  const [multiselectData, setMultiselectData] = useState([// ['Background Removal', 'rb'],
                                                          ['Super Resolution', 'sr'],
                                                          ['Deblurring', 'db'],
                                                          // ['HDR', 'hdr'],
                                                          ['Neural Style Transfer', 'sns'],
                                                          // ['Colorization', 'cl'],
                                                          // ['Refocus', 'rf'],
                                                          ['Face Blurring', 'fb'],
                                                          // ['Deshaking', 'ds']
                                                        ]);
  
  const [selected, setSelected] = useState(
    localStorage.getItem("multiSelect") ? JSON.parse(localStorage.getItem("multiSelect")) : []
  );
  const daynight = useSelector((state) => state.data.dayNight);
  const cookiesModal = useSelector((state) => state.data.cookiesModal);
  const [show, setShow] = useState(false);
  const [uploadShow, setUploadShow] = useState(false);

  const handleSelect = (card) => {
    if (selected.includes(card)) {
      setSelected(selected.filter((item) => item !== card));
      
      if (selected.length < 2) {
        // console.log("the length is: " + selected.length);
        setUploadShow(false);  
      }
      
    } else {
      if (selected.length > 2) {
        setShow(true);
        setTimeout(() => {
          setShow(false);
        }, 2000);
      } else {
        setSelected([...selected, card]);
      }

      setUploadShow(true);
    }
  };

  useEffect(() => {
    // TODO: check how to do this properly because we would like to save this for later sessions
    // localStorage.setItem("multiSelect", JSON.stringify(selected));
  }, [selected]);

  return (
    <>
      {cookiesModal ? <CookiesModal /> : ""}
      {show ? (
        <Alert
          variant="danger" // warning
          style={{ position: "fixed", left: "50%", transform: "translateX(-50%)", top: "36px", zIndex: "1040" }}
        >
          {data("mutiselection.alert")}
        </Alert>
      ) : (
        ""
      )}

      <div className={`${style.MultiSelection} ${daynight ? style.nightMode : ""}`}>
        {/*
        <div className={style.square}></div>
        <div className={style.smTraingle}></div>
        <div className={style.square} style={{ bottom: "0", left: "0", top: "unset" }}></div>
        <div
          className={style.smTraingle}
          style={{
            top: "0",
            left: "0",
            bottom: "unset",
            right: "unset",
            borderWidth: "40px 40px 0 0",
            borderColor: "#d2c2d0 transparent transparent",
          }}
        ></div>
        */}
        <div className={`container ${style.containerWrapper}`}>
        {/* this shit bootstrap col-lg-12 is for whatever fuck reason responsible if the text is over or underlaying the svg header wave */}
        <div className="col-lg-12 mx-auto"> 
          <div className="text-center">
            <h1 class="mainHeading mb-1">{data("mutiselection.text1")}</h1>
            <div class={style.mainText}>
              {data("mutiselection.text2.text1")} <span>{data("mutiselection.text2.text2")}</span>
            </div>
            {/*<div class="commonText">{data("mutiselection.text3")}</div>*/}
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-8 mx-auto">
              <div className="row">
                {/* TODO: change this to plain implementation without map due to translation and different icon issues */}
                {multiselectData.map((item) => (
                  <div className="col-md-6">
                    <div
                      className={`${style.multiSelectItem} ${selected.includes(item) ? style.selected : ""}`}
                      onClick={() => handleSelect(item)}
                    >
                      <div className="d-flex align-items-center">
                        {selected.includes(item) ? (
                          <i class="fas fa-check-circle"></i>
                        ) : (
                          <div className={style.checkbox}></div>
                        )}

                        <span>{item[0]}</span>
                      </div>
                      {/*
                      <div>
                        <img src="/images/sm-img1.jpg" alt="" className="mr-2" />
                        <img src="/images/sm-img1.jpg" alt="" />
                      </div>
                      */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      
      
      {/*<UploadMultiService data={data} />*/}
      {/* setUploadShow */}
      {selected.length > 0 ? (
        <>
          {/*<MultiSelectionUploadSection data={data} services={selected} />*/}
        </>
      ) : (
        ""
      )}

      {selected.length > 0 ? (
        <>
          <UploadMultiServiceSectionTasks data={data} services={selected} />
        </>
      ) : (
        ""
      )}
      </>
  );
}
