import { createStore, applyMiddleware, combineReducers } from "redux";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import logger from "redux-logger";
import thunk from "redux-thunk";

import reducer from "./reducer/index";

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers(reducer);

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer,
  applyMiddleware(thunk, logger));

// export  function configureStore(initialState) {
//   var store = createStore(
//     persistedReducer,
//     initialState,
//     applyMiddleware(thunk, logger)
//   );

//   return store;
// }
export const persistor = persistStore(store);
