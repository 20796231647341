import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from './elements/utils/SectionProps';
import SectionHeader from './elements/partials/SectionHeader';
import Image from './elements/Image';
import { useSelector } from "react-redux";

// import "./elements/assets/scss/style.scss";
import style from "../styles/featureTilesSection.module.css";

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const d = props.data;
  const daynight = useSelector((state) => state.data.dayNight);

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Build up the whole picture',
    paragraph: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum — semper quis lectus nulla at volutpat diam ut venenatis.'
  };

  return (
    <section
      // TODO: check if comments for ...props are ok 04.01.22
      // {...props}
      // className={outerClasses}
      className={`${outerClasses} ${style.gallerySection} ${
        daynight ? style.nightMode : ""
      }`}
    >
      <div className="container">
        <div className={innerClasses}>
          <h2 className={`${style.subHeading} subHeading center-content`}>{d("featuresTiles.title")}</h2>
          <br></br>
          {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
          <div className={style.commonText}>{d("featuresTiles.description")}</div>
          <br></br>
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <img src="/template/feature-tile-icon-01.svg" alt="" />
                    {/* 
                    <Image
                      src={require('./elements/assets/images/feature-tile-icon-01.svg')} // Frontend_Web/web/src/components/elements/assets/images/feature-tile-icon-01.svg
                      alt="Features tile icon 01"
                      width={64}
                      height={64} />
                    */}
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  {/* 
                  <h4 className="mt-0 mb-8">
                    Robust Workflow
                  </h4>
                  */}
                  <div className={style.commonTextHeader}>{d("featuresTiles.sections.sections1")}</div>
                  {/* 
                  <p className="m-0 text-sm">
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat.
                  </p>
                  */}
                  <div className={style.commonText}>{d("featuresTiles.sectionssContent.sections1.text1")}</div>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                  <img src="/template/feature-tile-icon-02.svg" alt="" />
                    {/*
                    <Image
                      src={require('./elements/assets/images/feature-tile-icon-02.svg')}
                      alt="Features tile icon 02"
                      width={64}
                      height={64} />
                    */}
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  {/* 
                  <h4 className="mt-0 mb-8">
                    Robust Workflow
                  </h4>
                  */}
                  <div className={style.commonTextHeader}>{d("featuresTiles.sections.sections2")}</div>
                  {/* 
                  <p className="m-0 text-sm">
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat.
                  </p>
                  */}
                  <div className={style.commonText}>{d("featuresTiles.sectionssContent.sections2.text1")}</div>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <img src="/template/feature-tile-icon-03.svg" alt="" />
                    {/*
                    <Image
                      src={require('./elements/assets/images/feature-tile-icon-03.svg')}
                      alt="Features tile icon 03"
                      width={64}
                      height={64} />
                    */}
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  {/* 
                  <h4 className="mt-0 mb-8">
                    Robust Workflow
                  </h4>
                  */}
                  <div className={style.commonTextHeader}>{d("featuresTiles.sections.sections3")}</div>
                  {/* 
                  <p className="m-0 text-sm">
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat.
                  </p>
                  */}
                  <div className={style.commonText}>{d("featuresTiles.sectionssContent.sections3.text1")}</div>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <img src="/template/feature-tile-icon-04.svg" alt="" />
                    {/*
                    <Image
                      src={require('./elements/assets/images/feature-tile-icon-04.svg')}
                      alt="Features tile icon 04"
                      width={64}
                      height={64} />
                    */}
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  {/* 
                  <h4 className="mt-0 mb-8">
                    Robust Workflow
                  </h4>
                  */}
                  <div className={style.commonTextHeader}>{d("featuresTiles.sections.sections4")}</div>
                  {/* 
                  <p className="m-0 text-sm">
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat.
                  </p>
                  */}
                  <div className={style.commonText}>{d("featuresTiles.sectionssContent.sections4.text1")}</div>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <img src="/template/feature-tile-icon-05.svg" alt="" />                      
                    {/*
                    <Image
                      src={require('./elements/assets/images/feature-tile-icon-05.svg')}
                      alt="Features tile icon 05"
                      width={64}
                      height={64} />
                    */}
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  {/* 
                  <h4 className="mt-0 mb-8">
                    Robust Workflow
                  </h4>
                  */}
                  <div className={style.commonTextHeader}>{d("featuresTiles.sections.sections5")}</div>
                  {/* 
                  <p className="m-0 text-sm">
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat.
                  </p>
                  */}
                  <div className={style.commonText}>{d("featuresTiles.sectionssContent.sections5.text1")}</div>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <img src="/template/feature-tile-icon-06.svg" alt="" />
                    {/*
                    <Image
                      src={require('./elements/assets/images/feature-tile-icon-06.svg')}
                      alt="Features tile icon 06"
                      width={64}
                      height={64} />
                    */}
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  {/* 
                  <h4 className="mt-0 mb-8">
                    Robust Workflow
                  </h4>
                  */}
                  <div className={style.commonTextHeader}>{d("featuresTiles.sections.sections6")}</div>
                  {/* 
                  <p className="m-0 text-sm">
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat.
                  </p>
                  */}
                  <div className={style.commonText}>{d("featuresTiles.sectionssContent.sections6.text1")}</div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;