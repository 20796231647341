import data from "./data.json";
import Jobs from "./components/Jobs";
import { useState } from "react";
import Header from "./components/Header";
import Search from "./components/Search";
import style from "./CareerOptions.css";
import { useSelector } from "react-redux";

import "./index.scss"; // watch out for style element side effects

function CareerOptions() {
  const [filterKeywords, setfilterKeywords] = useState([]);
  const daynight = useSelector((state) => state.data.dayNight);

  // const setSearchKeyword = (data) => {
  //   setfilterKeywords(data);
  // };

  const addFilterKeywords = (data) => {
    if (!filterKeywords.includes(data)) {
      setfilterKeywords([...filterKeywords, data]);
    }
  };

  const deleteKeyword = (data) => {
    const newKeywords = filterKeywords.filter((key) => key !== data);
    setfilterKeywords(newKeywords);
  };

  const clearAll = () => {
    setfilterKeywords([]);
  };

  return (
    <section className={`${style.careerSection} ${daynight ? style.nightMode : ""}`}>
      <div className={style.header}></div>

      {/* <Search setSearchKeyword={setSearchKeyword} /> */}

      {filterKeywords.length > 0 && (
        <Header
          keywords={filterKeywords}
          removeKeywords={deleteKeyword}
          clearAll={clearAll}
        />
      )}
      {/*<br /><br /><br />*/}
      <Jobs
        keywords={filterKeywords}
        data={data}
        setKeywords={addFilterKeywords}
      />
    </section>
  );
}

export default CareerOptions;
