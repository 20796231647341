import React, { useState } from "react";
import style from "../styles/cookiesBox.module.css";
import { useTranslation } from "react-multi-lang";
import { useSelector, useDispatch } from "react-redux";
import { setCookiesModal } from "../redux/action/image";
import CookiesPolicyModal from "./CookiesPolicyModal";
import WaveFooterTopCookies from "./WaveFooterTopCookies";

export default function CookiesModal() {
  const [show, setShow] = useState(false);

  const data = useTranslation();
  const dispatch = useDispatch();
  const daynight = useSelector((state) => state.data.dayNight);

  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };
  return (
    <>
      <WaveFooterTopCookies/>
      <div className={`${style.CookiesModal} ${daynight ? style.nightMode : ""}`}>
        {/* 
        <button className={style.closeBtn} onClick={() => dispatch(setCookiesModal(false))}>
          <i class="fas fa-times-circle"></i>
        </button>
        */}
        <div>
          <div>
            <h3>Cookies 🍪🍪🍪</h3>
            <p class="mb-4 mb-lg-0">{data("cookiesModal.text1")}</p>
          </div>
          <div className="d-flex flex-column">
            <a href="#" onClick={handleShow}>
              {data("cookiesModal.text2")}
            </a>
            <button
              onClick={() => {
                dispatch(setCookiesModal(false));
                localStorage.setItem("acceptedCookies", false);
              }}
              className={`btn btn-primary ${style.btn_primary}`}
            >
              {data("cookiesModal.text3")}
            </button>
          </div>
        </div>
      </div>
      <CookiesPolicyModal show={show} setShow={setShow} />
    </>
  );
}
