
import React, { useState, useEffect } from "react";
import style from "../../styles/heroSection.module.css";
import { Link } from "react-router-dom";
import HeroSectionSlider from "../../components/HeroSectionSlider";
import ServiceSectionSlider from "../../components/ServiceSectionSlider";
import { useSelector } from "react-redux";

export default function HeroSectionHome(props) {
  const d = props.data;
  const daynight = useSelector((state) => state.data.dayNight);

  const [isDesktop, setDesktop] = useState(window.innerWidth > 991);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 991);
  };

  // https://stackoverflow.com/a/60811141
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });


  return (
    <section className={`${style.heroSection} ${daynight ? style.nightMode : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
              {isDesktop ? (
                <div></div>
              ) : (
                <div>
                  <div className={style.mainText}>
                    {d("aboutus.title.text2")}
                    <span>{d("aboutus.title.text3")}</span>
                  </div>
                  <h1 className={style.mainHeading}>{d("aboutus.title.text1")}</h1>
                </div>
              )}
            </div>

          <div className="col-lg-12">
            {isDesktop ? (
                <div>
                <div className={style.mainText}>
                  {d("aboutus.title.text2")}
                  <span>{d("aboutus.title.text3")}</span>
                </div>
                <h1 className={style.mainHeading}>{d("aboutus.title.text1")}</h1>
              </div>
              ) : (
                <div></div>
              )}
          </div>
        </div>
      </div>
    </section>
  );
}
