import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from './elements/utils/SectionProps';
import SectionHeader from './elements/partials/SectionHeader';
import Image from './elements/Image';
import { useSelector } from "react-redux";

// import "./elements/assets/scss/style.scss"; // man muss er nur einmal importieren, mache ich in App.js
import style from "../styles/featureSplitGallerySection.module.css";

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplitGallery = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const [isDesktop, setDesktop] = useState(window.innerWidth > 1200);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 1200);
  };

  // https://stackoverflow.com/a/60811141
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const d = props.data;
  const tab = props.tab;
  const daynight = useSelector((state) => state.data.dayNight);
  
  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Workflow that just works',
    paragraph: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum — semper quis lectus nulla at volutpat diam ut venenatis.'
  };

  return (
    <section
      // TODO: check if comments for ...props are ok 04.01.22
      // {...props}
      // className={outerClasses}
      className={`${outerClasses} ${style.gallerySection} ${
        daynight ? style.nightMode : ""
      }`}
    >
      <div className="container">
        <div className={innerClasses}>
          <div className={splitClasses}>      
          {(tab === "tab1")  && 
            <div>
                {/* if one more element is placed above then the order changes, this is what I wanted lel */}
                <p></p>
                <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                {d("featuresTile.tabs.text1")}
                  </div>
                  <div className={style.commonTextHeader}>{d("featuresTile.tabsContent.tab1.title1")}</div>
                  <div className={style.commonText}>{d("featuresTile.tabsContent.tab1.text1")}</div>
                </div>
                <div>              
                  <img className={style.imgRoundenCorners} src="/demo/features/amber.jpg" alt=""/>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  {d("featuresTile.tabs.text2")}
                  </div>
                  <div className={style.commonTextHeader}>{d("featuresTile.tabsContent.tab1.title2")}</div>
                  <div className={style.commonText}>{d("featuresTile.tabsContent.tab1.text2")}</div>
                </div>
                <div >                
                  <img className={style.imgRoundenCorners} src="/demo/features/mosaic.jpg" alt=""/>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                {d("featuresTile.tabs.text3")}
                  </div>
                  <div className={style.commonTextHeader}>{d("featuresTile.tabsContent.tab1.title3")}</div>
                  <div className={style.commonText}>{d("featuresTile.tabsContent.tab1.text3")}</div>
                </div>
                <div >                
                  <img className={style.imgRoundenCorners} src="/demo/features/amber-mosaic.jpg" alt="" />
                </div>
              </div>
            </div>
          }

          {(tab === "tab2")  && 
            <div>
                <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                {d("featuresTile.tabs.text4")}
                  </div>
                  <div className={style.commonTextHeader}>{d("featuresTile.tabsContent.tab2.title1")}</div>
                  <div className={style.commonText}>{d("featuresTile.tabsContent.tab2.text1")}</div>
                </div>
                <div>              
                  <img className={style.imgRoundenCorners} src="/demo/features/butterfly.png" alt=""/>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  {d("featuresTile.tabs.text5")}
                  </div>
                  <div className={style.commonTextHeader}>{d("featuresTile.tabsContent.tab2.title2")}</div>
                  <div className={style.commonText}>{d("featuresTile.tabsContent.tab2.text2")}</div>
                </div>
                <div >                
                  <img className={style.imgRoundenCorners} src="/demo/features/XypFlMo.png" alt=""/>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                {d("featuresTile.tabs.text6")}
                  </div>
                  <div className={style.commonTextHeader}>{d("featuresTile.tabsContent.tab2.title3")}</div>
                  <div className={style.commonText}>{d("featuresTile.tabsContent.tab2.text3")}</div>
                </div>
                <div >                
                  <img className={style.imgRoundenCorners} src="/demo/features/JqQYGGc.png" alt="" />
                </div>
              </div>
            </div>
          }

          {(tab === "tab3")  && 
            <div>
                {/* if one more element is placed above then the order changes, this is what I want lel */}
                <p></p>
                <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                {d("featuresTile.tabs.text7")}
                  </div>
                  <div className={style.commonTextHeader}>{d("featuresTile.tabsContent.tab3.title1")}</div>
                  <div className={style.commonText}>{d("featuresTile.tabsContent.tab3.text1")}</div>
                </div>
                <div>              
                  <img className={style.imgRoundenCorners} src="/demo/features/test_03_noisy_0750.png" alt=""/>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  {d("featuresTile.tabs.text8")}
                  </div>
                  <div className={style.commonTextHeader}>{d("featuresTile.tabsContent.tab3.title2")}</div>
                  <div className={style.commonText}>{d("featuresTile.tabsContent.tab3.text2")}</div>
                </div>
                <div >                
                  <img className={style.imgRoundenCorners} src="/demo/features/test_03_usrnet_2355.png" alt=""/>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                {d("featuresTile.tabs.text9")}
                  </div>
                  <div className={style.commonTextHeader}>{d("featuresTile.tabsContent.tab3.title3")}</div>
                  <div className={style.commonText}>{d("featuresTile.tabsContent.tab3.text3")}</div>
                </div>
                <div >                
                  <img className={style.imgRoundenCorners} src="/demo/features/screen1noise.png" alt="" />
                </div>
              </div>
            </div>
          }

          {(tab === "tab4")  && 
            <div>          
              {/* if one more element is placed above then the order changes, this is what I want lel */}
              <p></p>
              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                {d("featuresTile.tabs.text10")}
                  </div>
                  <div className={style.commonTextHeader}>{d("featuresTile.tabsContent.tab4.title1")}</div>
                  <div className={style.commonText}>{d("featuresTile.tabsContent.tab4.text1")}</div>
                </div>
                <div>              
                  <img className={style.imgRoundenCorners} src="/demo/features/I2_1.png" alt=""/>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  {d("featuresTile.tabs.text11")}
                  </div>
                  <div className={style.commonTextHeader}>{d("featuresTile.tabsContent.tab4.title2")}</div>
                  <div className={style.commonText}>{d("featuresTile.tabsContent.tab4.text2")}</div>
                </div>
                <div >                
                  <img className={style.imgRoundenCorners} src="/demo/features/I2_slomo_clipped.gif" alt=""/>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                {d("featuresTile.tabs.text12")}
                  </div>
                  <div className={style.commonTextHeader}>{d("featuresTile.tabsContent.tab4.title3")}</div>
                  <div className={style.commonText}>{d("featuresTile.tabsContent.tab4.text3")}</div>
                </div>
                <div >                
                  <img className={style.imgRoundenCorners} src="/demo/features/I0_slomo_clipped.gif" alt="" />
                </div>
              </div>
            </div>
          }

          {(tab === "tab5")  && 
            <div>
                {/* if one more element is placed above then the order changes, this is what I want lel */}
                <p></p>
                <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                {d("featuresTile.tabs.text13")}
                  </div>
                  <div className={style.commonTextHeader}>{d("featuresTile.tabsContent.tab5.title1")}</div>
                  <div className={style.commonText}>{d("featuresTile.tabsContent.tab5.text1")}</div>
                </div>
                <div>              
                  <img className={style.imgRoundenCorners} src="/demo/features/sample_image_6.jpg" alt=""/>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  {d("featuresTile.tabs.text14")}
                  </div>
                  <div className={style.commonTextHeader}>{d("featuresTile.tabsContent.tab5.title2")}</div>
                  <div className={style.commonText}>{d("featuresTile.tabsContent.tab5.text2")}</div>
                </div>
                <div >                
                  <img className={style.imgRoundenCorners} src="/demo/features/sample_pred_6.jpeg" alt=""/>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                {d("featuresTile.tabs.text15")}
                  </div>
                  <div className={style.commonTextHeader}>{d("featuresTile.tabsContent.tab5.title3")}</div>
                  <div className={style.commonText}>{d("featuresTile.tabsContent.tab5.text3")}</div>
                </div>
                <div >                
                  <img className={style.imgRoundenCorners} src="/demo/features/screen2haze.png" alt="" />
                </div>
              </div>
            </div>
          }

          {(tab === "tab6")  && 
            <div>
              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                {d("featuresTile.tabs.text16")}
                  </div>
                  <div className={style.commonTextHeader}>{d("featuresTile.tabsContent.tab6.title1")}</div>
                  <div className={style.commonText}>{d("featuresTile.tabsContent.tab6.text1")}</div>
                </div>
                <div>              
                  <img className={style.imgRoundenCorners} src="/demo/features/hdr1.jpg" alt=""/>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  {d("featuresTile.tabs.text17")}
                  </div>
                  <div className={style.commonTextHeader}>{d("featuresTile.tabsContent.tab6.title2")}</div>
                  <div className={style.commonText}>{d("featuresTile.tabsContent.tab6.text2")}</div>
                </div>
                <div >                
                  <img className={style.imgRoundenCorners} src="/demo/features/hdr2.jpg" alt=""/>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                {d("featuresTile.tabs.text18")}
                  </div>
                  <div className={style.commonTextHeader}>{d("featuresTile.tabsContent.tab6.title3")}</div>
                  <div className={style.commonText}>{d("featuresTile.tabsContent.tab6.text3")}</div>
                </div>
                <div >                
                  <img className={style.imgRoundenCorners} src="/demo/features/hdr3.jpg" alt="" />
                </div>
              </div>
            </div>
          }
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplitGallery.propTypes = propTypes;
FeaturesSplitGallery.defaultProps = defaultProps;

export default FeaturesSplitGallery;