
import React, { useRef, useState, useEffect } from "react";

import style from "../styles/heroSectionHome.module.css";
import { Link } from "react-router-dom";
import StunningQualityModule from "../components/StunningQualityModule";
import { useSelector } from "react-redux";


export default function HeroSection(props) {
  // https://stackoverflow.com/a/60811141
  const d = props.data;
  const daynight = useSelector((state) => state.data.dayNight);
  

  return (
    <section className={`${style.heroSection} ${daynight ? style.nightMode : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
              <div className={style.sliderSiteDesktop}>
                <h1 className={style.mainHeading}>{d("heroSection.title.text1")}</h1>
                <div className={style.mainText}>
                  {d("heroSection.title.text2")}
                  <span>{d("heroSection.title.text3")}</span>
                </div>

                <Link to="/rbg" 
                  onClick={() => null} 
                  className={`btn btn-secondary ${style.buttonAdventure}`} href="/">
                  {d("heroSection.gohere")} <i class="fas fa-arrow-right"></i>
                </Link>
              </div>
          </div>

          <div className="col-lg-6">
            <div className={style.sliderSiteDesktop}> </div>
            <div className={style.imageFrame}>
              <StunningQualityModule data={d}/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
