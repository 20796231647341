import React, { useEffect } from "react";
import {Adsense} from '@ctrl/react-adsense';

import style from "../styles/ad.module.css";
import { useSelector } from "react-redux";

export default function AdBannner() {
  const daynight = useSelector((state) => state.data.dayNight);


  useEffect(() => {
    try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
    catch (e) {
    }
  }, []);

  return (
    <section
      className={`${style.linksSection} ${daynight ? style.nightMode : ""}`}
      style={{ borderBottom: "1px solid #edeff0" }}
    >
      {/*
      <div className="col-lg-12">
        <div className="banner">
          <Adsense
            client="ca-pub-8681409516658455"
            slot="2770708035"
            style={{ display: 'block' }}
            layout="in-article"
            format="fluid"
          />          
        </div>

        <div className="banner">
        <Adsense
          client="ca-pub-8681409516658455"
          slot="2770708035"
        />
        </div>
        
        <div className="banner">
        <Adsense
          client="ca-pub-8681409516658455"
          slot="2770708035"
          style={{ width: 500, height: 300 }}
          format=""
        />
        </div>
        <ins class="adsbygoogle"
          data-ad-client="ca-pub-8681409516658455"
          data-ad-slot="2770708035"
          data-ad-format="auto"
          data-full-width-responsive="true">
        </ins>
      </div>
      */}

        <>
          <ins className="adsbygoogle"
              style={{ display: "block" }}
              data-ad-client="ca-pub-8681409516658455"
              data-ad-slot="2770708035"
              data-ad-format="auto"
              data-full-width-responsive="true">
          </ins>
        </>
    </section>
  );
}
