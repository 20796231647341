import React, { useState, useEffect } from "react";
import style from "../styles/Header.module.css";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDayNight, setEditorData, setLogoutStates } from "../redux/action/image";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import WaveHeaderrBottom from "./WaveHeaderrBottom.js";
import HeaderSub from "./HeaderSub";
import SERVICES from "./services/Services";

// TODO: maybe delete all the dependencies later
import FeatureLink from "./headerSubMenuComponent/components/features/Navigation/Links/Features";
import SubMenu from "./headerSubMenuComponent/SubMenu";
import SubMenuv2 from "./headerSubMenuComponent/SubMenuv2";
import EditorModal from "../pages/imageEditor/components/EditorModal/EditorModal";

export default function Header(props) {
  const d = props.data;
  const dispatch = useDispatch();
  const daynight = useSelector((state) => state.data.dayNight);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const history = useHistory();


  useEffect(() => {
    // console.log("rerender lel");
    window.addEventListener("storage", (e) => console.log(e));
  }, []);

  document.addEventListener("click", (evt) => {
    const flyoutElement = document.getElementById("testNavItem");
    let targetElement = evt.target; // clicked element

    do {
      if (targetElement == flyoutElement) {
        // This is a click inside. Do nothing, just return.
        setDropdownVisible(true);
        return;
      }
      // Go up the DOM
      targetElement = targetElement.parentNode;
    } while (targetElement);

    // This is a click outside.
    setDropdownVisible(false);
  });

  const routeChange = () => {
    let path = `/doing`;
    history.push(path);
  };

  function luckyChange() {
    var serviceArr = [
      SERVICES.NS,
      SERVICES.SR,
      SERVICES.DH,
      SERVICES.DB,
      SERVICES.FI,
      SERVICES.THREEDP,
      SERVICES.RB,
    ];
    // console.log("the luckyRoute: " + luckyRoute  + " and now the key: " + key);
    var luckyRoute = null;

    for (var key in serviceArr) {
      console.log(serviceArr);
      /*
        if(SERVICES[keys] == value)
             console.log(keys);
        console.log(key, value);
        */
    }

    return luckyRoute;
  }

  const onLogOut = async (event) => {
    localStorage.removeItem("user");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");

    dispatch(setLogoutStates())
    history.push("/");
    history.go("/");

  };

  const [show, setShow] = useState(false);
  const [previousEditor, setPreviousEditor] = useState(false);
  const previousState = useSelector((state) => state.data.editorData);

  return (
    <>
      <EditorModal
        show={show}
        setShow={setShow}
        previousEditor={previousEditor}
      />
      {previousState?.annotations && !show && (
        <Button
          onClick={(e) => {
            setShow(true);
            setPreviousEditor(true);
          }}
          className={`${style.editorButton}`}
        >
          <i className="fas fa-edit"></i>
        </Button>
      )}
      <header
        className={`${style.customNavbar} fixed-top ${
          daynight ? style.nightMode : ""
        }`}
      >
        <nav className={`navbar navbar-expand-xl`}>
          <Link
            to="/"
            className={`navbar-brand ${style.navbar_brand}`}
            onClick={(e) => {
              document
                .querySelector(".navbar-collapse")
                .classList.remove("show");
            }}
          >
            {daynight ? (
              <img
                className={`${style.navbarImg}`}
                src="/images/logo-and-icons/logo_new_white.png"
                alt="dioptify"
              />
            ) : (
              <img
                className={`${style.navbarImg}`}
                src="/images/logo-and-icons/logo_new.png"
                alt="dioptify"
              />
            )}
            {/*<img className={`${style.navbarImg}`} src="/images/logo-and-icons/logo.svg" alt="dioptify" />*/}
          </Link>

          {/* the d-xl-none is the reason for responsive rendering... */}
          <button
            className={`navbar-toggler ${style.navbar_toggler} d-xl-none`}
            type="button"
            data-toggle="collapse"
            data-target="#collapsibleNavId"
            aria-controls="collapsibleNavId"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-stream"></i>
          </button>

          <div className="collapse navbar-collapse" id="collapsibleNavId">
            <ul className="navbar-nav text-center text-xl-left ml-0 ml-xl-3">
              {/*
              <li className={`${style.nav_item} nav-item active`}>
                <a className={`nav-link ${style.nav_link}`} href="/">
                  {d("header.link1")}
                </a>
              </li>
              */}
              <li
                className={`${style.nav_item} ${style.elements} nav-item active`}
              >
                {/*
                <Link
                  to="/doing"
                  className={`nav-link ${style.nav_link}`}
                  onClick={(e) => {
                    e.target.closest(".navbar-collapse").classList.remove("show");
                  }}
                >
                */}
                <Link
                  to="/aboutus"
                  className={`nav-link ${style.nav_link}`}
                  onClick={(e) => {
                    e.target
                      .closest(".navbar-collapse")
                      .classList.remove("show");
                  }}
                >
                  {d("header.link5")}
                </Link>
              </li>
              {/*
              <li className={`${style.nav_item} ${style.elements} nav-item active`}>
                <Link
                  to="/explore"
                  className={`nav-link ${style.nav_link}`}
                  onClick={(e) => {
                    e.target.closest(".navbar-collapse").classList.remove("show");
                  }}
                >
                  {d("header.link7")}
                </Link>
              </li>
              */}
              <li className={`${style.nav_item} ${style.elements} nav-item`}>
                <Link
                  to="/tools-api"
                  className={`nav-link ${style.nav_link}`}
                  onClick={(e) => {
                    e.target
                      .closest(".navbar-collapse")
                      .classList.remove("show");
                  }}
                >
                  {d("header.link2")}
                </Link>
              </li>
              <li className={`${style.nav_item} ${style.elements} nav-item`}>
                <Link
                  to="/faqs"
                  className={`nav-link ${style.nav_link}`}
                  onClick={(e) => {
                    e.target
                      .closest(".navbar-collapse")
                      .classList.remove("show");
                  }}
                >
                  {d("header.link10")}
                </Link>
              </li>
              <li className={`${style.nav_item} nav-item`}>
                <Link
                  to="/pricing"
                  className={`nav-link ${style.nav_link}`}
                  onClick={(e) => {
                    e.target
                      .closest(".navbar-collapse")
                      .classList.remove("show");
                  }}
                >
                  {d("header.link3")}{" "}
                  <i class="fa fa-tags" aria-hidden="true"></i>
                </Link>
              </li>
              {/*
              <li className={`${style.nav_item} nav-item`}>
                <a className={`nav-link ${style.nav_link}`} href="/">
                  {d("header.link3")}
                </a>
              </li>
              */}
              {/* 
              <li className={`${style.nav_item} ${style.elements} nav-item`}>
                <Link
                  to="/settings"
                  className={`nav-link ${style.nav_link}`}
                  onClick={(e) => {
                    e.target.closest(".navbar-collapse").classList.remove("show");
                  }}
                >
                  {d("header.link6")}
                </Link>
              </li>
              */}
              {/*
              <li className={`${style.nav_item} ${style.more} nav-item`}>
                <Link
                  to="/settings"
                  className={`nav-link ${style.nav_link}`}
                  onClick={(e) => {
                    e.target.closest(".navbar-collapse").classList.remove("show");
                  }}
                >
                  {d("header.link8")} <i className="fas fa-chevron-down"></i>
                </Link>
              </li>
              */}

              <li
                className={`${style.nav_item} ${style.more} nav-item my-0 my-xl-0`}
              >
                <div
                  className={`dropdown ${style.dropdown}`}
                  style={{ marginRight: ".5rem" }}
                >
                  {/* using lel stayle to create transparent button outline */}
                  <button
                    className={`btn btn-outline-lel ${style.btn}`}
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {d("header.link8")} <i className="fas fa-chevron-down"></i>
                  </button>
                  <div
                    className={`dropdown-menu ${style.dropdownMenu}`}
                    aria-labelledby="dropdownMenuButton"
                  >
                    {/*
                      <a
                        href="/aboutus"
                        className="dropdown-item"
                      >
                        <i className="fab fa-wpexplorer fa-lg"></i>
                        {d("header.link7")}
                      </a>
                      */}

                    <a href="/services" className="dropdown-item">
                      <i className="fas fa-link fa-lg"></i>{" "}
                      {d("header.button3")}
                    </a>
                    <a href="/aboutus" className="dropdown-item">
                      <i className="fas fa-concierge-bell fa-lg"></i>{" "}
                      {d("header.link5")}
                    </a>
                    <a className="dropdown-item" href="/tools-api">
                      <i className="fas fa-cubes fa-lg"></i> {d("header.link2")}
                    </a>

                    <a href="/faqs" className="dropdown-item">
                      <i className="fas fa-question fa-lg"></i>{" "}
                      {d("header.link10")}
                    </a>
                  </div>
                </div>
              </li>

              {/*
              <li className={`${style.nav_item} ${style.more} nav-item`}>                
                  <Link
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className={`nav-link ${style.nav_link}`}
                >
                  <div className={`dropdown ${style.dropdown}`} style={{ marginRight: '-.75rem' }}>
                    {d("header.link8")} <i className="fas fa-chevron-down"></i>
                    <div className={`dropdown-menu ${style.dropdownMenu}`} aria-labelledby="dropdownMenuButton">
                      <a
                        href="/aboutus"
                        className="dropdown-item"
                      >
                        <i className="fas fa-concierge-bell fa-lg"></i>
                        d("header.link5")}
                      </a>
                      <a
                        className="dropdown-item"
                        href="/tools-api"
                
                      >
                        <i className="fas fa-cubes fa-lg"></i>
                        {d("header.link2")}
                      </a>                      
                    </div>
                  </div>
                </Link>
              </li>
              */}

              {/* <FeatureLink className={`${style.more}`}/>*/}

              {/* 
              <li className={`${style.nav_item} nav-item d-xl-none`}>
                <a{d("header.link6")}
                  className={`nav-link ${style.nav_link}`}
                  data-toggle="collapse"
                  href="#collapseExample"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  {d("header.link4")}
                  <i className="fas fa-chevron-down"></i>
                </a>
                <div className="collapse" id="collapseExample">
                  <div className="card card-body border-0 " style={{ backgroundColor: daynight ? "#202020" : "" }}>
                    <div
                      className="d-flex justify-content-between"
                      style={{ width: "100%", maxWidth: "300px", margin: "auto" }}
                    >
                      <div
                        className={style.customDropdownItem}
                        onClick={(e) => {
                          history.push("/multiselection");
                          e.target.closest(".navbar-collapse").classList.remove("show");
                          e.target.closest(".collapse").classList.remove("show");
                        }}
                      >
                        <i className="far fa-user"></i>
                        <p>Multiselection</p>
                      </div>
                      <div className={style.customDropdownItem}>
                        <i className="fas fa-camera"></i>
                        <p>Configuration</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              {/* 
              
              {/*
              <li
                id="testNavItem"
                className={`${style.nav_item} ${style.test_nav_item} nav-item d-none d-xl-inline-block `}
              >
                <a
                  className={`nav-link ${style.nav_link}`}
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    setDropdownVisible(!dropdownVisible);
                  }}
                  // onMouseEnter={() => setDropdownVisible(!dropdownVisible)}
                  // onMouseLeave={() => setDropdownVisible(!dropdownVisible)}
                >
                  {d("header.link4")}
                  <i className="fas fa-chevron-down"></i>
                </a>
              </li>
              */}
            </ul>

            <ul className="navbar-nav text-center text-xl-left ml-auto">
              <li className={`${style.nav_item} nav-item my-0 my-xl-0`}>
                <a
                  className={`nav-link ${style.nav_link}`}
                  onClick={() => {
                    dispatch(setDayNight(!daynight));
                    localStorage.setItem("defaultDayNight", !daynight);
                  }}
                >
                  {/*<i className="fas fa-sun" style={{ color: "var(--blueColor)" }}></i>*/}
                  {/*<i className="fas fa-sun" style={{ color: "#00D100" }}></i>*/}
                  {daynight ? (
                    <i className="fas fa-sun fa-lg"></i>
                  ) : (
                    <i className="fas fa-moon fa-lg"></i>
                  )}
                </a>
              </li>
              <li className={`${style.nav_item} nav-item my-0 my-xl-0`}>
                {/**/}
                <div
                  className={`dropdown ${style.dropdown}`}
                  style={{ marginRight: ".5rem" }}
                >
                  <button
                    className={`btn btn-outline-secondary ${style.btn}`}
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {props.getLanguage === "gr" ? (
                      <>
                        <i className="fa fa-globe fa-lg"></i> de
                      </>
                    ) : props.getLanguage === "fr" ? (
                      <>
                        <i className="fa fa-globe fa-lg"></i> fr
                      </>
                    ) : props.getLanguage === "ch" ? (
                      <>
                        <i className="fa fa-globe fa-lg"></i> ch
                      </>
                    ) : props.getLanguage === "ru" ? (
                      <>
                        <i className="fa fa-globe fa-lg"></i> ru
                      </>
                    ) : props.getLanguage === "ar" ? (
                      <>
                        <i className="fa fa-globe fa-lg"></i> ar
                      </>
                    ) : props.getLanguage === "sp" ? (
                      <>
                        <i className="fa fa-globe fa-lg"></i> sp
                      </>
                    ): (
                      <>
                        <i className="fa fa-globe fa-lg"></i> en
                      </>
                    )}
                  </button>
                  <div
                    className={`dropdown-menu ${style.dropdownMenu}`}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      className="dropdown-item"
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        props.setLanguage("en");
                      }}
                    >
                      <i class="fa fa-language fa-lg"></i> en
                    </a>
                    <a
                      className="dropdown-item"
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        props.setLanguage("gr");
                      }}
                    >
                      <i class="fa fa-language fa-lg"></i> de
                    </a>
                    <a
                      className="dropdown-item"
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        props.setLanguage("fr");
                      }}
                    >
                      <i class="fa fa-language fa-lg"></i> fr
                    </a>
                    <a
                      className="dropdown-item"
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        props.setLanguage("sp");
                      }}
                    >
                      <i class="fa fa-language fa-lg"></i> sp
                    </a>
                    <a
                      className="dropdown-item"
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        props.setLanguage("ch");
                      }}
                    >
                      <i class="fa fa-language fa-lg"></i> ch
                    </a>
                    <a
                      className="dropdown-item"
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        props.setLanguage("ru");
                      }}
                    >
                      <i class="fa fa-language fa-lg"></i> ru
                    </a>
                    <a
                      className="dropdown-item"
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        props.setLanguage("ar");
                      }}
                    >
                      <i class="fa fa-language fa-lg"></i> ar
                    </a>
                  </div>
                </div>
                {/**/}
                {/*
                <div className={`dropdown ${style.dropdown}`}>
                  <a
                    className={`nav-link ${style.nav_link} `}
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {props.getLanguage === "gr" ? (
                      <>
                        {" "}
                        <img src="/images/de.png" alt="" /> gr
                      </>
                    ) : (
                      <>
                        <img src="/images/OIP.png" alt="" /> en
                      </>
                    )}
                  </a>
                  <div
                    className={`dropdown-menu ${style.dropdownMenu} text-center mr-4`}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        props.setLanguage("en");
                      }}
                    >
                      <img src="/images/OIP.png" alt="" /> en
                    </a>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        props.setLanguage("gr");
                      }}
                    >
                      <img src="/images/de.png" alt="" /> gr
                    </a>
                  </div>
                </div>
                */}
              </li>
              <li
                className={`${style.nav_item} ${style.less} nav-item my-1 my-xl-0`}
              >
                <Link
                  to="/services"
                  className={`${style.btn} btn btn-outline-success`}
                  onClick={(e) => {
                    e.target
                      .closest(".navbar-collapse")
                      .classList.remove("show");
                  }}
                >
                  {d("header.button3")}{" "}
                  {/* <i class="fa fa-link" aria-hidden="true"></i> */}
                </Link>
              </li>
              <li className={`${style.nav_item} nav-item my-1 my-xl-0`}>
                {/**/}
                <div className={`dropdown ${style.dropdown}`}>
                  <button
                    className={`btn btn-outline-secondary ${style.btn} btn-outline-success`}
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {d("header.text1")}
                  </button>
                  <div
                    className={`dropdown-menu ${style.dropdownMenu}`}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a href="/services" className="dropdown-item">
                      <i className="fas fa-link fa-lg"></i>{" "}
                      {d("header.button3")}
                    </a>
                    <Link
                      to="/multiselection"
                      className="dropdown-item "
                      onClick={(e) => {
                        e.target
                          .closest(".navbar-collapse")
                          .classList.remove("show");
                      }}
                    >
                      <i class="fa fa-align-center fa-lg"></i>{" "}
                      {d("header.button4")}
                    </Link>
                    <a
                      className="dropdown-item"
                      onClick={(e) => {
                        setShow(true);
                        setPreviousEditor(false);

                        e.target
                          .closest(".navbar-collapse")
                          .classList.remove("show");
                      }}
                    >
                      <i class="fa fa-indent fa-lg"></i> {d("header.text3")}
                    </a>
                  </div>
                </div>
                {/**/}
              </li>
              <li className={`${style.nav_item} nav-item my-1 my-xl-0`}>
                <Link
                  to="/posts?posts"
                  className={`${style.btn} btn btn-outline-success`}
                  onClick={(e) => {
                    e.target
                      .closest(".navbar-collapse")
                      .classList.remove("show");
                  }}
                >
                  {d("posts.title")}
                </Link>
              </li>
              {/*
              <li className={`${style.nav_item} nav-item my-1 my-xl-0`}>
                <Link
                  to="/ns"
                  className={`${style.btn} btn btn-outline-success`}
                  onClick={(e) => {
                    e.target.closest(".navbar-collapse").classList.remove("show");
                    // luckyChange();
                  }}
                >
                  {d("header.button1")}
                </Link>
              </li>
              */}
              {/* to="/auth" */}
              <li className={`${style.nav_item} nav-item my-1 my-xl-0`}>
                {localStorage.getItem("user") ? (
                  <Link
                    to="/user"
                    className={`nav-link ${style.nav_link}`}
                    onClick={(e) => {
                      e.target
                        .closest(".navbar-collapse")
                        .classList.remove("show");
                    }}
                  >
                    <i className="fas fa-user fa-2x"></i>
                  </Link>
                ) : (
                  <Link
                    to="/authentication"
                    className={`${style.btn} btn btn-outline-secondary`}
                    onClick={(e) => {
                      e.target
                        .closest(".navbar-collapse")
                        .classList.remove("show");
                    }}
                  >
                    <i class="fa fa-arrow-circle-right"></i>{" "}
                    {d("header.button2")}
                  </Link>
                )}
              </li>
              <li className={`${style.nav_item} nav-item my-1 my-xl-0`}>
                {localStorage.getItem("user") ? (
                  <Link
                    className={`${style.btn} btn btn-outline-danger`}
                    onClick={(e) => {
                      onLogOut(e);
                    }}
                  >
                    Logout
                  </Link>
                ) : (
                  <></>
                )}
              </li>

              {/*
              <li className={`${style.nav_item} nav-item`}>
                <Link
                  className={`${style.btn} btn btn-outline-secondary`}
                  to="/login"
                  onClick={(e) => {
                    e.target.closest(".navbar-collapse").classList.remove("show");
                  }}
                >
                  {d("header.button2")}
                </Link>
              </li>
              */}
            </ul>
          </div>
        </nav>
      </header>
      {/*
      <div className={`${style.customdropdown} ${dropdownVisible ? style.show : ""} d-none d-xl-block`}>
        <div className="container">
          <div className="row">
            <div className="col-6 mx-auto">
              <div className="d-flex justify-content-between">
                <div
                  className={style.customDropdownItem}
                  onClick={() => {
                    history.push("/multiselection");
                    // setDropdownVisible(!dropdownVisible);
                  }}
                >
                  <i className="far fa-user"></i>
                  <p>Multiselection</p>
                </div>
                <div className={style.customDropdownItem}>
                  <i className="fas fa-camera"></i>
                  <p>Configuration</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      */}
      <div
        className={`${style.overlay} ${dropdownVisible ? style.show : ""}`}
        // onClick={() => setDropdownVisible(!dropdownVisible)}
      ></div>
      {/* <HeaderSub></HeaderSub>*/}
      {/**/}
      <WaveHeaderrBottom />
      {/* */}
    </>
  );
}
