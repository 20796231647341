import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Login from "./LoginNew";
import Signup from "./Signup";
import style from "../styles/auth.module.css";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import CookiesModal from "../components/CookiesModal";


export default function Auth() {
  const data = useTranslation();
  const daynight = useSelector((state) => state.data.dayNight);
  const cookiesModal = useSelector((state) => state.data.cookiesModal);

  const [isDesktop, setDesktop] = useState(window.innerWidth > 991);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 991);
  };

	useEffect(() => {
		document.title = `${data("tab.login")} - dioptify.com`;

    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
	});

  return (
    /*<div class={style.formParent}>*/
    /*<div className={`${style.formParent} ${daynight ? style.nightMode : ""}`}>*/
    <>
    <section className={`${style.formParent} ${daynight ? style.nightMode : ""}`}>
      <div class="container-fluid">
        {/*<div className="col-lg-12 mx-auto">*/}
        <div style={{maxWidth:'1300px', margin:"auto"}}>



          {/*<h2 className={`${style.subHeading} subHeading`}>{data("auth.title")}</h2>*/}
          
          {/*
          <div class={style.oddText}>
            {data("auth.subtitle.text1")}{" "}
            <a href="#">{data("auth.subtitle.text2")}</a>{" "}
            {data("auth.subtitle.text3")}
          </div>
          */}
          
          {/*
          <div class={style.grayBox}>
            <img class={style.colorLogo} src="/images/colorLogo.svg" />
            <div class={style.oddText}>
              {data("auth.description.text1")}{" "}
              <a href="#">{data("auth.description.text2")}</a>{" "}
              {data("auth.description.text3")}
              <a href="#">{data("auth.description.text4")}</a>
            </div>
          </div>
          */}
          <div class="row">
            <div class="col-lg-6 mx-auto">
          {daynight
            ? <img className={`${style.logo}`} src="/images/logo-and-icons/logo_new_white.png" alt="dioptify" />
            : <img className={`${style.logo}`} src="/images/logo-and-icons/logo_new.png" alt="dioptify" />
          }

            {/*<div class={`${style.customFrom} authForm`}>*/}
            
            <div className={`${style.itemsLinks} itemsLinks ${style.nav} nav`} id="myTab" role="tablist">
            <a
              className={`${style.itemLink} active`}
              id="tab-1"
              data-toggle="tab"
              href="#tab1"
              role="tab"
              aria-controls="tab1"
              aria-selected="true"
            >
              {data("auth.login.title")}
            </a>
            <a
              className={style.itemLink}
              id="tab-2"
              data-toggle="tab"
              href="#tab2"
              role="tab"
              aria-controls="tab2"
              aria-selected="false"
            >
              {data("auth.signup.title")}
            </a>
          </div>
          
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab-1">
              <h2 className={`${style.subHeading} subHeading`}>{data("auth.welcome")}</h2>
              <Login />
            </div>
            <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab-2">
              <h2 className={`${style.subHeading} subHeading`}>{data("auth.welcomeBack")}</h2>
              <Signup />
            </div>
          </div>
            
            {/*
            <div>
                <Tabs className="itemLink"  defaultActiveKey="login" id="uncontrolled-tab-example">
                  <Tab className="itemLink" eventKey="login" title={data("auth.login.title")}>
                    <Login />
                  </Tab>
                  <Tab className="itemLink" eventKey="signup" title={data("auth.signup.title")}>
                    <Signup />
                  </Tab>
                </Tabs>
            </div>
            */}

            </div>
              {isDesktop ? (
                <div class="col-lg-6 mx-auto">
                  <div class={style.iceCream}>
                    <div class={style.melt}></div>
                  </div>
                </div>
              ) : (
                <div> </div>
              )}            

          </div>
        </div>
      </div>
    </section>
    {cookiesModal ? <CookiesModal /> : ""}
    </>
    /*</div>*/
    );
}
