import React, { useEffect } from "react";
import ServiceList from '../components/services/ServiceList';
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import CookiesModal from "../components/CookiesModal";

export default function Services() {
  const data = useTranslation();
  const cookiesModal = useSelector((state) => state.data.cookiesModal);

  useEffect(() => {
    document.title = `${data("tab.services")} - dioptify.com`;
  });  

  return (
    <>
      <ServiceList data={data} />
      {cookiesModal ? <CookiesModal /> : ""}
    </>
  );
}
