import React from "react";
import style from "../../../styles/windowsScreenStyle/cardSection.module.css";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";

export default function CardSection() {
  const data = useTranslation();
  const daynight = useSelector((state) => state.data.dayNight);
  return (
    <section class={`${style.cardSection} ${daynight ? style.nightMode : ""}`}>
      <div class="container">
        <div class="row">
          <div class="col-lg-6 mx-auto">
            <div class={style.cardParent}>
              <h2 class="subHeading mb-3">Get Started</h2>
              <div class="row">
                <div class="col-lg-4">
                  <div class={style.getCard}>
                    <div class={style.getCardNumber}>1</div>
                    <div>
                      <a href="#">{data("cardSection.text1.text1")}</a> <br />
                      {data("cardSection.text1.text2")}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class={style.getCard}>
                    <div class={style.getCardNumber}>2</div>
                    <div>
                      <a href="#">{data("cardSection.text2.text1")}</a> <br />
                      {data("cardSection.text2.text2")}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class={style.getCard}>
                    <div class={style.getCardNumber}>3</div>
                    <div>
                      {data("cardSection.text3.text1")}{" "}
                      <a href="#">{data("cardSection.text3.text2")} </a> <br />
                      {data("cardSection.text3.text3")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a href="#" class={style.infoLink}>
              <i class="fas fa-info-circle"></i>
              Installation instructions and help
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
