import React from "react";
import HeroSection from "../../components/servicesDetailsComponents/iiDetailComponent/HeroSection";
import ResultsSection from "../../components/servicesDetailsComponents/iiDetailComponent/ResultsSection";
import MidSection from "../../components/servicesDetailsComponents/iiDetailComponent/MidSection";
import CardSection from "../../components/servicesDetailsComponents/iiDetailComponent/CardSection";
import FAQsection from "../../components/servicesDetailsComponents/iiDetailComponent/FAQsection";
import CookiesModal from "../../components/CookiesModal";
import { useSelector } from "react-redux";

export default function IIDetail() {
  const cookiesModal = useSelector((state) => state.data.cookiesModal);
  return (
    <>
      <HeroSection />
      <ResultsSection />
      {/*<MidSection />*/} {/* TODO: add this back in when redesigned*/}
      <CardSection />
      <FAQsection />
      {cookiesModal ? <CookiesModal /> : ""}
    </>
  );
}
