import React, { useEffect, useState } from 'react';
// import '../App.css';
import Posts from '../components/posts/posts';
import VideoPosts from '../components/posts/videoposts';
import MultiPosts from '../components/posts/multiposts';
import EditorPosts from '../components/posts/editorposts';
import PostLoadingComponent from '../components/posts/postLoading';
import axiosInstance from '../axios';
import baseURL from '../config';
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import { useLocation, useHistory } from "react-router-dom";
import CookiesModal from "../components/CookiesModal";

import style from "../styles/postOverview.module.css";

function useQuery() {
	// return new URLSearchParams(useLocation().search);
	// console.log("useLocation().search: ", useLocation().search);
	// return new URLSearchParams({"clientId": "clientId"}).toString();

	return new URLSearchParams(useLocation().search).toString();
}

function PostOverview() {
	const daynight = useSelector((state) => state.data.dayNight);
	const d = useTranslation();
	const history = useHistory();
	const cookiesModal = useSelector((state) => state.data.cookiesModal);

	const PostLoading = PostLoadingComponent(Posts);
	const VideoPostLoading = PostLoadingComponent(VideoPosts);
	const MultiPostLoading = PostLoadingComponent(MultiPosts);
	const EditorPostLoading = PostLoadingComponent(EditorPosts);

	// images
	const [appState, setAppState] = useState({
		loading: true,
		posts: null,
	});

	const deletePost = (posts) => {
		setAppState({ loading: false, posts: posts });
	}

	// videos
	const [appVideoState, setAppVideoState] = useState({
		loading: true,
		videosposts: null,
	});

	// multiposts
	const [appMultiState, setAppMultiState] = useState({
		loading: true,
		multiposts: null,
	});

	// multiposts
	const [appEditorState, setAppEditorState] = useState({
		loading: true,
		editorposts: null,
	});

	const generativeEditorServices = ["pe", "ii", "sd"];


	useEffect(() => {
		document.title = `${d("tab.uploads")} - dioptify.com`;
	});

	useEffect(() => {
		let formData = new FormData();
		if (localStorage.getItem('user') !== null) {
			formData.append('owner', localStorage.getItem('user'));
		} else {
			formData.append('owner', localStorage.getItem('temp_user'));
		}

		// formData.append('owner', localStorage.getItem('user'));

		// images
		axiosInstance.post(`userlist`, formData).then((res) => {
			// console.log("----> sind in useEffect von den userlist lel", res.data);
			// console.log("----> process.env.REACT_APP_BASE_URL", process.env.REACT_APP_BASE_URL);

			for (var posts of res.data.posts) {
				posts.image = posts.image ? baseURL + posts.image : '/images/no-picture-available.jpg';
				posts.original_image = posts.original_image ? baseURL + posts.original_image : '/images/no-picture-available.jpg';

			}

			const allPosts = res.data.posts;
			// console.log(res.data.posts);
			setAppState({ loading: false, posts: allPosts });
		})
			.catch((err) => {
				// console.log("Unable to get at the moment: " + err);
			});

		// videos
		axiosInstance.post(`uservideolist`, formData).then((res) => {
			// console.log("----> sind in useEffect von den uservideolist lel", res.data);

			for (var posts of res.data.posts) {
				posts.file = baseURL + posts.file;
			}

			const allVideoPosts = res.data.posts;
			// console.log(res.data.posts);
			setAppVideoState({ loading: false, videosposts: allVideoPosts });
		})
			.catch((err) => {
				console.log("Unable to get at the moment: " + err);
			});

		// multiserviceimages
		/**/
		axiosInstance.post(`multiserviceuserlist`, formData).then((res) => {
			// console.log("----> sind in useEffect von den multiserviceuserlist lel", res.data.posts);

			for (var posts of res.data.posts) {
				posts.image = baseURL + posts.image;
			}

			const allMultiPosts = res.data.posts;
			// console.log(res.data.posts);
			setAppMultiState({ loading: false, multiposts: allMultiPosts });
		})
			.catch((err) => {
				// console.log("Unable to get at the moment: " + err);
			});
		/**/

		// editorserviceimages
		/**/
		axiosInstance.post(`editorerviceuserlist`, formData).then((res) => {
			// console.log("----> sind in useEffect von den multiserviceuserlist lel", res.data.posts);

			for (var posts of res.data.posts) {
				posts.image = baseURL + posts.image;
			}

			const allMultiPosts = res.data.posts;
			// console.log(res.data.posts);
			setAppEditorState({ loading: false, editorposts: allMultiPosts });
		})
			.catch((err) => {
				// console.log("Unable to get at the moment: " + err);
			});
		/**/

		// get whole list of all uploaded images
		/*
		axiosInstance.get().then((res) => {
			const allPosts = res.data;
			console.log(res.data);
			setAppState({ loading: false, posts: allPosts });
			console.log(res.data);
		});
		*/
	}, [setAppState]);

	// https://reactjs.org/docs/conditional-rendering.html
	// https://blog.logrocket.com/conditional-rendering-in-react-c6b0e5af381e/
	// https://betterprogramming.pub/using-url-parameters-and-query-strings-with-react-router-fffdcea7a8e9
	// let query = useQuery() === "posts=";
	let query = useQuery();

	// TODO: check if this is the best behaviour
	const testButton = (event) => {
		console.log("testButton", event);
		history.push({
			pathname: '/posts',
			search: '?posts',
		})
	};

	const testButtonv2 = (event) => {
		console.log("testButtonv2", event);
		history.push({
			pathname: '/posts',
			search: '?videoposts',
		})
	};

	const testButtonv3 = (event) => {
		console.log("testButtonv3", event);
		history.push({
			pathname: '/posts',
			search: '?multiposts',
		})
	};

	const testButtonv4 = (event) => {
		history.push({
			pathname: '/posts',
			search: '?generativeEditor',
		})
	};

	console.log(appState.posts)


	return (
		<>
			<section className={`${style.teamSection} ${daynight ? style.nightMode : ""}`}>
				<div className="container">
					<div className="col-lg-12 mx-auto">
						<h2 className={`mainHeading`}>
							{d("posts.title")}
						</h2>

						<div class="row">
							<div class="col-lg-12 mx-auto">

								{/* TODO: find a better and cleaner way for this logic here...*/}
								{(query === "posts=")
									?
									<>
										<div className={`${style.itemsLinks} itemsLinks ${style.nav} nav`} id="myTab" role="tablist">
											<a
												className={`${style.itemLink} active`}
												id="tab-1"
												data-toggle="tab"
												href="#tab1"
												role="tab"
												aria-controls="tab1"
												aria-selected="true"
											>
												{d("posts.images")}
											</a>
											{/*
									<a
										className={style.itemLink}
										id="tab-2"
										data-toggle="tab"
										href="#tab2"
										role="tab"
										aria-controls="tab2"
										aria-selected="false"
										onClick={(e) => {
											testButtonv2(e);
										}}
										>
										{d("posts.videos")}
									</a>
									*/}
											<a
												className={style.itemLink}
												id="tab-3"
												data-toggle="tab"
												href="#tab3"
												role="tab"
												aria-controls="tab3"
												aria-selected="false"
												onClick={(e) => {
													testButtonv3(e);
												}}
											>
												{d("posts.multiimages")}
											</a>
											<a
												className={style.itemLink}
												id="tab-4"
												data-toggle="tab"
												href="#tab4"
												role="tab"
												aria-controls="tab4"
												aria-selected="false"
												onClick={(e) => {
													testButtonv4(e);
												}}
											>
												{d("posts.generativeEditor")}
										</a>
										</div>

										<div className="tab-content" id="myTabContent">
											<div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab-1">
												<div className={`mt-5`}>
													<div className="PostOverview">
														<PostLoading isLoading={appState.loading} deletePost={deletePost} posts={appState.posts} data={d} />
													</div>
												</div>
											</div>
											<div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab-2">
												<div className={`mt-5`}>
													<div className="PostOverview">
														<VideoPostLoading isLoading={appVideoState.loading} posts={appVideoState.videosposts} data={d} />
													</div>
												</div>
											</div>

										</div>
									</>
									:
									<>

									</>
								}

								{/*
						{(query === "videoposts=")
		                    ?
							<>
								<div className={`${style.itemsLinks} itemsLinks ${style.nav} nav`} id="myTab" role="tablist">
									<a
										className={`${style.itemLink}`}
										id="tab-1"
										data-toggle="tab"
										href="#tab1"
										role="tab"
										aria-controls="tab1"
										aria-selected="false"
										onClick={(e) => {
											testButton(e);
										}}
										>
										{d("posts.images")}
									</a>
									<a
										className={`${style.itemLink} active`}
										id="tab-2"
										data-toggle="tab"
										href="#tab2"
										role="tab"
										aria-controls="tab2"
										aria-selected="false"
										>
										{d("posts.videos")}
									</a>
									<a
										className={style.itemLink}
										id="tab-3"
										data-toggle="tab"
										href="#tab3"
										role="tab"
										aria-controls="tab3"
										aria-selected="false"
										onClick={(e) => {
											testButtonv3(e);
										}}
										>
										{d("posts.multiimages")}
									</a>									
								</div>

								<div className="tab-content" id="myTabContent">
									<div className="tab-pane fade" id="tab1" role="tabpanel" aria-labelledby="tab-1">
										<div className={`mt-5`}>
											<div className="PostOverview">
												<PostLoading isLoading={appState.loading} posts={appState.posts} data={d}/>
											</div>
										</div>
									</div>
									<div className="tab-pane fade show active" id="tab2" role="tabpanel" aria-labelledby="tab-2">
										<div className={`mt-5`}>
											<div className="PostOverview">
												<VideoPostLoading isLoading={appVideoState.loading} posts={appVideoState.videosposts} data={d}/>
											</div>
										</div>
									</div>
								</div>
							</>
							:
							<>
							</>
						}
						*/}

								{(query === "multiposts=")
									?
									<>
										<div className={`${style.itemsLinks} itemsLinks ${style.nav} nav`} id="myTab" role="tablist">
											<a
												className={`${style.itemLink}`}
												id="tab-1"
												data-toggle="tab"
												href="#tab1"
												role="tab"
												aria-controls="tab1"
												aria-selected="false"
												onClick={(e) => {
													testButton(e);
												}}
											>
												{d("posts.images")}
											</a>
											{/*
									<a
										className={style.itemLink}
										id="tab-2"
										data-toggle="tab"
										href="#tab2"
										role="tab"
										aria-controls="tab2"
										aria-selected="false"
										onClick={(e) => {
											testButtonv2(e);
										}}
										>
										{d("posts.videos")}
									</a>
									*/}
											<a
												className={`${style.itemLink} active`}
												id="tab-3"
												data-toggle="tab"
												href="#tab3"
												role="tab"
												aria-controls="tab3"
												aria-selected="true"
												onClick={(e) => {
													testButtonv3(e);
												}}
											>
												{d("posts.multiimages")}
											</a>
											<a
												className={`${style.itemLink}`}
												id="tab-4"
												data-toggle="tab"
												href="#tab4"
												role="tab"
												aria-controls="tab4"
												aria-selected="true"
												onClick={(e) => {
													testButtonv4(e);
												}}
											>
												{d("posts.generativeEditor")}
											</a>
										</div>

										<div className="tab-content" id="myTabContent">
											<div className="tab-pane fade" id="tab1" role="tabpanel" aria-labelledby="tab-1">
												<div className={`mt-5`}>
													<div className="PostOverview">
														<PostLoading isLoading={appState.loading} posts={appState.posts} data={d} />
													</div>
												</div>
											</div>
											{/*
									<div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab-2">
										<div className={`mt-5`}>
											<div className="PostOverview">
												<VideoPostLoading isLoading={appVideoState.loading} posts={appVideoState.videosposts} data={d}/>
											</div>
										</div>
									</div>
									*/}
											<div className="tab-pane fade show active" id="tab2" role="tabpanel" aria-labelledby="tab-2">
												<div className={`mt-5`}>
													<div className="PostOverview">
														<MultiPostLoading isLoading={appMultiState.loading} posts={appMultiState.multiposts} data={d} />
													</div>
												</div>
											</div>
										
										</div>
									</>
									:
									<>
									</>
								}

								
								{(query === "generativeEditor=")
									?
									<>
										<div className={`${style.itemsLinks} itemsLinks ${style.nav} nav`} id="myTab" role="tablist">
											<a
												className={`${style.itemLink}`}
												id="tab-1"
												data-toggle="tab"
												href="#tab1"
												role="tab"
												aria-controls="tab1"
												aria-selected="false"
												onClick={(e) => {
													testButton(e);
												}}
											>
												{d("posts.images")}
											</a>
											{/*
									<a
										className={style.itemLink}
										id="tab-2"
										data-toggle="tab"
										href="#tab2"
										role="tab"
										aria-controls="tab2"
										aria-selected="false"
										onClick={(e) => {
											testButtonv2(e);
										}}
										>
										{d("posts.videos")}
									</a>
									*/}
											<a
												className={`${style.itemLink}`}
												id="tab-3"
												data-toggle="tab"
												href="#tab3"
												role="tab"
												aria-controls="tab3"
												aria-selected="true"
												onClick={(e) => {
													testButtonv3(e);
												}}
											>
												{d("posts.multiimages")}
											</a>
											<a
												className={`${style.itemLink} active`}
												id="tab-4"
												data-toggle="tab"
												href="#tab4"
												role="tab"
												aria-controls="tab4"
												aria-selected="true"
												onClick={(e) => {
													testButtonv4(e);
												}}
											>
												{d("posts.generativeEditor")}
											</a>
										</div>

										<div className="tab-content" id="myTabContent">
											<div className="tab-pane fade" id="tab1" role="tabpanel" aria-labelledby="tab-1">
												<div className={`mt-5`}>
													<div className="PostOverview">
														<PostLoading isLoading={appState.loading} posts={appState.posts} data={d} />
													</div>
												</div>
											</div>
											{/*
									<div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab-2">
										<div className={`mt-5`}>
											<div className="PostOverview">
												<VideoPostLoading isLoading={appVideoState.loading} posts={appVideoState.videosposts} data={d}/>
											</div>
										</div>
									</div>
									*/}
											<div className="tab-pane fade show fade" id="tab2" role="tabpanel" aria-labelledby="tab-2">
												<div className={`mt-5`}>
													<div className="PostOverview">
														<MultiPostLoading isLoading={appMultiState.loading} posts={appMultiState.multiposts} data={d} />
													</div>
												</div>
											</div>
											<div className="tab-pane fade show active" id="tab4" role="tabpanel" aria-labelledby="tab-4">
												<div className={`mt-5`}>
													<div className="PostOverview">
														<EditorPostLoading isLoading={appEditorState.loading} posts={appEditorState.editorposts} data={d} />
														{/* <MultiPostLoading isLoading={appMultiState.loading} posts={appMultiState.multiposts} data={d} /> */}
													</div>
												</div>
											</div>
										</div>
									</>
									:
									<>
									</>
								}

								{/* */}



								{/* LATEST METHOD (20.05.23) */}
								{/*
						<div className={`mt-5`}>
							<div className="PostOverview">
								<PostLoading isLoading={appState.loading} posts={appState.posts} data={d}/>
							</div>
						</div>
						*/}

								{/*old method*/}
								{/*
							<div className={`${style.itemsLinks} itemsLinks ${style.nav} nav`} id="myTab" role="tablist">
								<a
									className={`${style.itemLink} active`}
									id="tab-1"
									data-toggle="tab"
									href="#tab1"
									role="tab"
									aria-controls="tab1"
									aria-selected="true"
									>
									{d("posts.images")}
								</a>
								<a
									className={style.itemLink}
									id="tab-2"
									data-toggle="tab"
									href="#tab2"
									role="tab"
									aria-controls="tab2"
									aria-selected="false"
									>
									{d("posts.videos")}
								</a>
							</div>

							<div className="tab-content" id="myTabContent">
								<div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab-1">
									<div className={`mt-5`}>
										<div className="PostOverview">
											<PostLoading isLoading={appState.loading} posts={appState.posts} data={d}/>
										</div>
									</div>
								</div>
								<div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab-2">
									<div className={`mt-5`}>
										<div className="PostOverview">
											<VideoPostLoading isLoading={appVideoState.loading} posts={appVideoState.videosposts} data={d}/>
										</div>
									</div>
								</div>
							</div>
						*/}
							</div>
						</div>
					</div>
				</div>
			</section>
			{cookiesModal ? <CookiesModal /> : ""}
		</>
	);
}

export default PostOverview;
