import React from 'react';
import Loader from "../../components/loaderComponent/Loader";

function PostLoading(Component) {
	return function PostLoadingComponent({ isLoading, ...props }) {
		const d = props.data;
		
		if (!isLoading) return <Component {...props} />;
		
		return (
			<h2 style={{ fontSize: '25px' }}>
				{d("posts.loading")}
				<Loader />
			</h2>
		);
	};
}
export default PostLoading;
