import React, { useState } from 'react';
import axiosInstance from '../../axios';

export default function ImageRating(props) {
    const [rating, setRating] = useState(null);

    function updatePostRating(slug, newRating) {
        axiosInstance.patch(`/post/${slug}/rating/`, { rating: newRating })
            .then(response => {
                console.log("Rating updated successfully");
            })
            .catch(error => {
                console.error("Error updating rating:", error);
            });

        setRating('up')
        if (newRating === -1)
        {
            setRating('down')    
        }
    }

    return (
        <div>
            <div>
                <button
                    className="btn btn-secondary"
                    onClick={() => updatePostRating(props.slug, 1)}
                    style={rating === 'up' ? { color: 'green' } : {}}
                >
                    +1 <i className="fas fa-thumbs-up"></i>
                </button>
                <br /><br />
                <button
                    className="btn btn-secondary"
                    onClick={() => updatePostRating(props.slug, -1)}
                    style={rating === 'down' ? { color: 'red' } : {}}
                >
                    -1 <i className="fas fa-thumbs-down"></i>
                </button>
            </div>
            {rating && (
                <p> ➡️ {rating === 'up' ? 
                    <i className="fas fa-thumbs-up"></i>
                    :
                    <i className="fas fa-thumbs-down"></i>}!
                </p>
            )}
        </div>
    );
}
