import ReactPaginate from 'react-paginate';

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from '../../axios';
import { useHistory, useParams } from 'react-router-dom';
import { saveAs } from "file-saver";

const useStyles = makeStyles((theme) => ({
	cardMedia: {
		paddingTop: '56.25%', // 16:9
		borderRadius: '15px',
		margin: '10px 10px 10px 10px'
	},
	link: {
		margin: theme.spacing(1, 1.5),
	},
	cardHeader: {
		backgroundColor:
			theme.palette.type === 'light'
				? theme.palette.grey[200]
				: theme.palette.grey[700],
	},
	postTitle: {
		fontSize: '18px',
		textAlign: 'left',
	},
	postText: {
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'baseline',
		fontSize: '12px',
		textAlign: 'left',
		marginBottom: theme.spacing(2),
		color: "rgba(150, 150, 150, 1.0)"
	},
	postTextNight: {
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'baseline',
		fontSize: '12px',
		textAlign: 'left',
		marginBottom: theme.spacing(2),
		color: "rgba(120, 120, 120, 1.0)"
	},
	card: {
		backgroundColor: "rgba(30, 30, 30, 1.0)",
		color: "rgba(220, 220, 220, 1.0)"
	},
	cardNight: {
		backgroundColor: "rgba(245, 245, 245, 1.0)",
	}
}));


let items = [...Array(30).keys()];

/*
    <div className="items">
      {currentItems &&
        currentItems.map((item) => (
          <div>
            <h3>Item #{item.title}</h3>
          </div>
        ))}
    </div>
*/

function Items({ currentItems }) {
  
	const classes = useStyles();
	const d = useTranslation();
	const daynight = useSelector((state) => state.data.dayNight);
	const history = useHistory();

	const handleDelete = (e, post) => {
		e.preventDefault();
		axiosInstance
			.delete('admin/delete/' + post.id)
			.catch(function (error) {
				if (error.response) {
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				}
			})
			.then(function () {
				history.push({
					pathname: '/posts',
					search: '?posts',
				})
				window.location.reload();
			});
	};

	const saveFile = (e, post) => {
		e.preventDefault();
		var filename = post.image.substring(post.image.lastIndexOf('/')+1);
		
		saveAs(
			post.image,
			filename
		);
	};

return (
	<>
			{currentItems &&
				currentItems.map((post) => (
					<>
					<Grid item key={post.id} xs={12} md={6}>
						<Card className={`${daynight ? classes.card : classes.cardNight}`}>
							<Link
								color="textPrimary"
								href={'post/' + post.slug}
								className={classes.link}
							>
								<CardMedia
									className={classes.cardMedia}
									image={post.image}
									title="Image title"
								/>
							</Link>
							<CardContent className={classes.cardContent}>
								<Typography
									gutterBottom
									variant="h6"
									component="h2"
									className={classes.postTitle}
								>
									{d("posts.postTitleImg")}: {post.title.substr(0, 30)}...
								</Typography>
								<div className={`${daynight ? classes.postText : classes.postTextNight}`}>
									<Typography>
										{d("posts.postTitlePaid")}: {String(post.paid)}
										<br />
										{d("posts.postTitleService")}: {post.service}
										<br />
										{d("posts.postTitlePublished")}: {post.published.substr(0, 10)}
									</Typography>
								</div>
								<a
									className={`btn btn-outline-success`}
									onClick={(e) => {
										saveFile(e, post);
									}}
								>
									{d("posts.postDownload")}
									<>&nbsp;</>
									<i className="fas fa-cloud-download-alt"></i>
								</a>
								<>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</>
								<a
									className={`btn btn-outline-danger`}
									onClick={(e) => {
										handleDelete(e, post);
									}}
								>
									{d("posts.postDelete")}
								</a>
							</CardContent>
						</Card>
					</Grid>
					<br/>
					</>
				))}
	</>
  );
}

function PaginatedItems({ itemsPerPage, posts, data }) {
	// We start with an empty list of items.
	const [currentItems, setCurrentItems] = useState(null);
	const [pageCount, setPageCount] = useState(0);
	// Here we use item offsets; we could also use page offsets
	// following the API or data you're working with.
	const [itemOffset, setItemOffset] = useState(0);
  
	useEffect(() => {
	  // Fetch items from another resources.
	  const endOffset = itemOffset + itemsPerPage;
	  console.log(`Loading items from ${itemOffset} to ${endOffset}`);

	  items = posts

	  setCurrentItems(items.slice(itemOffset, endOffset));
	  setPageCount(Math.ceil(items.length / itemsPerPage));
	}, [itemOffset, itemsPerPage]);
  
	// Invoke when user click to request another page.
	const handlePageClick = (event) => {
	  const newOffset = (event.selected * itemsPerPage) % items.length;
	  console.log(
		`User requested page number ${event.selected}, which is offset ${newOffset}`
	  );
	  setItemOffset(newOffset);
	};
  
	return (
	  <>
		<Items currentItems={currentItems} />
		<ReactPaginate
		  nextLabel={data("posts.next")}
		  onPageChange={handlePageClick}
		  pageRangeDisplayed={3}
		  marginPagesDisplayed={2}
		  pageCount={pageCount}
		  previousLabel={data("posts.previous")}
		  pageClassName="page-item"
		  pageLinkClassName="page-link"
		  previousClassName="page-item"
		  previousLinkClassName="page-link"
		  nextClassName="page-item"
		  nextLinkClassName="page-link"
		  breakLabel="..."
		  breakClassName="page-item"
		  breakLinkClassName="page-link"
		  containerClassName="pagination"
		  activeClassName="active"
		  renderOnZeroPageCount={null}
		/>
	  </>
	);
  }
  


const PostsDebug = (props) => {
	const { posts } = props;
	const classes = useStyles();
	const d = useTranslation();
	const daynight = useSelector((state) => state.data.dayNight);
	const history = useHistory();

	const handleDelete = (e, post) => {
		e.preventDefault();
		axiosInstance
			.delete('admin/delete/' + post.id)
			.catch(function (error) {
				if (error.response) {
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				}
			})
			.then(function () {
				history.push({
					pathname: '/posts',
					search: '?posts',
				})
				window.location.reload();
			});
	};

	const saveFile = (e, post) => {
		e.preventDefault();
		var filename = post.image.substring(post.image.lastIndexOf('/')+1);
		
		saveAs(
			post.image,
			filename
		);
	};

	if (!posts || posts.length === 0) return (
		<p>{d("posts.missing")}<a href="/services">{d("posts.muploads")}</a></p>
	);

	return (
		<React.Fragment>
			<Container maxWidth="md" component="main">
				<Grid container spacing={5} alignItems="flex-end">
				{/*
					{posts.map((post) => {
						return (
							// Enterprise card is full width at sm breakpoint
							<Grid item key={post.id} xs={12} md={6}>
								<Card className={`${daynight ? classes.card : classes.cardNight}`}>
									<Link
										color="textPrimary"
										href={'post/' + post.slug}
										className={classes.link}
									>
										<CardMedia
											className={classes.cardMedia}
											image={post.image}
											title="Image title"
										/>
									</Link>
									<CardContent className={classes.cardContent}>
										<Typography
											gutterBottom
											variant="h6"
											component="h2"
											className={classes.postTitle}
										>
											{d("posts.postTitleImg")}: {post.title.substr(0, 30)}...
										</Typography>
										<div className={`${daynight ? classes.postText : classes.postTextNight}`}>
											<Typography>
												{d("posts.postTitlePaid")}: {String(post.paid)}
												<br />
												{d("posts.postTitleService")}: {post.service}
												<br />
												{d("posts.postTitlePublished")}: {post.published.substr(0, 10)}
											</Typography>
										</div>
										<a
											className={`btn btn-outline-success`}
											onClick={(e) => {
												saveFile(e, post);
											}}
										>
											{d("posts.postDownload")}
											<>&nbsp;</>
											<i className="fas fa-cloud-download-alt"></i>
										</a>
										<>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</>
										<a
											className={`btn btn-outline-danger`}
											onClick={(e) => {
												handleDelete(e, post);
											}}
										>
											{d("posts.postDelete")}
										</a>
									</CardContent>
								</Card>
							</Grid>
						);
					})}
				*/}
				<PaginatedItems itemsPerPage={5} posts={posts} data={d}/>
				</Grid>
			</Container>
			

		</React.Fragment>
	);
};
export default PostsDebug;
