import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";

import HeroSection from "../../components/servicesDetailsComponents/fbDetailComponent/HeroSection";
import ResultsSection from "../../components/servicesDetailsComponents/fbDetailComponent/ResultsSection";
import MidSection from "../../components/servicesDetailsComponents/fbDetailComponent/MidSection";
import CardSection from "../../components/servicesDetailsComponents/fbDetailComponent/CardSection";
import FAQsection from "../../components/servicesDetailsComponents/fbDetailComponent/FAQsection";
import CookiesModal from "../../components/CookiesModal";

export default function FBDetail() {
  const data = useTranslation();
  const cookiesModal = useSelector((state) => state.data.cookiesModal);

  useEffect(() => {
    document.title = `${data("serviceInfo.rb.tabtitle")} - dioptify.com`;
  });  

  return (
    <>
      <HeroSection data={data}/>
      <ResultsSection data={data}/>
      <MidSection data={data}/>
      {/* <CardSection data={data}/> */}
      <FAQsection data={data}/>
      {cookiesModal ? <CookiesModal /> : ""}
    </>
  );
}
