import React, { useEffect, useState } from "react";
import style from "../styles/cookies.module.css";
import CookiesTable from "../components/CookiesTable";
import data from "../data/cookiesTableData.json";
import { useSelector } from "react-redux";
import CookiesModal from "../components/CookiesModal";
import CookiesPolicyModal from "../components/CookiesPolicyModal";
import { useTranslation } from "react-multi-lang";

export default function TermsOfService() {
  const [show, setShow] = useState(false);
  const daynight = useSelector((state) => state.data.dayNight);
  const cookiesModal = useSelector((state) => state.data.cookiesModal);
  const d = useTranslation();

  useEffect(() => {
		document.title = `${d("termsofservice.title")} - dioptify.com`;
	});

  const handleShow = () => setShow(true);

  return (
    <>
      {cookiesModal ? <CookiesModal /> : ""}
      <div className={`${style.cookiesSection} ${daynight ? style.nightMode : ""}`}>
        <div class="col-lg-12 mx-auto">
          <div className={style.heroSection}>
            <h1 class="mainHeading mb-1">{d("tos.title")}</h1>
            <h4 className="commonText">{d("tos.subtitle")}</h4>
          </div>
          <div className="mx-2">
            <div className={style.card}>
              <h4 className="commonText">{d("legal.termsofservice.1")}</h4>
              <p>
                {d("legal.termsofservice.11")}
              </p>

              <h4 className="commonText">{d("legal.termsofservice.2")}</h4>
              <p>
                {d("legal.termsofservice.21")}
              </p>

              <p>
                {d("legal.termsofservice.22")}
              </p>

              <p>
                {d("legal.termsofservice.23")}
              </p>

              <h4 className="commonText">
                {d("legal.termsofservice.3")}
              </h4>
              <p>
                {d("legal.termsofservice.31")}
              </p>

              <p>
                {d("legal.termsofservice.32")}
              </p>

              <h4 className="commonText">
                {d("legal.termsofservice.4")}
              </h4>
              <p>
                {d("legal.termsofservice.41")}
              </p>

              <p>
                {d("legal.termsofservice.42")}
              </p>

              <p>
                {d("legal.termsofservice.43")}
              </p>

              <h4 className="commonText">
                {d("legal.termsofservice.5")}
              </h4>
              <p>
                {d("legal.termsofservice.51")} <a href="/privacy">www.dioptify/privacy</a>.
              </p>

              {/*
              <h4 className="commonText">6. Concluding provisions</h4>
              <p>
                6.1. Legal disputes arising from this Contract are governed exclusively by German law. Application of the UN Convention for the International Sale of Goods, the referral standards of the IPRG and the Regulation (EC) no. 593/2008 of the European Parliament and of the Council of June 17, 2008 on the law applicable to contractual obligations (Rome I Regulation) is excluded.
              </p>

              <p>
                6.2. The exclusive jurisdiction for disputes arising out of or in connection with this contract shall be the competent court in Vienna-Inner City, Austria.
              </p>

              <p>
                6.3. Changes and/or additions to this Contract must be made in writing to be valid; written form is also required for departure from this formal requirement.
              </p>

              <p>
                6.4. Should individual provisions of this Contract be void, unenforceable and/or invalid, this shall not result in the invalidity, unenforceability and/or invalidity of the entire Contract. In this case, the contracting parties undertake to agree on a provision which is as close as possible economically to the purpose pursued by the void, unenforceable and/or invalid provision to replace the void, unenforceable and/or invalid provision.
              </p>

              <p>
                6.5. If these Terms of Service are presented by the Operator in a language other than German, the translation was prepared by an independent specialist translator certified to ISO 17100:2015 and proofread by at least one other specialist translator. If, in the case of dispute, a court insists on a German-language contract text, then only the original German-language contract text shall be valid; under no circumstances shall a retranslation of the translated version be used.
              </p>
              */}
            </div>
          </div>
        </div>
      </div>

      <CookiesPolicyModal show={show} setShow={setShow} />
    </>
  );
}
