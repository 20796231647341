import React from "react";
import style from "../styles/footer.module.css";
import headerstyle from "../styles/Header.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import WaveFooterTop from "./WaveFooterTop";

export default function Footer(props) {
  const d = props.data;
  const daynight = useSelector((state) => state.data.dayNight);

  if (daynight) {
    document.getElementById("root").classList.add("nightMode");
  } else{
    document.getElementById("root").classList.remove("nightMode");

  }

  return (
    <>
      <div className={`${style.footerRoot}`}></div>
      <div className={`${style.footerDown}`}>
        <WaveFooterTop />
        <section
          className={`${style.linksSection} ${daynight ? style.nightMode : ""}`}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className={style.linksHeading}>{d("footer.title1")}</div>
                {/*
                  <a href="/" className={style.multiLinks}>
                    {d("footer.LearnLinks.text1")}
                  </a>
                  */}
                {/*
                  <a href="/" className={style.multiLinks}>
                    {d("footer.LearnLinks.text2")}
                  </a>
                  */}
                {/*
                  <a href="/marketing" className={style.multiLinks}>
                    {d("footer.LearnLinks.text3")}
                  </a>
                  */}
                {/*
                  <a href="/development" className={style.multiLinks}>
                    {d("footer.LearnLinks.text4")}
                  </a>
                  */}
                {/*
                  <a href="/" className={style.multiLinks}>
                    {d("footer.LearnLinks.text5")}
                  </a>
                  */}
                {/*
                  <a href="/" className={style.multiLinks}>
                    {d("footer.LearnLinks.text6")}
                  </a>
                  */}
                {/*
                  <a href="/" className={style.multiLinks}>
                    {d("footer.LearnLinks.text7")}
                  </a>
                  */}
                {/*
                  <a href="/company" className={style.multiLinks}>
                    {d("footer.LearnLinks.text8")}
                  </a>
                  */}
                {/*
                  <a href="/explore" className={style.multiLinks}>
                    {d("header.link7")}
                  </a>
                  */}
                <a href="/pricing" className={style.multiLinks}>
                  {d("footer.LearnLinks.text9")}
                </a>
              </div>

              <div className="col-lg-3 mt-4 mt-lg-0">
                <div className={style.linksHeading}>{d("footer.title2")}</div>
                <a href="/tools-api" className={style.multiLinks}>
                  {d("footer.title2")}
                </a>
                {/*
                  <a href="/tools-api" className={style.multiLinks}>
                    {d("footer.toolLinks.text5")}
                  </a>
                  <a href="/office365" className={style.multiLinks}>
                    Office365
                  </a>
                  */}
                {/*
                  <a href="/api" className={style.multiLinks}>
                    {d("footer.toolLinks.text1")}
                  </a>
                  */}
                {/*
                  <a href="/addons" className={style.multiLinks}>
                    {d("footer.toolLinks.text2")}
                  </a>
                  */}
                {/*
                  <a href="/desktop" className={style.multiLinks}>
                    {d("footer.toolLinks.text3")}
                  </a>
                  */}
                {/*
                  <a href="/" className={style.multiLinks}>
                    {d("footer.toolLinks.text4")}
                  </a>
                  */}
              </div>

              <div className="col-lg-3 mt-4 mt-lg-0">
                <div className={style.linksHeading}>{d("footer.title3")}</div>
                {/*
                  <a href="/faqs" className={style.multiLinks}>
                    {d("footer.supportLinks.text1")}
                  </a>
                  */}
                <a href="/contact" className={style.multiLinks}>
                  {d("footer.supportLinks.text2")}
                </a>
                <a href="/faqs" className={style.multiLinks}>
                  {d("header.link10")}
                </a>
                <a href="/credits" className={style.multiLinks}>
                  {d("header.link11")}
                </a>
                {/*
                  <a href="/" className={style.multiLinks}>
                    {d("footer.supportLinks.text3")}
                  </a>
                  */}
                {/*
                  <a href="/status" className={style.multiLinks}>
                    {d("footer.supportLinks.text4")}
                  </a>
                  */}
              </div>
              <div className="col-lg-3 mt-4 mt-lg-0">
                <div className={style.linksHeading}>{d("footer.title4")}</div>
                {/*
                  <a href="/" className={style.multiLinks}>
                    {d("footer.companyLinks.text1")}
                  </a>
                  */}
                {/*
                  <a href="/affiliate" className={style.multiLinks}>
                    {d("footer.companyLinks.text2")}
                  </a>
                  <a href="/publish" className={style.multiLinks}>
                    {" "}
                    {d("footer.companyLinks.text3")}
                  </a>
                  */}
                {/*
                  <a href="/career" className={style.multiLinks}>
                    {d("footer.companyLinks.text4")}
                  </a>
                  */}
                {/*
                  <a href="/history" className={style.multiLinks}>
                    {d("footer.companyLinks.text7")}
                  </a>
                  */}
                <a href="/aboutus" className={style.multiLinks}>
                  {d("footer.companyLinks.text5")}
                </a>
                {/*
                  <a href="/" className={style.multiLinks}>
                    {d("footer.companyLinks.text6")}
                  </a>
                  */}
                <a
                  className={style.socialLink}
                  href="https://www.facebook.com/people/Dioptify/100086955578047/"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  className={style.socialLink}
                  href="https://www.instagram.com/dioptify/"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                {/*
                  <a className={style.socialLink} href="/">
                    <i className="fab fa-twitter"></i>
                  </a>
                  */}
                <a
                  className={style.socialLink}
                  href="https://www.linkedin.com/company/dioptify"
                >
                  <i className="fab fa-linkedin"></i>
                </a>
                <a
                  className={style.socialLink}
                  href="https://github.com/dioptify"
                >
                  <i className="fab fa-github"></i>
                </a>
                {/*
                  <a className={style.socialLink} href="/">
                    <i className="fab fa-youtube"></i>
                  </a>
                  */}
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-5">
                <div className={`dropdown ${headerstyle.dropdown}`}>
                  <button
                    className={`btn btn-outline-secondary ${style.languageBtn}`}
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {props.getLanguage === "gr" ? (
                      <>
                        <i className="fa fa-globe fa-lg"></i> de
                      </>
                    ) : props.getLanguage === "fr" ? (
                      <>
                        <i className="fa fa-globe fa-lg"></i> fr
                      </>
                    ) : props.getLanguage === "ch" ? (
                      <>
                        <i className="fa fa-globe fa-lg"></i> ch
                      </>
                    ) : props.getLanguage === "ru" ? (
                      <>
                        <i className="fa fa-globe fa-lg"></i> ru
                      </>
                    ) : props.getLanguage === "ar" ? (
                      <>
                        <i className="fa fa-globe fa-lg"></i> ar
                      </>
                    ) : props.getLanguage === "sp" ? (
                      <>
                        <i className="fa fa-globe fa-lg"></i> sp
                      </>
                    ) : (
                      <>
                        <i className="fa fa-globe fa-lg"></i> en
                      </>
                    )}
                  </button>
                  <div
                    className={`dropdown-menu ${style.dropdownMenu}`}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      className="dropdown-item"
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        props.setLanguage("en");
                      }}
                    >
                      <i class="fa fa-language fa-lg"></i> en
                    </a>
                    <a
                      className="dropdown-item"
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        props.setLanguage("gr");
                      }}
                    >
                      <i class="fa fa-language fa-lg"></i> de
                    </a>
                    <a
                      className="dropdown-item"
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        props.setLanguage("fr");
                      }}
                    >
                      <i class="fa fa-language fa-lg"></i> fr
                    </a>
                    <a
                      className="dropdown-item"
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        props.setLanguage("sp");
                      }}
                    >
                      <i class="fa fa-language fa-lg"></i> sp
                    </a>
                    <a
                      className="dropdown-item"
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        props.setLanguage("ch");
                      }}
                    >
                      <i class="fa fa-language fa-lg"></i> ch
                    </a>
                    <a
                      className="dropdown-item"
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        props.setLanguage("ru");
                      }}
                    >
                      <i class="fa fa-language fa-lg"></i> ru
                    </a>
                    <a
                      className="dropdown-item"
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        props.setLanguage("ar");
                      }}
                    >
                      <i class="fa fa-language fa-lg"></i> ar
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 mt-4 mt-lg-0 text-center text-lg-right">
                <div className={style.oddLinks}>
                  <a className={style.oddLink} href="/privacy">
                    {d("footer.englishLinks.text1")}
                  </a>
                  <span>|</span>
                  <Link className={style.oddLink} to="/cookies">
                    {d("footer.englishLinks.text2")}
                  </Link>
                  <span>|</span>
                  <a className={style.oddLink} href="/termsofservice">
                    {d("footer.englishLinks.text3")}
                  </a>
                  <span>|</span>
                  <a className={style.oddLink} href="/termsandconditions">
                    {d("footer.englishLinks.text4")}
                  </a>
                  <span>|</span>
                  <a className={style.oddLink} href="/imprint">
                    {d("footer.englishLinks.text5")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className={style.pageFooter}>
          <div className="container" style={{ opacity: "0.5" }}>
            <a className={style.footerLogo} href="/">
              <img src="/images/logo-and-icons/logo.svg" alt="" />
            </a>
            <span className={style.footerText}>
              {d("auth.footer.text9")} ❤️ {d("auth.footer.text10")}
            </span>
            <a className={style.footerLogo} href="/">
              <img src="/images/logo-and-icons/logolabs2.svg" alt="" />
            </a>
            <div className={`${style.footerText} mt-4`}>
              {d("auth.footer.text8")}
            </div>
            <div className={`smText ${style.smText}`}>
              {d("auth.footer.text7")}
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
