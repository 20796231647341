import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import axiosInstance from '../axios';
import CookiesModal from "../components/CookiesModal";
import axios from 'axios';
import ChangePW from '../components/userComponents/ChangePW';
import { useTranslation } from "react-multi-lang";

// import ThemeConfig from '../components/dashboard/theme';
// import AppWebsiteVisits from "../components/dashboard/AppWebsiteVisits";
// import AppCurrentSubject from "../components/dashboard/AppCurrentSubject";
// import AppCurrentVisits from "../components/dashboard/AppCurrentVisits";
// import AppOrderTimeline from "../components/dashboard/AppOrderTimeline";
// import { Grid } from '@mui/material';

// Styles
import style from "../styles/user.module.css";

export default function User(props) {
  const daynight = useSelector((state) => state.data.dayNight);
  const cookiesModal = useSelector((state) => state.data.cookiesModal);
  const history = useHistory();
  const data = useTranslation();

  const initialFormData = Object.freeze({
		email: '',
		username: '',
	});

  const initialFormDataCelery = Object.freeze({
		type: '',
	});

	const [formData, updateFormData] = useState(initialFormData);
	const [chipData, updateChipData] = useState(0);
	const [apiData, updateAPIData] = useState("");
	const [formDataCelery, updateFormDataCelery] = useState(initialFormDataCelery);
  const [showChangePWComponent, setShowChangePWComponent] = useState(false);

	const onDebugLogOut = async(event) => {    
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');

    history.push('/');
    history.go('/');
  };

	const onChangePW = () => {    
    setShowChangePWComponent(!showChangePWComponent);
    console.log("changePW:", showChangePWComponent);
  };

	const onDebug = async(event) => {    
    // TODO: remove this formdata here because get does not accept any formdata
    updateFormData({
			...formData,
			email: "root@root.de",
			username: "root",
		});

    // console.log("FORMDATA: ", formData);

    let formData2 = new FormData();
    formData2.append('email', uuidv4());
    formData2.append('username', uuidv4());

    await axiosInstance.get(`user/creditscore/`, formData2).then((res) => {
      // posts: res.data,
      // console.log(res.data);
      updateChipData(res.data.credit_score);
    })
    .catch((err) => {
      // console.log("Unable to upload your file at the moment: " + err);
    });
	};

	const onDebugToken = async(event) => {    
    updateFormData({
			...formData,
			email: "root@root.de",
			username: "root",
		});


    // console.log("FORMDATA: ", formData);

    let formData2 = new FormData();
    formData2.append('email', uuidv4());
    formData2.append('username', uuidv4());

    await axiosInstance.get(`user/apitoken/`, formData2).then((res) => {
      // posts: res.data,
      // console.log(res.data);
      updateAPIData(res.data.api_token);
    })
    .catch((err) => {
      console.log("Unable to upload your file at the moment: " + err);
    });
	};

  useEffect(() => {
    const fetchUser = async () => {
      if(localStorage.getItem('user') === null) {
        history.push('/');
      }
    };
    fetchUser();

    onDebug();
    onDebugToken();

    setShowChangePWComponent(false);
  }, []);

  return (
    <section className={`${style.teamSection} ${daynight ? style.nightMode : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className={style.heroSection}>
              <h1 class="mainHeading mb-1">{data("user.title")}</h1>
            </div>
                          
            <h2 className={`${style.subHeading} subHeading`}>
            {data("user.user")}: <span style={{borderBottom: "7px solid var(--blueColor)", lineHeight: "1.3",}}>{localStorage.getItem('user')}</span>
            </h2>
            <br/>

            <div class="row">
            <div class="col-lg-12 mx-auto">
            {/*<div class={`${style.customFrom} authForm`}>*/}
            
            <div className={`${style.itemsLinks} itemsLinks ${style.nav} nav`} id="myTab" role="tablist">
              <a
                className={`${style.itemLink} active`}
                id="tab-1"
                data-toggle="tab"
                href="#tab1"
                role="tab"
                aria-controls="tab1"
                aria-selected="true"
              >
                {data("user.details.chips")}
              </a>
              {/*
              <a
                className={style.itemLink}
                id="tab-2"
                data-toggle="tab"
                href="#tab2"
                role="tab"
                aria-controls="tab2"
                aria-selected="false"
              >
                {data("user.details.details")}
              </a>
              <a
                className={style.itemLink}
                id="tab-3"
                data-toggle="tab"
                href="#tab3"
                role="tab"
                aria-controls="tab3"
                aria-selected="false"
              >
                {data("user.details.pandb")}
              </a>
              */}
              <a
                className={style.itemLink}
                id="tab-4"
                data-toggle="tab"
                href="#tab4"
                role="tab"
                aria-controls="tab4"
                aria-selected="false"
              >
                {data("user.details.api")}
              </a>
              <a
                className={style.itemLink}
                id="tab-5"
                data-toggle="tab"
                href="#tab5"
                role="tab"
                aria-controls="tab5"
                aria-selected="false"
              >
                {data("user.details.settings")}
              </a>
            </div>
          
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab-1">
              <div className={`mt-5 ${style.teamCarousel}`}>
                <div
                  id="teamSlider"
                  className={`carousel slide logoCarousel mt-3 ${style.teamSlider}`}
                  data-ride="carousel"
                >
                  <div>
                  <h2 className={`subHeadingSmall`}>
                    {data("user.chips")}: {chipData}
                  </h2>

                  </div>
                </div>
              </div>
            </div>
            {/*
            <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab-2">
              <div className={`mt-5 ${style.teamCarousel}`}>
                <div
                  id="teamSlider"
                  className={`carousel slide logoCarousel mt-3 ${style.teamSlider}`}
                  data-ride="carousel"
                >
                  <div>
                    <ThemeConfig>
                      <AppWebsiteVisits data={data}/>
                      <br/>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={6}>
                          <AppCurrentSubject />
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <AppCurrentVisits />
                        </Grid>
                      </ Grid>
                    </ThemeConfig>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="tab-3">
              <div className={`mt-5 ${style.teamCarousel}`}>
                <div
                  id="teamSlider"
                  className={`carousel slide logoCarousel mt-3 ${style.teamSlider}`}
                  data-ride="carousel"
                >
                  <div>
                    <ThemeConfig>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={4}>
                          <AppCurrentSubject />
                        </Grid>

                        <Grid item xs={12} md={6} lg={8}>
                          <AppOrderTimeline />
                        </Grid>
                      </ Grid>
                    </ThemeConfig>
                  </div>
                </div>
              </div>
            </div>
            */}
            <div className="tab-pane fade" id="tab4" role="tabpanel" aria-labelledby="tab-4">
              <div className={`mt-5 ${style.teamCarousel}`}>
                <div
                  id="teamSlider"
                  className={`carousel slide logoCarousel mt-3 ${style.teamSlider}`}
                  data-ride="carousel"
                >
                  <div style={{ overflowX: "hidden"}}>
                    <h2 className={`subHeadingSmall`}>
                    {data("user.api")}: {apiData}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="tab5" role="tabpanel" aria-labelledby="tab-5">
              <div className={`mt-5 ${style.teamCarousel}`}>
                <div
                  id="teamSlider"
                  className={`carousel slide logoCarousel mt-3 ${style.teamSlider}`}
                  data-ride="carousel"
                >
                  <div>
                    <Link
                      className={`btn btn-outline-danger ${style.buttonAdventureSecondary}`}
                      onClick={(e) => {
                        onDebugLogOut(e);
                      }}
                    >
                      Logout
                    </Link>
                  </div>
                  <div>
                    
                    <br />
                    {/*
                      https://stackoverflow.com/questions/70499543/create-react-app-creates-this-iframe-that-prevents-me-from-clicking-or-editing
                      https://github.com/facebook/create-react-app/issues/11880
                      https://github.com/remix-run/react-router/issues/8489
                    */}
                    <Link
                      to="#"
                      className={`btn btn-outline-secondary ${style.buttonAdventureRealSecondary}`}
                      onClick={(e) => {
                        onChangePW(e);
                      }}
                    >
                      {data("user.changepw")}
                    </Link>
                    
                    <br />
                    {showChangePWComponent ? (
                      <>
                        <ChangePW />
                      </>
                    ) : (
                      ""
                    )}                    
                  </div>
                </div>
              </div>
            </div>
          </div>
            </div>
          </div>

          {/*
            <div className={`mt-5 ${style.teamCarousel}`}>
              <div
                id="teamSlider"
                className={`carousel slide logoCarousel mt-3 ${style.teamSlider}`}
                data-ride="carousel"
              >
              <div>
                <Link
                  className={`${style.btn} btn btn-outline-danger`}
                  onClick={(e) => {
                    onDebugLogOut(e);
                  }}
                >
                  Logout
                </Link>
                <br /> <br />
                <Link
                  className={`${style.btn} btn btn-outline-success`}
                  onClick={(e) => {
                    onDebug(e);
                  }}
                >
                  Debug CreditScore
                </Link>
                <br /> <br />
                <Link
                  className={`${style.btn} btn btn-outline-success`}
                  onClick={(e) => {
                    onDebugToken(e);
                  }}
                >
                  Debug APIToken
                </Link>
              </div>

                {cookiesModal ? <CookiesModal /> : ""}
              </div>
            </div>
          */}

          {cookiesModal ? <CookiesModal /> : ""}
          </div>
        </div>
      </div>
    </section>
	);
}
