import Types from "../constant/image";

var defaultState = {
  image: [],
  video: [],
  dayNight: localStorage.getItem("defaultDayNight") ? localStorage.getItem("defaultDayNight") === "true" : false,
  cookiesModal: localStorage.getItem("acceptedCookies")
    ? localStorage.getItem("acceptedCookies") === "false"
      ? false
      : true
    : true,
  functionalCookies: true,
  analyticsCookies: true,
  editorData:{}
};

var imageReducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.SET_VIDEO: {
      let newState = Object.assign({}, state);
      newState.video = [...state.video, action.payload];
      return newState;
    }
    case Types.DELETE_VIDEO: {
      let newState = Object.assign({}, state);
      newState.video = state.video.filter((item) => item.id !== action.payload);
      return newState;
    }

    case Types.SET_IMAGE: {
      let newState = Object.assign({}, state);
      newState.image = [...state.image, action.payload];
      return newState;
    }
    case Types.DELETE_IMAGE: {
      let newState = Object.assign({}, state);
      newState.image = state.image.filter((item) => item.id !== action.payload);
      return newState;
    }

    case Types.SET_DAYNIGHT: {
      let newState = Object.assign({}, state);
      newState.dayNight = action.payload;
      return newState;
    }

    case Types.SET_EDITOR_DATA: {
      let newState = Object.assign({}, state);
      newState.editorData = action.payload;
      return newState;
    }

    case Types.SET_COOKIES_MODAL: {
      let newState = Object.assign({}, state);
      newState.cookiesModal = action.payload;
      return newState;
    }
    case Types.SET_FUNCTIONAL_COOKIES: {
      let newState = Object.assign({}, state);
      newState.functionalCookies = action.payload;
      return newState;
    }
    case Types.SET_ANALYTICS_COOKIES: {
      let newState = Object.assign({}, state);
      newState.analyticsCookies = action.payload;
      return newState;
    }

    case Types.LOGOUT: {
      let newState = Object.assign({}, state);
      newState.image = [];
      newState.video = [];
      newState.editorData = {};
      return newState;
    }

    default:
      return state;
  }
};

export default imageReducer;
