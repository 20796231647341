import React from "react";
import style from "../../../styles/windowsScreenStyle/midSection.module.css";
import { useSelector } from "react-redux";

export default function MidSection(props) {
  const d = props.data;
  const daynight = useSelector((state) => state.data.dayNight);

  return (
    <section class={`${style.midSection} ${daynight ? style.nightMode : ""}`}>
      <div class="container">
        <div class={`row mt-5 ${style.customRow}`}>
          <div class="col-lg-6 mt-3 mt-lg-0">
            <h2 class="subHeading mb-2">{d("serviceInfo.ns.subtitle.section3.title")}</h2>
            <div class="commonText">
              {d("serviceInfo.ns.subtitle.section3.text")}
            </div>
          </div>
          <div class="col-lg-6">
            <div class={style.midSectionLeftSide}>
              <div class={style.squareBox}></div>
              <img
                class={style.blackDotsImage}
                src="/images/black-dots-img.svg"
                alt=""
              />
              <img
                class={`img-fluid ${style.mainImg}`}
                src="/images/marketing/ns_inf_2.png"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
