import React from 'react';
import { DropdownMenu, MenuItem } from 'react-bootstrap-dropdown-menu';

export default class SubMenu extends React.Component {
  constructor() {
    super();
    this.state = {
      css: {
        textTrigger: {
          fontWeight: 'normal'
        }
      }
    }
    this.onMouseover = this.onMouseover.bind(this);
    this.onMouseout = this.onMouseout.bind(this);
  }
  onMouseover(event) {
    event.preventDefault();
    this.setState({
      css: {
        textTrigger: {
          fontWeight: 'bold'
        }
      }
    });
  }
  onMouseout(event) {
    event.preventDefault();
    this.setState({
      css: {
        textTrigger: {
          fontWeight: 'normal'
        }
      }
    });
  }
  render() {
    return (
      <DropdownMenu css={this.state.css} onMouseover={this.onMouseover} onMouseout={this.onMouseout} triggerType='text' trigger='Settings'>
        <MenuItem text='Home' location='/texttrigger' />
        <MenuItem text='Edit Profile' location='/texttrigger' />
        <MenuItem text='Logout' location='/texttrigger' />
      </DropdownMenu>
    )
  }
}