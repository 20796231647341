import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import style from "../../styles/postOverview.module.css";
import Grid from "@material-ui/core/Grid";

const  PostGradiantLoading = ({dayNight, classes}) =>  {
  return (
    <Grid item xs={12} md={6}>
      <div className={dayNight ? style.containerNight : style.containerDay}>
        <Card className={`${dayNight ? classes.card : classes.cardNight}`}>
          <CardContent className={classes.cardContent}>
            {" "}
            <div className={`${dayNight ? style.cardNight : style.cardDay}`}>
              <div
                className={`${
                  dayNight ? style.gradientNight : style.gradientDay
                } ${style.element1}`}
              ></div>

              <div
                className={`${
                  dayNight ? style.gradientNight : style.gradientDay
                } ${style.element3}`}
              ></div>

              <div
                className={`${
                  dayNight ? style.gradientNight : style.gradientDay
                } ${style.element4}`}
              ></div>
              <div
                className={`${
                  dayNight ? style.gradientNight : style.gradientDay
                } ${style.element5}`}
              ></div>

              <div
                className={`${
                  dayNight ? style.gradientNight : style.gradientDay
                } ${style.element6}`}
              ></div>

              <div className="d-flex">
                <div
                  className={`${
                    dayNight ? style.gradientNight : style.gradientDay
                  } ${style.element7}`}
                ></div>
                <div
                  className={`${
                    dayNight ? style.gradientNight : style.gradientDay
                  } ${style.element8}`}
                ></div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </Grid>
  );
}
export default PostGradiantLoading;
