import React from "react";
import style from "../../styles/tools.module.scss";
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";

export default function HeroSection() {
  const d = useTranslation();
  const daynight = useSelector((state) => state.data.dayNight);

  function classToggle(event) {
    // var el = document.querySelector('style.iconCards__content');
    // el.classList.toggle('step-animation');
    console.log("clicked");
  }
  
  // document.querySelector('#toggle-animation').addEventListener('click', classToggle);

  return (
    <section class={`${style.toolsSection} ${daynight ? style.nightMode : ""}`}>
      {/* 
      <div class={style.halfCircle}></div>
      <div class={style.circle}></div>
      <img class={style.toolsDotsImg} src="/images/black-dots-img.svg" alt="" />
      <img class={style.toolsDotsImgTwo} src="/images/black-dots-img.svg" alt="" />
      <div class={style.triangle}></div>
      <img class={style.downArrowsImg} src="/images/down-arrows-img.svg" alt="" />
      */}
      {/*<div class="container">*/}
      <div style={{maxWidth:'1600px', margin:"auto"}}>
        <div class="col-lg-12 mx-auto">
          <div class={style.heroSectionContents}>
            <h1 class="mainHeading mb-1">{d("toolsapi.title")}</h1>
            <div class="commonText">{d("toolsapi.subtitle")} <i className="fas fa-kiwi-bird"></i>{/*<i class="fad fa-question-circle"></i>*/}</div>
          </div>

          {/*
          <div className={`d-flex align-items-center justify-content-center`}>
            <figure className={`mt-3 ${style.iconCards}`}>
              <div className={`${style.iconCards__content}`}>
                <div className={`d-flex align-items-center justify-content-center ${style.iconCards__item}`}>
                  <div class={style.toolsCard}>
                    <a class={style.imgLink} href="#">
                      <img
                        class="img-fluid"
                        src="/images/cysharpUI.jpg"
                      />
                    </a>
                    <h2 class={style.subHeading}>
                      dioptify for <br />
                      Windows / Mac / Linux
                    </h2>
                    <a href="/desktop" class={`btn btn-primary ${style.btn}`}>
                      Learn more
                    </a>
                    <a href="#" class={`btn btn-outline-primary ${style.btn} ${style.outline}`}>
                      Download
                    </a>
                  </div>
                </div>
                <div className={`d-flex align-items-center justify-content-center ${style.iconCards__item}`}>
                  <div class={style.toolsCard}>
                    <a class={style.imgLink} href="#">
                      <img
                        class="img-fluid"
                        src="/images/psUI.jpg"
                      />
                    </a>
                    <h2 class={style.subHeading}>
                      dioptify for <br />
                      Photoshop
                    </h2>
                    <a href="/addons" class={`btn btn-primary ${style.btn}`}>
                      Learn more
                    </a>
                    <a href="#" class={`btn btn-outline-primary ${style.btn} ${style.outline}`}>
                      Download
                    </a>
                  </div>
                </div>
                <div className={`d-flex align-items-center justify-content-center ${style.iconCards__item}`}>
                  <div class={style.toolsCard}>
                    <a class={style.imgLink} href="#">
                      <a href="/api">
                        <img
                          class="img-fluid"
                          src="/images/rest-api-resized.png"
                        />
                      </a>
                    </a>
                    <h2 class={style.subHeading}>
                      API <br />
                      Integration
                    </h2>
                    <a href="/api" class={`btn btn-primary ${style.btn}`}>
                      Learn more
                    </a>
                  </div>                  
                </div>
              </div>
            </figure>
          </div>
          */}

          {/* Solution: https://stackoverflow.com/a/57308563 */}
          {/*
          <div className={`${style.checkbox}`}>
              <input class="d-none" id="toggle-animation" type="checkbox" checked 
              onClick={(event) => classToggle(event)}/>
              <label className={`${style.checkbox__checkbox}`} for="toggle-animation"></label>
              <label className={`${style.checkbox__label}`} for="toggle-animation">Toggle animation</label>
          </div>
          */}


        {/**/}
          {/*
          <div class="row">
            <div class="col-lg-4">
              <div class={style.toolsCard}>
                <a class={style.imgLink} href="/desktop">
                  <img
                    class="img-fluid"
                    src="/images/cysharpUI.jpg"
                  />
                </a>
                <h2 class={style.subHeading}>
                  dioptify {d("toolsapi.for")} <br />
                  Desktop
                </h2>
                <a href="/desktop" class={`btn btn-primary ${style.btn}`}>
                  {d("toolsapi.more")}
                </a>
                <a href="#" class={`btn btn-outline-primary ${style.btn} ${style.outline}`}>
                  Download
                </a>
              </div>
            </div>
            <div class="col-lg-4">
              <div class={style.toolsCard}>
                <a class={style.imgLink} href="/photoshop">
                  <img
                    class="img-fluid"
                    src="/images/psUI.jpg"
                  />
                </a>
                <h2 class={style.subHeading}>
                  dioptify {d("toolsapi.for")} <br />
                  Photoshop
                </h2>
                <a href="/photoshop" class={`btn btn-primary ${style.btn}`}>
                  {d("toolsapi.more")}
                </a>
                <a href="#" class={`btn btn-outline-primary ${style.btn} ${style.outline}`}>
                  Download
                </a>
              </div>
            </div>
            <div class="col-lg-4">
              <div class={style.toolsCard}>
                <a class={style.imgLink} href="/office365">
                  <img
                    class="img-fluid"
                    src="/images/office365.png"
                  />
                </a>
                <h2 class={style.subHeading}>
                  dioptify {d("toolsapi.for")} <br />
                  Office365
                </h2>
                <a href="/office365" class={`btn btn-primary ${style.btn}`}>
                  {d("toolsapi.more")}
                </a>
              </div>
            </div>
            */}
            {/*
            <div class="col-lg-3">
              <div class={style.toolsCard}>
                <a class={style.imgLink} href="#">
                  <a href="/api">
                    <img
                      class="img-fluid"
                      src="/images/rest-api-resized.png"
                    />
                  </a>
                </a>
                <h2 class={style.subHeading}>
                  API <br />
                  Integration
                </h2>
                <a href="/api" class={`btn btn-primary ${style.btn}`}>
                  {d("toolsapi.more")}
                </a>
              </div>
            </div>
            */}
          {/*</div>*/}
          {/*
          <div className="smText">
                {d("footer.title6")}
          </div>
          */}
          {/*<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>{/*TODO: remove after content is back, it is only here for lack of skills*/}

        {/**/}
        </div>
      </div>
    </section>
  );
}
