import React from "react";
import { useTranslation } from "react-multi-lang";

import UploadServiceSectionTasks from "../UploadServiceSectionTasks";
import AdBannner from "../../AdBannner";
import { SERVICES } from '../Services';


export default function Deshaking() {
  const data = useTranslation();

  return (
    <>
      <UploadServiceSectionTasks data={data} service={SERVICES.DS}/>
      <AdBannner data={data} />
    </>
  );
}
