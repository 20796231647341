import React from "react";
import faqStyles from "../../styles/faqs.module.css";
import style from "../../styles/financialFaqSection.module.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import Faq from "./Faq";
import { Accordion } from "react-bootstrap";

export default function FAQsection(props) {
  const daynight = useSelector((state) => state.data.dayNight);
  const d = useTranslation();

  return (
    <section class={`${style.fqSection} ${daynight ? style.nightMode : ""}`}>
      <div className={`${faqStyles.catagoryTitle}`}>
        <h2>{d(props?.selectedCatagory?.name)}</h2>
      </div>
      <Accordion>
        {props?.selectedCatagory?.questions?.map((question, key) => {
          return (
            <Faq
              key={key}
              eventKey={question?.id}
              question={question?.question}
              answer={question?.answer}
            ></Faq>
          );
        })}
      </Accordion>
    </section>
  );
}
