import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../elements/utils/SectionProps';
import Input from '../elements/Input';
import { useSelector } from "react-redux";
import { Alert } from "react-bootstrap";

// import "./elements/assets/scss/style.scss";
import style from "../../styles/ctaSection.module.css";


const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const d = props.data;
  const daynight = useSelector((state) => state.data.dayNight);

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );  

  var email = "";
  
  function sendEmail(event) {
    // console.log("clicke on kiwi: " + email);
    // console.log("checken with kiwi: " + validateEmail(email));
    if(validateEmail(email)) {
      // send email here;
      setShow(false);

      return true;
    } else {
        setShow(true);
        setTimeout(() => {
          setShow(false);
        }, 4000);
    }
  }

  function enterPressed(event) {
    var code = event.keyCode || event.which;
    if(code === 13) {
        sendEmail()
    } 
  }

  function updateInputValue(evt) {
    email = evt.target.value;
  }

  function validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  const [show, setShow] = useState(false);

  return (
    <section
      {...props}
      // className={outerClasses}
      className={`${outerClasses} ${style.gallerySection} ${
        daynight ? style.nightMode : ""
      }`}
    >
    {show ? (
      <Alert
        variant="danger" // warning
        style={{ position: "fixed", left: "50%", transform: "translateX(-50%)", top: "36px", zIndex: "1040" }}
      >
        {d("cta.error")}
      </Alert>
    ) : (
      ""
    )}
      <div className="container">
        <div
          className={innerClasses}
        >
          <div className="cta-slogan">
            {/*<h2 className={`${style.subHeading} subHeading`}>{d("gallery.title")}</h2>*/}
            <h2 className={`${style.h2} subHeading`}>{d("faq.title")}?</h2>
            {/*
            <h3 className="m-0">
              For previewing layouts and visual?
            </h3>
            */}
          </div>
          
          <div className="cta-action">
            <Input onChange={evt => updateInputValue(evt)} id="newsletter" type="email" 
              label="Subscribe" labelHidden hasIcon="right" 
              placeholder={d("cta.email")} onKeyPress={enterPressed.bind(this)}
            >
              {/*
              <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#376DF9" />
              </svg>
              */}  {/*<i className="fas fa-kiwi-bird"></i> */}
              <a className={style.commonLink} onClick={e => sendEmail(e)}>
                <i className="fas fa-kiwi-bird"></i>
              </a>
            </Input>
          </div>
        </div>
      </div>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;