import React, { useState } from 'react';
import axiosInstance from '../../axios';
import { useHistory } from 'react-router-dom';
import { Alert } from "react-bootstrap";

import style from "../../styles/uploadSection.module.css";
import VideoEditor from "../VideoEditor";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { setImage, setVideo } from "../../redux/action/image";
import { SERVICES } from './Services';
import axios from 'axios';
import Loader from "../../components/loaderComponent/Loader";


export default function UploadVideoServiceSectionTasks(props) {
	const daynight = useSelector((state) => state.data.dayNight);

	let resSuccess = false;
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(false);

	//https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1
	function slugify(string) {
		const a =
			'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
		const b =
			'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
		const p = new RegExp(a.split('').join('|'), 'g');

		return string
			.toString()
			.toLowerCase()
			.replace(/\s+/g, '-') // Replace spaces with -
			.replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
			.replace(/&/g, '-and-') // Replace & with 'and'
			.replace(/[^\w\-]+/g, '') // Remove all non-word characters
			.replace(/\-\-+/g, '-') // Replace multiple - with single -
			.replace(/^-+/, '') // Trim - from start of text
			.replace(/-+$/, ''); // Trim - from end of text
	}


	const d = props.data;
	const service = props.service;
    console.log("the service on generic uploadserviceserction: " + service);

	const dispatch = useDispatch();
	const onImageChange = async(event) => {

		if (event.target.files && event.target.files[0]) {
			let img = event.target.files[0];
			let slug = uuidv4();
			
			let user = 'anon';
			if(localStorage.getItem('user') !== null) {
				user = localStorage.getItem('user');
			} else {
				if(localStorage.getItem('temp_user') === null) {
					user = uuidv4();
					localStorage.setItem('temp_user', user);
				} else {
					user = localStorage.getItem('temp_user');
				}
			}

			event.preventDefault();
			let formData = new FormData();
			formData.append('title', uuidv4());
			formData.append('slug', slug);
			formData.append('service', service);
			formData.append('owner', user);
			// formData.append('author', 1);
			formData.append('excerpt', uuidv4());
			formData.append('content', uuidv4());
			formData.append('file', img);
			
			console.log('file: ' + img.name);

			// let resSuccess = false
			setLoading(true); // to show the loader svg

			/*
			await axiosInstance.post(`admin/createv/`, formData).then((res) => {
				// posts: res.data,
				console.log(res.data);
				resSuccess = true;
				console.log("ES HAT DOCH GERADE FUNKTIONIERT..." + resSuccess);
			})
			.catch((err) => {
				alert("Unable to upload your file at the moment: " + err);
				resSuccess = false;
				setLoading(false); // to disable the loader svg
			});

			// get image after manipulation
			// alert("bevor erfolgreiche Response: " + resSuccess);
			console.log("bevor rrfolgreiche Response: " + resSuccess);

			// TODO: investigate why this breaked so hard lol
			// TODO: found here massive deal breaker -> why did this work in the past? 
			// alert("BEVOR WARUM JETZT NICHT MEHR?...");
			if(resSuccess) {
				console.log("WARUM JETZT NICHT MEHR?...");
				setTimeout(() => {  
					axiosInstance.get('videopost/' + slug).then((res) => {
						// posts: res.data,
						console.log(res.data);
						resSuccess = true;
						
						// TODO: and add here correct redux handling
                        setLoading(false); // to disable the loader svg						
						dispatch(setVideo({ video: res.data.file, id: uuidv4() }));
						// console.log("ES HAT DOCH GERADE FUNKTIONIERT..." + res.data.file); // video kommt hiere an
					})
					.catch((err) => {
                        setLoading(false); // to disable the loader svg						
						alert("Unable to upload your image at the moment: " + err);
					});
				 }, 1000); // sehr schmutzig hier... 
			}
			*/
			const getStatus = async(taskID) => {
				console.log("-------------------------------> taskID", taskID);
				// await axios.get(`http://0.0.0.0:1337/api/createvideo/${taskID}/`).then((res) => {
				await axiosInstance.get(`createvideo/${taskID}/`).then((res) => {
				  console.log("----> sind in getStatus von den createvideo Tasks", res.data);
				  
				  const taskStatus = res.data.task_status;
				  setShow(false);
			  
				  // if (taskStatus === 'SUCCESS' || taskStatus === 'FAILURE') return false;
				  if (taskStatus === 'SUCCESS' ) getProcessedImage();
				  if (taskStatus === 'SUCCESS' || taskStatus === 'FAILURE') return taskStatus;
				  setTimeout(function() {
					getStatus(res.data.task_id);
				  }, 1000); // this recalls the function recursive to see if the tasks has ended
		  
				})
				.catch((err) => {
				  console.log("Unable to get at the moment: " + err);
				  // TODO: add here some toast or other message to show an error
				  setLoading(false);
				  setShow(true);
				  setTimeout(() => {
					setShow(false);
				  }, 4000);
				});
			  }
		  
		
			const getProcessedImage = async() => {
				console.log("-----------------<><> kokmmen wir hier rein?");

				axiosInstance.get('videopost/' + slug).then((res) => {
					// posts: res.data,
					console.log(res.data);
					resSuccess = true;

					setLoading(false); // to disable the loader svg
					setShow(false);

					// TODO: change to more mature logic here
					// tests due to some services overwrite original files
					if(service !== "rbv" && service !== "hdrv") {
						if(!(res.data.paid)) {
							dispatch(setVideo({ video: res.data.preview_file, id: uuidv4() }));
						} else {
							dispatch(setVideo({ video: res.data.file, id: uuidv4() }));
						}
					} else {
						if(!(res.data.paid)) {
							// TODO: check if this correct for all services
							// dispatch(setVideo({ video: res.data.preview_file, id: uuidv4() }));
							dispatch(setVideo({ video: res.data.original_file, id: uuidv4() }));
						} else {
							dispatch(setVideo({ video: res.data.original_file, id: uuidv4() }));
						}
					}
				})
				.catch((err) => {
					setLoading(false); // to disable the loader svg
					// alert("Unable to upload your image at the moment: " + err);

					setShow(true);
					setTimeout(() => {
					  setShow(false);
					}, 4000);
				});
			}

			await axiosInstance.post(`createvideo/`, formData).then((res) => {
				// posts: res.data,
				console.log("bevor RRRRRRRRRRRrrrfolgreiche Response: ", res.data);
				
				let taskStatus = getStatus(res.data.task_id); // TESTs
				setShow(false);

				// this way don't work because the taskStatus is pending and will not get set to success
				// console.log("dddddddddddddddddddddd taskStatus: ", taskStatus);
				// if (taskStatus === 'SUCCESS') getProcessedImage();

				resSuccess = true;
			})
			.catch((err) => {
				// alert("Unable to upload your image at the moment: " + err);
				resSuccess = false;
                setLoading(false); // to disable the loader svg

				setShow(true);
				setTimeout(() => {
				  setShow(false);
				}, 4000);
			});

			console.log("bevor rrfolgreiche Response: " + resSuccess);
		}
	};
	const isImagePick = useSelector((state) => state.data.video.length);


	return (
		<>
			{show ? (
			<Alert
				variant="danger" // warning
				style={{ position: "fixed", left: "50%", transform: "translateX(-50%)", top: "36px", zIndex: "1040" }}
			>
				{d("error.errorUpload")}
			</Alert>
			) : (
			""
			)}		
			<section 
				className={`${style.heroSection} ${daynight ? style.nightMode : ""}`}
			>
				<div className="col-lg-12">
					{isImagePick !== 0 ? (
						<>
						<div className="">
							<div className={style.heroContentSide}>
							<div className={style.uploadImageCard}>
								<div className={style.uploadImageCardBodySmall}>
								<label
									for="uploadImage"
									className={`btn btn-primary ${style.btn_primary}`}
									href="/"
									>
									<i className="fas fa-cloud-upload-alt"></i> {d("heroSection.uploadVideo")}
									</label>
									<input
									className="d-none"
									type="file"
									id="uploadImage"
									accept="video/*"
									onChange={onImageChange}
								/>
								<div className={`${style.mainText} mainText text-center`}>{d("subHeroSection.drop")}</div>
								{loading ?  (
									<div className="text-center">
										{/*<img src="/images/loader.gif" alt="" /> */}
										<Loader></Loader>
									</div>
								) : ""}
								</div>
							</div>
							</div>
						</div>
						</>
					) : (
						<div className="container">
    
						{service == SERVICES.FI ?  (
                            <h1 className={`${style.mainHeading} mainHeading text-center`}>
                                {d("uploadImageFI")}
                            </h1>
                        ) : ""}
                        {service == SERVICES.NSV ?  (
                            <h1 className={`${style.mainHeading} mainHeading text-center`}>
                                {d("uploadImageNSV")}
                            </h1>
                        ) : ""}
                        {service == SERVICES.HDRV ?  (
                            <h1 className={`${style.mainHeading} mainHeading text-center`}>
                                {d("uploadImageHDRV")}
                            </h1>
                        ) : ""}
                        {service == SERVICES.VI ?  (
                            <h1 className={`${style.mainHeading} mainHeading text-center`}>
                                {d("uploadImageVI")}
                            </h1>
                        ) : ""}
                        {service == SERVICES.RBV ?  (
                            <h1 className={`${style.mainHeading} mainHeading text-center`}>
                                {d("uploadImageRBV")}
                            </h1>
                        ) : ""}
    
    					<div className="row">
							<div className="col-lg-12">
							<div
								className={style.heroContentSide}
								style={{ width: "100%", maxWidth: "420px", marginTop: "3em" }}
							>
								<div className={style.uploadImageCard}>
								<label
									for="uploadImage"
									className={`${style.uploadImageCardBody} w-100`}
								>
									<img src="/images/logo-and-icons/new3small.svg" alt="" />
									<label
									for="uploadImage"
									className={`btn btn-primary ${style.btn_primary}`}
									href="/"
									>
									<i className="fas fa-upload"></i>{" "}
									{d("heroSection.uploadVideo")}
									</label>
									<input
									className="d-none"
									type="file"
									id="uploadImage"
									accept="video/*"
									onChange={onImageChange}
									/>
									<div className={`${style.mainText} mainText`}>
									{d("heroSection.drop")}
									</div>
								</label>
								{loading ?  (
                                    <div className="text-center">
                                        {/*<img src="/images/loader.gif" alt="" /> */}
										<Loader></Loader>
										<br/><br/>
                                    </div>
                                ) : ""}                                
                                {/*
								<div className={style.uploadImageCardFooter}>
									<span className={`subText ${style.subText}`}>
									{d("heroSection.paste.text1")} <a href="/">URL</a>
									</span>
									<span
									className={`${style.keyWord} ${style.subText} subText`}
									>
									{d("heroSection.paste.text2")}
									</span>
									<span className={`${style.subText} subText`}>+</span>
									<span
									className={`${style.keyWord} ${style.subText} subText`}
									>
									{d("heroSection.paste.text3")}
									</span>
								</div>
                                */}
								</div>
			
								{/*
								<div className={style.otherImages}>
								<div className={style.othersContents}>
									<div className={style.othersHeading}>
									{d("heroSection.noImage")}
									</div>
									<div className={style.othersHeading}>
									{d("heroSection.try")}
									</div>
								</div>
								<div>
									<img
									className={style.othersImage}
									src="/images/sm-img1.jpg"
									alt=""
									/>
									<img
									className={style.othersImage}
									src="/images/sm-img1.jpg"
									alt=""
									/>
									<img
									className={style.othersImage}
									src="/images/sm-img1.jpg"
									alt=""
									/>
									<img
									className={style.othersImage}
									src="/images/sm-img1.jpg"
									alt=""
									/>
								</div>
								</div>
								*/}
								<div className={`${style.smText} smText`}>
								{d("heroSection.uploadText.text1")}
								<a href="/termsofservice"> {d("heroSection.uploadText.text2")} </a>
								{/* 
								{d("heroSection.uploadText.text3")}
								<a href="/">{d("heroSection.uploadText.text4")}</a>{" "}
								{d("heroSection.uploadText.text5")}
								<a href="/">{d("heroSection.uploadText.text6")}</a>{" "}
								{d("heroSection.uploadText.text7")}.
								*/}
								</div>
							</div>
							</div>
						</div>
						</div>
					)}
				</div>
			</section>
			{isImagePick !== 0 ? <VideoEditor /> : ""}
		</>
	);
}
