import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";

import HeroSection from "../../components/servicesDetailsComponents/fiDetailComponent/HeroSection";
import ResultsSection from "../../components/servicesDetailsComponents/fiDetailComponent/ResultsSection";
import MidSection from "../../components/servicesDetailsComponents/fiDetailComponent/MidSection";
import CardSection from "../../components/servicesDetailsComponents/fiDetailComponent/CardSection";
import FAQsection from "../../components/servicesDetailsComponents/fiDetailComponent/FAQsection";
import CookiesModal from "../../components/CookiesModal";

export default function FIDetail() {
  const data = useTranslation();
  const cookiesModal = useSelector((state) => state.data.cookiesModal);

  useEffect(() => {
    document.title = `${data("serviceInfo.fi.tabtitle")} - dioptify.com`;
  });  

  return (
    <>
      <HeroSection data={data}/>
      <ResultsSection data={data}/>
      <MidSection data={data}/>
      {/*<CardSection data={data}/>*/}
      <FAQsection data={data}/>
      {cookiesModal ? <CookiesModal /> : ""}
    </>
  );
}
