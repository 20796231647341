import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
//MaterialUI
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import style from "../../styles/services.module.css";

export default function Create() {
	const history = useHistory();
	const { id } = useParams();
	const daynight = useSelector((state) => state.data.dayNight);

	const handleSubmit = (e) => {
		e.preventDefault();
		axiosInstance
			.delete('admin/delete/' + id)
			.catch(function (error) {
				if (error.response) {
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				}
			})
			.then(function () {
					history.push({
						pathname: '/admin/',
					});
					window.location.reload();
			});
	};

	return (
		<section className={`${style.teamSection} ${daynight ? style.nightMode : ""}`}>
			<div className="container">
				<div className="row">
					<div className="col-lg-12 mx-auto">
						<Container component="main" maxWidth="sm">
							<Box
								display="flex"
								justifyContent="center"
								m={1}
								p={1}
								bgcolor="background.paper"
							>
								<Button
									variant="contained"
									color="secondary"
									type="submit"
									onClick={handleSubmit}
								>
									Press here to confirm delete
								</Button>
							</Box>
						</Container>
					</div>
				</div>
			</div>			
		</section>		
	);
}
