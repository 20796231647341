import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { PayPalButton } from "react-paypal-button-v2";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import { PRICINGTABLECORONCE, PRICINGTABLECOR, PAYPALKEYVALUESUBSCRIPTION, PAYPALKEYVALUESUBSCRIPTIONLIVE } from "../PricingTable";
import ApiService from "../api";

export default function PayPalButtonOwn(props) {
  const daynight = useSelector((state) => state.data.dayNight);
  const cookiesModal = useSelector((state) => state.data.cookiesModal);
  const history = useHistory();
  const d = useTranslation();

  const [show, setShow] = useState(false);
  const [email, setEmail] = useState('');

  const plan = props.plan;
  const [client_id, setClientID] = useState('');

  
  function getKeyByValue(object, value) {
    if(value.includes("sp")) {
      return Object.keys(object).find(key => object[key][1] === value);
    }

    return Object.keys(object).find(key => object[key][2] === value);
  }

  /*
  * function for server callback after paypal onetime payment
  */
  function payPalOneTimeServerCallBack(details, data) {
    ApiService.payOncePayPal({email, details: details, pay_plan: plan, data: data}).then(response => {
      // console.log(response.data);
      setShow(true);

      setTimeout(() => {
        setShow(false);
        history.push('/user');
      }, 4000);
    }).catch(error => {
        console.log("error: ", error)
        setShow(false);
    })    
  }

  /*
  * function for server callback after paypal subscription payment
  */
  function payPalSubServerCallBack(details, data) {
    ApiService.paySubPayPal({email, details: details, pay_plan: plan, data: data}).then(response => {
      // console.log(response.data);
      setShow(true);

      setTimeout(() => {
        setShow(false);
        history.push('/user');
      }, 4000);
    }).catch(error => {
        console.log("error: ", error)
        setShow(false);
    })    
  }


  // use useEffect lifecycle method
  useEffect(() => {
    // console.log("loaded!", PRICINGTABLECORONCE[getKeyByValue(PRICINGTABLECORONCE, plan)][0]);

    // TODO: what happens when there is no email? 
    const fetchUser = async () => {
      if(localStorage.getItem('user') !== null) {
          setEmail(localStorage.getItem('user'));
      }};
    fetchUser();

    if(process.env.REACT_APP_DEBUG_STATE === "true") {
      setClientID(process.env.REACT_APP_PAYPAL_CLIENT_ID);
    } else {
      setClientID(process.env.REACT_APP_PAYPAL_CLIENT_ID_LIVE);
    }

    // console.log("ID: ", client_id);
  });
  
  if(plan.includes("sp")) {
    return (
      <>
        {show ? (
          <Alert
              variant="success" // warning
              style={{ position: "fixed", left: "50%", transform: "translateX(-50%)", top: "36px", zIndex: "1040" }}
          >
              {d("price.success")}
          </Alert>
          ) : (
          ""
        )}

        <section style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }} 
          className="container"
        >
          {/* https://github.com/Luehang/react-paypal-button-v2/issues/115 */}
          <PayPalButton
            style={{
              label: 'subscribe'
            }}
            options={{vault: true,
                      "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID_LIVE}} // TODO: check why client_id is empty
            createSubscription={(data, actions) => {
              let plan_ident;
              if(process.env.REACT_APP_DEBUG_STATE === "true") {
                plan_ident = PAYPALKEYVALUESUBSCRIPTION[getKeyByValue(PAYPALKEYVALUESUBSCRIPTION, plan)][2];
              } else {
                plan_ident = PAYPALKEYVALUESUBSCRIPTIONLIVE[getKeyByValue(PAYPALKEYVALUESUBSCRIPTIONLIVE, plan)][2];
              }

              return actions.subscription.create({
                /* TODO: add all the plans inside paypal like on stripe */
                /* Creates the subscription */
                plan_id: plan_ident
              });
            }}
            onApprove={(data, actions) => {
              // Capture the funds from the transaction
              return actions.subscription.get().then(function(details) {
                // Show a success message to your buyer
                // console.log("Subscription completed and data is: ", data);
    
                // OPTIONAL: Call your server to save the subscription
                
                return payPalSubServerCallBack(details, data);
                /*
                return fetch("/paypal-subscription-complete", {
                  method: "post",
                  body: JSON.stringify({
                    orderID: data.orderID,
                    subscriptionID: data.subscriptionID
                  })
                });
                */
              });
            }}
          />
        </section>
      </>
    );
  } else {
    return (
      <>
        {show ? (
          <Alert
              variant="success" // warning
              style={{ position: "fixed", left: "50%", transform: "translateX(-50%)", top: "36px", zIndex: "1040" }}
          >
              {d("price.success")}
          </Alert>
          ) : (
          ""
        )}

        <section style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }} 
          className="container"
        >
          <PayPalButton
            amount={PRICINGTABLECORONCE[getKeyByValue(PRICINGTABLECORONCE, plan)][0]} //"0.01"
            // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
            onSuccess={(details, data) => {
              // console.log("Transaction completed by " + details.payer.name.given_name);
    
              // OPTIONAL: Call your server to save the transaction
              return payPalOneTimeServerCallBack(details, data);
              /*
              return fetch("/paypal-transaction-complete", {
                method: "post",
                body: JSON.stringify({
                  orderID: data.orderID
                })
              });
              */
            }}
          />
        </section>
      </>
    );
  }
}
