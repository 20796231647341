import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { useStore } from "../../../hooks";
import style from "./Services.module.scss";
import CanvasDraw from "react-canvas-draw";
import { SERVICES } from "../../../../../components/services/Services";

export default function ImageInpainting(props) {
 

  const initStateObj = Object.freeze({
    color: "#ffc600",
    width: 400,
    height: 400,
    brushRadius: 10,
    lazyRadius: 1, // 12
    backgroundImg:
      "https://upload.wikimedia.org/wikipedia/commons/a/a1/Nepalese_Mhapuja_Mandala.jpg",
    imgs: [
      "https://upload.wikimedia.org/wikipedia/commons/a/a1/Nepalese_Mhapuja_Mandala.jpg",
      "https://i.imgur.com/a0CGGVC.jpg",
    ],
    customImg: null,
    reduxState: "reduxState",
  });

  const initStateImageObj = Object.freeze({
    customImg: null,
  });

  const initStatePBEImageObj = Object.freeze({
    customImg: null,
  });

  const initDims = Object.freeze({
    width: null,
    height: null,
  });
  const state = useStore();
  const dispatch = useDispatch();
  const d = useTranslation();
  const [isExpand, setIsExpand] = useState(false);
  const daynight = useSelector((state) => state.data.dayNight);
  const data = useTranslation();

  const [stateObj, setState] = useState(initStateObj);
  const [stateObjImage, setStateImage] = useState(initStateImageObj);
  const [stateObjPBEImage, setStatePBEImage] = useState(initStatePBEImageObj);
  const [stateDims, setStateDims] = useState(initDims);
  const [prompt, setPrompt] = useState("");

  let uploadedImage = React.createRef();
  let uploadedPBEImage = React.createRef();
  let imageUploader = React.createRef();
  let pbeImageUploader = React.createRef();

  const service = props?.service

  const handleChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleClose = () => {
    props.setShow(false);
    console.log(state);
  };

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);

      const readerAdvanced = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.onload = () => resolve(fileReader.result);
          fileReader.readAsDataURL(file);
        });
      };

      const readFile = (file) => {
        readerAdvanced(file).then((result) =>
          setStateImage({
            customImg: result,
          })
        );
      };

      readFile(file);
    }
  };

  const getRealDimensions = (image) => {
    if (image != null) {
      let uimg = new Image();
      uimg.src = image; // stateObjImage.customImg
      uimg.decode().then(() => {
        let decWidth = uimg.width;
        let decHeight = uimg.height;

        setStateDims({
          width: decWidth,
          height: decHeight,
        });

        const getCanvasDimensions = () => {
          // Width is determined by the css value for the viewport width this is then respected by the device pixel ratio. This is then used to set the canvas.width value
          var _width = Math.round(
            (Number(stateDims.width) / devicePixelRatio) * devicePixelRatio
          );

          // height is determined by the css value for the viewport height this is then respected by the device pixel ratio. This is then used to set the canvas.height value
          var _height = Math.round(
            (Number(stateDims.height) / devicePixelRatio) * devicePixelRatio
          );
        };

        getCanvasDimensions();
      });
    }
  };

  const handlePbEImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedPBEImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);

      const readerAdvanced = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.onload = () => resolve(fileReader.result);
          fileReader.readAsDataURL(file);
        });
      };

      const readFile = (file) => {
        readerAdvanced(file).then((result) =>
          setStatePBEImage({
            customImg: result,
          })
        );
      };

      readFile(file);
    }
  };

  useEffect(() => {
    getRealDimensions(stateObjImage.customImg); // This will be executed when the state changes
  }, [stateObjImage]);

  let saveableCanvas = null;
  let loadableCanvas = null;

  const saveFile = (e, post) => {
    e.preventDefault();

    // THIS SAVING WORKS
    /*
		saveAs(
			post,
			"test"
		);

		saveAs(
			stateObjImage.customImg,
			"actualImage"
		);
    */

    // props.parentCallback(stateObjImage.customImg, post);
    props.parentCallback(
      stateObjImage.customImg,
      post,
      prompt,
      stateObjPBEImage.customImg
    );
  };

  async function getImageFromUrl(url) {
    console.log("getImageFromUrl")
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch the image");
      }
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error("Error fetching the image:", error);
      return null;
    }
  }

  function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  async function convertImageUrlToBase64(url) {
    const imageBlob = await getImageFromUrl(url);
    if (!imageBlob) {
      console.error("Failed to fetch the image from the URL");
      return null;
    }
    const base64Image = await blobToBase64(imageBlob);
    setStateImage({
      customImg: `data:image/jpeg;base64,${base64Image}`,
    });
    console.log(base64Image);
    return base64Image;
  }

  console.log(props);
  useEffect(() => {
    if(props.service === 'ii' || props.service === 'sd' || props.service ==='pe'){
      convertImageUrlToBase64(props.imgSrc);
    }
    console.log(props);
  }, [props.service]);
  
  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={`${!isExpand ? style.modal90w : style.modal100w} ${
        daynight ? style.nightMode : ""
      }`}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body>
        <section
          className={`${style.teamSection} ${daynight ? style.nightMode : ""}`}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mx-auto">
                <div>
                  {/* {service == SERVICES.II ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop:"10px"
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            handleImageUpload(e);
                          }}
                          ref={imageUploader}
                          style={{
                            display: "none",
                          }}
                        />
                        <div
                          className={style.uploadImageCard}
                          onClick={() => imageUploader.current.click()}
                        >
                          <img
                            ref={uploadedImage}
                            style={{
                              width: "100%",
                              height: "100%",
                              position: "acsolute",
                            }}
                          />
                        </div>
                        <div className={style.commonTextHeader}>
                          {d("heroSection.upload")}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {service == SERVICES.SD ? (
                    <>
                      <b className={`${style.expText}`}>
                        {d("imageEditor.editor.promptTitle")}:{" "}
                      </b>
                      <input
                        type="text"
                        name="prompt"
                        id="prompt"
                        onChange={handleChange}
                        placeholder={d("imageEditor.editor.prompt")}
                        class={style.commonInput}
                      />

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            handleImageUpload(e);
                          }}
                          ref={imageUploader}
                          style={{
                            display: "none",
                          }}
                        />
                        <div
                          className={style.uploadImageCard}
                          onClick={() => imageUploader.current.click()}
                        >
                          <img
                            ref={uploadedImage}
                            style={{
                              width: "100%",
                              height: "100%",
                              position: "acsolute",
                            }}
                          />
                        </div>
                        <div className={style.commonTextHeader}>
                          {d("heroSection.upload")}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )} */}

                  {service == SERVICES.PE ? (
                    <>
                      <div class="row">
                        <div class="col-lg-6 mx-auto">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) => {
                                handleImageUpload(e);
                              }}
                              ref={imageUploader}
                              style={{
                                display: "none",
                              }}
                            />
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                              }}
                              className={style.uploadImageCard}
                              onClick={() => imageUploader.current.click()}
                            >
                              <img
                                ref={uploadedImage}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  position: "acsolute",
                                }}
                              />
                            </div>
                            <div className={style.commonTextHeader}>
                              {d("heroSection.upload")}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 mx-auto">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) => {
                                handlePbEImageUpload(e);
                              }}
                              ref={pbeImageUploader}
                              style={{
                                display: "none",
                              }}
                            />
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                              }}
                              className={style.uploadImageCard}
                              onClick={() => pbeImageUploader.current.click()}
                            >
                              <img
                                ref={uploadedPBEImage}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  position: "acsolute",
                                }}
                              />
                            </div>
                            <div className={style.commonTextHeader}>
                              {d("heroSection.upload")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <div className={style.tools}>
                    <button
                      className={`btn btn-secondary ${style.btn_primary}`}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        handleClose();
                      }}
                    >
                      {d("imageEditor.editor.close")}
                    </button>
                    <button
                      className={`btn btn-secondary ${style.btn_primary}`}
                      onClick={(e) => {
                        saveFile(e, saveableCanvas.getDataURL());
                      }}
                    >
                      
                      {d("imageEditor.editor.apply")}
                    </button>
                    <>&nbsp;</>

                    <button
                      className={`btn btn-secondary ${style.btn_primary}`}
                      onClick={() => {
                        saveableCanvas.eraseAll();
                      }}
                    >
                      {d("imageEditor.editor.erase")}
                    </button>
                    <>&nbsp;</>
                    <button
                      className={`btn btn-secondary ${style.btn_primary}`}
                      onClick={() => {
                        saveableCanvas.undo();
                      }}
                    >
                      {d("imageEditor.editor.undo")}
                    </button>
                    <>&nbsp;</>

                    <>&nbsp;</>

                    <div>
                      <div className={style.commonTextHeader}>
                      {d("imageEditor.editor.brushRadius")}:
                      </div>
                      <input
                        type="number"
                        value={stateObj.brushRadius}
                        onChange={(e) =>
                          setState({
                            brushRadius: parseInt(e.target.value, 10),
                          })
                        }
                      />
                    </div>
                    <>&nbsp;</>
                    <div>
                      <div className={style.commonTextHeader}>{d("imageEditor.editor.lazyRadius")}:</div>
                      <input
                        type="number"
                        value={stateObj.lazyRadius}
                        onChange={(e) =>
                          setState({ lazyRadius: parseInt(e.target.value, 10) })
                        }
                      />
                    </div>
                  </div>

                  {stateDims.height != null && (
                    <>
                      <div
                        className={`container`}
                        style={{
                          position: "relative",
                          color: "white",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          objectFit: "contain",
                          margin: "0 0 0 0",
                          maxHeight: "65%",
                          maxWidth: "100%",
                          minWidth: "0",
                          minHeight: "0",
                          overflowX: "auto",
                          overflowY: "auto",
                          height:"65vh"
                        }}
                      >
                        <CanvasDraw
                          imgSrc={stateObjImage.customImg}
                          enablePanAndZoom
                          clampLinesToDocument
                          ref={(canvasDraw) => (saveableCanvas = canvasDraw)}
                          brushColor="#ffcc00bb"
                          brushRadius={stateObj.brushRadius}
                          lazyRadius={stateObj.lazyRadius}
                          canvasWidth={stateDims.width}
                          canvasHeight={stateDims.height}
                          zoomExtents={{ min: 0.33, max: 3 }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
}
