import { toast as toaster } from "react-toastify"

export function checkIfInferencedImageAvailable(service) {
  return (
    service === "rb" ||
    service === "hdr" ||
    service === "cl" ||
    service === "ds" ||
    service === "ii" ||
    service === "rf" ||
    service === undefined
  );
}

export  function cutLongFilename(filename, maxLength,d) {
  if(!filename || filename ==='no-picture-available.jpg'){
    // return d("header.uploadText.text4");
    return "...";
  }
  if (filename.length <= maxLength) {
    return filename;
  }

  const extensionIndex = filename.lastIndexOf('.');
  const extension = filename.substring(extensionIndex);
  const filenameWithoutExtension = filename.substring(0, extensionIndex);

  const filenameLength = filenameWithoutExtension.length;
  const maxCharsBeforeExtension = maxLength - extension.length - 1;

  if (maxCharsBeforeExtension < 1) {
    return filename.substring(0, maxLength);
  }

  const trimmedFilename = filenameWithoutExtension.substring(0, maxCharsBeforeExtension);
  const ellipsis = '...';

  return trimmedFilename + ellipsis + extension;
}

export function convertToMetricDate(date) {
  const [year, month, day] = date.split('-');
  return `${day}/${month}/${year}`;
}

export function decodeServiceName (key){
  switch (key) {
    case "rb":
      return "serviceRB";
    case "hdr":
      return "serviceHDR";
    case "ns":
      return "serviceNS";
    case "nsv":
      return "serviceNSV";
    case "pns":
      return "servicePNS";
    case "sr":
      return "serviceSR";
    case "srv":
      return "serviceSRV";
    case "dh":
      return "serviceDH";
    case "db":
      return "serviceDB";
    case "ci":
      return "serviceCI";
    case "cv":
      return "serviceCV";
    case "fi":
      return "serviceFI";
    case "3dp":
      return "service3DP";
    case "rbv":
      return "serviceRBV";
    case "hdvr":
      return "serviceHDVR";
    case "ii":
      return "serviceII";
    case "iv":
      return "serviceIV";
    case "opr":
      return "serviceOPR";
    case "dpi":
      return "serviceDPI";
    case "dpv":
      return "serviceDPV";
    case "cl":
      return "serviceCL";
    case "ds":
      return "serviceDS";
    case "rf":
      return "serviceRF";
    case "fb":
      return "serviceFB";
    case "sd":
      return "serviceSD";
    case "pe":
      return "servicePE";
    default:
      return "Unknown service";
  }
}

export function toast(message, type){
  toaster(message, {
    type,
    hideProgressBar: true,
    isLoading: false,
    position: "top-right",
    autoClose: 2500,
  })
}