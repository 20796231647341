import React, { useEffect } from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";

import HeroSection from "../components/HeroSection";
import FeaturesTiles from "../components/FeaturesTiles";
import GallerySection from "../components/GallerySection";
import DetailSection from "../components/DetailSection";
import SubDetailSection from "../components/SubDetailSection";
import SubHeroSection from "../components/SubHeroSection";
import AdBannner from "../components/AdBannner";
import StunningQuality from "../components/StunningQuality";
import CookiesModal from "../components/CookiesModal";


export default function Home() {
  const data = useTranslation();
  const cookiesModal = useSelector((state) => state.data.cookiesModal);

  useEffect(() => {
    document.title = `${data("tab.home")} - dioptify.com`;
  });  

  return (
    <>
      <HeroSection data={data} />
      <GallerySection data={data} />
      <FeaturesTiles data={data} />
      <DetailSection data={data} />
      <StunningQuality data={data} />      
      <SubDetailSection data={data} />
      <AdBannner data={data} />
      {/*<SubHeroSection data={data} />*/}
      {cookiesModal ? <CookiesModal /> : ""}
    </>
  );
}
