import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from '../../axios';
import { useHistory, useParams } from 'react-router-dom';
import { saveAs } from "file-saver";

import style from "../../styles/postOverview.module.css";

const useStyles = makeStyles((theme) => ({
	cardMedia: {
		paddingTop: '56.25%', // 16:9
		borderRadius: '15px',
		margin: '10px 10px 10px 10px'
	},
	link: {
		margin: theme.spacing(1, 1.5),
	},
	cardHeader: {
		backgroundColor:
			theme.palette.type === 'light'
				? theme.palette.grey[200]
				: theme.palette.grey[700],
	},
	postTitle: {
		fontSize: '18px',
		textAlign: 'left',
	},
	postText: {
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'baseline',
		fontSize: '12px',
		textAlign: 'left',
		marginBottom: theme.spacing(2),
		color: "rgba(150, 150, 150, 1.0)"
	},
	postTextNight: {
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'baseline',
		fontSize: '12px',
		textAlign: 'left',
		marginBottom: theme.spacing(2),
		color: "rgba(120, 120, 120, 1.0)"
	},
	card: {
		backgroundColor: "rgba(66, 66, 64, 1.0)",
		color: "rgba(220, 220, 220, 1.0)",
		borderRadius: "25px"
	},
	cardNight: {
		backgroundColor: "rgba(245, 245, 245, 1.0)",
		borderRadius: "25px"
	}
}));

const MultiPosts = (props) => {
	const { posts } = props;
	const classes = useStyles();
	const d = useTranslation();
	const daynight = useSelector((state) => state.data.dayNight);
	const history = useHistory();

	const handleDelete = (e, post) => {
		e.preventDefault();
		axiosInstance
			.delete('admin/msdelete/' + post.id)
			.catch(function (error) {
				if (error.response) {
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				}
			})
			.then(function () {
				history.push({
					pathname: '/posts',
					search: '?multiposts',
				})
				window.location.reload();
			});
	};

	const saveFile = (e, post) => {
		e.preventDefault();
		var filename = post.image.substring(post.image.lastIndexOf('/')+1);
		
		saveAs(
			post.image,
			filename
		);
	};

	if (!posts || posts.length === 0) return (
		<>
			{/*<p>{d("posts.missing")}<a href="/services">{d("posts.muploads")}</a></p>*/}
			<p><a style={{color:'var(--greenColor)', fontWeight:800}} href="/services">{d("posts.missing")}{d("posts.muploads")} <i class="fa fa-arrow-circle-right" aria-hidden="true"></i></a></p>
			<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
		</>

	);

	return (
		<React.Fragment>
			<Container maxWidth="md" component="main">
				<Grid container spacing={5} alignItems="flex-end">
					{posts.map((post) => {
						return (
							// Enterprise card is full width at sm breakpoint
							<Grid item key={post.id} xs={12} md={6}>
								<Card className={`${daynight ? classes.card : classes.cardNight}`}>
									<Link
										color="textPrimary"
										href={'multiservicepost/' + post.slug}
										className={classes.link}
									>
										<CardMedia
											className={classes.cardMedia}
											image={post.image}
											title={d("posts.gotitle")}
										/>
									</Link>
									<CardContent className={classes.cardContent}>
										{/*
										<Typography
											gutterBottom
											variant="h6"
											component="h2"
											className={classes.postTitle}
										>
											{d("posts.postTitleImg")}: {post.title.substr(0, 30)}...
										</Typography>
										*/}
										<div className={`${daynight ? classes.postText : classes.postTextNight}`}>
											<Typography>
												<a style={{fontWeight:800, color:"var(--dioptifyGreen)"}}>{d("posts.postTitleImg")}</a>: {(post.image.substring(post.image.lastIndexOf('/')+1)).slice(0, 35)}
												<br />
												<a style={{fontWeight:800, color:"var(--dioptifyGreen)"}}>{d("posts.postTitlePaid")}</a>: {post.paid ? d("posts.paidYes") : d("posts.paidNo")}
												<br />
												<a style={{fontWeight:800, color:"var(--dioptifyGreen)"}}>{d("posts.postTitleService")} 1</a>: {post.service1}
												<br />
												<a style={{fontWeight:800, color:"var(--dioptifyGreen)"}}>{d("posts.postTitleService")} 2</a>: {(post.service2 === "NONE") ? "-" : post.service2}
												<br />
												<a style={{fontWeight:800, color:"var(--dioptifyGreen)"}}>{d("posts.postTitleService")} 3</a>: {(post.service3 === "NONE") ? "-" : post.service3}
												<br />
												<a style={{fontWeight:800, color:"var(--dioptifyGreen)"}}>{d("posts.postTitlePublished")}</a>: {post.published.substr(0, 10)}
											</Typography>
										</div>
										{/*
										<Button
											variant="contained"
											style={{
												borderRadius: 5,
												backgroundColor: "#00D100",
											}}
											type="submit"
											onClick={(e) => {
												handleDelete(e, post);
											}}											
										>
											{d("posts.postDelete")}
										</Button>
										<>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</>
										<Button
											variant="contained"
											style={{
												borderRadius: 5,
												backgroundColor: "#ff6961",
											}}
											type="submit"
											onClick={(e) => {
												handleDelete(e, post);
											}}											
										>
											{d("posts.postDelete")}
										</Button>
										*/}
										<a
											className={`btn btn-outline-secondary ${style.buttonAdventure}`}
											onClick={(e) => {
												saveFile(e, post);
											}}
										>
											{d("posts.postDownload")}
											<>&nbsp;</>
											<i className="fas fa-cloud-download-alt"></i>
										</a>

										{localStorage.getItem('user') ? (
											<>
												<>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</>
	
												<a
													className={`btn btn-outline-danger ${style.buttonAdventureSecondary}`}
													onClick={(e) => {
														handleDelete(e, post);
													}}
												>
													{d("posts.postDelete")}
													<>&nbsp;</>
													<i className="fas fa-trash"></i>
												</a>
												</>
											) : (
												<></>
											)
										}										
									</CardContent>
								</Card>
							</Grid>
						);
					})}
				</Grid>
			</Container>
		</React.Fragment>
	);
};
export default MultiPosts;
