import React, { useState } from "react";
import style from "../styles/cookies.module.css";
import CookiesTable from "../components/CookiesTable";
import data from "../data/cookiesTableData.json";
import { useSelector } from "react-redux";
import CookiesModal from "../components/CookiesModal";
import CookiesPolicyModal from "../components/CookiesPolicyModal";
import { useTranslation } from "react-multi-lang";

export default function Cookies() {
  const [show, setShow] = useState(false);
  const daynight = useSelector((state) => state.data.dayNight);
  const cookiesModal = useSelector((state) => state.data.cookiesModal);
  const d = useTranslation();

  const handleShow = () => setShow(true);
  return (
    <>
      {cookiesModal ? <CookiesModal /> : ""}
      <div className={`${style.cookiesSection} ${daynight ? style.nightMode : ""}`}>
        <div class="col-lg-12 mx-auto">
          <div className={style.heroSection}>
            <h1 class="mainHeading mb-1">{d("cookie.title")}</h1>
            <h4 className="commonText">{d("cookie.subtitle")}</h4>
          </div>
          <div className="mx-2">
            <div className={style.card}>
              <p>
                {d("legal.cookies.0")}
              </p>
              <p>
                {d("legal.cookies.01")}
              </p>
              <h3 className="commonText">{d("legal.cookies.1")}</h3>
              <p>
                {d("legal.cookies.12")}
              </p>
              <p>
                {d("legal.cookies.13")}
              </p>
              <h3 className="commonText">{d("legal.cookies.2")}</h3>
              <p>
                {d("legal.cookies.21")}
              </p>
              <p>
                {d("legal.cookies.211")}
              </p>
              <p>{d("legal.cookies.22")}</p>
              <ul>
                <li>
                  {d("legal.cookies.221")}
                </li>
                <li>
                  {d("legal.cookies.222")}
                </li>
                <li>
                  {d("legal.cookies.223")}
                </li>
              </ul>
              <p>{d("legal.cookies.23")}</p>
              <h3 className="commonText">First-party cookies</h3>
              <CookiesTable data={data[0]} />

              <h3 className="commonText">Third-party cookies</h3>
              <CookiesTable data={data[1]} />
              <CookiesTable data={data[2]} />
              <CookiesTable data={data[3]} />
              {/*<CookiesTable data={data[4]} />*/}
              <h3 className="commonText">{d("legal.cookies.3")}</h3>
              <p>
                {d("legal.cookies.31")}
              </p>
              <p>{d("legal.cookies.311")}</p>
              <button className={`btn btn-primary ${style.btn_primary} mb-3`} onClick={handleShow}>
                {d("legal.cookies.3111")}
              </button>
              <p>
                {d("legal.cookies.312")}
              </p>
              <ul>
                <li>
                  <a href="https://support.google.com/chrome/answer/95647?hl=en">Google Chrome</a>
                </li>
                <li>
                  <a href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">Internet Explorer</a>
                </li>
                <li>
                  <a href="https://www.opera.com/de/use-cases/clean-browser-and-remove-trackers">Opera</a>
                </li>
                <li>
                  <a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?">Firefox</a>
                </li>
                <li>
                  <a href="https://support.apple.com/en-au/guide/safari/sfri11471/mac">Safari</a>
                </li>
              </ul>
              <p>
                {d("legal.cookies.313")}
              </p>
            </div>
          </div>
        </div>
      </div>

      <CookiesPolicyModal show={show} setShow={setShow} />
    </>
  );
}
