import React, { useEffect } from "react";
import style from "../../styles/timeline.module.scss";
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";

export default function HeroSection() {
  const d = useTranslation();
  const daynight = useSelector((state) => state.data.dayNight);

  function classToggle(event) {
    // var el = document.querySelector('style.iconCards__content');
    // el.classList.toggle('step-animation');
    console.log("clicked");
  }
  
  // document.querySelector('#toggle-animation').addEventListener('click', classToggle);

  useEffect(() => {
		document.title = `${d("aboutus.story")} - dioptify.com`;
	});
  
  return (
    <section class={`${style.toolsSection} ${daynight ? style.nightMode : ""}`}>
      <div class="container">
        <div class="col-lg-12 mx-auto">
          <div class={style.heroSectionContents}>
            <h1 class="mainHeading mb-1">{d("timeline.title")}</h1>
            <div class="commonText">{d("timeline.subtitle")}</div>
          </div>

          {/*className={`${style.cdContainer} d-flex align-items-center justify-content-center`}*/}
          <section className={`${style.cdContainer} ${style.cdTimeline}`}>
              <div className={`${style.cdTimelineBlock}`}>
                <div className={`${style.cdTimelineImg} ${style.cdPicture}`}>
                  <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/148866/cd-icon-picture.svg" alt="Picture"></img>
                </div> 
                <div className={`${style.cdTimelineContent}`}>
                  <h2>Title of section 1</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto, optio, dolorum provident rerum aut hic quasi placeat iure tempora laudantium ipsa ad debitis unde? Iste voluptatibus minus veritatis qui ut.</p>
                  {/*<a href="#0" className={`${style.cdReadMore}`}>Read more</a>*/}
                  <span className={`${style.cdDate}`}>01.03.2021</span>
                </div> 
              </div> 

              <div className={`${style.cdTimelineBlock}`}>
                <div className={`${style.cdTimelineImg} ${style.cdMovie}`}>
                  <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/148866/cd-icon-movie.svg" alt="Movie"></img>
                </div> 

                <div className={`${style.cdTimelineContent}`}>
                  <h2>Title of section 2</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto, optio, dolorum provident rerum aut hic quasi placeat iure tempora laudantium ipsa ad debitis unde?</p>
                  {/*<a href="#0" className={`${style.cdReadMore}`}>Read more</a>*/}
                  <span className={`${style.cdDate}`}>01.05.2021</span>
                </div> 
              </div> 

              <div className={`${style.cdTimelineBlock}`}>
                <div className={`${style.cdTimelineImg} ${style.cdPicture}`}>
                  <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/148866/cd-icon-picture.svg" alt="Picture"></img>
                </div> 

                <div className={`${style.cdTimelineContent}`}>
                  <h2>Title of section 3</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi, obcaecati, 
                    quisquam id molestias eaque asperiores voluptatibus cupiditate error assumenda delectus odit 
                    similique earum voluptatem doloremque dolorem ipsam quae rerum quis. Odit, itaque, deserunt corporis 
                    vero ipsum nisi eius odio natus ullam provident pariatur temporibus quia eos repellat consequuntur 
                    perferendis enim amet quae quasi repudiandae sed quod veniam dolore possimus rem voluptatum eveniet 
                    eligendi quis fugiat aliquam sunt similique aut adipisci.</p>
                  {/*<a href="#0" className={`${style.cdReadMore}`}>Read more</a>*/}
                  <span className={`${style.cdDate}`}>25.05.2021</span>
                </div> 
              </div> 

              <div className={`${style.cdTimelineBlock}`}>
                <div className={`${style.cdTimelineImg} ${style.cdLocation}`}>
                  <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/148866/cd-icon-location.svg" alt="Location"></img>
                </div>

                <div className={`${style.cdTimelineContent}`}>
                  <h2>Title of section 4</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto, optio, dolorum provident rerum aut hic quasi placeat iure tempora laudantium ipsa ad debitis unde? Iste voluptatibus minus veritatis qui ut.</p>
                  {/*<a href="#0" className={`${style.cdReadMore}`}>Read more</a>*/}
                  <span className={`${style.cdDate}`}>12.08.2021</span>
                </div> 
              </div> 

              <div className={`${style.cdTimelineBlock}`}>
                <div className={`${style.cdTimelineImg} ${style.cdLocation}`}>
                  <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/148866/cd-icon-location.svg" alt="Location"></img>
                </div> 

                <div className={`${style.cdTimelineContent}`}>
                  <h2>Title of section 5</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto, optio, dolorum provident rerum.</p>
                  {/*<a href="#0" className={`${style.cdReadMore}`}>Read more</a>*/}
                  <span className={`${style.cdDate}`}>23.12.2021</span>
                </div> 
              </div> 

              <div className={`${style.cdTimelineBlock}`}>
                <div className={`${style.cdTimelineImg} ${style.cdMovie}`}>
                  <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/148866/cd-icon-movie.svg" alt="Movie"></img>
                </div> 

                <div className={`${style.cdTimelineContent}`}>
                  <h2>Final Section</h2>
                  <p>This is the content of the last section</p>
                  <span className={`${style.cdDate}`}>02.01.2022</span>
                </div> 
              </div> 
            </section> 
        </div>
      </div>
    </section>
  );
}
