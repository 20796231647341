import React, { useState } from "react";
import style from "../styles/career.module.css";
import CookiesTable from "../components/CookiesTable";
import data from "../data/cookiesTableData.json";
import { useSelector } from "react-redux";
import CookiesModal from "../components/CookiesModal";
import CookiesPolicyModal from "../components/CookiesPolicyModal";
import CareerOptions from "../components/careerComponent/CareerOptions";

export default function Career() {
  const [show, setShow] = useState(false);
  const daynight = useSelector((state) => state.data.dayNight);
  const cookiesModal = useSelector((state) => state.data.cookiesModal);

  const handleShow = () => setShow(true);
  return (
    <>
      {cookiesModal ? <CookiesModal /> : ""}
      <div className={`${style.cookiesSection} ${daynight ? style.nightMode : ""}`}>
        <div class="col-lg-12 mx-auto">
          <div className={style.heroSection}>
            <h1 class="mainHeading mb-1">Career</h1>
            <h4 className="commonText">Version: 2021-03-30</h4>
          </div>
        </div>
      </div>
      
      <CareerOptions />
      <CookiesPolicyModal show={show} setShow={setShow} />
    </>
  );
}
