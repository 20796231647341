import React from "react";
import style from "../../styles/ourTeamSection.module.css";
import { useSelector } from "react-redux";

export default function HeroSection(props) {
  const daynight = useSelector((state) => state.data.dayNight);
  const d = props.data;

  return (
    <section class={`${style.toolsSection} ${daynight ? style.nightMode : ""}`}>
      <div class="container">
        <div class="col-lg-12 mx-auto">
          <div class={style.heroSectionContents}>
            <h2 className={`${style.subHeading} subHeading`}>
              {d("aboutus.team")}
            </h2>
          </div>
          <div class="row">
          <div class="col-lg-4">
              <div class={style.toolsCard}>
                <a class={style.imgLink}>
                  <a>
                    <img
                      className={style.imgRoundenCorners}
                      class="img-fluid"
                      src="/team/team1.jpg"
                    />
                  </a>
                </a>
                <h2 class={style.subHeading}>
                  Kevin
                </h2>
                <p>CEO/CTO</p>
                {/*
                <a href="#" class={`btn btn-primary ${style.btn}`}>
                  Learn more
                </a>
                */}
              </div>
          </div>
          <div class="col-lg-4">
              <div class={style.toolsCard}>
                <a class={style.imgLink}>
                  <a>
                    <img
                      className={style.imgRoundenCorners}
                      class="img-fluid"
                      src="/team/team2cropped.jpeg"
                    />
                  </a>
                </a>
                <h2 class={style.subHeading}>
                  Adrian
                </h2>
                <p>CEO/CFO</p>
                {/*
                <a href="#" class={`btn btn-primary ${style.btn}`}>
                  Learn more
                </a>
                */}
              </div>
          </div>
          <div class="col-lg-4">
              <div class={style.toolsCard}>
                <a class={style.imgLink}>
                  <a>
                    <img
                      className={style.imgRoundenCorners}
                      class="img-fluid"
                      src="/team/team3.jpg"
                    />
                  </a>
                </a>
                <h2 class={style.subHeading}>
                  Ulrich
                </h2>
                <p>Technical Advisor</p>
                {/*
                <a href="#" class={`btn btn-primary ${style.btn}`}>
                  Learn more
                </a>
                */}
              </div>
          </div>
          </div>
        </div>
      </div>
    </section>
  );
}
