import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { useParams, useHistory } from 'react-router-dom';
//MaterialUI
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import Button from '@material-ui/core/Button';
import { saveAs } from "file-saver";


import style from "../../styles/services.module.css";

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
}));

export default function EditorPost() {
	const daynight = useSelector((state) => state.data.dayNight);
	const d = useTranslation();
	const history = useHistory();
	
	const { slug } = useParams();
	const classes = useStyles();

	const [data, setData] = useState({
		posts: [],
	});

	let imageSrc;
	if(data.posts.service !== "rb" && data.posts.service !== "hdr" && data.posts.service !== "cl" && data.posts.service !== "ds" && data.posts.service !== "rf") {
		if(!(data.posts.paid)) {
			imageSrc = data.posts.preview_image;
		} else {
			imageSrc = data.posts.image;
		}
	} else {
		if(!(data.posts.paid)) {
			// TODO: check if this correct for all services
			// dispatch(setImage({ image: post.preview_image, id: uuidv4() }));	
			imageSrc = data.posts.original_image;

		} else {
			imageSrc = data.posts.original_image;
		}
	}

	useEffect(() => {
		axiosInstance.get('editorservicepost/' + slug).then((res) => {
			setData({
				posts: res.data,
			});
			console.log(res.data);
		});
	}, [setData]);

	const backButton = (event) => {    
        // console.log(event);
		history.push({
			pathname: '/posts',
			search: '?multiposts',
		})
    };

	const handleDelete = (e, post) => {
		e.preventDefault();
		axiosInstance
			.delete('admin/msdelete/' + post.id)
			.catch(function (error) {
				if (error.response) {
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				}
			})
			.then(function () {
				history.push({
					pathname: '/posts',
					search: '?multiposts',
				})
				window.location.reload();
			});
	};

	const saveFile = (e, post) => {
		e.preventDefault();
		var filename = post.image.substring(post.image.lastIndexOf('/')+1);

		if(post.service !== "rb" && post.service !== "hdr" && post.service !== "cl" && post.service !== "ds" && post.service !== "rf") {
			if(!(post.paid)) {
				saveAs(
					post.preview_image,
					filename
				);
			} else {
				saveAs(
					post.image,
					filename
				);
			}
		} else {
			if(!(post.paid)) {
				// TODO: check if this correct for all services
				// dispatch(setImage({ image: post.preview_image, id: uuidv4() }));	
				saveAs(
					post.original_image,
					filename
				);
			} else {
				saveAs(
					post.original_image,
					filename
				);
			}
		}

		// original save logic
		/*
		saveAs(
			post.image,
			filename
		);
		*/
	};

	return (
		<section className={`${style.teamSection} ${daynight ? style.nightMode : ""}`}>
			<div className="container">
				<div className="row">
					<Button
						className={`btn btn-outline-success`}
						onClick={(e) => {
							backButton(e);
						}}
					>
						<div className={style.logo}>
							<i class="fas fa-arrow-circle-left fa-2x"></i>
						</div>
					</Button>
					<div className="col-lg-12 mx-auto">
						<Container component="main" maxWidth="md">
							<div className={classes.heroContent}>
								<Container maxWidth="md">
									{/*
									<Typography
										component="h1"
										variant="h2"
										align="center"
										color="textPrimary"
										gutterBottom
									>
										{data.posts.title}{' '}
									</Typography>{' '}
									*/}
									{/*
									<Typography
										variant="h5"
										align="center"
										color="textSecondary"
										paragraph
									>
										{data.posts.excerpt}{' '}
									</Typography>{' '}
									*/}
									{/*
									<h2>
										{data.posts.excerpt}{' '}
									</h2>
									*/}
									<br />
								</Container>{' '}
							</div>{' '}
							<img src={ imageSrc } />
							{/*
							<img src={ data.posts.image } />
							<img src={ (data.posts.paid && data.posts.service !== "rb" && data.posts.service !== "hdr") ? data.posts.image : data.posts.original_image } />
							<img src={ (!data.posts.paid && data.posts.service !== "rb" && data.posts.service !== "hdr") ? data.posts.preview_image : data.posts.preview_image } />
							*/}
							<br /><br />
							<a
								className={`btn btn-outline-secondary ${style.downloadButtonSingle}`}
								onClick={(e) => {
									saveFile(e, data.posts);
								}}
							>
								{d("posts.postDownload")}
								<>&nbsp;</>
								<i className="fas fa-cloud-download-alt"></i>
							</a>
							<>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</>

							{localStorage.getItem('user') ? (
									<a
										className={`btn btn-outline-danger ${style.downloadButtonSingleSecondary}`}
										onClick={(e) => {
											handleDelete(e, data.posts);
										}}
									>
										{d("posts.postDelete")}
										<>&nbsp;</>
										<i className="fas fa-trash"></i>
									</a>
								) : (
									<></>
								)
							}
						</Container>
					</div>
				</div>
			</div>			
		</section>
	);
}
