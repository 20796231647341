import React from "react";
import style from "../../../styles/windowsScreenStyle/resultSection.module.css";
import { useSelector } from "react-redux";

export default function ResultsSection(props) {
  const d = props.data;
  const daynight = useSelector((state) => state.data.dayNight);
  
  return (
    <section
      class={`${style.resultSection} ${daynight ? style.nightMode : ""}`}
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class={style.resultImageSide}>
              {/*
              <img
                class={style.blackDotsImage}
                src="/images/black-dots-img.svg"
                alt=""
              />
              */}
              <div class={style.squareBox}></div>
              <img
                class={`img-fluid ${style.mainImg}`}
                src="/demo/features/cl_compare_sd.jpeg"
              />
            </div>
          </div>
          <div class="col-lg-6 mt-5 pt-0 pl-3 pt-lg-4 pl-lg-5">
            <h2 class="subHeading mb-2">{d("serviceInfo.cl.subtitle.section2.title")}</h2>
            <div class="commonText">
              {d("serviceInfo.cl.subtitle.section2.text")}
            </div>
            <a class="commonLink mt-4" href="/cl">
            {d("serviceInfo.cl.subtitle.section2.try")}<i class="fas fa-chevron-right"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
