import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useTranslation } from "react-multi-lang";

import CheckoutCapsuleInModal from '../financialComponent/CheckoutCapsuleInModal';
import PayPalButtonOwn from '../financialComponent/paypal/PayPalButtonOwn';
import PayPalButtonOwnSubs from '../financialComponent/paypal/PayPalButtonOwnSubs';
import style from "../../styles/multiStepsSection.module.css";

export default function FormPersonalDetails(props) {
  const next = e => {
    e.preventDefault();
    props.nextStep();
  };

  const back = e => {
    e.preventDefault();
    props.sOrP("card", false);
    props.sOrP("paypal", false);
    props.prevStep();
  };

  // render() {
    const d = useTranslation();
    const { values, handleChange } = props;

    return (
      <>
        {/*TODO: investigate why this is throwing: PayPal SDK not found*/}
        {/*<PayPalButtonOwnSubs />*/}
        {props.values.card
        ?
          <CheckoutCapsuleInModal plan={props.plan}/>
        :
          <PayPalButtonOwn plan={props.plan}/>
        }
        
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          }} 
          class="container"
        >
          
          <MuiThemeProvider>
            <>
                {/*
                <AppBar title="Enter Personal Details" />
                <TextField
                  placeholder="Enter Your Occupation"
                  label="Occupation"
                  onChange={handleChange('occupation')}
                  defaultValue={values.occupation}
                  margin="normal"
                  fullWidth
                />
                <br />
                <TextField
                  placeholder="Enter Your City"
                  label="City"
                  onChange={handleChange('city')}
                  defaultValue={values.city}
                  margin="normal"
                  fullWidth
                />
                <br />
                <TextField
                  placeholder="Enter Your Bio"
                  label="Bio"
                  onChange={handleChange('bio')}
                  defaultValue={values.bio}
                  margin="normal"
                  fullWidth
                />
                <br />
                */}
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={back}
                > <i class="fas fa-arrow-circle-left fa-2x"></i> &nbsp; {d("price.back")}
                </Button>

                {/*
                <Button
                  color="primary"
                  variant="contained"
                  onClick={next}
                >{d("price.forward")}</Button>
                */}
            </>
          </MuiThemeProvider>
        </div>
      </>
    );
  }
// }

// export default FormPersonalDetails;
