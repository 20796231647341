/** External Dependencies */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import MenuItem from '@scaleflex/ui/core/menu-item';
import SaveAs from '@scaleflex/icons/save-as';
import Label from '@scaleflex/ui/core/label';

/** Internal Dependencies */
import { useStore, useTransformedImgData } from '../../hooks';
import getFileFullName from '../../utils/getFileFullName';
import {
  CLOSING_REASONS,
  ELLIPSE_CROP,
  SUPPORTED_IMAGE_TYPES,
} from '../../utils/constants';
import { HIDE_LOADER, SET_FEEDBACK, SET_ORIGINAL_IMAGE, SHOW_LOADER } from '../../actions';
import Modal from '../../components/common/Modal';
import Slider from '../../components/common/Slider';
import restrictNumber from '../../utils/restrictNumber';
import { Resize } from '../../components/tools/Resize';
import ButtonWithMenu from '../../components/common/ButtonWithMenu';
import {
  StyledFileExtensionSelect,
  StyledFileNameInput,
  StyledQualityWrapper,
  StyledResizeOnSave,
} from './Topbar.styled';
import { useTranslation } from 'react-multi-lang';
import { setEditorData } from '../../../../redux/action/image';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from "uuid";

import axiosInstance, { baseURLclear } from '../../../../axios';
import isSameImage from '../../utils/isSameImage';
import loadImage from '../../utils/loadImage';

const DEFAULT_QUALITY_VAL = 0.92;

const sliderStyle = { marginBottom: 16 };
const sownloadButtonWrapperStyle = { width: 50 }; // 67px same width as tabs bar
const sownloadButtonMenuStyle = { marginLeft: 12 };

let isFieSaveMounted = true;

const SaveButton = () => {
  const state = useStore();
  const dispatch1 = useDispatch();


  const optionSaveFnRef = useRef();
  const {
    theme,
    dispatch,
    originalImage,
    resize,
    isLoadingGlobally,
    isApplyingServiceLoading,
    haveNotSavedChanges,
    feedback,
    t,
    adjustments: { crop } = {},
    config: {
      onClose,
      closeAfterSave,
      onBeforeSave,
      forceToPngInEllipticalCrop,
      defaultSavedImageName,
      defaultSavedImageType,
      defaultSavedImageQuality = DEFAULT_QUALITY_VAL,
      useCloudimage,
      moreSaveOptions,
    },
  } = state;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const d = useTranslation()
  const [loading, setLoading] = useState(false);
  const [imageSource, setImageSource] = useState("");
  const imageBeingLoadedSrc = useRef(null);


  const baseUrl = baseURLclear;


  const [imageFileInfo, setImageFileInfo] = useState({
    quality:
      defaultSavedImageQuality <= 0 || defaultSavedImageQuality > 1
        ? DEFAULT_QUALITY_VAL
        : defaultSavedImageQuality,
  });
  

  const transformImgFn = useTransformedImgData();
  const isQualityAcceptable = ['jpeg', 'jpg', 'webp'].includes(
    imageFileInfo.extension,
  );
  const isBlockerError = feedback.duration === 0;
  useEffect(()=>{
    dispatch1(setEditorData(state))
  },
  [state?.adjustments,state.resize,state?.annotations,state?.imgSrc,state.tabId, state?.filter, state?.finetunesProps, state.isApplyingServiceLoading])

  useEffect(()=>{
    dispatch(setEditorData(state))
  },
  [])

  const cancelModal = () => {
    //setEditorData(state)

    if (isFieSaveMounted && isModalOpened) {
      optionSaveFnRef.current = null;
      setIsModalOpened(false);
    }
  };

  const uploadImage = async (file) => {
   
    if (file) {
      let img = file;
      console.log(file)
      let slug = uuidv4();

      let user = "anon";
      if (localStorage.getItem("user") !== null) {
        user = localStorage.getItem("user");
      } else {
        if (localStorage.getItem("temp_user") === null) {
          user = uuidv4();
          localStorage.setItem("temp_user", user);
        } else {
          user = localStorage.getItem("temp_user");
        }
      }

      //	event.preventDefault();
      let formData = new FormData();
      formData.append("title", uuidv4());
      formData.append("slug", slug);
      formData.append("service", "sr");
      formData.append("owner", user);
      formData.append("excerpt", uuidv4());
      formData.append("content", uuidv4());
      formData.append("new_model", true);
      formData.append('is_editor_upload', true);
      formData.append("image", img);
      setLoading(true);

      await axiosInstance
        .post(`createimage/`, formData)
        .then((res) => {
          let responseData = res.data["serializer.data"];
          
          let imageSource = `${baseUrl}${responseData["image"]}`
          console.log(imageSource)
          setImageSource(imageSource)
          loadAndSetOriginalImage(imageSource)
          setLoading(false);

         
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const setNewOriginalImage = useCallback((newOriginalImage) => {
    dispatch({
      type: SET_ORIGINAL_IMAGE,
      payload: {
        originalImage: newOriginalImage,
      },
    });
  }, []);

  const setError = useCallback((newError) => {
    dispatch({
      type: SET_FEEDBACK,
      payload: {
        feedback: {
          message: newError.message || newError,
          duration: 0,
        },
      },
    });
  }, []);

  const loadAndSetOriginalImage = (imgToLoad) =>
  new Promise((resolve) => {
    console.log(imgToLoad);
    const imgSrc = imgToLoad?.src || imgToLoad;
    if (
      imageBeingLoadedSrc.current === imgSrc ||
      (!imgSrc && originalImage) ||
      isSameImage(imgSrc, originalImage)
    ) {
      if (!imageBeingLoadedSrc.current) {
        resolve();
      }
      return;
    }

    const triggerResolve = () => {
      imageBeingLoadedSrc.current = null;
      resolve();
    };

    imageBeingLoadedSrc.current = imgSrc;

    if (typeof imgToLoad === "string") {
      loadImage(imgToLoad, defaultSavedImageName)
        .then(setNewOriginalImage)
        .catch(setError)
        .finally(triggerResolve);
    } else if (imgToLoad instanceof HTMLImageElement) {
      if (!imgToLoad.name && defaultSavedImageName) {
        // eslint-disable-next-line no-param-reassign
        imgToLoad.name = defaultSavedImageName;
      }
      if (!imgToLoad.complete) {
        imgToLoad.addEventListener("load", () => {
          setNewOriginalImage(imgToLoad);
          triggerResolve();
        });
        return;
      }

      setNewOriginalImage(imgToLoad);
      triggerResolve();
    } else {
      setError(t("invalidImageError"));
      triggerResolve();
    }
  });

  const convertBase64ToBinary = (base64String) => {
    const base64WithoutPrefix = base64String.replace(/^data:image\/jpeg;base64,/, '');
    const byteCharacters = atob(base64WithoutPrefix);
    const byteArray = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArray[i] = byteCharacters.charCodeAt(i);
    }
    return byteArray;
  };

  const onSave = (transformedData, triggerSave) =>{

    console.log(transformedData)
    const binaryData = convertBase64ToBinary(transformedData.imageBase64)
    const binaryImage = new Blob([binaryData], { type: "image/jpeg" });

    const finalFile = new File([binaryImage], transformedData.name, {
      type: "image/jpeg",
    });

    uploadImage(finalFile)

    // var a = document.createElement("a"); //Create <a>
    // a.href =  transformedData.imageBase64; //Image Base64 Goes here
    // a.download = transformedData.name; //File name Here
    // a.click(); //Downloaded file
    // console.log(transformedData)
  }

  const handleSave = () => {
    const transformedData = transformImgFn(imageFileInfo, false, true);
    const onSaveFn = optionSaveFnRef.current || onSave;
    const savingResult = onSaveFn(
      transformedData.imageData,
      transformedData.designState,
    );


    const hideLoadingSpinner = () => {
      dispatch({ type: HIDE_LOADER });
    };
    if (savingResult instanceof Promise) {
      savingResult.finally(hideLoadingSpinner);
    } else {
      hideLoadingSpinner();
    }

    optionSaveFnRef.current = null;
    if (closeAfterSave && onClose) {
      onClose(CLOSING_REASONS.AFTER_SAVE, haveNotSavedChanges);
    }
  };

  const startSaving = () => {
    dispatch({ type: SHOW_LOADER });
    setIsModalOpened(false);
    setTimeout(handleSave, 3);
  };

  const validateInfoThenSave = () => {
    const onSaveFn = optionSaveFnRef.current || onSave;
    if (typeof onSaveFn !== 'function') {
      throw new Error('Please provide onSave function handler.');
    }
    if (!imageFileInfo.name || !imageFileInfo.extension) {
      dispatch({
        type: SET_FEEDBACK,
        payload: {
          feedback: {
            message: d('nameIsRequired'),
          },
        },
      });
      return;
    }

    startSaving();
  };

  const changeFileName = (e) => {
    const name = e.target.value;
    setImageFileInfo({
      ...imageFileInfo,
      name,
    });
  };

  const changeQuality = (newQuality) => {
    setImageFileInfo({
      ...imageFileInfo,
      quality: restrictNumber(newQuality / 100, 0.01, 1),
    });
  };

  const triggerSaveHandler = () => {
    if (useCloudimage) {
      const transformedCloudimageData = transformImgFn(imageFileInfo);
      const onSaveFn = optionSaveFnRef.current || onSave;
      onSaveFn(
        transformedCloudimageData.imageData,
        transformedCloudimageData.designState,
      );
      return;
    }

    if (
      !optionSaveFnRef.current &&
      typeof onBeforeSave === 'function' &&
      onBeforeSave(imageFileInfo) === false
    ) {
      validateInfoThenSave();
      return;
    }

    setIsModalOpened(true);
  };

  const resizeImageFile = (newSize) => {
    setImageFileInfo({
      ...imageFileInfo,
      size: {
        ...imageFileInfo.size,
        ...newSize,
      },
    });
  };

  const changeSaveFnAndTriggerAnother = (saveFn, fnToTrigger) => {
    if (typeof saveFn === 'function') {
      optionSaveFnRef.current = saveFn;
      fnToTrigger();
    } else {
      throw new Error(
        'onSave function callback is required as an argument to the passed function.',
      );
    }
  };

  const setFileNameAndExtension = () => {
    const { name, extension } = getFileFullName(
      defaultSavedImageName || originalImage.name,
      forceToPngInEllipticalCrop && crop.ratio === ELLIPSE_CROP
        ? 'png'
        : SUPPORTED_IMAGE_TYPES.includes(
            defaultSavedImageType?.toLowerCase(),
          ) && defaultSavedImageType,
    );

    setImageFileInfo({ ...imageFileInfo, name, extension });
  };

  useEffect(() => {
    if (originalImage) {
      setFileNameAndExtension();
    }
  }, [originalImage]);

  useEffect(() => {
    if (originalImage && (!imageFileInfo.name || !imageFileInfo.extension)) {
      setFileNameAndExtension();
    }
  }, [isModalOpened]);

  useEffect(() => {
    setImageFileInfo({
      ...imageFileInfo,
      size: {
        width: resize.width,
        height: resize.height,
      },
    });
  }, [resize]);

  useEffect(() => {
    isFieSaveMounted = true;

    return () => {
      isFieSaveMounted = false;
    };
  }, []);

  const menuItems =
    Array.isArray(moreSaveOptions) && moreSaveOptions.length > 0
      ? moreSaveOptions.map((option, i) => ({
          ...option,
          key: `${option.label || i}-option-key`,
          onClick:
            typeof option.onClick === 'function'
              ? () =>
                  option.onClick(
                    (saveCallback) =>
                      changeSaveFnAndTriggerAnother(
                        saveCallback,
                        triggerSaveHandler,
                      ),
                    (saveCallback) =>
                      changeSaveFnAndTriggerAnother(saveCallback, startSaving),
                  )
              : undefined,
        }))
      : [];

  return (
    <>
      <ButtonWithMenu
        className="FIE_topbar-save"
        color="primary"
        onClick={triggerSaveHandler}
        icon={<SaveAs size={20} color={"white"}></SaveAs>}
        menuPosition="bottom"
        menuItems={menuItems}
        menuStyle={sownloadButtonMenuStyle}
        wrapperStyle={sownloadButtonWrapperStyle}
        disabled={isLoadingGlobally || isBlockerError || isApplyingServiceLoading}
      />
      {isModalOpened && (
        <Modal
          className="FIE_save-modal"
          title={d('saveAsModalLabel')}
          Icon={(props) => (
            <SaveAs color={theme.palette['accent-primary']} {...props} />
          )}
          isOpened={isModalOpened}
          onCancel={cancelModal}
          onDone={validateInfoThenSave}
          doneLabel={d('save')}
          cancelLabel={d('cancel')}
          doneButtonColor="primary"
          cancelButtonColor="gray"

          areButtonsDisabled={isLoadingGlobally || isApplyingServiceLoading}
          zIndex={11110}
        >
          <StyledFileNameInput
            className="FIE_save-file-name-input"
            value={imageFileInfo.name}
            onChange={changeFileName}
            size="sm"
            placeholder={t('name')}
            error={!imageFileInfo.name}
            focusOnMount
          />
          <StyledFileExtensionSelect
            className="FIE_save-extension-selector"
            onChange={(ext) =>
              setImageFileInfo({ ...imageFileInfo, extension: ext })
            }
            value={imageFileInfo.extension}
            placeholder={d('extension')}
            size="sm"
          >
            {SUPPORTED_IMAGE_TYPES.map((ext) => (
              <MenuItem key={ext} value={ext}>
                {ext}
              </MenuItem>
            ))}
          </StyledFileExtensionSelect>
          {isQualityAcceptable && (
            <StyledQualityWrapper className="FIE_save-quality-wrapper">
              <Label>{d('quality')}</Label>
              <Slider
                annotation="%"
                min={1}
                max={100}
                onChange={changeQuality}
                value={parseInt(imageFileInfo.quality * 100, 10)}
                width="100%"
                style={sliderStyle}
              />
            </StyledQualityWrapper>
          )}
          <StyledResizeOnSave className="FIE_save-resize-wrapper">
            <Label>{d('resize')}</Label>
            <Resize
              onChange={resizeImageFile}
              currentSize={imageFileInfo?.size || {}}
              hideResetButton
              alignLeft
            />
          </StyledResizeOnSave>
        </Modal>
      )}
    </>
  );
};

export default SaveButton;
