import React, { useState } from "react";
import style from "../../../styles/windowsScreenStyle/faqSection.module.css";
import { Accordion, Card, Button } from "react-bootstrap";
import { useSelector } from "react-redux";

export default function FAQsection(props) {
  const d = props.data;
  const daynight = useSelector((state) => state.data.dayNight);

  return (
    <section class={`${style.fqSection} ${daynight ? style.nightMode : ""}`}>
      <div class="container">
        <h2 class="subHeading mb-3">{d("serviceInfo.ns.faq.title")}</h2>
        <Accordion>
          <Card>
            <Card.Header className={style.article_title}>
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                {d("serviceInfo.ns.faq.title1")}
                <i class="fas fa-plus more-less glyphicon glyphicon-plus"></i>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body className={style.accordion_content}>
                <p>
                  {d("serviceInfo.ns.faq.text1")}
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header className={style.article_title}>
              <Accordion.Toggle as={Button} variant="link" eventKey="1">
                {d("serviceInfo.ns.faq.title2")}
                <i class="fas fa-plus more-less glyphicon glyphicon-plus"></i>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body className={style.accordion_content}>
                {" "}
                <p>
                  {d("serviceInfo.ns.faq.text2")}
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header className={style.article_title}>
              <Accordion.Toggle as={Button} variant="link" eventKey="2">
                {d("serviceInfo.ns.faq.title3")}
                <i class="fas fa-plus more-less glyphicon glyphicon-plus"></i>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body className={style.accordion_content}>
                <p class="mb-0">
                  {d("serviceInfo.ns.faq.text3")}
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </section>
  );
}
