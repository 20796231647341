import React from "react";
import style from "../../../styles/windowsScreenStyle/heroSection.module.css";
import { useSelector } from "react-redux";

export default function HeroSection(props) {
  const d = props.data;
  const daynight = useSelector((state) => state.data.dayNight);

  return (
    <section class={`${style.heroSection} ${daynight ? style.nightMode : ""}`}>
      <div class={style.triangle}></div>
      {/*<img class={style.downArrowsImg} src="./img/down-arrows-img.svg" alt="" />*/}
      <div class="container">
        <div class="col-lg-12 mx-auto">
          <div class={style.heroSectionContents}>
            <h1 class={`mainHeading mb-1`}>{d("serviceInfo.fi.title")}</h1>
            <div class="commonText">{d("serviceInfo.fi.subtitle")}</div>
          </div>
          <div class={`row ${style.customRow}`}>
          <div class="col-lg-6 mt-3">
            <div class={style.heroSectionLeftSide}>
              {/*<div class={style.halfCircle}></div>*/}
              {/*<img class={style.blackDotsImage} src="/images/black-dots-img.svg" alt="" />*/}
              <h2 class="subHeading mb-2">{d("serviceInfo.fi.section1.title")}</h2>
              <div class="commonText">
                {d("serviceInfo.fi.subtitle.section1.text")}
              </div>
              {/*
              <a class="commonLink mt-4" href="#">
                Download for Free<i class="fas fa-chevron-right"></i>
              </a>
              */}
            </div>
          </div>
          <div class="col-lg-6">
            <div class={style.heroSectionRightSide}>
              
              <div class={style.squareBox}></div>
              {/*<img class={style.blackDotsImg} src="/images/black-dots-img.svg" alt="" /> */}
              <video
                muted="muted"
                loop="loop"
                autoplay="autoplay"
                // playsinline="playsinline"
                // controls="controls"
                style={{ width: "100%", height: "100%" }}
                class={`img-fluid ${style.videoImg}`}
              >
                {/*<source src="/video/dog_video.mp4" type="video/mp4" />*/}
                <source src="/videos/marketing/fi_sd.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
        </div>
      </div>
    </section>
  );
}
