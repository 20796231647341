import React from "react";
import style from "../styles/wavefooterCookies.module.css";
import { useSelector } from "react-redux";

export default function WaveFooterTopCookies(props) {
  const d = props.data;
  const daynight = useSelector((state) => state.data.dayNight);

  return (
    <>
      <section className={`${style.linksSection} ${daynight ? style.nightMode : ""}`}>
      {!daynight ?  (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className={``}>
              {/*f7f9fa*/}
              <path fill="#f2fafb" fill-opacity="1.0" 
                d="M0,192L48,186.7C96,181,192,171,288,149.3C384,128,480,96,576,106.7C672,117,768,171,864,192C960,213,1056,203,1152,192C1248,181,1344,171,1392,165.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
              </path>
          </svg>
        ) : ""}
        {daynight ?  (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className={``}>
              {/*202020*/}
              <path fill="#121212" fill-opacity="1" 
                d="M0,192L48,186.7C96,181,192,171,288,149.3C384,128,480,96,576,106.7C672,117,768,171,864,192C960,213,1056,203,1152,192C1248,181,1344,171,1392,165.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
              </path>
          </svg>
        ) : ""}
      </section>
    </>
  );
}
