import React from "react";
import style from "../../styles/faqs.module.css";
import { useTranslation } from "react-multi-lang";

export default function FAQsCatagories(props) {

  const d = useTranslation();

  return (
    <div className={style.catagorySection}>
      {props?.catagories?.map((catagory,index) => {
        return (
          <div key={index}
            onClick={() => {
              props.selectCatagory(catagory);
            }}
            className={`${style.catagory} ${
              catagory.selected ? style.active : ""
            }`}
          >
            <p>{d(catagory.name)}</p>
          </div>
        );
      })}
    </div>
  );
}
