import React, { Component } from "react";
import Routes from "./Routes";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import "./components/elements/assets/scss/style.scss"; // das ist schuld, dass man horizontal scrollen kann
// import "./components/careerComponent/index.scss";

import {store} from "./redux/store";
import { Provider, useSelector } from "react-redux";
import { setDefaultLanguage } from "react-multi-lang";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';


//const store = createStore();

if (window.navigator.language.includes("de")) setDefaultLanguage("gr");
else setDefaultLanguage("en");


class App extends Component {
  componentDidMount() {}

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
        <ToastContainer limit={3} newestOnTop={true} />
      </Provider>
    );
  }
}

export default App;
