import React, { useState } from "react";
import style from "../styles/cookiesBoxTest.module.css";
import { useTranslation } from "react-multi-lang";
import { useSelector, useDispatch } from "react-redux";
import { setCookiesModal } from "../redux/action/image";
import CookiesPolicyModal from "./CookiesPolicyModal";
import WaveFooterTopCookies from "./WaveFooterTopCookies";
import CheckoutCapsuleInModal from '../components/financialComponent/CheckoutCapsuleInModal';

import UserForm from "../components/multiStepComponent/UserForm";

export default function CookiesModalTest(props) {
  const [show, setShow] = useState(false);

  const data = useTranslation();
  const dispatch = useDispatch();
  const daynight = useSelector((state) => state.data.dayNight);

  const plan = props.plan;

  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  // https://stackoverflow.com/questions/38901106/how-to-make-a-shared-state-between-two-react-components
  // https://www.geeksforgeeks.org/how-to-pass-data-from-child-component-to-its-parent-in-reactjs/
  const onTrigger = (event) => {
    props.parentCallback(false);
    event.preventDefault();
  }

  return (
    <>
    <div className={`${style.fadeMe}`}>
    </div>
      <div className={`${style.CookiesModal} ${daynight ? style.nightMode : ""}`}>
        {/*<button className={style.closeBtn} onClick={() => dispatch(setCookiesModal(false))}>*/}
        <button className={style.closeBtn} onClick={(event) => onTrigger(event)}>
          <i class="fas fa-times-circle"></i>
        </button>
        
        <div>
          <div>
            <h3>{data("price.checkout")} 💳 💳 💳</h3>
            {/*<p class="mb-4 mb-lg-0">{data("cookiesModal.text1")}</p>*/}
          </div>
          {/*
          <div className="d-flex flex-column">
            <a href="#" onClick={handleShow}>
              {data("cookiesModal.text2")}
            </a>
            <button
              onClick={() => {
                dispatch(setCookiesModal(false));
                localStorage.setItem("acceptedCookies", false);
              }}
              className={`btn btn-primary ${style.btn_primary}`}
            >
              {data("cookiesModal.text3")}
            </button>
          </div>
          */}
        </div>
        <br />

        {/*<CheckoutCapsuleInModal />*/}
        <UserForm lang={data} plan={plan} />
      </div>
      
    </>
  );
}
