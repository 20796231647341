import React from "react";
import HeroSection from "../components/photoshopScreenComponent/HeroSection";
import ResultsSection from "../components/photoshopScreenComponent/ResultsSection";
import MidSection from "../components/photoshopScreenComponent/MidSection";
import CardSection from "../components/photoshopScreenComponent/CardSection";
import FAQsection from "../components/photoshopScreenComponent/FAQsection";
import CookiesModal from "../components/CookiesModal";
import { useSelector } from "react-redux";

export default function Photoshop() {
  const cookiesModal = useSelector((state) => state.data.cookiesModal);
  return (
    <>
      <HeroSection />
      <ResultsSection />
      {/*<MidSection />*/} {/* TODO: add this back in when redesigned*/}
      <CardSection />
      <FAQsection />
      {cookiesModal ? <CookiesModal /> : ""}
    </>
  );
}
