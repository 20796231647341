import React, { useEffect, useState } from 'react';
// import '../App.css';
// import Posts from '../components/posts/posts';
import PostsDebug from '../components/posts/postsdebugv4';
import VideoPosts from '../components/posts/videoposts';
import PostLoadingComponent from '../components/posts/postLoading';
import axiosInstance from '../axios';
import baseURL from '../config';
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import { useLocation, useHistory } from "react-router-dom";

import style from "../styles/postOverview.module.css";

function useQuery() {
	// return new URLSearchParams(useLocation().search);
	// console.log("useLocation().search: ", useLocation().search);
	// return new URLSearchParams({"clientId": "clientId"}).toString();

	return new URLSearchParams(useLocation().search).toString();
}

function Debugv4() {
	const daynight = useSelector((state) => state.data.dayNight);
	const d = useTranslation();
	const history = useHistory();

	const PostLoading = PostLoadingComponent(PostsDebug);
	const VideoPostLoading = PostLoadingComponent(VideoPosts);
	// images
	const [appState, setAppState] = useState({
		loading: true,
		posts: null,
	});

	// videos
	const [appVideoState, setAppVideoState] = useState({
		loading: true,
		videosposts: null,
	});
	
	useEffect(() => {
		document.title = `${d("tab.uploads")} - dioptify.com`;
	});

	useEffect(() => {
		let formData = new FormData();
		if(localStorage.getItem('user') !== null) {
			formData.append('owner', localStorage.getItem('user'));
		} else {
			formData.append('owner', localStorage.getItem('temp_user'));
		}

		// formData.append('owner', localStorage.getItem('user'));

		// images
		axiosInstance.post(`userlist`, formData).then((res) => {
			console.log("----> sind in useEffect von den userlist lel", res.data);
			
			for (var posts of res.data.posts) {
				posts.image = baseURL + posts.image;
			}

			const allPosts = res.data.posts;
			console.log(res.data.posts);
			setAppState({ loading: false, posts: allPosts });
		  })
		  .catch((err) => {
			console.log("Unable to get at the moment: " + err);
		});

		// videos
		axiosInstance.post(`uservideolist`, formData).then((res) => {
			console.log("----> sind in useEffect von den uservideolist lel", res.data);
			
			for (var posts of res.data.posts) {
				posts.file = baseURL + posts.file;
			}

			const allVideoPosts = res.data.posts;
			console.log(res.data.posts);
			setAppVideoState({ loading: false, videosposts: allVideoPosts });
		  })
		  .catch((err) => {
			console.log("Unable to get at the moment: " + err);
		});

		// get whole list of all uploaded images
		/*
		axiosInstance.get().then((res) => {
			const allPosts = res.data;
			console.log(res.data);
			setAppState({ loading: false, posts: allPosts });
			console.log(res.data);
		});
		*/
	}, [setAppState]);

	// https://reactjs.org/docs/conditional-rendering.html
	// https://blog.logrocket.com/conditional-rendering-in-react-c6b0e5af381e/
	// https://betterprogramming.pub/using-url-parameters-and-query-strings-with-react-router-fffdcea7a8e9
	let query = useQuery() === "posts=";

	// TODO: check if this is the best behaviour
	const testButton = (event) => {    
        // console.log(event);
		history.push({
			pathname: '/debugv3',
			search: '?posts',
		})
    };

	const testButtonv2 = (event) => {    
        // console.log(event);
		history.push({
			pathname: '/debugv3',
			search: '?videoposts',
		})
    };


	return (
		<section className={`${style.teamSection} ${daynight ? style.nightMode : ""}`}>
			<div className="container">
				<div className="col-lg-12 mx-auto">
					<h2 className={`mainHeading`}>
						{d("posts.title")} DevState
					</h2>

					<div class="row">
						<div class="col-lg-12 mx-auto">

						{query
		                    ?
							<>
								<div className={`${style.itemsLinks} itemsLinks ${style.nav} nav`} id="myTab" role="tablist">
									<a
										className={`${style.itemLink} active`}
										id="tab-1"
										data-toggle="tab"
										href="#tab1"
										role="tab"
										aria-controls="tab1"
										aria-selected="true"
										>
										{d("posts.images")}
									</a>
									<a
										className={style.itemLink}
										id="tab-2"
										data-toggle="tab"
										href="#tab2"
										role="tab"
										aria-controls="tab2"
										aria-selected="false"
										onClick={(e) => {
											testButtonv2(e);
										}}
										>
										{d("posts.videos")}
									</a>
								</div>

								<div className="tab-content" id="myTabContent">
									<div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab-1">
										<div className={`mt-5`}>
											<div className="PostOverview">
												<PostLoading isLoading={appState.loading} posts={appState.posts} data={d}/>
											</div>
										</div>
									</div>
									<div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab-2">
										<div className={`mt-5`}>
											<div className="PostOverview">
												<VideoPostLoading isLoading={appVideoState.loading} posts={appVideoState.videosposts} data={d}/>
											</div>
										</div>
									</div>
								</div>
							</>
							:
							<>
								<div className={`${style.itemsLinks} itemsLinks ${style.nav} nav`} id="myTab" role="tablist">
									<a
										className={`${style.itemLink}`}
										id="tab-1"
										data-toggle="tab"
										href="#tab1"
										role="tab"
										aria-controls="tab1"
										aria-selected="true"
										onClick={(e) => {
											testButton(e);
										}}
										>
										{d("posts.images")}
									</a>
									<a
										className={`${style.itemLink} active`}
										id="tab-2"
										data-toggle="tab"
										href="#tab2"
										role="tab"
										aria-controls="tab2"
										aria-selected="false"
										>
										{d("posts.videos")}
									</a>
								</div>

								<div className="tab-content" id="myTabContent">
									<div className="tab-pane fade" id="tab1" role="tabpanel" aria-labelledby="tab-1">
										<div className={`mt-5`}>
											<div className="PostOverview">
												<PostLoading isLoading={appState.loading} posts={appState.posts} data={d}/>
											</div>
										</div>
									</div>
									<div className="tab-pane fade show active" id="tab2" role="tabpanel" aria-labelledby="tab-2">
										<div className={`mt-5`}>
											<div className="PostOverview">
												<VideoPostLoading isLoading={appVideoState.loading} posts={appVideoState.videosposts} data={d}/>
											</div>
										</div>
									</div>
								</div>
							</>
						}
						{/*
							<div className={`${style.itemsLinks} itemsLinks ${style.nav} nav`} id="myTab" role="tablist">
								<a
									className={`${style.itemLink} active`}
									id="tab-1"
									data-toggle="tab"
									href="#tab1"
									role="tab"
									aria-controls="tab1"
									aria-selected="true"
									>
									{d("posts.images")}
								</a>
								<a
									className={style.itemLink}
									id="tab-2"
									data-toggle="tab"
									href="#tab2"
									role="tab"
									aria-controls="tab2"
									aria-selected="false"
									>
									{d("posts.videos")}
								</a>
							</div>

							<div className="tab-content" id="myTabContent">
								<div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab-1">
									<div className={`mt-5`}>
										<div className="PostOverview">
											<PostLoading isLoading={appState.loading} posts={appState.posts} data={d}/>
										</div>
									</div>
								</div>
								<div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab-2">
									<div className={`mt-5`}>
										<div className="PostOverview">
											<VideoPostLoading isLoading={appVideoState.loading} posts={appVideoState.videosposts} data={d}/>
										</div>
									</div>
								</div>
							</div>
						*/}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Debugv4;
