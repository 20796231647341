import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { useParams, useHistory } from 'react-router-dom';
//MaterialUI
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import Button from '@material-ui/core/Button';
import { saveAs } from "file-saver";

// import Plyr from "plyr-react";
// import "plyr-react/dist/plyr.css";

import style from "../../styles/services.module.css";

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
}));

export default function VideoPost() {
	const daynight = useSelector((state) => state.data.dayNight);
	const d = useTranslation();
	const history = useHistory();
	
	const { slug } = useParams();
	const classes = useStyles();

	const [data, setData] = useState({
		posts: [],
	});

	let videoSrc;
	if(data.posts.service !== "rbv" && data.posts.service !== "hdrv") {
		if(!(data.posts.paid)) {
			videoSrc = {
				type: "video",
				sources: [
					{
					src: data.posts.preview_file,
					}
				]
			};
		} else {
			videoSrc = {
				type: "video",
				sources: [
					{
					src: data.posts.file,
					}
				]
			};
		}
	} else {
		if(!(data.posts.paid)) {
			// TODO: check if this correct for all services
			// dispatch(setImage({ image: post.preview_image, id: uuidv4() }));	
			videoSrc = {
				type: "video",
				sources: [
					{
					src: data.posts.original_file,
					}
				]
			};
		} else {
			videoSrc = {
				type: "video",
				sources: [
					{
					src: data.posts.original_file,
					}
				]
			};
		}
	}

	/*
	const videoSrc = {
		type: "video",
		sources: [
			{
			src: data.posts.file,
			}
		]
	};
	*/

	useEffect(() => {
		axiosInstance.get('videopost/' + slug).then((res) => {
			setData({
				posts: res.data,
			});
			console.log(res.data);
		});
	}, [setData]);

	const backButton = (event) => {    
		// console.log(event);
		history.push({
			pathname: '/posts',
			search: '?videoposts',
		})
	};

	const handleDelete = (e, post) => {
		e.preventDefault();
		axiosInstance
			.delete('admin/delete/' + post.id)
			.catch(function (error) {
				if (error.response) {
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				}
			})
			.then(function () {
					history.push({
						pathname: '/posts',
						search: '?videoposts',
					});
					window.location.reload();
			});
	};

	const saveFile = (e, post) => {
		e.preventDefault();
		var filename = post.file.substring(post.file.lastIndexOf('/')+1);
		
		if(post.service !== "rbv" && post.service !== "hdrv") {
			if(!(post.paid)) {
				saveAs(
					post.preview_file,
					filename
				);
			} else {
				saveAs(
					post.file,
					filename
				);
			}
		} else {
			if(!(post.paid)) {
				// TODO: check if this correct for all services
				// dispatch(setImage({ image: post.preview_image, id: uuidv4() }));	
				saveAs(
					post.original_file,
					filename
				);
			} else {
				saveAs(
					post.original_file,
					filename
				);
			}
		}

		// original save logic
		/*
		saveAs(
			post.file,
			filename
		);
		*/
	};	

	return (
		<section className={`${style.teamSection} ${daynight ? style.nightMode : ""}`}>
			<div className="container">
				<div className="row">
					<Button
						className={`btn btn-outline-success`}
						onClick={(e) => {
							backButton(e);
						}}
					>
						<div className={style.logo}>
							<i class="fas fa-arrow-circle-left fa-2x"></i>
						</div>
					</Button>					
					<div className="col-lg-12 mx-auto">
						<Container component="main" maxWidth="md">
							<div className={classes.heroContent}>
								<Container maxWidth="md">
									{/*
									<Typography
										component="h1"
										variant="h2"
										align="center"
										color="textPrimary"
										gutterBottom
									>
										{data.posts.title}{' '}
									</Typography>{' '}
									*/}
									{/*
									<Typography
										variant="h5"
										align="center"
										color="textSecondary"
										paragraph
									>
										{data.posts.excerpt}{' '}
									</Typography>{' '}
									*/}
									{/*
									<h2>
										{data.posts.excerpt}{' '}
									</h2>
									*/}
									<br />
								</Container>{' '}
							</div>{' '}
							<img src={ data.posts.image } />

							{/*<Plyr source={videoSrc} />*/}

							<br />
							<a
								className={`btn btn-outline-success`}
								onClick={(e) => {
									saveFile(e, data.posts);
								}}
							>
								{d("posts.postDownload")}
								<>&nbsp;</>
								<i className="fas fa-cloud-download-alt"></i>
							</a>
							<>&nbsp; &nbsp; &nbsp;</>
							<a
								className={`btn btn-outline-danger`}
								onClick={(e) => {
									handleDelete(e, data.posts);
								}}
							>
								{d("posts.postDelete")}
								<>&nbsp;</>
								<i className="fas fa-trash"></i>
							</a>							
						</Container>
					</div>
				</div>
			</div>			
		</section>
	);
}
