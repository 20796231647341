import React from "react";
import HeroSection from "../components/windowsScreenComponent/HeroSection";
import ResultsSection from "../components/windowsScreenComponent/ResultsSection";
import MidSection from "../components/windowsScreenComponent/MidSection";
import CardSection from "../components/windowsScreenComponent/CardSection";
import FAQsection from "../components/windowsScreenComponent/FAQsection";
import CookiesModal from "../components/CookiesModal";
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";

export default function WindowsMacLinux() {
  const data = useTranslation();
  const cookiesModal = useSelector((state) => state.data.cookiesModal);

  return (
    <>
      <HeroSection data={data}/>
      <ResultsSection />
      {/*<MidSection />*/} {/* TODO: add this back in when redesigned*/}
      <CardSection />
      <FAQsection />
      {cookiesModal ? <CookiesModal /> : ""}
    </>
  );
}
