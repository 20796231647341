import React, { useState } from "react";
import style from "../../styles/user.module.css";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import Input from '../../components/elements/Input';
import axiosInstance from '../../axios';
import Cookies from "js-cookie";

export default function ChangePW() {
  const data = useTranslation();
  const history = useHistory();

  var email = "";
  var username = "";
  var oldPw = "";
  var pw1 = "";
  var pw2 = "";
  
  const initialFormData = Object.freeze({
		old_password: '',
		password: '',
		password2: '',
	});

	const [formData, updateFormData] = useState(initialFormData);
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleSubmit = async(e) => {
		e.preventDefault();

		await axiosInstance
			.put(`user/change_password/`, {
                old_password: formData.old_password,
				password: formData.password,
				password2: formData.password2,
			})
			.then((res) => {
                // console.log(res);
                // console.log(res.data);
                history.push('/user');
                history.go('/user');
      
                setShow(false);
			})
			.catch((err) => {
				// alert("Unable to upload your file at the moment: " + err);
                setShow(true);
                setTimeout(() => {
                    setShow(false);
                }, 4000);
			});
	};

  function sendEmail(event) {
    // console.log("clicke on kiwi: " + email);
    // console.log("checken with kiwi: " + validateEmail(email));
    if(validateEmail(email) && (pw1 === pw2)) {
      // send email here;
      setShow(false);

      return true;
    } else {
        setShow(true);
        setTimeout(() => {
          setShow(false);
        }, 4000);
    }
  }

  function enterPressed(event) {
    var code = event.keyCode || event.which;
    if(code === 13) {
        // sendEmail()
    } 
  }

  function updateInputValueOldPW(evt) {
    oldPw = evt.target.value;
    updateFormData({
        ...formData,
        old_password: oldPw,
    });
  }

  function updateInputValuePW1(evt) {
    pw1 = evt.target.value;

		updateFormData({
			...formData,
			password: pw1,
		});
  }

  function updateInputValuePW2(evt) {
    pw2 = evt.target.value;
    updateFormData({
        ...formData,
        password2: pw2,
    });
  }

  function validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  const [show, setShow] = useState(false);


  return (
    <>
        {show ? (
        <Alert
            variant="danger" // warning
            style={{ position: "fixed", left: "50%", transform: "translateX(-50%)", top: "36px", zIndex: "1040" }}
        >
            {data("cta.errorReg")}
        </Alert>
        ) : (
        ""
        )}    
        <div class={style.formBody}>
            <form >
            <input
                type="password"
                name=""
                id=""
                class={style.commonInput}
                onChange={evt => updateInputValueOldPW(evt)} 
                placeholder={data("cta.oldPW")} 
                onKeyPress={enterPressed.bind(this)}
            />
            <input
                type="password"
                name=""
                id=""
                class={style.commonInput}
                onChange={evt => updateInputValuePW1(evt)} 
                placeholder={data("cta.pw1")} 
                onKeyPress={enterPressed.bind(this)}
            />
            <input
                type="password"
                name=""
                id=""
                class={style.commonInput}
                onChange={evt => updateInputValuePW2(evt)} 
                placeholder={data("cta.pw2")} 
                onKeyPress={enterPressed.bind(this)}
            />

            <div class={style.loginTxt}>
                <input 
                type="checkbox"
                name=""
                id=""
                className="mr-2" 
                checked={checked}
                onChange={handleChange}
                />
                <label for="#" class={style.oddText}>
                {data("auth.signup.text5.text1")}{" "}
                <a href="termsofservice">{data("auth.signup.text5.text2")}</a>,
                <a href="termsandconditions">{data("auth.signup.text5.text3")}</a>{" "}
                {data("auth.signup.text5.text4")}
                <a href="privacy">{data("auth.signup.text5.text5")}</a>.
                </label>
            </div>
            <button
                disabled={checked ? false : true}
                onClick={handleSubmit}
                style={{margin: "auto", display: "block"}}
                className={`btn btn-secondary ${style.buttonAdventureRealSecondary}`}>
                {data("user.changepwconfirm")} <i class="fas fa-arrow-right"></i>
            </button>            
            </form>
        </div>
    </>
  );
}
