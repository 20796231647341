import React, { useState } from "react";
import style from "../styles/cookies.module.css";
import { Modal, Button } from "react-bootstrap";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import { useSelector, useDispatch } from "react-redux";
import { setAnalyticsCookies, setFunctionalCookies } from "../redux/action/image";
import { setCookiesModal } from "../redux/action/image";

export default function CookiesPolicyModal(props) {
  const dispatch = useDispatch();
  const daynight = useSelector((state) => state.data.dayNight);
  const functionalCookies = useSelector((state) => state.data.functionalCookies);
  const analyticsCookies = useSelector((state) => state.data.analyticsCookies);
  const data = useTranslation();
  const handleClose = () => {
    props.setShow(false);
  };
  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      dialogClassName={`${style.modal90w} ${daynight ? style.nightMode : ""}`}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="flex-column border-0">
        <h3 className={`commonText mt-0 ${style.mainHeadingModal}`}>{data("infoSettingModal.text1")}</h3>
        <p>
          {data("infoSettingModal.text2.text1")} <Link to="/cookies">{data("infoSettingModal.text2.text2")}</Link>
        </p>
      </Modal.Header>
      <Modal.Body>
        <h4 className={style.secondaryHeading}>
          {data("infoSettingModal.text3.text1")} <span>{data("infoSettingModal.text3.text2")}</span>
        </h4>
        <div>
          <p>{data("infoSettingModal.text4")}</p>
          <BootstrapSwitchButton checked={true} size="sm" onstyle="success" disabled={true} style="opacity" />
        </div>
        <h4 className={style.secondaryHeading}>{data("infoSettingModal.text5")}</h4>
        <div>
          <p>{data("infoSettingModal.text6")}</p>
          <BootstrapSwitchButton
            checked={functionalCookies}
            size="sm"
            onstyle="success"
            offstyle="secondary"
            onChange={(checked) => {
              dispatch(setFunctionalCookies(checked));
            }}
          />
        </div>
        {/*
        <h4 className={style.secondaryHeading}>{data("infoSettingModal.text7")}</h4>
        <div>
          <p>{data("infoSettingModal.text8")}</p>
          <BootstrapSwitchButton
            checked={analyticsCookies}
            size="sm"
            onstyle="success"
            offstyle="secondary"
            onChange={(checked) => {
              dispatch(setAnalyticsCookies(checked));
            }}
          />
        </div>
        */}
      </Modal.Body>
      <Modal.Footer>
        {/*
        <Button onClick={handleClose} variant="outline-secondary" className={`${style.cookiesBtn}`}>
          {data("infoSettingModal.button1")}
        </Button>
        */}
        <Button
          onClick={() => {
            props.setShow(false);
            dispatch(setFunctionalCookies(false));
            dispatch(setAnalyticsCookies(false));
            dispatch(setCookiesModal(false));
            localStorage.setItem("acceptedCookies", false);
          }}
          variant="outline-secondary"
          className={`${style.cookiesBtn}`}
        >
          {data("infoSettingModal.button2")}
        </Button>
        <Button
          onClick={() => {
            props.setShow(false);
            dispatch(setFunctionalCookies(true));
            dispatch(setAnalyticsCookies(true));
            dispatch(setCookiesModal(false));
            localStorage.setItem("acceptedCookies", false);
          }}
          variant="success"
          className={`${style.cookiesBtn} mr-0`}
        >
          {data("infoSettingModal.button3")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
