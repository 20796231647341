import React from "react";
import style from "../styles/detailSection.module.css";
import { useSelector } from "react-redux";

export default function SubDetailSection(props) {
  const d = props.data;
  const daynight = useSelector((state) => state.data.dayNight);
  return (
    <section
      className={`${style.detailSection} ${style.workflowSection} ${
        daynight ? style.nightMode : ""
      }`}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-center">
            <img
              className="img-fluid"
              src={`/images/${d("detail2.image")}`}
              alt=""
            />
          </div>
          <div className="col-lg-6 mt-5 mt-lg-0">
            <h2 className={`${style.subHeading} subHeading mb-1`}>
              {d("detail2.title")}
            </h2>
            <div className={style.commonText}>{d("detail2.description")}</div>
            <a className={style.commonLink} href="/tools-api">
              {d("detail2.link")} <i className="fas fa-directions"></i>
            </a>
            {/*
            <a className={style.commonLink} href="/api">
              {d("detail2.link2")} <i className="fas fa-kiwi-bird"></i>
            </a>
            */}
          </div>
        </div>
      </div>
    </section>
  );
}
