/** External Dependencies */
import Konva from "konva";

/** Internal Dependencies */
import * as CustomKonvaFilters from "../../../custom/filters";

const konvaFilters = Konva.Filters;

export const AVAILABLE_FILTERS = [
  {
    label: "Background Removal",
    key: "rb",
    filterFn: null,
  },
  {
    label: "Super Resolution",
    key: "sr",
    filterFn: null,
  },
  {
    label: "Deblurring",
    key: "db",
    filterFn: null,
  },
  {
    label: "HDR",
    key: "hdr",
    filterFn: null,
  },
  {
    label: "Neural Style Transfer",
    key: "sns",
    filterFn: null,
  },
  {
    label: "Colorization",
    key: "cl",
    filterFn: null,
  },
  {
    label: "Refocus",
    key: "rf",
    filterFn: null,
  },
  {
    label: "Face Blurring",
    key: "fb",
    filterFn: null,
  },
  {
    label: "Image Inpainting",
    key: "ii",
    filterFn: null,
  },
  {
    label: "Stable Diffusion",
    key: "sd",
    filterFn: null,
  },
  {
    label: "Logical Replacement",
    key: "pe",
    filterFn: null,
  },
  {
    label: "Deshaking",
    key: "ds",
    filterFn: null,
  },

];
