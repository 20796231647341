import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import style from "./uploadedImageModal.module.scss";

export default function UploadedImageModal(props) {

  const daynight = useSelector((state) => state.data.dayNight);
  const data = useTranslation();

  const handleClose = () => {
    props.setShow(false);
  };

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={`${style.modal90w} ${daynight ? style.nightMode : ""}`}
      keyboard={false}
    >
      <Modal.Header className="flex-column border-0">
        <h3 className={`commonText mt-0 ${style.mainHeadingModal}`}>
          {data("uploadedImageModal.text1")}
        </h3>
        {!props?.success ? (
          <h4
            className={`mt-0 ${style?.errorMessage} ${style.secondaryHeading}`}
          >
            {data("uploadedImageModal.text2")}
          </h4>
        ) : (
          <h4
            className={`mt-0 ${style?.successMessage} ${style.secondaryHeading}`}
          >
            {data("uploadedImageModal.text3")}
          </h4>
        )}
      </Modal.Header>
      <Modal.Body>
        <div className={`d-flex ${style.spaceEvenly}`}>
          <div className={`${style.imageContainer}`}>
            <img
              src={props?.base64Image}
              className={`${style.resizeableImage}`}
            />
          </div>
          <span className={`${style.imageName} ${style.secondaryHeading}`}>
            <a style={{ fontWeight: 800, color: "var(--dioptifyGreen)" }}>
              {data("imageEditor.text7")}
            </a>{" "}
            <a style={{ textOverflow: "ellipsis" }}>
              {props?.file?.name?.substring(props?.file?.name?.lastIndexOf("/") + 1)
                .slice(0, 30)}
            </a>
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={handleClose}
          variant="success"
          className={`${style.cookiesBtn} mr-0`}
        >
          {data("uploadedImageModal.button1")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
