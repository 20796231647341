import React from "react";
import UploadServiceSectionTasks from "../UploadServiceSectionTasks";
import { SERVICES } from '../Services';

import { useTranslation } from "react-multi-lang";

export default function Dehazing() {
  const data = useTranslation();

  return (
    <>
      <UploadServiceSectionTasks data={data} service={SERVICES.DH}/>
    </>
  );
}
