import React, { useState } from "react";
import style from "../styles/cookiesContact.module.css";
import CookiesTable from "../components/CookiesTable";
import { useSelector } from "react-redux";
import CookiesModal from "../components/CookiesModal";
import CookiesPolicyModal from "../components/CookiesPolicyModal";
import Cta from "../components/contactUsComponent/Cta";
import ContactForm from "../components/contactUsComponent/ContactForm";
import { useTranslation } from "react-multi-lang";


export default function Contact() {
  const [show, setShow] = useState(false);
  const daynight = useSelector((state) => state.data.dayNight);
  const cookiesModal = useSelector((state) => state.data.cookiesModal);
  const data = useTranslation();

  const handleShow = () => setShow(true);
  return (
    <>
      {cookiesModal ? <CookiesModal /> : ""}
      <div className={`${style.cookiesSection} ${daynight ? style.nightMode : ""}`}>
        <div class="col-lg-12 mx-auto">
          <div className={style.heroSection}>
            <h1 class="mainHeading mb-1">{data("contact.title")}</h1>
            <h4 className="commonText">{data("contact.subtitle")}</h4>
          </div>
          {/* <Cta split data={data}/> */}
          <ContactForm data={data}/>
        </div>
      </div>

      <CookiesPolicyModal show={show} setShow={setShow} />
    </>
  );
}
