import React, { useEffect } from "react";
import { FileDrop } from "react-file-drop";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { setImage } from "../../redux/action/image";
import { useHistory } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { getLanguage, setLanguage, useTranslation } from "react-multi-lang";
import { setDefaultTranslations } from "react-multi-lang";
import en from "../../data/en.json";
import gr from "../../data/gr.json";
import sp from "../../data/sp.json";
import fr from "../../data/fr.json";
import ch from '../../data/ch.json';
import ru from "../../data/ru.json";
import ar from "../../data/ar.json"

setDefaultTranslations({ gr,sp,fr,ch,ru,ar, en });

export default function FrontLayout({ children }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useTranslation();
  // useEffect(() => {
  //   if (defaultLanguage) {
  //     setLanguage(defaultLanguage);
  //   }
  // });
  const getHeightAndWidthFromDataUrl = (dataURL) =>
    new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        });
      };
      img.src = dataURL;
    });

  useEffect(() => {
    // debugger
    const defaultLanguage = localStorage.getItem("defaultLanguage");
    if (defaultLanguage !== null) {
      setLanguage(defaultLanguage);
    } else {
      setLanguage("en");
    }

    document.onpaste = async function (e) {
      console.log(e);
      try{
        var clipboardItem = e.clipboardData.items[0];
        var blob = clipboardItem.getAsFile();
        var blobUrl = window.webkitURL.createObjectURL(blob);
        const dimensions = await getHeightAndWidthFromDataUrl(blobUrl);
        dispatch(
          setImage({
            image: blobUrl,
            id: uuidv4(),
            width: dimensions.width,
            height: dimensions.height,
          })
        );
        history.push(`/rbg`);
  
      } catch(error) {
        console.log(error);
      }
    };
  }, []);
  return (
    <>
      {/*
      <FileDrop
        onDrop={async (files) => {
          const fileAsDataURL = window.URL.createObjectURL(files[0]);
          const dimensions = await getHeightAndWidthFromDataUrl(fileAsDataURL);
          dispatch(
            setImage({
              image: URL.createObjectURL(files[0]),
              id: uuidv4(),
              width: dimensions.width,
              height: dimensions.height,
            })
          );
          history.push(`/ns`);
        }}
      >
        {data("filearea")}
      </FileDrop>
      */}
      <Header
        data={data}
        getLanguage={getLanguage()}
        setLanguage={(item) => {
          setLanguage(item);
          localStorage.setItem("defaultLanguage", item);
        }}
      />
        {children}
      <Footer
        data={data}
        getLanguage={getLanguage()}
        setLanguage={(item) => {
          setLanguage(item);
          localStorage.setItem("defaultLanguage", item);
        }}
      />
    </>
  );
}
