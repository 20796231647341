import React, { useState } from "react";
import style from "../../styles/financialFaqSection.module.css";
import { Accordion, Card, Button } from "react-bootstrap";
import { useSelector } from "react-redux";

export default function FAQsection(props) {
  const d = props.data;
  const daynight = useSelector((state) => state.data.dayNight);

  return (
    <section class={`${style.fqSection} ${daynight ? style.nightMode : ""}`}>
      <div class="container">
        <h2 class={`${style.subHeadingFix} subHeading mb-3`}>{d("faq.title2")}</h2>
        <Accordion>
          <Card>
            <Card.Header className={style.article_title}>
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                {d("price.faq.title1")}
                <i class="fas fa-plus more-less glyphicon glyphicon-plus"></i>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body className={style.accordion_content}>
                <p>
                  {d("price.faq.text1")}
                </p>
                <p>
                  {d("price.faq.text11")}
                </p>
                <p>
                  {d("price.faq.text111")}
                </p>
                {/*
                <p>
                  Images are processed through the dioptify
                  <a href="#">API</a>, which requires an active Internet
                  connection and an API key (to be found in your
                  <a href="#">account settings</a>
                  ).
                </p>
                */}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header className={style.article_title}>
              <Accordion.Toggle as={Button} variant="link" eventKey="1">
                {d("price.faq.title2")}
                <i class="fas fa-plus more-less glyphicon glyphicon-plus"></i>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body className={style.accordion_content}>
                {" "}
                {/*
                <p>
                  Downloading dioptify for Windows/Mac/Linux is free! To
                  process images you need credits, which can be used for all
                  dioptify products and integrations. You can find the pricing
                  for credits <a href="#">here</a>.
                </p>
                <p>
                  As a <a href="#">dioptify user</a> you get 1 free credit for
                  signing up, and 50 free API calls for preview images each
                  month. Since dioptify for Windows/Mac/Linux utilizes the API
                  that means you can process 50 preview images (up to 0.25
                  megapixels, e.g. 625x400) with it for free each month.
                </p>
                */}
                <p>
                  {d("price.faq.text2")}
                </p>
                <p>
                  {d("price.faq.text22")}
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header className={style.article_title}>
              <Accordion.Toggle as={Button} variant="link" eventKey="2">
                {d("price.faq.title3")}
                <i class="fas fa-plus more-less glyphicon glyphicon-plus"></i>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body className={style.accordion_content}>
                {/*
                <p class="mb-0">
                  <strong>Windows</strong>: Windows 7/8/10 (64-bit
                </p>
                <p class="mb-0">
                  <strong>Mac</strong>: macOS 10.10 or newer (64-bit)
                </p>
                <p class="mb-0">
                  <strong>Linux</strong>: Ubuntu/Debian (64-bit)
                </p>
                */}
                <p>
                  {d("price.faq.text3")}
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          {/*
          <Card>
            <Card.Header className={style.article_title}>
              <Accordion.Toggle as={Button} variant="link" eventKey="3">
                {d("price.faq.title4")}
                <i class="fas fa-plus more-less glyphicon glyphicon-plus"></i>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="3">
              <Card.Body className={style.accordion_content}>
                <p>
                  {d("price.faq.text4")}
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          */}
          <Card>
            <Card.Header className={style.article_title}>
              <Accordion.Toggle as={Button} variant="link" eventKey="4">
                {d("price.faq.title5")}
                <i class="fas fa-plus more-less glyphicon glyphicon-plus"></i>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="4">
              <Card.Body className={style.accordion_content}>
                <p>
                  {d("price.faq.text5")}
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header className={style.article_title}>
              <Accordion.Toggle as={Button} variant="link" eventKey="5">
                {d("price.faq.title6")}
                <i class="fas fa-plus more-less glyphicon glyphicon-plus"></i>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="5">
              <Card.Body className={style.accordion_content}>
                <p>
                  {d("price.faq.text6")}
                </p>
                <p>
                  {d("price.faq.text66")}
                </p>
                <p>
                  {d("price.faq.text666")}
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header className={style.article_title}>
              <Accordion.Toggle as={Button} variant="link" eventKey="6">
                {d("price.faq.title7")}
                <i class="fas fa-plus more-less glyphicon glyphicon-plus"></i>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="6">
              <Card.Body className={style.accordion_content}>
                <p>
                  {d("price.faq.text7")}
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </section>
  );
}
