import React, { useState } from 'react';
import axiosInstance from '../../../axios';
import { useHistory } from 'react-router-dom';


import style from "../../../styles/multiServiceSection.module.css";
import ImageEditor from "../../ImageEditor";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { setImage } from "../../../redux/action/image";
import axios from "axios";


//MaterialUI
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

/*
const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));
*/

export default function UploadMultiService(props) {
	const daynight = useSelector((state) => state.data.dayNight);

	let resSuccess = false;

	//https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1
	function slugify(string) {
		const a =
			'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
		const b =
			'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
		const p = new RegExp(a.split('').join('|'), 'g');

		return string
			.toString()
			.toLowerCase()
			.replace(/\s+/g, '-') // Replace spaces with -
			.replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
			.replace(/&/g, '-and-') // Replace & with 'and'
			.replace(/[^\w\-]+/g, '') // Remove all non-word characters
			.replace(/\-\-+/g, '-') // Replace multiple - with single -
			.replace(/^-+/, '') // Trim - from start of text
			.replace(/-+$/, ''); // Trim - from end of text
	}
	//

	const history = useHistory();
	const initialFormData = Object.freeze({
		title: '',
		slug: '',
		excerpt: '',
		content: '',
	});

	const [postData, updateFormData] = useState(initialFormData);
	const [postimage, setPostImage] = useState(null);

	const handleChange = (e) => {
		if ([e.target.name] == 'image') {
			setPostImage({
				image: e.target.files,
			});
			console.log(e.target.files);
		}
		if ([e.target.name] == 'title') {
			updateFormData({
				...postData,
				[e.target.name]: e.target.value.trim(),
				['slug']: slugify(e.target.value.trim()),
			});
		} else {
			updateFormData({
				...postData,
				[e.target.name]: e.target.value.trim(),
			});
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let formData = new FormData();
		formData.append('title', postData.title);
		formData.append('slug', postData.slug);
		formData.append('author', 1);
		formData.append('excerpt', postData.excerpt);
		formData.append('content', postData.content);
		formData.append('image', postimage.image[0]);
		axiosInstance.post(`admin/create/`, formData);
		history.push({
			pathname: '/admin/',
		});
		window.location.reload();
	};

	// const config = { headers: { 'Content-Type': 'multipart/form-data' } };
	// const URL = 'http://127.0.0.1:8000/api/admin/creats/';
	// let formData = new FormData();
	// formData.append('title', postData.title);
	// formData.append('slug', postData.slug);
	// formData.append('author', 1);
	// formData.append('excerpt', postData.excerpt);
	// formData.append('content', postData.content);
	// formData.append('image', postimage.image[0]);
	// axios
	// 	.post(URL, formData, config)
	// 	.then((res) => {
	// 		console.log(res.data);
	// 	})
	// 	.catch((err) => console.log(err));

	// const classes = useStyles();


	// TODO: add here the info from locaStorage and then global var for using video or image editor
	const d = props.data;
	const dispatch = useDispatch();
	const onImageChange = async(event) => {

		if (event.target.files && event.target.files[0]) {
			let img = event.target.files[0];
			let slug = uuidv4();
			
			event.preventDefault();
			let formData = new FormData();
			formData.append('title', uuidv4());
			formData.append('slug', slug);
			formData.append('service1', 'ns'); // fi
			formData.append('service2', 'sr');
			formData.append('service3', 'dh');
			formData.append('author', 1);
			formData.append('excerpt', uuidv4());
			formData.append('content', uuidv4());
			formData.append('file', img);
			
			// let resSuccess = false
			await axiosInstance.post(`admin/createms/`, formData).then((res) => {
				// posts: res.data,
				console.log("bevor RRRRRRRRRRRrrrfolgreiche Response: " + res.data);
				resSuccess = true;
			})
			.catch((err) => {
				alert("Unable to upload your image at the moment: " + err);
				resSuccess = false;
			});

			// get image after manipulation
			// alert("bevor rrfolgreiche Response: " + resSuccess);
			console.log("bevor rrfolgreiche Response: " + resSuccess);

			if(resSuccess) {
				setTimeout(() => {  
					axiosInstance.get('multiservicepost/' + slug).then((res) => {
						// posts: res.data,
						console.log(res.data);
						resSuccess = true;
						dispatch(setImage({ image: res.data.file, id: uuidv4() }));
					})
					.catch((err) => {
						alert("Unable to upload your image at the moment: " + err);
					});
				 }, 1000); // sehr schmutzig hier... 

				/*
				alert("Erfolgreiche Response");
				axiosInstance.get('post/' + slug).then((res) => {
					// posts: res.data,
					console.log(res.data);
					resSuccess = true;
				})
				.catch((err) => {
					alert("Unable to upload your image at the moment: " + err);
				});
				*/
			}

			/*history.push({
				pathname: '/admin/',
			});*/
		}

		// window.location.reload();

		// original one from pakistan
		/*
		if (event.target.files && event.target.files[0]) {
			let img = event.target.files[0];
			let form = new FormData();
			form.append("image", img);
			axios({ url: "http://localhost:4000/image", method: "post", data: form })
			// axios({ url: "http://0.0.0.0:8000/image", method: "post", data: form })
			// axios({ url: "http://0.0.0.0:8000/api/admin/create/", method: "post", data: form })
				.then((res) => {
				dispatch(setImage({ image: res.data, id: uuidv4() }));
				})
				.catch((err) => {
				alert("Unable to upload your image at the moment");
				});
		}*/
	};
	const isImagePick = useSelector((state) => state.data.image.length);


	return (
		<>
			<section 
				className={`${style.heroSection} ${daynight ? style.nightMode : ""}`}
			>
			<div className="col-lg-12">
				{isImagePick !== 0 ? (
					<>
					<label
						for="uploadImage"
						className={`${style.uploadImageCardBody} text-center w-100`}
					>
						<label
						for="uploadImage"
						className={`btn btn-primary ${style.btn_primary}`}
						href="/"
						>
						<i className="fas fa-upload"></i> {d("heroSection.upload")}
						</label>
						<input
						className="d-none"
						type="file"
						id="uploadImage"
						accept="image/*"
						onChange={onImageChange}
						/>
						<div className={`${style.mainText} mainText`}>
						{d("heroSection.drop")}
						</div>
					</label>
					</>
				) : (
					<div className="container">
					<h1 className={`${style.mainHeading} mainHeading text-center`}>
						{d("uploadImage")}
					</h1>
					<div className="row">
						<div className="col-lg-12">
						<div
							className={style.heroContentSide}
							style={{ width: "100%", maxWidth: "420px", marginTop: "3em" }}
						>
							<div className={style.uploadImageCard}>
							<label
								for="uploadImage"
								className={`${style.uploadImageCardBody} w-100`}
							>
								<img src="/images/logo-and-icons/new3small.svg" alt="" />
								<label
								for="uploadImage"
								className={`btn btn-primary ${style.btn_primary}`}
								href="/"
								>
								<i className="fas fa-upload"></i>{" "}
								{d("heroSection.upload")}
								</label>
								<input
								className="d-none"
								type="file"
								id="uploadImage"
								accept="image/*"
								onChange={onImageChange}
								/>
								<div className={`${style.mainText} mainText`}>
								{d("heroSection.drop")}
								</div>
							</label>
							<div className={style.uploadImageCardFooter}>
								<span className={`subText ${style.subText}`}>
								{d("heroSection.paste.text1")} <a href="/">URL</a>
								</span>
								<span
								className={`${style.keyWord} ${style.subText} subText`}
								>
								{d("heroSection.paste.text2")}
								</span>
								<span className={`${style.subText} subText`}>+</span>
								<span
								className={`${style.keyWord} ${style.subText} subText`}
								>
								{d("heroSection.paste.text3")}
								</span>
							</div>
							</div>
		
							<div className={style.otherImages}>
							<div className={style.othersContents}>
								<div className={style.othersHeading}>
								{d("heroSection.noImage")}
								</div>
								<div className={style.othersHeading}>
								{d("heroSection.try")}
								</div>
							</div>
							<div>
								<img
								className={style.othersImage}
								src="/images/sm-img1.jpg"
								alt=""
								/>
								<img
								className={style.othersImage}
								src="/images/sm-img1.jpg"
								alt=""
								/>
								<img
								className={style.othersImage}
								src="/images/sm-img1.jpg"
								alt=""
								/>
								<img
								className={style.othersImage}
								src="/images/sm-img1.jpg"
								alt=""
								/>
							</div>
							</div>
							<div className={`${style.smText} smText`}>
							{d("heroSection.uploadText.text1")}
							<a href="/"> {d("heroSection.uploadText.text2")} </a>
							{d("heroSection.uploadText.text3")}
							<a href="/">{d("heroSection.uploadText.text4")}</a>{" "}
							{d("heroSection.uploadText.text5")}
							<a href="/">{d("heroSection.uploadText.text6")}</a>{" "}
							{d("heroSection.uploadText.text7")}.
							</div>
						</div>
						</div>
					</div>
					</div>
				)}
			</div>
			</section>
			{isImagePick !== 0 ? <ImageEditor /> : ""}
		</>
	);
}
