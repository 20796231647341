import React, { useEffect, useState } from 'react';
// import '../App.css';
import Posts from '../components/admin/posts';
import PostLoadingComponent from '../components/posts/postLoading';
import axiosInstance from '../axios';
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";

import style from "../styles/services.module.css";

function Admin() {
	const daynight = useSelector((state) => state.data.dayNight);
	const d = useTranslation();

	const PostLoading = PostLoadingComponent(Posts);
	const [appState, setAppState] = useState({
		loading: true,
		posts: null,
	});

	useEffect(() => {
		axiosInstance.get().then((res) => {
			const allPosts = res.data;
			setAppState({ loading: false, posts: allPosts });
			console.log(res.data);
		});
	}, [setAppState]);

	return (
		<section className={`${style.teamSection} ${daynight ? style.nightMode : ""}`}>
			{/*
				<div className="App">
					<h1>Latest Posts</h1>
					<PostLoading isLoading={appState.loading} posts={appState.posts} />
				</div>
			*/}
			<div className="container">
				<div className="row">
					<div className="col-lg-12 mx-auto">
						{/*
							<div class={style.heroSectionContents}>
								<h1 class="mainHeading mb-1">{d("toolsapi.title")}</h1>
								<div class="commonText">{d("toolsapi.subtitle")}</div>
							</div>
						*/}
						<h2 className={`mainHeading`}>
							{d("posts.title")}
						</h2>
						<div className={`mt-5`}>
							<div className="PostOverview">
								<PostLoading isLoading={appState.loading} posts={appState.posts} data={d}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
export default Admin;
