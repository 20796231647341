import React from 'react';

import style from "../../styles/loaderSection.module.scss";

const Loader = ({ ...props }) => {

  return (
    <div className={`${props?.fromEditor ? style.editor : ''}`}>
      <div className={`${style.dots}`}>
            <div className={`${style.dot}`}></div>
            <div className={`${style.dot}`}></div>
            <div className={`${style.dot}`}></div>
            <div className={`${style.dot}`}></div>
            <div className={`${style.dot}`}></div>
        </div>

      <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" stdDeviation="12" result="blur" />
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
            <feBlend in="SourceGraphic" in2="goo" />
          </filter>
        </defs>
      </svg>
    </div>
  );
}

export default Loader;