import React, { useState } from "react";
import axiosInstance from '../axios';
import Cookies from "js-cookie";

import style from "../styles/auth.module.css";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import { Alert } from "react-bootstrap";

export default function Login() {
  const data = useTranslation();
  const history = useHistory();
  const [show, setShow] = useState(false);

  const initialFormData = Object.freeze({
		email: '',
		password: '',
	});

	const [formData, updateFormData] = useState(initialFormData);

	const handleChange = (e) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
		});
	};

  const handleSubmit = async(e) => {
		e.preventDefault();
		// console.log(formData);

		await axiosInstance
			.post(`token/`, {
				email: formData.email,
				password: formData.password,
			})
			.then((res) => {
				localStorage.setItem('access_token', res.data.access);
				localStorage.setItem('refresh_token', res.data.refresh);
				axiosInstance.defaults.headers['Authorization'] =
					'JWT ' + localStorage.getItem('access_token');

        localStorage.setItem('user', formData.email);
        // Cookies.set('me', { "data": res.data.refreshv, "lel": "lol" }, { expires: 365, path: '', domain: '', secure: false })
        Cookies.set('me', res.data.refresh, { expires: 365, path: '', domain: '', secure: false, sameSite: 'None' })

				// very dirty because you need to push before go otherwise you stay on same site
        history.push('/');
				history.go('/');
        setShow(false);
			})
			.catch((err) => {
				// alert("Unable to upload your file at the moment: " + err);
        setShow(true);
        setTimeout(() => {
          setShow(false);
        }, 4000);
			});
	};

  return (
    <>
    {show ? (
      <Alert
        variant="danger" // warning
        style={{ position: "fixed", left: "50%", transform: "translateX(-50%)", top: "36px", zIndex: "1040" }}
      >
        {data("cta.errorLogin")}
      </Alert>
    ) : (
      ""
    )}    
      <div
        class={style.customFrom}
        style={{
        }}
      >
        {/*<img src="/images/logo-and-icons/logo.svg" alt="" />*/}
        {/*
        <div class={style.socialMediaLinks}>
          <a href="#">
            <img
              class={style.facebookIcon}
              src="/images/login-facebook-icon.svg"
              alt=""
            />
            {data("auth.login.text1")}
          </a>
          <a href="#">
            <img
              class={style.googleIcon}
              src="/images/login-google-icon.svg"
              alt=""
            />
            {data("auth.login.text2")}
          </a>
        </div>
        */}
        <div class={style.formBody}>
          {/*
          <div class={style.orLines}>
            <div class={style.line}></div>
            <div class={style.oddText}>{data("auth.login.text3")}</div>
            <div class={style.line}></div>
          </div>
          */}
          <form action="">
            <input
              type="email"
              name="email"
              id="email"
              autoComplete="email"
              onChange={handleChange}
              placeholder={data("cta.email")} 
              class={style.commonInput}
            />
            <input
              type="password"
              name="password"
              id="password"
              onChange={handleChange}
              placeholder={data("cta.pw")}
              class={style.commonInput}
            />

            {/*
            <div class="mb-3">
              <input type="checkbox" name="" id="" className="mr-2" />
              <label for="#" class={style.oddText}>
                {" "}
                {data("auth.login.text4")}
              </label>
            </div>
            */}
            {/*
            <button
              class={`btn btn-primary ${style.btn}`}
              onClick={() => history.push("/home")}
            >
            */}
            {/*
            <button
              class={`btn btn-primary ${style.btn}`}
              onClick={handleSubmit}
            >
              {data("auth.login.text5")}
            </button>
            */}
            <button
              onClick={handleSubmit}
              className={`btn btn-secondary ${style.buttonAdventure}`}>
              {data("auth.login.text5")} <i class="fas fa-arrow-right"></i>
            </button>

            {/*
            <div class={`${style.oddText} ${style.two}`}>
              {data("auth.login.text6.text1")}
              <a href="#">{data("auth.login.text6.text2")}</a>{" "}
              {data("auth.login.text6.text3")}
              <a href="#">{data("auth.login.text6.text4")}</a>{" "}
              {data("auth.login.text6.text5")}
            </div>
            */}
          </form>
        </div>
      </div>
      {/*
      <div class={style.formFooterLinks}>
        <a href="#" class={style.oddText}>
          {data("auth.login.text7")}
        </a>
        <a href="#" class={style.oddText}>
          {data("auth.login.text8")}
        </a>
        <a href="#" class={style.oddText}>
          {data("auth.login.text9")}
        </a>
      </div>
      */}
    </>
  );
}
