import React from "react";
import style from "../styles/detailSection.module.css";
import { useSelector } from "react-redux";

export default function DetailSection(props) {
  const d = props.data;
  const daynight = useSelector((state) => state.data.dayNight);
  return (
    <>
      <section
        className={`${style.detailSection} ${daynight ? style.nightMode : ""}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 text-center">
              {/*src={`/images/${d("detail1.image")}`}*/}
              <img
                className="img-fluid"              
                src={`/images/cooperations.png`}
                alt=""
              />
            </div>
            <div className="col-lg-6 mt-5 mt-lg-0">
              <h2 className={`${style.subHeading} subHeading mb-1`}>
                {d("detail1.title")}
              </h2>
              <div className={style.commonText}>{d("detail1.description")}</div>
              <div className={style.commonText}>
                {d("detail1.description2")}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
