import React from "react";
import { useTranslation } from "react-multi-lang";

import UploadServiceSectionTasksWithEditor from "../UploadServiceSectionTasksWithEditor";
import AdBannner from "../../AdBannner";
import { SERVICES } from '../Services';


export default function ImageInpaintingNew() {
  const data = useTranslation();

  return (
    <>
      <UploadServiceSectionTasksWithEditor data={data} service={SERVICES.II}/>
      <AdBannner data={data} />
    </>
  );
}
