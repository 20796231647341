import React, { useEffect } from "react";
import HeroSection from "../components/exploreComponent/HeroSection";
import HeroSectionHome from "../components/aboutUsComponent/HeroSectionHome";
import DetailsSection from "../components/exploreComponent/DetailsSection";
import SiteSection from "../components/exploreComponent/SiteSection";
import CookiesModal from "../components/CookiesModal";
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";

export default function ExploreFull() {
  const data = useTranslation();
  const cookiesModal = useSelector((state) => state.data.cookiesModal);

	useEffect(() => {
		document.title = `${data("tab.explore")} - dioptify.com`;
	});

  return (
    <>
      {/* 
      <HeroSectionHome data={data} />
      <SiteSection data={data} />
      */}
      <HeroSection data={data} />
      {/* <DetailsSection /> */}
      {cookiesModal ? <CookiesModal /> : ""}
    </>
  );
}
