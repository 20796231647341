/** External Dependencies */
import React from 'react';

/** Internal Dependencies */
import { useFilter, useStore } from '../../../hooks';
import Carousel from '../../common/Carousel';
import ServiceItem from './ServiceItem';
import { AVAILABLE_FILTERS } from './Service.constants';

const style = { maxWidth: '100%', width: '100%' };

const Services = () => {
  const { originalImage } = useStore();
  const [appliedFilter, applyFilter] = useFilter();

  console.log(AVAILABLE_FILTERS)
  return (
    <Carousel className="FIE_filters" style={style}>
      {AVAILABLE_FILTERS.map((filter) => (
        // <div>{filter?.label}</div>
        <ServiceItem
          key={filter.label}
          backendKey={filter.key}
          serviceLabel={`editorService.${filter.label}`}
          serviceFn={filter.filterFn}
          applyService={applyFilter}
          isActive={appliedFilter === filter.filterFn}
          image={originalImage}
        />
      ))}
    </Carousel>
  );
};
export default Services;
