// https://stackoverflow.com/a/43001417
export const SERVICES = {
  NS: "ns",
  NSV: "nsv",
  SNS: "sns",
  SR: "sr",
  DH: "dh",
  DB: "db",
  FI: "fi",
  THREEDP: "3dp",
  RB: "rb",
  RBV: "rbv",
  HDR: "hdr",
  HDRV: "hdrv",
  PST: "pst",
  IP: "ip",
  OPR: "opr",
  DP: "dp",
  DPV: "dpv",
  II: "ii",
  VI: "vi",
  CL: "cl",
  DS: "ds",
  RF: "rf",
  FB: "fb",
  SD: "sd",
  PE: "pe",
};
  
export default class Services {};