import React, { useState } from "react";
import "./MultiStepProgressBar.css";
import { ProgressBar, Step } from "react-step-progress-bar";

import UploadedImageModal from "../uploadedImageModal/uploadedImageModal";

const MultiStepProgressBar = ({
  files,
  count,
  rejectedFiles,
  uploadedFiles,
  resetUploadingBar,
}) => {
  const [show, setShow] = useState(false);

  const [base64Image, setBase64Image] = useState({});
  const [success, setSuccess] = useState(false);
  const [selectedFile, setSelectedFile] = useState(false);

  var stepPercentage = (count / files.length) * 100;

  if (
    Array.isArray(files) &&
    files.length > 0 &&
    Array.isArray(rejectedFiles)&&
    Array.isArray(uploadedFiles)&&
    rejectedFiles.length + uploadedFiles.length === files.length
  ) {
    resetUploadingBar();
  }

  const showImageDialog = async (file, show, index) => {
    if (rejectedFiles.includes(index)) {
      setSuccess(false);
    } else {
      setSuccess(true);
    }
    setSelectedFile(file);
    let convertedImage = await convertBase64(file);
    setBase64Image(convertedImage);
    setShow(show);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <>
      <UploadedImageModal
        show={show}
        setShow={setShow}
        base64Image={base64Image}
        success={success}
        file={selectedFile}
      />

      {files?.length ? (
        <ProgressBar percent={stepPercentage}>
          {files?.map(() => {
            return (
              <Step>
                {({ accomplished, index }) => (
                  <div
                    onClick={() => {
                      showImageDialog(files[index], true, index);
                    }}
                    key={index}
                    className={`${
                      accomplished
                        ? rejectedFiles.includes(index)
                          ? "indexedStepError accomplishedError"
                          : "indexedStep accomplished"
                        : "indexedStep"
                    }`}
                  >
                    {accomplished ? (
                      rejectedFiles.includes(index) ? (
                        <i class="fa fa-times" aria-hidden="true"></i>
                      ) : uploadedFiles.includes(index) ? (
                        <i class="fa fa-check" aria-hidden="true"></i>
                      ) : (
                        index + 1
                      )
                    ) : (
                      index + 1
                    )}
                  </div>
                )}
              </Step>
            );
          })}
        </ProgressBar>
      ) : (
        ""
      )}
    </>
  );
};

export default MultiStepProgressBar;
