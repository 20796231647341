import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;
export const baseURLclear = process.env.REACT_APP_BASE_URL_CLEAR;
export const baseMLURL = process.env.REACT_APP_BASE_ML_URL_CLEAR;

// const baseURL = 'http://127.0.0.1:1337/api/';
// export const baseURLclear = 'http://127.0.0.1:1337';
// export const baseURLclear = 'http://34.239.222.93:1337'; // our public gpu server
// const baseURL = 'http://127.0.0.1:8000/api/';
// const baseURL = 'http://192.168.1.145:8000/api/';
// const baseURL = 'http://localhost:8000/api/';
// const baseURL = 'http://20.61.169.205:8000/api/';
// const baseURL = 'http://85.215.89.254:8000/api/'; // our public server
// const baseURL = 'http://34.239.222.93:1337/api/'; // our public gpu server

const axiosInstance = axios.create({
	baseURL: baseURL,
	timeout: 10000, // 500000, 5000
	headers: {
		Authorization: localStorage.getItem('access_token')
			? 'JWT ' + localStorage.getItem('access_token')
			: null,
		'Content-Type': 'application/json',
		accept: 'application/json',
	},
});

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		const originalRequest = error.config;

		if (typeof error.response === 'undefined') {
			// alert(
			console.log(
					'OH OH, a server/network error occurred. ' +
					'Sorry about this - we will get it fixed shortly.' + error
			);
			return Promise.reject(error);
		}

		if (
			error.response.status === 401 &&
			originalRequest.url === baseURL + 'token/refresh/'
		) {
			window.location.href = '/authentication/';
			return Promise.reject(error);
		}

		
		if (
			error.response.data.code === 'token_not_valid' &&
			error.response.status === 401
		) {
			const refreshToken = localStorage.getItem('refresh_token');

			if (refreshToken) {
				const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

				// exp date in token is expressed in seconds, while now() returns milliseconds:
				const now = Math.ceil(Date.now() / 1000);
				// console.log("tokenParts.exp: ", tokenParts.exp);
				// console.log("error.response: ", error.response);

				if (tokenParts.exp > now) {
					await new Promise(r => setTimeout(r, 500)); // TODO: did this fixed the bug?

					return axiosInstance
						.post('/token/refresh/', {
							refresh: refreshToken,
						})
						.then((response) => {
							console.log("response: ", response);
							error.response.status = 200;

							localStorage.setItem('access_token', response.data.access);
							localStorage.setItem('refresh_token', response.data.refresh);

							axiosInstance.defaults.headers['Authorization'] =
								'JWT ' + response.data.access;
							originalRequest.headers['Authorization'] =
								'JWT ' + response.data.access;

							return axiosInstance(originalRequest);
						})
						.catch((err) => {
							console.log("error in /token/refresh/: ", err);
						});
				} else {
					// console.log('Refresh token is expired', tokenParts.exp, now);
					window.location.href = '/authentication/';
				}
			} else {
				// console.log('Refresh token not available.');
				window.location.href = '/authentication/';
			}
		}

		// specific error handling done elsewhere
		return Promise.reject(error);
	}
);

export default axiosInstance;
