import React from "react";
import style from "../styles/ImageEditor.module.css";
import { useSelector } from "react-redux";
import ImageContainer from "./ImageContainer";
import { useTranslation } from "react-multi-lang";

export default function ImageEditor() {
  let image = useSelector((state) => state.data.image).reverse();
  let reverseArray = image.slice().reverse();
  const daynight = useSelector((state) => state.data.dayNight);
  const d = useTranslation();
  console.log(reverseArray);
  
  return (
    <div className={`${style.customBox} ${daynight ? style.nightMode : ""}`}>
      <div className="container">
        {reverseArray.map((item, i) => (
          <ImageContainer key={i} data={item} index={i} length={reverseArray.length} />
        ))}
      </div>
      {/*<p>{d("imageEditor.text6")}</p>*/}
    </div>
  );
}


/*
import React from "react";
import style from "../styles/ImageEditor.module.css";
import { useSelector } from "react-redux";
import ImageContainer from "./ImageContainer";
import { useTranslation } from "react-multi-lang";

export default function ImageEditor() {
  let image = useSelector((state) => state.data.image);
  let reverseArray = image.slice().reverse();
  const daynight = useSelector((state) => state.data.dayNight);
  const d = useTranslation();
  console.log(reverseArray);
  return (
    <div className={`${style.customBox} ${daynight ? style.nightMode : ""}`}>
      <div className="container">
        {image.map((item, i) => (
          <ImageContainer data={item} index={i} key={item.id} />
        ))}
      </div>
      <p>{d("imageEditor.text6")}</p>
    </div>
  );
}


*/