import React from "react";
import style from "../styles/ImageEditor.module.css";
import { useSelector } from "react-redux";
// TODO: ./node_modules/plyr/dist/plyr.min.js 1947:29
// Module parse failed: Unexpected token (1947:29)
// File was processed with these loaders:
// * ./node_modules/babel-loader/lib/index.js
// find another video player or fix this shit
// import VideoContainer from "./VideoContainer";
import { useTranslation } from "react-multi-lang";

export default function VideoEditor() {
  let video = useSelector((state) => state.data.video).reverse();
  let reverseArray = video.slice().reverse();
  const daynight = useSelector((state) => state.data.dayNight);
  const d = useTranslation();
  console.log("reverseArray: " + reverseArray);
  
  return (
    <div className={`${style.customBox} ${daynight ? style.nightMode : ""}`}>
      <div className="container">
        {reverseArray.map((item, i) => (
          {/*<VideoContainer data={item} index={i} length={reverseArray.length} />*/}
        ))}
      </div>
      {/*<p>{d("imageEditor.text6")}</p>*/}
    </div>
  );
}
