import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../axios";
import { useHistory, useParams } from "react-router-dom";
import { saveAs } from "file-saver";

import style from "../../styles/postOverview.module.css";

import PostGradiantLoading from "./postGradiantLoading";
import { Tabs, Tab } from "react-bootstrap";
import EditorModal from "../../pages/imageEditor/components/EditorModal/EditorModal";
import { checkIfInferencedImageAvailable, convertToMetricDate, cutLongFilename, decodeServiceName, toast } from "../../utils/helpers";
import { Spinner } from "reactstrap"

const useStyles = makeStyles((theme) => ({
  cardMedia: {
    paddingTop: "56.25%", // 16:9
    borderRadius: "15px",
    margin: "10px 10px 10px 10px",
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  postTitle: {
    fontSize: "18px",
    textAlign: "left",
  },
  postText: {
    display: "flex",
    justifyContent: "left",
    alignItems: "baseline",
    fontSize: "12px",
    textAlign: "left",
    marginBottom: theme.spacing(2),
    color: "rgba(150, 150, 150, 1.0)",
  },
  postTextNight: {
    display: "flex",
    justifyContent: "left",
    alignItems: "baseline",
    fontSize: "12px",
    textAlign: "left",
    marginBottom: theme.spacing(2),
    color: "rgba(120, 120, 120, 1.0)",
  },
  card: {
    backgroundColor: "rgba(66, 66, 64, 1.0)",
    color: "rgba(220, 220, 220, 1.0)",
    borderRadius: "25px",
  },
  cardNight: {
    backgroundColor: "rgba(245, 245, 245, 1.0)",
    borderRadius: "25px",
  },
}));

const Posts = (props) => {
  const { posts } = props;
  const classes = useStyles();
  const d = useTranslation();
  const daynight = useSelector((state) => state.data.dayNight);
  const history = useHistory();
  const [currentDoc, setCurrentDoc] = useState(10);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [imageForEditor, setImageForEditor] = useState();
  const [imageResolution, setImageResolution] = useState([1,1]);
  const [deleteLoading, setDeleteLoading] = useState(false);


  let count = 10;

  const handleScroll = useCallback(() => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    const scrollPosition = scrollTop + clientHeight;
    const scrollThreshold = scrollHeight - 1500;
    if (scrollPosition >= scrollThreshold && scrollHeight > 0) {

      fetchData();

    }
  }, []);
  const fetchData = useCallback(async() => {
  
    setCurrentDoc((doc) => doc + 10);
    count = count + 10;
    if (count >= posts.length) {
      setLoading(false);
      return;
    } 
    setLoading(true);
    await blockExecution(5000);

    setLoading(false);


  
  }, [currentDoc]);

  function blockExecution(duration) {
    return new Promise(resolve => {
      setTimeout(resolve, duration);
    });
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  const handleDelete = (e, post) => {
    e.preventDefault();
    setDeleteLoading(true)
    axiosInstance
      .delete("admin/delete/" + post.id)
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
        setDeleteLoading(false)
        toast(d("posts.delete"), "error")

      })
      .then(function (res) {
        setDeleteLoading(false)
        let itemToDelete = posts.findIndex(postItem=>postItem.id === post.id);
        if(itemToDelete > -1){
          posts.splice(itemToDelete,1)
        }
        props.deletePost(posts)

        toast(d("posts.deleteSuccess"), "success")

        // debugger
        // history.push({
        //   pathname: "/posts",
        //   search: "?posts",
        // });
        // window.location.reload();
      });
  };

 

  const saveFile = (e, post) => {
    e.preventDefault();
    var filename = post.image.substring(post.image.lastIndexOf("/") + 1);

    saveAs(post.image, filename);
  };

  if (!posts || posts.length === 0)
    return (
      <>
        {/*<p>{d("posts.missing")}<a href="/services">{d("posts.muploads")}</a></p>*/}
        <p>
          <a
            style={{ color: "var(--greenColor)", fontWeight: 800 }}
            href="/services"
          >
            {d("posts.missing")}
            {d("posts.muploads")}{" "}
            <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
          </a>
        </p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </>
    );

  const handleClick = (post) => {
    setShow(true);
    setImageForEditor(post.image);
  };

  var xxx = -1;
  var yyy = -1;
  function getMeta(url,post) {
    const img = new Image();
    img.src = url;
    let k = [];
    // var xxx;
    // var yyy;
    // img.onload = function() {   console.log("-------------------> the item:1 " + this.width, this.height);  }
    img.onload = function () {
      k.push(this.width, this.height);
      xxx = this.height;
      yyy = this.width;
    };

    var width = img.width;
    var height = img.height;
    // console.log("-------------------> the item afterafterafter: " + width, height);
    // console.log("-------------------> the item awdawdaw: " + xxx, yyy);
    // console.log("-------------------> the item afterafterafter: " + k);
    if (width != 0) {
      setImageResolution([width, height])
      post.resoulution = [width,height]
    } else {
      setImageResolution([1, 1])
      post.resoulution = [width,height]
    }
  }

  const onImageLoad = (url,post) =>{
    getMeta(url,post)
  }

  return (
    <React.Fragment>
      <EditorModal show={show} setShow={setShow} image={imageForEditor} />
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {posts.slice(0, currentDoc).map((post) => {
            return (
              // Enterprise card is full width at sm breakpoint
              <Grid item key={post.id} xs={12} md={6}>
                <Card
                  className={`${daynight ? `nightModeTab ${classes?.card}`  : ` ${classes?.cardNight}`}`}
                >
                  {/* <div className={`${style.imageTabs}`}>
    <div className={`${style.tab} ${style.selected}`}>Orignal</div>
    <div className={`${style.tab}`}>Inference</div>
  </div> */}
                  {/* <div className={`${style.imageTabs}`}>
    
   </div> */}

                  <Tabs
                    defaultActiveKey={checkIfInferencedImageAvailable(post?.service) ? "inferenced": "original" }
                    id="controlled-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey="original" title={d("uploadedImages.original")}>
                      <UploadImageTab
                        post={post}
                        classes={classes}
                        image={post.image}
                        daynight={daynight}
                        saveFile={saveFile}
                        handleClick={handleClick}
                        handleDelete={handleDelete}
                        onImageLoad ={onImageLoad}
                        imageResolution={post.resoulution}
                        deleteLoading = {deleteLoading}
                      ></UploadImageTab>
                    </Tab>
                    <Tab  eventKey="inferenced" title={d("uploadedImages.inferenced")}>
                      <UploadImageTab
                        post={post}
                        image={post?.original_image}
                        classes={classes}
                        daynight={daynight}
                        saveFile={saveFile}
                        handleClick={handleClick}
                        handleDelete={handleDelete}
                        onImageLoad ={onImageLoad}
                        imageResolution={post?.resoulution}
                        deleteLoading = {deleteLoading}

                      ></UploadImageTab>
                    </Tab>
                  </Tabs>
                </Card>
              </Grid>
            );
          })}
          {loading ? (
            <>
              <PostGradiantLoading
                dayNight={daynight}
                classes={classes}
              ></PostGradiantLoading>
              <PostGradiantLoading
                dayNight={daynight}
                classes={classes}
              ></PostGradiantLoading>
            </>
          ) : (
            ""
          )}
        </Grid>
      </Container>
    </React.Fragment>
  );
};

const UploadImageTab = (props) => {
  const d = useTranslation();

  return (
    <>
    <img  style={{
                display:"none"
              }} src={props.image} onLoad={()=>{props?.onImageLoad(props?.image, props.post)}}></img>
      <Link
        color="textPrimary"
        href={"post/" + props.post.slug}
        className={props?.classes?.link}
      >
        <CardMedia
          alt="Failed to Load Image"
          className={props.classes.cardMedia}
          image={props.image}
          onLoad={props?.onImageLoad}
          title={d("posts.gotitle")}
        />
      </Link>
      <CardContent className={props.classes.cardContent}>
        {/*
  <Typography
    gutterBottom
    variant="h6"
    component="h2"
    className={classes.postTitle}
  >
    {d("posts.postTitleImg")}: {post.title.substr(0, 30)}...
  </Typography>
  */}
        <div
          className={`${
            props.daynight
              ? props.classes.postText
              : props.classes.postTextNight
          }`}
        >
          <Typography>
            <a
              style={{
                fontWeight: 800,
                color: "var(--dioptifyGreen)",
              }}
            >
              {d("posts.postTitleImg")}
            </a>
            :{" "}
            { cutLongFilename( props.image
              .substring(props?.image.lastIndexOf("/") + 1),35,d)
           
            }
            <br />
            <a
              style={{
                fontWeight: 800,
                color: "var(--dioptifyGreen)",
              }}
            >
              {d("posts.postTitlePaid")}
            </a>
            :  {props?.post?.paid ? (
                <i
                  className={`fa fa-check ${style.checkMarkSuccess}`}
                  aria-hidden="true"
                ></i>
              ) : (
                <i
                  className={`fa fa-times ${style.checkMarkFailure}`}
                  aria-hidden="true"
                ></i>
              )}
            <br />
            <a
              style={{
                fontWeight: 800,
                color: "var(--dioptifyGreen)",
              }}
            >
              {d("posts.postTitleFromEditor")}
            </a>
            :  {props?.post?.editor ? (
                <i
                  className={`fa fa-check ${style.checkMarkSuccess}`}
                  aria-hidden="true"
                ></i>
              ) : (
                <i
                  className={`fa fa-times ${style.checkMarkFailure}`}
                  aria-hidden="true"
                ></i>
              )}
            <br />
            <a
              style={{
                fontWeight: 800,
                color: "var(--dioptifyGreen)",
              }}
            >
              {d("posts.postInferenced")}
            </a>
            :  {props?.post?.inference ? (
                <i
                  className={`fa fa-check ${style.checkMarkSuccess}`}
                  aria-hidden="true"
                ></i>
              ) : (
                <i
                  className={`fa fa-times ${style.checkMarkFailure}`}
                  aria-hidden="true"
                ></i>
              )}
            <br />
            <a
              style={{
                fontWeight: 800,
                color: "var(--dioptifyGreen)",
              }}
            >
              {d("posts.postTitleService")}
            </a>
            : {d(decodeServiceName(props?.post?.service)) }
            <br />
            <a
              style={{
                fontWeight: 800,
                color: "var(--dioptifyGreen)",
              }}
            >
              {d("posts.postTitlePublished")}
            </a>
            : {convertToMetricDate(props.post.published.substr(0, 10)) }
            <br/>
            <span>
              <a style={{ fontWeight: 800, color: "var(--dioptifyGreen)" }}>
                {d("imageEditor.text3")}
              </a>{" "}
              {props?.imageResolution && <span>{props?.imageResolution[0]} ×{" "}
                {props?.imageResolution[1]}</span>}

              
            </span>
          </Typography>
        </div>
        {/*
  <Button
    variant="contained"
    style={{
      borderRadius: 5,
      backgroundColor: "#00D100",
    }}
    type="submit"
    onClick={(e) => {
      handleDelete(e, post);
    }}											
  >
    {d("posts.postDelete")}
  </Button>
  <>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</>
  <Button
    variant="contained"
    style={{
      borderRadius: 5,
      backgroundColor: "#ff6961",
    }}
    type="submit"
    onClick={(e) => {
      handleDelete(e, post);
    }}											
  >
    {d("posts.postDelete")}
  </Button>
  */}
        <div className={`${style.actionButtons}`}>
          <a
            className={`btn btn-outline-secondary ${style.buttonAdventure}`}
            onClick={(e) => {
              props.saveFile(e, props.post);
            }}
          >
            {d("posts.postDownload")}
            <>&nbsp;</>
            <i className="fas fa-cloud-download-alt"></i>
          </a>

          <a
            className={`btn btn-outline-secondary ${style.buttonAdventure}`}
            onClick={(e) => {
              props.handleClick(props.post);
            }}
          >
            {/* {d("posts.postDownload")} */}
            {/*{d("imageEditor.text10")}*/}

            <>&nbsp;</>
            <i className="fas fa-edit"></i>
          </a>
          {localStorage.getItem("user") ? (
            <>
              {/* <>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</> */}

              <a
                className={`btn btn-outline-danger ${style.buttonAdventureSecondary}`}
                onClick={(e) => {
                  props.handleDelete(e, props.post);
                }}
              >
                {props.deleteLoading ? <Spinner  size="sm" color="light" className="mt-1 me-1" />: <>{d("posts.postDelete")}
                <>&nbsp;</>
                <i className="fas fa-trash"></i></>}
              </a>

            </>
          ) : (
            <></>
          )}
        </div>
      </CardContent>
    </>
  );
};
export default Posts;
