import React from "react";
import style from "../styles/waveheader.module.css";
import { useSelector } from "react-redux";

export default function WaveHeaderrBottom(props) {
  const d = props.data;
  const daynight = useSelector((state) => state.data.dayNight);

  return (
    <>
      <section className={`${style.customNavbar} fixed-top`}>
      {!daynight ?  (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path fill="#CAECC2" fillOpacity="1" 
                d="M0,192L48,186.7C96,181,192,171,288,149.3C384,128,480,96,576,106.7C672,117,768,171,864,192C960,213,1056,203,1152,192C1248,181,1344,171,1392,165.3L1440,160L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z">
              </path>
          </svg>
        ) : ""}
        {/**/}
        {daynight ?  (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path fill="var(--dioptifyGreen)" fillOpacity="1" 
                d="M0,192L48,186.7C96,181,192,171,288,149.3C384,128,480,96,576,106.7C672,117,768,171,864,192C960,213,1056,203,1152,192C1248,181,1344,171,1392,165.3L1440,160L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z">
              </path>
          </svg>
        ) : ""}
        {/**/}
      </section>
    </>
  );
}
