import React from "react";
import UploadVideoServiceSectionTasks from "../UploadVideoServiceSectionTasks";
import { SERVICES } from '../Services';

import { useTranslation } from "react-multi-lang";

export default function I3DPhoto() {
  const data = useTranslation();

  return (
    <>
      <UploadVideoServiceSectionTasks data={data} service={SERVICES.THREEDP}/>
    </>
  );
}
