import React from "react";
import UploadMultiService from "./UploadMultiService";
import { useTranslation } from "react-multi-lang";

export default function MultiService() {
  const data = useTranslation();

  return (
    <>
      <UploadMultiService data={data} />
    </>
  );
}
