// https://stackoverflow.com/a/43001417

export const PRICINGTABLECOR = {
    50:     [4.99, 0.0499, "sp1"],
    100:    [24.99, "∞", "sp2"],
    250:    [0.51, 0.00204, "sp3"],
    500:    [0.51, 0.00102, "sp4"],
    1000:   [0.51, 0.00051, "sp5"],
    2500:   [0.51, 0.000204, "sp6"],
    5000:   [0.51, 0.000102, "sp7"],
    7500:   [0.51, 0.000068, "sp8"],
    10000:  [0.51, 0.000051, "sp9"],
    15000:  [0.51, 0.000034, "sp10"],
    25000:  [0.51, 0.0000204, "sp11"],
    50000:  [0.51, 0.0000102, "sp12"],
    75000:  [0.51, 0.0000068, "sp13"],
    100000: [0.51, 0.0000051, "sp14"],
    999:    ["-", "?", "-"],
};

export const PRICINGTABLECORONCE = {
    1:      [9.99, 0.0999, "op1"],
    10:     [29.99, 0.06, "op2"],
    100:    [0.51, 0.0051, "op3"],
    250:    [0.51, 0.00204, "op4"],
    500:    [0.51, 0.00102, "op5"],
    1000:   [0.51, 0.00051, "op6"],
    2500:   [0.51, 0.000204, "op7"],
    5000:   [0.51, 0.000102, "op8"],
    7500:   [0.51, 0.000068, "op9"],
    10000:  [0.51, 0.000051, "op10"],
    25000:  [0.51, 0.0000204, "op11"],
    999:    ["-", "?", "-"],
};

export const PAYPALKEYVALUESUBSCRIPTION = {
    50:     [0.51, "sp1", "P-49C0955513769984TMJYN6ZI"],
    100:    [0.51, "sp2", "P-65K67908UD8113642MJYN7CY"],
    250:    [0.51, "sp3", "P-11W09669FD2364836MJYN7LI"],
    500:    [0.51, "sp4", "P-38J623342D249904YMJYN7QA"],
    1000:   [0.51, "sp5", "P-00676637VJ928710RMJYN7UQ"],
    2500:   [0.51, "sp6", "P-9BK46929BV3709818MJYN7ZY"],
    5000:   [0.51, "sp7", "P-9FD23849MH659422CMJYN76Y"],
    7500:   [0.51, "sp8", "P-1CR20392EA649384AMJYOAEA"],
    10000:  [0.51, "sp9", "P-57969992HE6883515MJYOAIQ"],
    15000:  [0.51, "sp10", "P-76M447273V139884PMJYOAOA"],
    25000:  [0.51, "sp11", "P-28L45465575039459MJYOATI"],
    50000:  [0.51, "sp12", "P-72M27528C7133903SMJYOAXI"],
    75000:  [0.51, "sp13", "P-6EM99492LL337142DMJYOA3Q"],
    100000: [0.51, "sp14", "P-37F06387PY699781MMJYOA7Q"],
};

export const PAYPALKEYVALUESUBSCRIPTIONLIVE = {
    50:     [0.51, "sp1", "P-06S35899HD2807533MNGVTPY"],
    100:    [0.51, "sp2", "P-3M6201153T908574SMNGVTYQ"],
    250:    [0.51, "sp3", "P-8A362454FX594651NMNGVT5Y"],
    500:    [0.51, "sp4", "P-9PX852301R7671146MNGVUCQ"],
    1000:   [0.51, "sp5", "P-9X612958YA362910DMNGVUHA"],
    2500:   [0.51, "sp6", "P-4L120377C5214283UMNGVULQ"],
    5000:   [0.51, "sp7", "P-9BJ28832JU417715LMNGVUPY"],
    7500:   [0.51, "sp8", "P-89598953P0056013UMNGVUTY"],
    10000:  [0.51, "sp9", "P-39E08938XV320783VMNGVUXI"],
    15000:  [0.51, "sp10", "P-32X837063V415245FMNGVU4A"],
    25000:  [0.51, "sp11", "P-64T19245Y6968601DMNGVU7I"],
    50000:  [0.51, "sp12", "P-9SW7819586309381KMNGVVPY"],
    75000:  [0.51, "sp13", "P-11G20533906327641MNGVVUQ"],
    100000: [0.51, "sp14", "P-89W72168RM544722HMNGVV3A"],
};

export default class PricingTable {};