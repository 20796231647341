import React from "react";
import style from "../styles/gallerySection.module.css";
import { useSelector } from "react-redux";
import FeaturesSplitGallery from "./FeaturesSplitGallery";

export default function GallerySection(props) {
  const d = props.data;
  const daynight = useSelector((state) => state.data.dayNight);
  return (
    <section className={`${style.gallerySection}  ${daynight ? style.nightMode : ""}`}>
      <div className="container">
        <div className="col-lg-12">
          <div className={`${style.card}`}>
            <h2 className={`${style.subHeading} subHeading`}>{d("servicePresentation.title")}</h2>
            <div className={`${style.itemsLinks} itemsLinks ${style.nav} nav`} id="myTab" role="tablist">
              <a
                className={`${style.itemLink} active`}
                id="tab-3"
                data-toggle="tab"
                href="#tab3"
                role="tab"
                aria-controls="tab3"
                aria-selected="true"
              >
                {d("servicePresentation.tabs.text3")}
              </a>
              <a
                className={style.itemLink}
                id="tab-2"
                data-toggle="tab"
                href="#tab2"
                role="tab"
                aria-controls="tab2"
                aria-selected="false"
              >
                {d("servicePresentation.tabs.text2")}
              </a>
              <a
                className={`${style.itemLink}`}
                id="tab-1"
                data-toggle="tab"
                href="#tab1"
                role="tab"
                aria-controls="tab1"
                aria-selected="false"
              >
                {d("servicePresentation.tabs.text1")}
              </a>
              {/*
              <a
                className={style.itemLink}
                id="tab-4"
                data-toggle="tab"
                href="#tab4"
                role="tab"
                aria-controls="tab4"
                aria-selected="false"
              >
                {d("servicePresentation.tabs.text4")}
              </a>
              */}
              {/*
              <a
                className={style.itemLink}
                id="tab-5"
                data-toggle="tab"
                href="#tab5"
                role="tab"
                aria-controls="tab5"
                aria-selected="false"
              >
                {d("servicePresentation.tabs.text5")}
              </a>
              */}
              <a
                className={style.itemLink}
                id="tab-6"
                data-toggle="tab"
                href="#tab6"
                role="tab"
                aria-controls="tab6"
                aria-selected="false"
              >
                {d("serviceHDR")}
              </a>
            </div>
            <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade" id="tab1" role="tabpanel" aria-labelledby="tab-1">
              <FeaturesSplitGallery data={d} tab={"tab1"}/>
            </div>
            <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab-2">
              <FeaturesSplitGallery data={d} tab={"tab2"}/>
            </div>
            <div className="tab-pane fade show active" id="tab3" role="tabpanel" aria-labelledby="tab-3">
              <FeaturesSplitGallery data={d} tab={"tab3"}/>
            </div>
            <div className="tab-pane fade" id="tab4" role="tabpanel" aria-labelledby="tab-4">
              <FeaturesSplitGallery data={d} tab={"tab4"}/>
            </div>
            <div className="tab-pane fade" id="tab5" role="tabpanel" aria-labelledby="tab-5">
              <FeaturesSplitGallery data={d} tab={"tab5"}/>
            </div>
            <div className="tab-pane fade" id="tab6" role="tabpanel" aria-labelledby="tab-6">
              <FeaturesSplitGallery data={d} tab={"tab6"}/>
            </div>
          </div>
        </div>
        </div>
      </div>
    </section>
  );
}
