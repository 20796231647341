import React from "react";
import { useTranslation } from "react-multi-lang";

import UploadServiceSectionTasks from "../UploadServiceSectionTasks";
import AdBannner from "../../AdBannner";
import { SERVICES } from '../Services';


export default function Refocus() {
  const data = useTranslation();

  return (
    <>
      <UploadServiceSectionTasks data={data} service={SERVICES.RF}/>
      <AdBannner data={data} />
    </>
  );
}
