/** External Dependencies */
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useStore } from "../../../hooks";
import { Layer, Image } from "react-konva";
import axiosInstance, { baseURLclear } from "../../../../../axios";
/** Internal Dependencies */
import {
  StyledServiceItem,
  ServiceItemPreview,
  ServiceItemLabel,
} from "./Services.styled";
import { useTranslation } from "react-multi-lang";
import { v4 as uuidv4 } from "uuid";
import { setImage } from "../../../../../redux/action/image";
import useOriginalImgData from "../../../hooks/useOriginalImgData";
import isSameImage from "../../../utils/isSameImage";
import loadImage from "../../../utils/loadImage";
import {
  HIDE_LOADER,
  SET_FEEDBACK,
  SET_ORIGINAL_IMAGE,
  SHOW_LOADER,
} from "../../../actions";
import ImageInpainting from "./ImageInpainting";
import { toast } from "../../../../../utils/helpers";
import { SHOW_SERVICES_LOADER } from "../../../actions/showLServicesLoader";
import { HIDE_SERVICES_LOADER } from "../../../actions/hideServiceLoader";

const MAX_FILTER_PREVIEW_WIDTH = 60;
const MAX_FILTER_PREVIEW_HEIGHT = 45;

const ServiceItem = ({
  serviceLabel,
  serviceFn,
  backendKey,
  isActive,
  image,
}) => {
  const state = useStore();

  const {
    originalImage,
    t,
    dispatch,
    config: { defaultSavedImageName },
  } = state;
  let resSuccess = false;
  const imageNodeRef = useRef();
  const baseUrl = baseURLclear;

  const d = useTranslation();
  const [checked, setChecked] = useState(true);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  let [chipData, updateChipData] = useState(0);
  const [freePLan, setPlan] = useState(false);
  const service = backendKey;
  const [imageFileInfo, setImageFileInfo] = useState({
    quality: 0.92,
  });

  // const dispatch = useDispatch();
  const imageBeingLoadedSrc = useRef(null);

  console.log(state);

  const transformImgFn = useOriginalImgData();

  const convertBase64ToBinary = (base64String) => {
    const byteCharacters = atob(base64String);
    const byteArray = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArray[i] = byteCharacters.charCodeAt(i);
    }
    return byteArray;
  };

  const handleFilterApplying = useCallback(async () => {
    if (service !== "ii" && service !== "pe" && service !== "sd") {
      const base64Img = await convertImageUrlToBase64(state.imgSrc);
      //   const transformedData = transformImgFn(imageFileInfo, false, true);
      const binaryData = convertBase64ToBinary(base64Img);

      const binaryImage = new Blob([binaryData], { type: "image/jpeg" });

      const finalFile = new File([binaryImage], "test.jpeg", {
        type: "image/jpeg",
      });

      uploadImage(finalFile);
    } else {
      setShow(true);
    }
  }, [serviceFn]);

  async function getImageFromUrl(url) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch the image");
      }
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error("Error fetching the image:", error);
      return null;
    }
  }

  function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  async function convertImageUrlToBase64(url) {
    const imageBlob = await getImageFromUrl(url);
    if (!imageBlob) {
      console.error("Failed to fetch the image from the URL");
      return null;
    }
    const base64Image = await blobToBase64(imageBlob);
    return base64Image;
  }

  const setNewOriginalImage = useCallback((newOriginalImage) => {
    dispatch({
      type: SET_ORIGINAL_IMAGE,
      payload: {
        originalImage: newOriginalImage,
      },
    });
  }, []);

  const setError = useCallback((newError) => {
    dispatch({
      type: SET_FEEDBACK,
      payload: {
        feedback: {
          message: newError.message || newError,
          duration: 0,
        },
      },
    });
  }, []);

  const loadAndSetOriginalImage = (imgToLoad) =>
    new Promise((resolve) => {
      const imgSrc = imgToLoad?.src || imgToLoad;
      if (
        imageBeingLoadedSrc.current === imgSrc ||
        (!imgSrc && originalImage) ||
        isSameImage(imgSrc, originalImage)
      ) {
        if (!imageBeingLoadedSrc.current) {
          resolve();
        }
        return;
      }

      const triggerResolve = () => {
        imageBeingLoadedSrc.current = null;
        resolve();
      };

      imageBeingLoadedSrc.current = imgSrc;

      if (typeof imgToLoad === "string") {
        loadImage(imgToLoad, defaultSavedImageName)
          .then(setNewOriginalImage)
          .catch(setError)
          .finally(triggerResolve);
      } else if (imgToLoad instanceof HTMLImageElement) {
        if (!imgToLoad.name && defaultSavedImageName) {
          // eslint-disable-next-line no-param-reassign
          imgToLoad.name = defaultSavedImageName;
        }
        if (!imgToLoad.complete) {
          imgToLoad.addEventListener("load", () => {
            setNewOriginalImage(imgToLoad);
            triggerResolve();
          });
          return;
        }

        setNewOriginalImage(imgToLoad);
        triggerResolve();
      } else {
        setError(t("invalidImageError"));
        triggerResolve();
      }
    });

  const uploadImage = async (file) => {

    let user = fetchUser();
    if (file) {
      let img = file;
      let slug = uuidv4();
      //	event.preventDefault();
      let formData = new FormData();
      formData.append("title", uuidv4());
      formData.append("slug", slug);
      formData.append("service", backendKey);
      formData.append("owner", user);
      formData.append("excerpt", uuidv4());
      formData.append("content", uuidv4());
      formData.append("new_model", checked);
      formData.append("image", img);
      formData.append("is_editor_upload", false);

      setLoading(true); // to show the loader svg
      dispatch({ type: SHOW_SERVICES_LOADER });

      const getStatus = async (taskID) => {
        await axiosInstance
          .get(`createimage/${taskID}/`)
          .then((res) => {
            const taskStatus = res.data.task_status;
            setShow(false);
            if (taskStatus === "SUCCESS") getProcessedImage();
            if (taskStatus === "SUCCESS" || taskStatus === "FAILURE")
              return taskStatus;
            setTimeout(function () {
              getStatus(res.data.task_id);
            }, 1000); // this recalls the function recursive to see if the tasks has ended
          })
          .catch((err) => {
            setLoading(false);
            dispatch({ type: HIDE_SERVICES_LOADER });
            setShow(true);
            toast("Failed to process image","error")
            setTimeout(() => {
              setShow(false);
            }, 4000);
          });
      };

      const getProcessedImage = async () => {
        axiosInstance
          .get("post/" + slug)
          .then((res) => {
            resSuccess = true;

            //setLoading(false); // to disable the loader svg
            setShow(false);
            dispatch({ type: HIDE_SERVICES_LOADER });

            // TODO: change to more mature logic here
            // tests due to some services overwrite original files
            if (
              service !== "rb" &&
              service !== "hdr" &&
              service !== "cl" &&
              service !== "ds" &&
              service !== "rf"
            ) {
              if (!res.data.paid) {
                loadAndSetOriginalImage(res?.data?.preview_image);
                dispatch(
                  setImage({
                    image: res.data.preview_image,
                    service: res?.data?.service,
                    originalImage: res?.data?.preview_image,
                    paid: false,
                    id: uuidv4(),
                  })
                );
              } else {
                loadAndSetOriginalImage(res?.data?.image);

                dispatch(
                  setImage({
                    image: res.data.image,
                    service: res?.data?.service,
                    originalImage: res?.data?.image,
                    paid: true,
                    id: uuidv4(),
                  })
                );
              }
            } else {
              if (!res.data.paid) {
                // TODO: check if this correct for all services
                // dispatch(setImage({ image: res.data.preview_image, id: uuidv4() }));

                loadAndSetOriginalImage(res?.data?.original_image);
                dispatch(
                  setImage({
                    image: res?.data?.original_image,
                    originalImage: res?.data?.image,
                    service: res?.data?.service,
                    paid: true,
                    id: uuidv4(),
                  })
                );
              } else {
                loadAndSetOriginalImage(res?.data?.original_image);
                dispatch(
                  setImage({
                    image: res?.data?.original_image,
                    originalImage: res?.data?.image,
                    service: res?.data?.service,
                    paid: false,
                    id: uuidv4(),
                  })
                );
              }
            }

            // to be dynamic when all the credits are gone it needs to update the cost
            getChipAmount();
          })
          .catch((err) => {
            setLoading(false); // to disable the loader svg
            // alert("Unable to upload your image at the moment: " + err);
            dispatch({ type: HIDE_SERVICES_LOADER });

            setShow(true);
            // setShowProgressBar(false)
            setTimeout(() => {
              setShow(false);
            }, 4000);
          });
      };

      await axiosInstance
        .post(`createimage/`, formData)
        .then((res) => {
          let taskStatus = getStatus(res.data.task_id); // TESTs
          setShow(false);

          // this way don't work because the taskStatus is pending and will not get set to success
          // console.log("dddddddddddddddddddddd taskStatus: ", taskStatus);
          // if (taskStatus === 'SUCCESS') getProcessedImage();

          resSuccess = true;
        })
        .catch((err) => {
          dispatch({ type: HIDE_SERVICES_LOADER });

          resSuccess = false;
          setLoading(false); // to disable the loader svg
          setShow(true);
          setTimeout(() => {
            setShow(false);
          }, 4000);
        });
    }
  };

  const fetchUser = () => {
    let user = "anon";
    if (localStorage.getItem("user") !== null) {
      user = localStorage.getItem("user");
    } else {
      if (localStorage.getItem("temp_user") === null) {
        user = uuidv4();
        localStorage.setItem("temp_user", user);
      } else {
        user = localStorage.getItem("temp_user");
      }
    }

    return user;
  };

  const getChipAmount = async (event) => {
    let formData2 = new FormData();
    formData2.append("email", uuidv4());
    formData2.append("username", uuidv4());

    console.log("get chippp ammount");

    await axiosInstance
      .get(`user/creditscore/`, formData2)
      .then((res) => {
        updateChipData(res.data.credit_score);
        // updateChipData is not working in time
        chipData = res.data.credit_score;

        if (chipData > 0) {
          setPlan(false);
        } else {
          setPlan(true);
        }
      })
      .catch((err) => {
        console.log("Unable to upload your file at the moment: " + err);
      });
  };

  const cacheImageNode = useCallback(() => {
    if (imageNodeRef.current) {
      imageNodeRef.current.cache();
    } else {
      setTimeout(cacheImageNode, 0);
    }
  }, []);

  useEffect(() => {
    if (image) {
      cacheImageNode();
    }

    return () => {
      imageNodeRef.current?.clearCache();
    };
  }, [image]);

  const imgRatio = image.width / image.height;
  const isVerticalImg = imgRatio < 1;
  const filterImgPreviewWidth = isVerticalImg
    ? MAX_FILTER_PREVIEW_WIDTH
    : MAX_FILTER_PREVIEW_HEIGHT * imgRatio;
  const filterImgPreviewHeight = isVerticalImg
    ? MAX_FILTER_PREVIEW_WIDTH / imgRatio
    : MAX_FILTER_PREVIEW_HEIGHT;

  const handleChildCallback = async (img, mask, prompt, pbe_image) => {
    // console.log("SPEAKING OF CHILDREN CALLBACK TO PARENT IMAGE: ", img);
    // console.log("SPEAKING OF CHILDREN CALLBACK TO PARENT MASK: ", mask);
    // console.log("SPEAKING OF CHILDREN CALLBACK TO PARENT PBE_IMAGE: ", pbe_image);

    onImageChange(img, mask, prompt, pbe_image);
  };

  const onImageChange = async (image, maskImage, prompt, pbeImage) => {
    // if (event.target.files && event.target.files[0]) {
    if (true) {
      // let img = event.target.files[0];
      let img;
      let mask;
      let pbe_img;

      await fetch(image)
        .then((res) => res.blob())
        .then((data) => {
          img = data;
        })
        .then(() => {
          console.log("img: ", img);
        });

      await fetch(maskImage)
        .then((res) => res.blob())
        .then((data) => {
          mask = data;
        })
        .then(() => {
          console.log("mask: ", mask);
        });

      // TODO: add here proper mechanism for catching nonetype pbeImages due to usage of II or SD services
      console.log("pbeImage: ", pbeImage);
      if (pbeImage !== null) {
        await fetch(pbeImage)
          .then((res) => res.blob())
          .then((data) => {
            pbe_img = data;
          })
          .then(() => {
            console.log("pbe_img: ", pbe_img);
          });
      }

      // TODO: get correct filename!
      // TODO: check if good behavior regarding regex
      // https://stackoverflow.com/a/6582227
      let imgFile = await dataUrlToFile(
        image,
        "testFileName." + img.type.match(/\/[0-9a-z]+$/i)[0].substring(1),
        img.type
      );
      let maskFile = await dataUrlToFile(
        maskImage,
        "testFileNameMask." + mask.type.match(/\/[0-9a-z]+$/i)[0].substring(1),
        mask.type
      );
      // TODO: add here proper mechanism for catching nonetype pbeImages due to usage of II or SD services
      let pbeImgFile = null;
      if (pbeImage !== null) {
        pbeImgFile = await dataUrlToFile(
          pbeImage,
          "testFileNamePBE." +
            pbe_img.type.match(/\/[0-9a-z]+$/i)[0].substring(1),
          pbe_img.type
        );
      }

      // let img = image;
      let slug = uuidv4();

      let user = "anon";
      if (localStorage.getItem("user") !== null) {
        user = localStorage.getItem("user");
      } else {
        if (localStorage.getItem("temp_user") === null) {
          user = uuidv4();
          localStorage.setItem("temp_user", user);
        } else {
          user = localStorage.getItem("temp_user");
        }
      }

      // event.preventDefault();
      let formData = new FormData();
      formData.append("title", uuidv4());
      formData.append("slug", slug);
      formData.append("service", service);
      formData.append("owner", user);
      formData.append("excerpt", uuidv4());
      formData.append("content", uuidv4());
      formData.append("new_model", checked);
      formData.append("image", imgFile);
      formData.append("mask", maskFile);
      formData.append("prompt", prompt);
      // TODO: add here proper mechanism for catching nonetype pbeImages due to usage of II or SD services
      if (pbeImage !== null) {
        formData.append("paint_by_example_image", pbeImgFile);
      }

      setLoading(true); // to show the loader svg
      dispatch({ type: SHOW_SERVICES_LOADER });


      const getStatus = async (taskID) => {
        await axiosInstance
          .get(`createeditorimage/${taskID}/`)
          .then((res) => {
            const taskStatus = res.data.task_status;
           
            setShow(false);

            if (taskStatus === "SUCCESS") getProcessedImage();
            if (taskStatus === "SUCCESS" || taskStatus === "FAILURE")
              
              return taskStatus;
            setTimeout(function () {
              getStatus(res.data.task_id);
            }, 1000); // this recalls the function recursive to see if the tasks has ended
          })
          .catch((err) => {
            console.log("Unable to get at the moment: " + err);
            // TODO: add here some toast or other message to show an error
            setLoading(false);
            dispatch({ type: HIDE_SERVICES_LOADER });

            setShow(true);
            setTimeout(() => {
              setShow(false);
            }, 4000);
          });
      };

      const getProcessedImage = async () => {
        axiosInstance
          .get("editorservicepost/" + slug)
          .then((res) => {
            resSuccess = true;
            dispatch({ type: HIDE_SERVICES_LOADER });

            setLoading(false); // to disable the loader svg
            setShow(false);

            // TODO: change to more mature logic here
            // tests due to some services overwrite original files
            if (
              service !== "rb" &&
              service !== "hdr" &&
              service !== "cl" &&
              service !== "ds" &&
              service !== "rf"
            ) {
              if (!res.data.paid) {
                loadAndSetOriginalImage(res?.data?.preview_image);

                dispatch(
                  setImage({ image: res.data.preview_image, id: uuidv4() })
                );
              } else {
                loadAndSetOriginalImage(res?.data?.image);

                dispatch(setImage({ image: res.data.image, id: uuidv4() }));
              }
            } else {
              if (!res.data.paid) {
                loadAndSetOriginalImage(res?.data?.original_image);

                // TODO: check if this correct for all services
                // dispatch(setImage({ image: res.data.preview_image, id: uuidv4() }));
                dispatch(
                  setImage({ image: res.data.original_image, id: uuidv4() })
                );
              } else {
                loadAndSetOriginalImage(res?.data?.original_image);

                dispatch(
                  setImage({ image: res.data.original_image, id: uuidv4() })
                );
              }
            }

            // to be dynamic when all the credits are gone it needs to update the cost
            getChipAmount();
          })
          .catch((err) => {
            setLoading(false); // to disable the loader svg
            // alert("Unable to upload your image at the moment: " + err);
            dispatch({ type: HIDE_SERVICES_LOADER });

            setShow(true);
            setTimeout(() => {
              setShow(false);
            }, 4000);
          });
      };

      await axiosInstance
        .post(`createeditorimage/`, formData)
        .then((res) => {
          let taskStatus = getStatus(res.data.task_id); // TESTs
          setShow(false);
          resSuccess = true;
        })
        .catch((err) => {
          resSuccess = false;
          setLoading(false); // to disable the loader svg
          dispatch({ type: HIDE_SERVICES_LOADER });
          toast("failed to convert image","error")
          setShow(true);
          setTimeout(() => {
            setShow(false);
          }, 4000);
        });
    }
  };

  async function dataUrlToFile(dataUrl, fileName, fileType) {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: fileType });
  }

  return (
    <StyledServiceItem
      className="FIE_filters-item"
      onClick={handleFilterApplying}
      aria-selected={isActive}
    >
      <ImageInpainting
        show={show}
        setShow={setShow}
        imgSrc={state.imgSrc}
        parentCallback={handleChildCallback}
        service={backendKey}
      />

      <ServiceItemPreview
        className="FIE_filters-item-preview"
        width={MAX_FILTER_PREVIEW_WIDTH}
        height={MAX_FILTER_PREVIEW_HEIGHT}
      >
        <Layer onTap={handleFilterApplying}>
          <Image
            image={image}
            filters={serviceFn ? [serviceFn] : []}
            width={filterImgPreviewWidth}
            height={filterImgPreviewHeight}
            x={-(filterImgPreviewWidth - MAX_FILTER_PREVIEW_WIDTH) / 2}
            y={-(filterImgPreviewHeight - MAX_FILTER_PREVIEW_HEIGHT) / 2}
            ref={imageNodeRef}
          />
        </Layer>
      </ServiceItemPreview>
      <ServiceItemLabel className="FIE_filters-item-label">
        {d(serviceLabel)}
      </ServiceItemLabel>
    </StyledServiceItem>
  );
};

ServiceItem.defaultProps = {
  serviceFn: undefined,
};

ServiceItem.propTypes = {
  image: PropTypes.instanceOf(HTMLImageElement).isRequired,
  serviceLabel: PropTypes.string.isRequired,
  serviceFn: PropTypes.func,
  applyService: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default memo(ServiceItem);
