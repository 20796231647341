import React from "react";
import style from "../styles/cookies.module.css";

export default function CookiesTable(props) {
  const data = props.data;
  return (
    <div style={{ overflowX: "auto" }}>
      <h5>{data.title}</h5>
      <div className={style.responsive_table}>
        <table>
          <thead>
            <tr>
              {data.header.map((item) => (
                <th>{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.body.map((item) => (
              <tr>
                <td>{item.firstColumn}</td>
                <td>{item.secondColumn}</td>
                <td>{item.thirdColumn}</td>
                <td>{item.fourColumn}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
