/** External Dependencies */
import React, { memo, useRef, useState } from "react";
import PropTypes from "prop-types";
import ThemeProvider from "@scaleflex/ui/theme";

/** Internal Dependencies */
import { AppProvider } from "../../context";
import defaultConfig from "../../context/defaultConfig";
import deepMerge from "../../utils/deepMerge";
import { FontsFaces, OverrideDefaultStyles } from "./globalStyles";
import Editor from "../App/Editor";
import { useLocation, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import axiosInstance, { baseURLclear } from "../../../../axios";
import Dropzone, { useDropzone } from "react-dropzone";
import style from "../../../../styles/uploadSection.module.css";
import { useSelector } from "react-redux";
import { getLanguage, setLanguage, useTranslation } from "react-multi-lang";
import { TOOLS_IDS } from "../../utils/constants";

import Loader from "../../../../components/loaderComponent/Loader";
import { useStore } from "../../hooks";
import { toast } from "../../../../utils/helpers";

const AssemblyPoint = (props) => {
  const { previousEditor } = props;

  const d = useTranslation();
  const [loading, setLoading] = useState(false);
  const [imageSource, setImageSource] = useState("");
  const daynight = useSelector((state) => state.data.dayNight);
  const previousState = useSelector((state) => state.data.editorData);
  const imageBeingLoadedSrc = useRef(null);

  const baseUrl = baseURLclear;
  // const state = useStore()
  //console.log(state)
  const uploadImage = async (file) => {
    if (file) {
      let img = file;
      console.log(file);
      let slug = uuidv4();

      let user = "anon";
      if (localStorage.getItem("user") !== null) {
        user = localStorage.getItem("user");
      } else {
        if (localStorage.getItem("temp_user") === null) {
          user = uuidv4();
          localStorage.setItem("temp_user", user);
        } else {
          user = localStorage.getItem("temp_user");
        }
      }

      //	event.preventDefault();
      let formData = new FormData();
      formData.append("title", uuidv4());
      formData.append("slug", slug);
      formData.append("service", "sr");
      formData.append("owner", user);
      formData.append("excerpt", uuidv4());
      formData.append("content", uuidv4());
      formData.append("new_model", true);
      formData.append("is_editor_upload", true);
      formData.append("image", img);
      setLoading(true);

      await axiosInstance
        .post(`createimage/`, formData)
        .then((res) => {
          setLoading(false);
          let responseData = res.data["serializer.data"];
          let imageSource = `${baseUrl}${responseData["image"]}`;
          setImageSource(imageSource);
        })
        .catch((err) => {
          toast(d("editorErrors.uploadImage"), "error");
          setLoading(false);
        });
    }
  };
  const onFileChange = async (files) => {
    await uploadImage(files[0]);
  };

  let crop = {
    ratio: defaultConfig[TOOLS_IDS.CROP]?.ratio,
    ratioTitleKey:
      defaultConfig[TOOLS_IDS.CROP]?.ratioTitleKey ??
      defaultConfig[TOOLS_IDS.CROP]?.ratio,
    width: null,
    height: null,
    x: 0,
    y: 0,
  };

  let defaultAndProvidedConfigMerged = deepMerge(defaultConfig, props);

  console.log(previousEditor);

  defaultAndProvidedConfigMerged.crop = crop;

  if (previousEditor) {
    defaultAndProvidedConfigMerged.filter = previousState?.filter;
    defaultAndProvidedConfigMerged.isFlippedX =
      previousState?.adjustments?.isFlippedX;
    defaultAndProvidedConfigMerged.isFlippedY =
      previousState?.adjustments?.isFlippedY;
    defaultAndProvidedConfigMerged.rotation =
      previousState?.adjustments?.rotation;
    defaultAndProvidedConfigMerged.annotations =
      previousState.annotations || {};
    defaultAndProvidedConfigMerged.finetunesProps =
      previousState.finetunesProps;
    defaultAndProvidedConfigMerged.tabId = previousState.tabId || null;
    defaultAndProvidedConfigMerged.toolId = previousState.toolId || null;
    defaultAndProvidedConfigMerged.crop =
      previousState?.adjustments?.crop || {};
    defaultAndProvidedConfigMerged.isApplyingServiceLoading =
      previousState?.isApplyingServiceLoading;
  } else {
    defaultAndProvidedConfigMerged.filter = null;
    defaultAndProvidedConfigMerged.isFlippedX = false;
    defaultAndProvidedConfigMerged.isFlippedY = false;
    defaultAndProvidedConfigMerged.rotation = 0;
    defaultAndProvidedConfigMerged.annotations = {};
    defaultAndProvidedConfigMerged.finetunesProps = {};
    defaultAndProvidedConfigMerged.tabId = null;
    defaultAndProvidedConfigMerged.toolId = null;
  }

  if (daynight) {
    defaultAndProvidedConfigMerged.theme.palette["bg-secondary"] = "#222224";
    defaultAndProvidedConfigMerged.theme.palette["bg-primary"] = "#454545";
    defaultAndProvidedConfigMerged.theme.palette["accent-primary"] = "green";
    defaultAndProvidedConfigMerged.theme.palette["accent-primary-active"] =
      "rgb(69, 69, 69)";
  } else {
    defaultAndProvidedConfigMerged.theme.palette["bg-secondary"] = "#f4f4f4";
    // defaultAndProvidedConfigMerged.theme.palette['bg-secondary-active'] = 'rgb(84, 97, 108)';
    defaultAndProvidedConfigMerged.theme.palette["bg-primary"] = "white";
    defaultAndProvidedConfigMerged.theme.palette["accent-primary"] = "green";
    defaultAndProvidedConfigMerged.theme.palette["accent-primary-active"] =
      "gray";
  }

  if (getLanguage() === "gr") {
    defaultAndProvidedConfigMerged.language = "gr";
  } else {
    defaultAndProvidedConfigMerged.language = "en";
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onFileChange,
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
    multiple: false
  });

  return (
    <React.StrictMode>
      <ThemeProvider theme={defaultAndProvidedConfigMerged.theme}>
        <FontsFaces />
        <OverrideDefaultStyles />
        <AppProvider config={defaultAndProvidedConfigMerged}>
          {!props?.imageToEdit &&
            !imageSource &&
            (!previousState?.imgSrc || !previousEditor) && (
              <div
                className={`${style.heroSection} ${
                  daynight ? style.nightMode : ""
                }`}
              >
                <div className={style.heroContentSide}>
                  <div
                    className={`${style.uploadImageCard} ${
                      isDragActive ? style?.active : ""
                    }`}
                  >
                    <section>
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <label
                          for="uploadImage"
                          className={`${style.uploadImageCardBody} w-100`}
                        >
                          <img
                            src="/images/logo-and-icons/new3small.svg"
                            alt=""
                          />
                          <label
                            for="uploadImage"
                            className={`btn btn-secondary ${style.btn_primary}`}
                            href="/"
                          >
                            <i className="fas fa-upload"></i>
                            {d("imageEditorNew.text1")}
                          </label>

                          <div className={`${style.mainText} mainText`}>
                            {d("imageEditorNew.text2")}
                          </div>
                        </label>
                        {loading && (
                          <div className="text-center">
                            <Loader></Loader>
                            <br />
                            <br />
                          </div>
                        )}
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            )}
          {(props?.imageToEdit ||
            imageSource ||
            (previousState?.imgSrc && previousEditor)) && (
            <Editor
              imageToEdit={
                props.imageToEdit || imageSource || previousState?.imgSrc
              }
            />
          )}
        </AppProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
};

AssemblyPoint.defaultProps = {
  useCloudimage: false,
  cloudimage: {},
  // TODO: Remove this property from here after PROD. release
  img: undefined,
};

AssemblyPoint.propTypes = {
  // TODO: Remove this property from here after PROD. release
  img: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(HTMLImageElement),
    PropTypes.instanceOf(SVGImageElement),
    PropTypes.instanceOf(ImageBitmap),
  ]),
  useCloudimage: PropTypes.bool,
  cloudimage: PropTypes.instanceOf(Object),
};

export default memo(AssemblyPoint);
