import React, { useState } from "react";
import style from "../styles/auth.module.css";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import Input from '../components/elements/Input';
import axiosInstance from '../axios';
import Cookies from "js-cookie";

export default function Signup() {
  const data = useTranslation();
  const history = useHistory();

  var email = "";
  var username = "";
  var pw1 = "";
  var pw2 = "";
  var promocode = "";
  
  const initialFormData = Object.freeze({
		email: '',
		username: '',
		password: '',
		promocode: '',
	});

	const [formData, updateFormData] = useState(initialFormData);
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleSubmit = async(e) => {
		e.preventDefault();
		// console.log(formData);

    // console.log(initialFormData);

		await axiosInstance
			.post(`user/create/`, {
				email: formData.email,
				username: formData.username,
				password: formData.password,
				promocode: formData.promocode, // TODO: remove all the promocode stuff when not needed anymore
			})
			.then((res) => {
        // for getting the tokens
        axiosInstance
        .post(`token/`, {
          email: formData.email,
          password: formData.password,
        })
        .then((res) => {
          localStorage.setItem('access_token', res.data.access);
          localStorage.setItem('refresh_token', res.data.refresh);
          axiosInstance.defaults.headers['Authorization'] =
            'JWT ' + localStorage.getItem('access_token');
  
          localStorage.setItem('user', formData.email);
  
          // very dirty because you need to push before go otherwise you stay on same site
          history.push('/');
          history.go('/');
          setShow(false);
        })
        .catch((err) => {
          // alert("Unable to upload your file at the moment: " + err);
          setShow(true);
          setTimeout(() => {
            setShow(false);
          }, 4000);
        });


        // history.push('/');
				
        // console.log(res);
				//console.log(res.data);
        setShow(false);
			})
			.catch((err) => {
				// alert("Unable to upload your file at the moment: " + err);
        setShow(true);
        setTimeout(() => {
          setShow(false);
        }, 4000);
			});
	};

  function sendEmail(event) {
    // console.log("clicke on kiwi: " + email);
    // console.log("checken with kiwi: " + validateEmail(email));
    if(validateEmail(email) && (pw1 === pw2)) {
      // send email here;
      setShow(false);

      return true;
    } else {
        setShow(true);
        setTimeout(() => {
          setShow(false);
        }, 4000);
    }
  }

  function enterPressed(event) {
    var code = event.keyCode || event.which;
    if(code === 13) {
        sendEmail()
    } 
  }

  function updateInputValue(evt) {
    email = evt.target.value;

		updateFormData({
			...formData,
			email: email,
		});
  }

  function updateInputValueUserName(evt) {
    username = evt.target.value;

		updateFormData({
			...formData,
			username: username,
		});
  }

  function updateInputValuePromoCode(evt) {
    promocode = evt.target.value;

		updateFormData({
			...formData,
			promocode: promocode,
		});
  }

  function updateInputValuePW1(evt) {
    pw1 = evt.target.value;

		updateFormData({
			...formData,
			password: pw1,
		});
  }

  function updateInputValuePW2(evt) {
    pw2 = evt.target.value;
  }

  function validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  const [show, setShow] = useState(false);


  return (
    <>
    {show ? (
      <Alert
        variant="danger" // warning
        style={{ position: "fixed", left: "50%", transform: "translateX(-50%)", top: "36px", zIndex: "1040" }}
      >
        {data("cta.errorReg")}
      </Alert>
    ) : (
      ""
    )}    
      <div
        class={style.customFrom}
        style={{
        }}
      >
        {/*<img src="/images/logo-and-icons/logo.svg" alt="" />*/}
        {/*
        <div class={style.socialMediaLinks}>
          <a href="#">
            <img
              class={style.facebookIcon}
              src="/images/login-facebook-icon.svg"
              alt=""
            />
            {data("auth.signup.text1")}
          </a>
          <a href="#">
            <img
              class={style.googleIcon}
              src="/images/login-google-icon.svg"
              alt=""
            />
            {data("auth.signup.text2")}
          </a>
        </div>
        */}
        <div class={style.formBody}>
          {/*
          <div class={style.orLines}>
            <div class={style.line}></div>
            <div class={style.oddText}>{data("auth.signup.text3")}</div>
            <div class={style.line}></div>
          </div>
          */}
          <form >
          <input
              type="email"
              name=""
              id=""
              class={style.commonInput}
              onChange={evt => updateInputValue(evt)} 
              placeholder={data("cta.email")} 
              onKeyPress={enterPressed.bind(this)}
            />
            <input
              type="username"
              name=""
              id=""
              class={style.commonInput}
              onChange={evt => updateInputValueUserName(evt)} 
              placeholder={data("cta.userName")} 
              onKeyPress={enterPressed.bind(this)}
            />
            <input
              type="password"
              name=""
              id=""
              class={style.commonInput}
              onChange={evt => updateInputValuePW1(evt)} 
              placeholder={data("cta.pw1")} 
              onKeyPress={enterPressed.bind(this)}
            />
            <input
              type="password"
              name=""
              id=""
              class={style.commonInput}
              onChange={evt => updateInputValuePW2(evt)} 
              placeholder={data("cta.pw2")} 
              onKeyPress={enterPressed.bind(this)}
            />
            <input
              type="promocode"
              name=""
              id=""
              class={style.commonInput}
              onChange={evt => updateInputValuePromoCode(evt)} 
              placeholder="Promocode" 
              onKeyPress={enterPressed.bind(this)}
            />

            <div class={style.loginTxt}>
              <input 
                type="checkbox"
                name=""
                id=""
                className="mr-2" 
                checked={checked}
                onChange={handleChange}
              />
              <label for="#" class={style.oddText}>
                {data("auth.signup.text5.text1")}{" "}
                <a href="termsofservice">{data("auth.signup.text5.text2")}</a>,
                <a href="termsandconditions">{data("auth.signup.text5.text3")}</a>{" "}
                {data("auth.signup.text5.text4")}
                <a href="privacy">{data("auth.signup.text5.text5")}</a>.
              </label>
            </div>
            {/*
            <div class={`${style.loginTxt} mb-3`}>
              <input type="checkbox" name="" id="" className="mr-2" />
              <label for="#" class={style.oddText}>
                {data("auth.signup.text4")}
              </label>
            </div>
            */}
            
            {/*
            <button
              class={`btn btn-primary ${style.btn}`}
              onClick={() => history.push("/home")}
            >
              {data("auth.signup.text9")}
            </button>
            */}
            
            {/*
            <button
              disabled={checked ? false : true}
              class={`btn btn-primary ${style.btn}`}
              onClick={handleSubmit}
            >
              {data("auth.signup.text9")}
            </button>
            */}

            <button
              disabled={checked ? false : true}
              onClick={handleSubmit}
              className={`btn btn-secondary ${style.buttonAdventure}`}>
              {data("auth.signup.text9")} <i class="fas fa-arrow-right"></i>
            </button>            
            
            {/*
            <div class={`${style.oddText} ${style.two}`}>
              {data("auth.signup.text6.text1")}
              <a href="#">{data("auth.signup.text6.text2")}</a>{" "}
              {data("auth.signup.text6.text3")}
              <a href="#">{data("auth.signup.text6.text4")}</a>{" "}
              {data("auth.signup.text6.text5")}.
            </div>
            */}
          </form>
        </div>
      </div>
      {/*
      <div class={style.formFooterLinks}>
        <a href="#" class={style.oddText}>
          {data("auth.signup.text7")}
        </a>
        <a href="#" class={style.oddText}>
          {data("auth.signup.text8")}
        </a>
      </div>
      */}
    </>
  );
}
