import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import style from "../styles/stunningQualityModule.module.scss";
import { useSelector } from "react-redux";

export default function StunningQualityModule(props) {
  const d = props.data;
  const daynight = useSelector((state) => state.data.dayNight);

  return (
    <section
      className={`${style.stunningSection} ${daynight ? style.nightMode : ""}`}
    >
      <div className="container">
      <div className={`${style.shapeBlob}`}></div>
      <div className="col-lg-12">
        <Tabs defaultActiveKey="animals" id="uncontrolled-tab-example">
        <Tab eventKey="animals" title={d("stunning.text3")}>
            <div style={{ width: "100%", maxWidth: "900px", margin: "auto" }}>
              <ReactCompareSlider
                style={{ borderRadius: "15px" }}
                itemOne={
                  <ReactCompareSliderImage
                    src="/demo/dioptify_with_noise_500x.png" 
                    alt="Image one"
                  />
                }
                itemTwo={
                  <ReactCompareSliderImage
                    src="/demo/dioptify_without_noise_500x.png" 
                    alt="Image two"
                    style={{
                      backgroundColor: "#fff",
                      backgroundImage:
                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                      backgroundSize: "59px",
                    }}
                  />
                }
              />
            </div>
          </Tab>
          {/*
          <Tab eventKey="people" title={d("stunning.text4")}>
            <div style={{ width: "100%", maxWidth: "900px", margin: "auto" }}>
              <ReactCompareSlider
                style={{ borderRadius: "15px" }}
                itemOne={
                  <ReactCompareSliderImage
                    src="/demo/I0_0.png" 
                    alt="Image one"
                  />
                }
                itemTwo={
                  <ReactCompareSliderImage
                    src="/demo/I0_slomo_clipped.gif" 
                    alt="Image two"
                    style={{
                      backgroundColor: "#fff",
                      backgroundImage:
                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                      backgroundSize: "59px",
                    }}
                  />
                }
              />
            </div>
          </Tab>
          */}
          <Tab eventKey="products" title={d("stunning.text2")}>
            <div style={{ width: "100%", maxWidth: "900px", margin: "auto" }}>
              <ReactCompareSlider
                style={{ borderRadius: "15px" }}
                itemOne={
                  <ReactCompareSliderImage
                    src="/demo/zebra_intermediate_480x320.jpg" 
                    alt="Image one"
                  />
                }
                itemTwo={
                  <ReactCompareSliderImage
                    src="/demo/zebra_ddsr_1_480x320.png" 
                    alt="Image two"
                    style={{
                      backgroundColor: "#fff",
                      backgroundImage:
                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                      backgroundSize: "59px",
                    }}
                  />
                }
              />
            </div>
          </Tab>
          <Tab eventKey="cars" title={d("stunning.text1")}>
            <div style={{ width: "100%", maxWidth: "900px", margin: "auto" }}>
              <ReactCompareSlider
                style={{ borderRadius: "15px" }}
                itemOne={
                  <ReactCompareSliderImage
                    src="/demo/Rubber_Duck_(8374802487).jpg" 
                    alt="Image one"
                  />
                }
                itemTwo={
                  <ReactCompareSliderImage
                    src="/demo/Rubber_Duck_8374802487_KGIbMmy.jpg" 
                    alt="Image two"
                    style={{
                      backgroundColor: "#fff",
                      backgroundImage:
                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                      backgroundSize: "59px",
                    }}
                  />
                }
              />
            </div>
          </Tab>
          {/*
          <Tab eventKey="graphics" title={d("stunning.text5")}>
            <div style={{ width: "100%", maxWidth: "900px", margin: "auto" }}>
              <ReactCompareSlider
                style={{ borderRadius: "15px" }}
                itemOne={
                  <ReactCompareSliderImage
                    src="/demo/Rubber_Duck_(8374802487).jpg" 
                    alt="Image one"
                  />
                }
                itemTwo={
                  <ReactCompareSliderImage
                    src="/demo/Rubber_Duck_8374802487_aLCSqEt.jpg" 
                    alt="Image two"
                    style={{
                      backgroundColor: "#fff",
                      backgroundImage:
                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                      backgroundSize: "59px",
                    }}
                  />
                }
              />
            </div>
          </Tab>
          */}
          <Tab eventKey="graphics" title={d("serviceHDR")}>
            <div style={{ width: "100%", maxWidth: "900px", margin: "auto" }}>
              <ReactCompareSlider
                style={{ borderRadius: "15px" }}
                itemOne={
                  <ReactCompareSliderImage
                    src="/servicesvisual/hdr.png"
                    alt="Image one"
                  />
                }
                itemTwo={
                  <ReactCompareSliderImage
                    src="/servicesvisual/hdr_done.png"
                    alt="Image two"
                    style={{
                      backgroundColor: "#fff",
                      backgroundImage:
                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                      backgroundSize: "59px",
                    }}
                  />
                }
              />
            </div>
          </Tab>
        </Tabs>
      </div>
      </div>
    </section>
  );
}
