/*
import React, { useState } from 'react';
import { useSelector } from "react-redux";

import CanvasDraw from "react-canvas-draw";

// Styles
import style from "../styles/debug.module.scss";

export default function Debugv2(props) {
  const daynight = useSelector((state) => state.data.dayNight);

  this.state = {
    color: "#ffc600",
    width: 400,
    height: 400,
    brushRadius: 10,
    lazyRadius: 12,
    backgroundImg: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Nepalese_Mhapuja_Mandala.jpg",
    imgs: [
      "https://upload.wikimedia.org/wikipedia/commons/a/a1/Nepalese_Mhapuja_Mandala.jpg",
      "https://i.imgur.com/a0CGGVC.jpg"
    ]
  };

  window.setInterval(() => {
    this.state.color = "#" + Math.floor(Math.random() * 16777215).toString(16);
  }, 2000);


  let saveableCanvas = null;
  let loadableCanvas = null


  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);

  const handleImageUpload = e => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = e => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);

      console.log("current.src: ", current.src);
      console.log("current.file: ", current.file);
      console.log("uploadedImage: ", uploadedImage);

      this.state.backgroundImg = uploadedImage.current;
    }
  };

  return (
    <section className={`${style.teamSection} ${daynight ? style.nightMode : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h2 className={`${style.subHeading} subHeading`}>
              debugging!
            </h2>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                ref={imageUploader}
                style={{
                  display: "none"
                }}
              />
              <div
                style={{
                  height: "400px",
                  width: "400px",
                  border: "1px dashed black"
                }}
                onClick={() => imageUploader.current.click()}
              >
                <img
                  ref={uploadedImage}
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "acsolute"
                  }}
                />
              </div>
              Click to upload Image
            </div>

            <div>
              <h1>React Canvas Draw</h1>
              <h2>default</h2>
              <p>
                This is a simple <span>{`<CanvasDraw />`}</span> component with
                default values.
              </p>
              <p>Try it out! Draw on this white canvas:</p>
              <CanvasDraw onChange={() => console.log("onChange")} />
              <h2>Custom Brush-Color</h2>
              <p>
                Let's spice things up by using custom brush colors{" "}
                <span>{`<CanvasDraw brushColor={this.state.color} />`}</span>. We
                randomly change them every 2 seconds. But you could easily use a
                color-picker!
              </p>
              <div>
                Current color:{" "}
                <div
                  style={{
                    display: "inline-block",
                    width: "24px",
                    height: "24px",
                    backgroundColor: this.state["color"],
                    border: "1px solid #272727"
                  }}
                />
              </div>
              <CanvasDraw brushColor={this.state.color} />
              <h2>Background Image</h2>
              <p>You can also set the `imgSrc` prop to draw on a background-image.</p>
              <p>
                It will automatically resize to fit the canvas and centered vertically
                & horizontally.
              </p>
              <CanvasDraw
                brushColor="rgba(155,12,60,0.3)"
                imgSrc="https://upload.wikimedia.org/wikipedia/commons/a/a1/Nepalese_Mhapuja_Mandala.jpg"
              />

              <h2>Refreshable Background Image</h2>
              <p>This will refresh the background in every two seconds.</p>
              <CanvasDraw
                brushColor="rgba(155,12,60,0.3)"
                imgSrc={this.state.backgroundImg}
              />
              <h2>Hide UI</h2>
              <p>To hide the UI elements, set the `hideInterface` prop. You can also hide the grid with the `hideGrid` prop.</p>
              <CanvasDraw hideInterface hideGrid />
              <h2>Zoom & Pan</h2>
              <p>
                Set the <span>enablePanAndZoom</span> prop to enable mouse scrolling
                and panning (using Ctrl), pinch zooming, and two-finger panning. If
                you want to ensure that all lines stay within the bounds of the
                canvas, set the <span>clampLinesToDocument</span> property.
              </p>
              <CanvasDraw
                enablePanAndZoom
                clampLinesToDocument
                gridColor="#ccc"
                imgSrc="https://upload.wikimedia.org/wikipedia/commons/a/a1/Nepalese_Mhapuja_Mandala.jpg"
              />
              <h2>Save & Load</h2>
              <p>
                This part got me most excited. Very easy to use saving and loading of
                drawings. It even comes with a customizable loading speed to control
                whether your drawing should load instantly (loadTimeOffset = 0) or
                appear after some time (loadTimeOffset `{'>'}` 0){" "}
                <span>{`<CanvasDraw loadTimeOffset={10} />`}</span>
              </p>
              <p>Try it out! Draw something, hit "Save" and then "Load".</p>
              <div className={style.tools}>
                <button
                  onClick={() => {
                    localStorage.setItem(
                      "savedDrawing",
                      saveableCanvas.getSaveData()
                    );
                  }}
                >
                  Save
                </button>
                <button
                  onClick={() => {
                    saveableCanvas.eraseAll();
                  }}
                >
                  Erase
                </button>
                <button
                  onClick={() => {
                    saveableCanvas.undo();
                  }}
                >
                  Undo
                </button>
                <button
                  onClick={() => {
                    console.log(saveableCanvas.getDataURL());
                    alert("DataURL written to console")
                  }}
                >
                  GetDataURL
                </button>
                <div>
                  <label>Width:</label>
                  <input
                    type="number"
                    value={this.state.width}
                    onChange={e =>
                      {this.state["width"] = parseInt(e.target.value, 10);
                      console.log("parseInt(e.target.value, 10): ", parseInt(e.target.value, 10))}
                    }
                  />
                </div>
                <div>
                  <label>Height:</label>
                  <input
                    type="number"
                    value={this.state.height}
                    onChange={e =>
                      this.setState({ height: parseInt(e.target.value, 10) })
                    }
                  />
                </div>
                <div>
                  <label>Brush-Radius:</label>
                  <input
                    type="number"
                    value={this.state.brushRadius}
                    onChange={e =>
                      this.setState({ brushRadius: parseInt(e.target.value, 10) })
                    }
                  />
                </div>
                <div>
                  <label>Lazy-Radius:</label>
                  <input
                    type="number"
                    value={this.state.lazyRadius}
                    onChange={e =>
                      this.setState({ lazyRadius: parseInt(e.target.value, 10) })
                    }
                  />
                </div>
              </div>
              <CanvasDraw
                ref={canvasDraw => (saveableCanvas = canvasDraw)}
                brushColor={this.state.color}
                brushRadius={this.state.brushRadius}
                lazyRadius={this.state.lazyRadius}
                canvasWidth={this.state.width}
                canvasHeight={this.state.height}
              />
              <p>
                The following is a disabled canvas with a hidden grid that we use to
                load & show your saved drawing.
              </p>
              <button
                onClick={() => {
                  loadableCanvas.loadSaveData(
                    localStorage.getItem("savedDrawing")
                  );
                }}
              >
                Load what you saved previously into the following canvas. Either by
                calling `loadSaveData()` on the component's reference or passing it
                the `saveData` prop:
              </button>
              <CanvasDraw
                disabled
                hideGrid
                ref={canvasDraw => (loadableCanvas = canvasDraw)}
                saveData={localStorage.getItem("savedDrawing")}
              />
            </div>


            </div>
          </div>
        </div>
    </section>
	);
}
*/

import React, { Component } from "react";
import { useSelector } from "react-redux";
import { render } from "react-dom";

import CanvasDraw from "react-canvas-draw";
import style from "../styles/debug.module.scss";

export default class Debugv2 extends Component {
  constructor(props) {
    super(props);

    this.uploadedImage = React.createRef();
    this.imageUploader = React.createRef();
    // uploadedImage = React.useRef(null);
    // imageUploader = React.useRef(null);
    // this.daynight = useSelector((state) => state.data.dayNight);
  }
  

  state = {
    color: "#ffc600",
    width: 400,
    height: 400,
    brushRadius: 10,
    lazyRadius: 12,
    backgroundImg: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Nepalese_Mhapuja_Mandala.jpg",
    imgs: [
      "https://upload.wikimedia.org/wikipedia/commons/a/a1/Nepalese_Mhapuja_Mandala.jpg",
      "https://i.imgur.com/a0CGGVC.jpg"
    ],
    customImg: null,
    reduxState : "reduxState"
  };

  componentDidMount() {
    // let's change the color randomly every 2 seconds. fun!
    window.setInterval(() => {
      this.setState({
        color: "#" + Math.floor(Math.random() * 16777215).toString(16)
      });
    }, 2000);

    // let's change the background image every 2 seconds. fun!
    window.setInterval(() => {
      if (
        this.state.imgs &&
        this.state.imgs.length &&
        this.state.backgroundImg
      ) {
        let img = '';
        let imgs = this.state.imgs;
        for (let i = 0; i < imgs.length; i++) {
          if (this.state.backgroundImg !== imgs[i]) {
            img = imgs[i];
          }
        }

        this.setState({
          backgroundImg: img,
        });
      }
    }, 2000);

    window.addEventListener('storage', () => {
      // When local storage changes, dump the list to
      // the console.
      console.log(JSON.parse(window.localStorage.getItem('defaultDayNight')));
    });
 

    // TODO: add this shit support for reduxe useSelector inside class base component...
    // or at least some other sort of listener OR convert this shit into normal function
    /*
    const daynight = useSelector((state) => state.data.dayNight);
    useEffect(() => {
        this.setState({
            reduxState : daynight
        })
    }, [])
    */
  }

  handleImageUpload = e => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = this.uploadedImage;
      current.file = file;
      reader.onload = e => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);

      /*
      this.setState({
        customImg: current.src,
      });
      */

      const readerAdvanced = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = () => resolve(fileReader.result);
            fileReader.readAsDataURL(file);
        });
      }
      const readFile = (file) => {
        // readerAdvanced(file).then(result => console.log(result));
        readerAdvanced(file).then(result => 
            this.setState({
              customImg: result,
            })
          );
      }

      // TODO: add support for transparent images
      /*
      this.setState({
        customImg: null,
      })
      */

      readFile(file);
    }
  };


  render() {
    return (
    <section className={`${style.teamSection} ${false ? style.nightMode : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx-auto">      
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={this.handleImageUpload}
                  ref={this.imageUploader}
                  style={{
                    display: "none"
                  }}
                />
                <div
                  style={{
                    height: "400px",
                    width: "400px",
                    border: "1px dashed black"
                  }}
                  onClick={() => this.imageUploader.current.click()}
                >
                  <img
                    ref={this.uploadedImage}
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "acsolute"
                    }}
                  />
                </div>
                Click to upload Image
              </div>

              <h2>Custom Preview Background Image</h2>
              <div className={style.tools}>
                <button
                  onClick={() => {
                    localStorage.setItem(
                      "savedDrawing",
                      this.saveableCanvasCustom.getSaveData()
                    );
                  }}
                >
                  Save
                </button>
                <button
                  onClick={() => {
                    this.saveableCanvasCustom.eraseAll();
                  }}
                >
                  Erase
                </button>
                <button
                  onClick={() => {
                    this.saveableCanvasCustom.undo();
                  }}
                >
                  Undo
                </button>
                <button
                  onClick={() => {
                    console.log(this.saveableCanvasCustom.getDataURL());
                    alert("DataURL written to console")
                  }}
                >
                  GetDataURL
                </button>
                <div>
                  <label>Width:</label>
                  <input
                    type="number"
                    value={this.state.width}
                    onChange={e =>
                      this.setState({ width: parseInt(e.target.value, 10) })
                    }
                  />
                </div>
                <div>
                  <label>Height:</label>
                  <input
                    type="number"
                    value={this.state.height}
                    onChange={e =>
                      this.setState({ height: parseInt(e.target.value, 10) })
                    }
                  />
                </div>
                <div>
                  <label>Brush-Radius:</label>
                  <input
                    type="number"
                    value={this.state.brushRadius}
                    onChange={e =>
                      this.setState({ brushRadius: parseInt(e.target.value, 10) })
                    }
                  />
                </div>
                <div>
                  <label>Lazy-Radius:</label>
                  <input
                    type="number"
                    value={this.state.lazyRadius}
                    onChange={e =>
                      this.setState({ lazyRadius: parseInt(e.target.value, 10) })
                    }
                  />
                </div>
              </div>
              {/*brushColor="rgba(155,12,60,0.3)"*/}
              <CanvasDraw
                imgSrc={this.state.customImg}

                enablePanAndZoom
                clampLinesToDocument

                ref={canvasDraw => (this.saveableCanvasCustom = canvasDraw)}
                brushColor={this.state.color}
                brushRadius={this.state.brushRadius}
                lazyRadius={this.state.lazyRadius}
                canvasWidth={this.state.width}
                canvasHeight={this.state.height}
              />
              <div>{this.state.reduxState}</div>
              <p>
                The following is a disabled canvas with a hidden grid that we use to
                load & show your saved drawing.
              </p>
              <button
                onClick={() => {
                  this.loadableCanvas.loadSaveData(
                    localStorage.getItem("savedDrawing")
                  );
                }}
              >
                Load what you saved previously into the following canvas. Either by
                calling `loadSaveData()` on the component's reference or passing it
                the `saveData` prop:
              </button>
              <CanvasDraw
                disabled
                hideGrid
                ref={canvasDraw => (this.loadableCanvas = canvasDraw)}
                saveData={localStorage.getItem("savedDrawing")}
              />
              <p>
                The saving & loading also takes different dimensions into account.
                Change the width & height, draw something and save it and then load it
                into the disabled canvas. It will load your previously saved
                masterpiece scaled to the current canvas dimensions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>      
    );
  }
}

// render(<Debugv2 />, document.querySelector("#demo"));