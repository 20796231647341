import React from "react";
import HeroSection from "../components/timlineScreenComponent/HeroSection";
import DetailsSection from "../components/timlineScreenComponent/DetailsSection";
import CookiesModal from "../components/CookiesModal";
import { useSelector } from "react-redux";

export default function Timeline() {
  const cookiesModal = useSelector((state) => state.data.cookiesModal);
  return (
    <>
      <HeroSection />
      {/*<DetailsSection />*/}
      {cookiesModal ? <CookiesModal /> : ""}
    </>
  );
}
