/** External Dependencies */
import { createGlobalStyle } from 'styled-components';

/** Internal Dependencies */
import { ROOT_CONTAINER_CLASS_NAME } from '../../utils/constants';

const FontsFaces = createGlobalStyle`
  .${ROOT_CONTAINER_CLASS_NAME} {
    font-family:   font-family: "Poppins", sans-serif;
  }

  .SfxModal-Wrapper * {
    font-family: font-family: "Poppins", sans-serif;
  }
`;

const OverrideDefaultStyles = createGlobalStyle`
  .Menu-open {
    overflow: visible !important;
  }

  .${ROOT_CONTAINER_CLASS_NAME}, #SfxPopper {
    box-sizing: border-box;

    .SfxPopper-root .SfxMenu-root {
      overflow: visible;
      width: max-content;
      color:var(--dioptifyGreen);

      .SfxMenuItem-prefix {
        margin-right: 6px;
        color:red;
      }
    }
  }
 
  .SfxInput-root {
   
    color:var(--dioptifyGreen);
  }
  .sc-lxwit0-0{

  }
  .SfxButton-Label {
    font-family:"Open Sans", sans-serif !important;
    line-height: 14px !important;

  }

  // .SfxModalActions-root .SfxButton-root {
  // color: white;
  // align-items: center;
  // border-radius: 20px;
  // &:hover {
  //   color: #fff;
  //   background-color: #6c757d;
  //   border-color: #6c757d;
  // }

  // }
  .SfxModalActions-root .SfxButton-root:first-child {
    color: white;
    align-items: center;
    border-radius: 20px;
    background-color: var(--dioptifyGrey);
    border-color: #808080;
    color: white;
    span{
      font-family:"Open Sans", sans-serif !important
    }
  }
  
  .SfxModalActions-root .SfxButton-root:first-child:hover {
    color: #fff;
    background-color: #DC3545;
    border-color: #DC3545
    
  }
  .SfxModalActions-root .SfxButton-root:nth-child(2) {
    color: white;
    align-items: center;
    border-radius: 20px;
    background-color: var(--greenColor);
    border-color: var(--greenColor);
    span{
      font-family:"Open Sans", sans-serif !important
    }
    
  }
  
  .SfxModalActions-root .SfxButton-root:nth-child(2):hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    
  }
  .${ROOT_CONTAINER_CLASS_NAME} *, #SfxPopper * {
    box-sizing: border-box;
    scrollbar-color: rgba(203, 211, 218, 1) rgba(203, 211, 218, 0.35);
    font-family:"Open Sans", sans-serif !important;

    :not(button) > svg:not([color]) {
      color:var(--dioptifyGreen);
      font-szie: 0.8rem;
    }

    :disabled, [aria-disabled="true"] {
      cursor: not-allowed;
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(203, 211, 218, 0.35);
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(203, 211, 218, 1);
      border-radius: 10px;
    }
  }
`;

export { FontsFaces, OverrideDefaultStyles };
