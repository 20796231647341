import React from "react";
import style from "../styles/wavefooter.module.css";
import { useSelector } from "react-redux";

export default function WaveFooterTop(props) {
  const d = props.data;
  const daynight = useSelector((state) => state.data.dayNight);

  return (
    <>
      <section className={`${style.linksSection} ${daynight ? style.nightMode : ""}`}>
      {!daynight ?  (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className={`${daynight ? style.openIt : style.closeIt}`}>
              <path fill="#f7f9fa" fillOpacity="0.8" 
                d="M0,192L48,186.7C96,181,192,171,288,149.3C384,128,480,96,576,106.7C672,117,768,171,864,192C960,213,1056,203,1152,192C1248,181,1344,171,1392,165.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
              </path>
          </svg>
        ) : ""}
        {daynight ?  (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className={``}>
              <path fill="var(--dioptifyGreen)" fillOpacity="1" 
                d="M0,192L48,186.7C96,181,192,171,288,149.3C384,128,480,96,576,106.7C672,117,768,171,864,192C960,213,1056,203,1152,192C1248,181,1344,171,1392,165.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
              </path>
          </svg>
        ) : ""}
      </section>
    </>
  );
}
