import React, { useState } from "react";
import style from "../styles/faqs.module.css";
import { useSelector } from "react-redux";
import FAQsection from "../components/newFaqs/FAQsection";
import { useTranslation } from "react-multi-lang";
import FAQsCatagories from "../components/newFaqs/FAQsCatagories";
import FAQsHelp from "../components/newFaqs/FAQsHelp";
import faqsCatagoriesData from "../data/faqsCatagories.json";

export default function NewFAQs() {

  const daynight = useSelector((state) => state.data.dayNight);
  const d = useTranslation();
  const [faqsCatagories, setFaqsCatagory] = useState(faqsCatagoriesData);
  const [selectedCatagory, setSelectedCatagory] = useState(
    faqsCatagoriesData[0]
  );

  const selectCatagory = (catagory) => {
    unSelectAllCatagories();
    catagory.selected = true;
    setSelectedCatagory(catagory);
    setFaqsCatagory([...faqsCatagories]);
  };

  const unSelectAllCatagories = () => {
    if (Array.isArray(faqsCatagories)) {
      faqsCatagories.forEach((catagory) => {
        catagory.selected = false;
      });
    }
  };

  const unSelectAllAnswers = () => {
    if (selectedCatagory && Array.isArray(selectedCatagory.questions)) {
      selectedCatagory.questions.forEach((question) => {
        console.log(question);
        question.opend = false;
      });
    }
  };

  const selectAnswer = (question) => {
    unSelectAllAnswers();
    question.opend = !question.opend;
    setSelectedCatagory({ ...selectedCatagory });
    setFaqsCatagory([...faqsCatagories]);
  };

  return (
    <section className={`${daynight ? style.nightMode : ""}`}>
      <div className={`${style.faqsIntroContainer} `}>
        <div className={style.faqsIntoImg}>
          <img src={daynight ? "/images/newFaqBlack.jpg":"/images/newFaq.jpg"}></img>
        </div>
        <div className={style.faqsIntroTextContainer}>
          <h1>{d("newFaqs.faqHeading")}</h1>
          <p>{d("newFaqs.faqDescription")}</p>
        </div>
      </div>
      <div className={`${style.faqsContainer} `}>
        <div className={style.questionsWrapper}>
          <FAQsCatagories
            catagories={faqsCatagories}
            selectCatagory={selectCatagory}
          ></FAQsCatagories>
          <FAQsection
            selectedCatagory={selectedCatagory}
            openAnswer={selectAnswer}
          ></FAQsection>
          {/* <FAQsHelp></FAQsHelp> */}
        </div>
      </div>
    </section>
  );
}
