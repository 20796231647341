import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { setEditorData } from "../../../../redux/action/image";
import { useStore } from "../../hooks";
import AssemblyPoint from "../AssemblyPoint";
import style from "./EditorModal.module.scss";

export default function EditorModal(props) {
  const state = useStore();
  const dispatch = useDispatch();
  const [isExpand, setIsExpand] = useState(false);
  const daynight = useSelector((state) => state.data.dayNight);
  const data = useTranslation();
  const handleClose = () => {
    props.setShow(false);
  };

  const handleButtonClick = () => {
    setIsExpand(!isExpand); // Toggle the value
  };

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={`${!isExpand ? style.modal90w : style.modal100w} ${
        daynight ? style.nightMode : ""
      }`}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="border-0">
        <div className={style.headeCOntainer}>
          <div>
            <h3 className={`commonText mt-0 ${style.mainHeadingModal}`}>
              {data("Editor.text1")}
            </h3>
          </div>
          <div className={style.headeActionButton}>
            <button
              onClick={() => {
                handleClose();
              }}
              className={style.minusBtn}
            >
              <i class="fa fa-minus" aria-hidden="true"></i>
            </button>

            <button onClick={handleButtonClick} className={style.expandBtn}>
              {isExpand ? (
                <i class="fas fa-compress-arrows-alt"></i>
              ) : (
                <i class="fas fa-expand-arrows-alt"></i>
              )}
            </button>
            <button
              onClick={() => {
                dispatch(setEditorData({}));
                handleClose();
              }}
              className={style.closeBtn}
            >
              <i class="fas fa-times-circle"></i>
            </button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <AssemblyPoint
          imageToEdit={props.image}
          previousEditor={props?.previousEditor}
        ></AssemblyPoint>
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  );
}
