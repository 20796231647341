import React from "react";
import style from "../../styles/exploreSection.module.css";
import { useSelector } from "react-redux";

export default function HeroSection(props) {
  const daynight = useSelector((state) => state.data.dayNight);
  const d = props.data;

  return (
    <section class={`${style.toolsSection} ${daynight ? style.nightMode : ""}`}>
      <div class="container">
        <div class="col-lg-12 mx-auto">
          <div class={style.heroSectionContents}>
            <h1 class="mainHeading mb-1">{d("explore.title")}</h1>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <div class={style.flipCard}>
                <div class={style.flipCardInner}>
                  <div class={style.flipCardFront}>
                  <br /> <br /> <br />
                  <i class="fas fa-question fa-10x"></i>
                  </div>
                  <div class={style.flipCardBack}>
                    <br /> <br /> <br />
                    <h1>{d("explore.card1")}</h1> 
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class={style.flipCard}>
                <div class={style.flipCardInner}>
                  <div class={style.flipCardFront}>
                  <br /> <br /> <br />
                  <i class="fas fa-question fa-10x"></i>
                  </div>
                  <div class={style.flipCardBack}>
                    <br /> <br /> <br />
                    <h1>{d("explore.card2")}</h1> 
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class={style.flipCard}>
                <div class={style.flipCardInner}>
                  <div class={style.flipCardFront}>
                  <br /> <br /> <br />
                  <i class="fas fa-question fa-10x"></i>
                  </div>
                  <div class={style.flipCardBack}>
                    <br /> <br /> <br />
                    <h1>{d("explore.card3")}</h1> 
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-lg-4">
              <div class={style.flipCard}>
                <div class={style.flipCardInner}>
                  <div class={style.flipCardFront}>
                  <br /> <br /> <br />
                  <i class="fas fa-question fa-10x"></i>
                  </div>
                  <div class={style.flipCardBack}>
                    <br /> <br /> <br />
                    <h1>{d("explore.card4")}</h1> 
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class={style.flipCard}>
                <div class={style.flipCardInner}>
                  <div class={style.flipCardFront}>
                  <br /> <br /> <br />
                  <i class="fas fa-question fa-10x"></i>
                  </div>
                  <div class={style.flipCardBack}>
                    <br /> <br /> <br />
                    <h1>{d("explore.card5")}</h1> 
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class={style.flipCard}>
                <div class={style.flipCardInner}>
                  <div class={style.flipCardFront}>
                  <br /> <br /> <br />
                  <i class="fas fa-question fa-10x"></i>
                  </div>
                  <div class={style.flipCardBack}>
                    <br /> <br /> <br />
                    <h1>{d("explore.card6")}</h1> 
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-lg-4">
              <div class={style.flipCard}>
                <div class={style.flipCardInner}>
                  <div class={style.flipCardFront}>
                  <br /> <br /> <br />
                  <i class="fas fa-question fa-10x"></i>
                  </div>
                  <div class={style.flipCardBack}>
                    <br /> <br /> <br />
                    <h1>{d("explore.card7")}</h1> 
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class={style.flipCard}>
                <div class={style.flipCardInner}>
                  <div class={style.flipCardFront}>
                  <br /> <br /> <br />
                  <i class="fas fa-question fa-10x"></i>
                  </div>
                  <div class={style.flipCardBack}>
                    <br /> <br /> <br />
                    <h1>{d("explore.card8")}</h1> 
                </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class={style.flipCard}>
                <div class={style.flipCardInner}>
                  <div class={style.flipCardFront}>
                  <br /> <br /> <br />
                  <i class="fas fa-question fa-10x"></i>
                  </div>
                  <div class={style.flipCardBack}>
                    <br /> <br /> <br />
                    <h1>{d("explore.card9")}</h1> 
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
