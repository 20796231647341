import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import React from "react";
import CheckoutFormInModal from './CheckoutFormInModal';

const CheckoutCapsuleInModal = (props) => {
    let stripePromise = loadStripe('pk_test_51JjWX4HUgH7ntAFfY8OODQ8hFDMCjQYOFGRaxJxWqt4us273k3LscyAdOC83AvQ5a79J7tK3DH0GSpgXOuU2R1VO00mccjr3LE');
    if(process.env.REACT_APP_DEBUG_STATE === "true") {
        stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CLIENT_ID_DEBUG);
      } else {
        stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CLIENT_ID_LIVE);
      }

    // const stripePromise = loadStripe('pk_test_51JjWX4HUgH7ntAFfY8OODQ8hFDMCjQYOFGRaxJxWqt4us273k3LscyAdOC83AvQ5a79J7tK3DH0GSpgXOuU2R1VO00mccjr3LE');

    return (
        <Elements stripe={stripePromise}>
            <CheckoutFormInModal plan={props.plan}/>
        </Elements>
    );
};

export default CheckoutCapsuleInModal;