import React, { useEffect } from "react";
import HeroSection from "../components/toolsScreenComponent/HeroSection";
import DetailsSection from "../components/toolsScreenComponent/DetailsSection";
import CookiesModal from "../components/CookiesModal";
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";

export default function Tools() {
  const data = useTranslation();
  const cookiesModal = useSelector((state) => state.data.cookiesModal);

  useEffect(() => {
		document.title = `${data("tab.toolsApi")} - dioptify.com`;
	});

  return (
    <>
      <HeroSection />
      {/**/}
      <DetailsSection />
      {/**/}
      {cookiesModal ? <CookiesModal /> : ""}
    </>
  );
}
