import React from "react";
import style from "../../styles/financialFaqSection.module.css";
import { Accordion, Card, Button } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";

export default function Faq(props) {
  const d = useTranslation();

  return (
    <Card>
      <Card.Header className={style.article_title}>
        <Accordion.Toggle as={Button} variant="link" eventKey={props?.eventKey}>
          {d(props?.question)}
          <i class="fas fa-plus more-less glyphicon glyphicon-plus"></i>
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey={props.eventKey}>
        <Card.Body className={style.accordion_content}>
          <p>{d(props?.answer)}</p>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}
