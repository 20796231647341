import React, { useEffect, useState } from "react";
import style from "../styles/cookies.module.css";
import CookiesTable from "../components/CookiesTable";
import data from "../data/cookiesTableData.json";
import { useSelector } from "react-redux";
import CookiesModal from "../components/CookiesModal";
import CookiesPolicyModal from "../components/CookiesPolicyModal";
import { useTranslation } from "react-multi-lang";

export default function PrivacyPolicy() {
  const [show, setShow] = useState(false);
  const daynight = useSelector((state) => state.data.dayNight);
  const cookiesModal = useSelector((state) => state.data.cookiesModal);
  const d = useTranslation();

  useEffect(() => {
		document.title = `${d("privacy.title")} - dioptify.com`;
	});

  const handleShow = () => setShow(true);
  return (
    <>
      {cookiesModal ? <CookiesModal /> : ""}
      <div className={`${style.cookiesSection} ${daynight ? style.nightMode : ""}`}>
        <div class="col-lg-12 mx-auto">
          <div className={style.heroSection}>
            <h1 class="mainHeading mb-1">{d("privacy.title")}</h1>
            <h4 className="commonText">{d("privacy.subtitle")}</h4>
          </div>
          <div className="mx-2">
            <div className={style.card}>
              <h4 className="commonText">
                {d("legal.privacy.0")}
              </h4>
              <ul>
                <li>{d("legal.privacy.01")}</li>
                <li>{d("legal.privacy.02")}</li>
                <li>{d("legal.privacy.03")}</li>
                <li>{d("legal.privacy.04")}</li>
              </ul>

              <p>
                {d("legal.privacy.021")}
              </p>
              <p>
                {d("legal.privacy.022")}
              </p>
              <h4 className="commonText">
                {d("legal.privacy.1")}
              </h4>

              <p>
                {d("legal.privacy.12")}
              </p>

              <p>
                {d("legal.privacy.13")}
              </p>
              <p>
                <strong>dioptify UG (haftungsbeschränkt)</strong><br/>
                Tulpenweg 1a<br/>
                31303 Burgdorf, Deutschland<br/>
                +49 177 2930 783
              </p>

              <p>
                {d("legal.privacy.14")}
              </p>

              <p>
                {d("legal.privacy.15")}
              </p>

              <p>
                {d("legal.privacy.16")}
              </p>

              <h4 className="commonText">
                {d("legal.privacy.2")}
              </h4>
              <h4>
                {d("legal.privacy.21")}
              </h4>
              <p>
                {d("legal.privacy.211")}
              </p>
              <h4>
                {d("legal.privacy.22")}
              </h4>
              <p>
                {d("legal.privacy.221")}
              </p>
              <p>
                {d("legal.privacy.222")}
              </p>
              <h4>
                {d("legal.privacy.23")}
              </h4>
              <p>
                {d("legal.privacy.231")}
              </p>

              <p>
                {d("legal.privacy.232")}
              </p>

              <h4 className="commonText">
                {d("legal.privacy.3")}
              </h4>
              <h4>
                {d("legal.privacy.31")}
              </h4>
              <p>
                {d("legal.privacy.311")}
              </p>
              <p>
                {d("legal.privacy.312")}
              </p>
              <p>
                {d("legal.privacy.313")}
              </p>
              <h4>
                {d("legal.privacy.32")}
              </h4>
              <p>
                {d("legal.privacy.321")}
              </p>
              <p>
                {d("legal.privacy.322")}
              </p>
              <p>
                {d("legal.privacy.323")}
              </p>
              <p>
                {d("legal.privacy.324")}
              </p>
              <h4 className="commonText">
                {d("legal.privacy.4")}
              </h4>
              <p>
                {d("legal.privacy.41")}
              </p>
              <p>
                {d("legal.privacy.42")}
              </p>
              <p>
                {d("legal.privacy.43")}
              </p>
              <p>
                German Data Protection Authority<br/>
                Graurheindorfer Straße 153<br/>
                53117 Bonn<br/>
                {d("legal.privacy.telephone")}: +49 (0)228-997799-0<br/>
                E-Mail: poststelle@bfdi.bund.de
              </p>
              <p>
                {d("legal.privacy.44")}
              </p>
              <p>
                {d("legal.privacy.45")}
              </p>

              <hr/>

              <h4 className="commonText">
                {d("legal.privacy.5")}
              </h4>

              <p>
                {d("legal.privacy.51")}
              </p>

              <p>
                {d("legal.privacy.52")}
              </p>

              <ul>
                <li>
                  Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA
                </li>
                <li>
                  Hetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen, DE
                </li>
                <li>
                  Mailjet SAS, 13-13bis, Rue de l’Aubrac, 75012 Paris, FR
                </li>
                <li>
                  Stripe.com, Inc., 354 Oyster Point Blvd South San Francisco, CA 94080 United States
                </li>
                <li>
                  Cloudflare, Inc., 101 Townsend St, San Francisco, CA 94107, USA
                </li>
                <li>
                  The Rocket Science Group, LLC dba Mailchimp, 675 Ponce de Leon Ave NE, Suite 5000, Atlanta, GA 30308 USA
                </li>
                <li>
                  Zendesk, Inc., 1019 Market Street, San Francisco, CA 94103, USA
                </li>
              </ul>
              <p>
                {d("legal.privacy.53")}
              </p>
            </div>
          </div>
        </div>
      </div>

      <CookiesPolicyModal show={show} setShow={setShow} />
    </>
  );
}
