import React, { useEffect } from "react";
import AuthFooter from "../../components/AuthFooter";
import { getLanguage, setLanguage, useTranslation } from "react-multi-lang";
import { setDefaultTranslations } from "react-multi-lang";
import en from "../../data/en.json";
import gr from "../../data/gr.json";
import fr from "../../data/fr.json"
import sp from "../../data/sp.json"
import ch from '../../data/ch.json';
import ru from "../../data/ru.json"
import ar from "../../data/ar.json"

import { Link } from "react-router-dom";

setDefaultTranslations({ gr, fr, en,ch,ru,ar, sp });

export default function AuthLayout({ children }) {
  const data = useTranslation();
  useEffect(() => {
    const defaultLanguage = localStorage.getItem("defaultLanguage");
    // debugger
    if (defaultLanguage !== null) {
      setLanguage(defaultLanguage);
    } else {
      setLanguage("en");
    }
  }, []);

  return (
    <>
      <div
        className="fixed-top"
        style={{
          boxShadow: "0px 0px 40px #0000001a",
          padding: "0.5em 1em",
          backgroundColor: "#ffffff",
        }}
      >
        <Link to="/">
          <img src="/images/logo.svg" alt="" style={{ maxWidth: "210px" }} />
        </Link>
      </div>
      {children}
      <AuthFooter
        data={data}
        getLanguage={getLanguage()}
        setLanguage={(item) => {
          setLanguage(item);
          localStorage.setItem("defaultLanguage", item);
        }}
      />
    </>
  );
}
