const types = {
  SET_IMAGE: "SET_IMAGE",
  SET_VIDEO: "SET_VIDEO",
  DELETE_IMAGE: "DELETE_IMAGE",
  DELETE_VIDEO: "DELETE_VIDEO",
  SET_DAYNIGHT: "SET_DAYNIGHT",
  SET_COOKIES_MODAL: "SET_COOKIES_MODAL",
  SET_FUNCTIONAL_COOKIES: "SET_FUNCTIONAL_COOKIES",
  SET_ANALYTICS_COOKIES: "SET_ANALYTICS_COOKIES",
  SET_EDITOR_DATA:"SET_EDITOR_DATA",
  LOGOUT: "LOGOUT"
};

export default types;
