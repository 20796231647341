import axios from "axios";
import { baseURLclear } from "../../axios";

// export const API_URL ='http://localhost:1337'
export const API_URL = baseURLclear;

export const api = axios.create({
    baseURL: API_URL,
    headers: {
        "Content-type": "application/json"
    }
});

 export default class ApiService{
    static saveStripeInfo(data={}){
        return api.post(`${API_URL}/payments/save-stripe-info/`, data)
    }

    static payOnceDioptify(data={}){
        return api.post(`${API_URL}/payments/pay-once-dioptify/`, data)
    }

    static subscribeDioptify(data={}){
        return api.post(`${API_URL}/payments/subscribe/`, data)
    }

    // paypal onetime payment
    static payOncePayPal(data={}){
        return api.post(`${API_URL}/payments/pay-once-paypal-dioptify/`, data)
    }

    // paypal subscription payment
    static paySubPayPal(data={}){
        return api.post(`${API_URL}/payments/pay-sub-paypal-dioptify/`, data)
    }
}
