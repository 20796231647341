import React from 'react';
import { useHistory } from 'react-router-dom';

import { SERVICES } from '../../services/Services';
import style from "../../../styles/uploadSection.module.css";

export default function HowItWorks(props) {
	const d = props.data;
	const service = props.service;
	
	const history = useHistory();

	const handleInfos = async(e) => {
		e.preventDefault();

        history.push({
            pathname: '/services/' + service + '/info',
        });
	};

	return (
		<div className={`col-xl-6`}>
			<div
				className={style.heroContentSide}
				style={{ width: "100%", maxWidth: "500px", marginTop: "3em", textAlign: 'center' }}
			>
				<div className={style.commonTextHeader}>{d("howitworks." + service + ".title")}</div>
				<div className={style.commonText}>{d("howitworks." + service + ".description")}</div>
				
				<hr style={{ backgroundColor: "white"}}/>

				<div className={style.commonTextHeader}>{d("howitworks." + "runtimeandcosts" + ".title")}</div>
				<div className={style.commonText}>{d("howitworks." + service + ".costs")}</div>

				<hr style={{ backgroundColor: "white"}}/>

				<div className={style.commonTextHeader}>{d("howitworks." + "whichimages" + ".title")}</div>
				<div className={style.commonText}>{d("howitworks." + service + ".supported")}</div>

				<button
					onClick={handleInfos}
					className={`btn btn-secondary ${style.buttonAdventure}`}>
					{d("howitworks.buttons.info")} <i class="fas fa-info-circle"></i>
				</button>

				{/*
				<button
					onClick={handleExamples}
					className={`btn btn-secondary ${style.buttonAdventure}`}>
					{d("howitworks.buttons.examples")} <i class="fas fa-microchip"></i>
				</button>
				*/}
			</div>
		</div>
	);
}
