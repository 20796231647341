import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from '../../axios';
import { useHistory, useParams } from 'react-router-dom';
import { saveAs } from "file-saver";
// import Plyr from "plyr-react";
// import "plyr-react/dist/plyr.css";

const useStyles = makeStyles((theme) => ({
	cardMedia: {
		paddingTop: '56.25%', // 16:9
		borderRadius: '15px',
		margin: '10px 10px 10px 10px'
	},
	link: {
		margin: theme.spacing(1, 1.5),
	},
	cardHeader: {
		backgroundColor:
			theme.palette.type === 'light'
				? theme.palette.grey[200]
				: theme.palette.grey[700],
	},
	postTitle: {
		fontSize: '18px',
		textAlign: 'left',
	},
	postText: {
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'baseline',
		fontSize: '12px',
		textAlign: 'left',
		marginBottom: theme.spacing(2),
		color: "rgba(150, 150, 150, 1.0)"
	},
	postTextNight: {
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'baseline',
		fontSize: '12px',
		textAlign: 'left',
		marginBottom: theme.spacing(2),
		color: "rgba(120, 120, 120, 1.0)"
	},
	card: {
		backgroundColor: "rgba(30, 30, 30, 1.0)",
		color: "rgba(220, 220, 220, 1.0)"
	},
	cardNight: {
		backgroundColor: "rgba(245, 245, 245, 1.0)",
	}	
}));

const VideoPosts = (props) => {
	const { posts } = props;
	const classes = useStyles();
	const d = useTranslation();
	const daynight = useSelector((state) => state.data.dayNight);
	const history = useHistory();

	const handleDelete = (e, post) => {
		e.preventDefault();
		axiosInstance
			.delete('admin/delete/' + post.id)
			.catch(function (error) {
				if (error.response) {
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				}
			})
			.then(function () {
					history.push({
						pathname: '/posts',
						search: '?videoposts',
					});
					window.location.reload();
			});
	};

	const saveFile = (e, post) => {
		e.preventDefault();
		var filename = post.file.substring(post.file.lastIndexOf('/')+1);
		
		saveAs(
			post.file,
			filename
		);
	};

	if (!posts || posts.length === 0) return (
		<>
			<p>{d("posts.missing")}<a href="/services">{d("posts.muploads")}</a></p>
			<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
		</>
	);

	return (
		<React.Fragment>
			<Container maxWidth="md" component="main">
				<Grid container spacing={5} alignItems="flex-end">
					{posts.map((post) => {
						return (
							// Enterprise card is full width at sm breakpoint
							<Grid item key={post.id} xs={12} md={6}>
								<Card className={`${daynight ? classes.card : classes.cardNight}`}>
									{/*
									<Plyr source={{
											type: "video",
											sources: [
												{
													src: post.file,
												}
											]
										}} 
									/>
									*/}
									{/*
									<Link
										color="textPrimary"
										href={'videopost/' + post.slug}
										className={classes.link}
									>
										<CardMedia
											className={classes.cardMedia}
											image="/images/loader.gif"	
											title="Image title"
										/>
									</Link>
									*/}
									<CardContent className={classes.cardContent}>
										{/*
										<Typography
											gutterBottom
											variant="h6"
											component="h2"
											className={classes.postTitle}
										>
											{d("posts.postTitleVid")}: {post.title.substr(0, 30)}...
										</Typography>
										*/}
										<div className={`${daynight ? classes.postText : classes.postTextNight}`}>
											<Typography>
												{d("posts.postTitlePaid")}: {post.paid ? d("posts.paidYes") : d("posts.paidNo")}
												<br />
												{d("posts.postTitleService")}: {post.service}
												<br />
												{d("posts.postTitlePublished")}: {post.published.substr(0, 10)}
											</Typography>
										</div>
										<a
											className={`btn btn-outline-primary`}
											href={'videopost/' + post.slug}
										>
											{d("posts.postTitleVid")}
											<>&nbsp;</>
											<i class="fas fa-file-video"></i>
										</a>
										<>&nbsp; &nbsp; &nbsp;</>
										<a
											className={`btn btn-outline-success`}
											onClick={(e) => {
												saveFile(e, post);
											}}
										>
											{d("posts.postDownload")}
											<>&nbsp;</>
											<i className="fas fa-cloud-download-alt"></i>
										</a>
										<>&nbsp; &nbsp; &nbsp;</>
										<a
											className={`btn btn-outline-danger`}
											onClick={(e) => {
												handleDelete(e, post);
											}}
										>
											{d("posts.postDelete")}
											<>&nbsp;</>
											<i className="fas fa-trash"></i>
										</a>										
									</CardContent>
								</Card>
							</Grid>
						);
					})}
				</Grid>
			</Container>
		</React.Fragment>
	);
};
export default VideoPosts;
