import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import style from "../../styles/multiStepsSection.module.css";

export class FormUserDetails extends Component {
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };

  render() {
    const { values, handleChange, sOrP } = this.props;
    const data = this.props.lang;

    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        }} 
        class="container"
      >
        <MuiThemeProvider>
          <>
            <label
             style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              }}
            for="uploadImage"
            className={`btn btn-success ${style.btn_primary}`}
            href="/"
            onClick={(e) => {
                      sOrP("card", true)
                      this.continue(e);
                      } 
                    }
            >
            <i className="fas fa-credit-card fa-3x"></i>{" "} &nbsp;
            {data("price.creditcard")}
            </label>
            <br/>     
            <label
             style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              }} 
            for="uploadImage"
            className={`btn btn-success ${style.btn_primary}`}
            href="/"
            onClick={(e) => {
                      sOrP("paypal", true)
                      this.continue(e);
                      } 
                    }
            >
            <i className="fab fa-cc-paypal fa-3x"></i>{" "} &nbsp;
            PAYPAL
            </label>
            {/*
            <br/>     
            <label
             style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              }} 
            for="uploadImage"
            className={`btn btn-success ${style.btn_primary}`}
            href="/"
            onClick={(e) => {
                      sOrP("paypal", true)
                      this.continue(e);
                      } 
                    }
            >
            <i className="fab fa-bitcoin fa-3x"></i>{" "}
            BITCOIN
            </label>        
            <label
             style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              }} 
            for="uploadImage"
            className={`btn btn-success ${style.btn_primary}`}
            href="/"
            onClick={(e) => {
                      sOrP("paypal", true)
                      this.continue(e);
                      } 
                    }
            >
            <i className="fab fa-ethereum fa-3x"></i>{" "}
            ETHEREUM
            </label>
            */}

            {/*
            <AppBar title="Enter User Details" />
            <TextField
              placeholder="Enter Your First Name"
              label="First Name"
              onChange={handleChange('firstName')}
              defaultValue={values.firstName}
              margin="normal"
              fullWidth
            />
            <br />
            <TextField
              placeholder="Enter Your Last Name"
              label="Last Name"
              onChange={handleChange('lastName')}
              defaultValue={values.lastName}
              margin="normal"
              fullWidth
            />
            <br />
            <TextField
              placeholder="Enter Your Email"
              label="Email"
              onChange={handleChange('email')}
              defaultValue={values.email}
              margin="normal"
              fullWidth
            />
            <br />
            <Button
              color="primary"
              variant="contained"
              onClick={this.continue}
            >Continue</Button>
            */}
          </>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default FormUserDetails;
