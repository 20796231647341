import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import React, { useState, useEffect } from "react";
import ApiService from "./api";
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import { Alert } from "react-bootstrap";
import { useHistory } from "react-router-dom";

// import '../../styles/financial.module.css';
import style from "../../styles/financialInModal.module.css";

const CheckoutForm = (props) => {
        const [error, setError] = useState(null);
        const [email, setEmail] = useState('');
        const stripe = useStripe();
        const elements = useElements();
        const daynight = useSelector((state) => state.data.dayNight);
        const d = useTranslation();

        const plan = props.plan;
        const [show, setShow] = useState(false);
        const history = useHistory();
        
        // https://reactjs.org/docs/conditional-rendering.html
        const [loggedIn, setLoggedIn] = useState("---");
        const changeLoggedIn = (logged) => setLoggedIn(logged);
        useEffect(() => {
            const fetchUser = async () => {
            if(localStorage.getItem('user') !== null) {
                changeLoggedIn(localStorage.getItem('user'));
                setEmail(localStorage.getItem('user'));
            } else {
                changeLoggedIn("---");
            }
            };
            fetchUser();
        }, []);

        // Handle real-time validation errors from the card Element.
        const handleChange = (event) => {
            if (event.error) {
                setError(event.error.message);
            } else {
                setError(null);
            }
        }

        // Handle form submission.
        const handleSubmit = async (event) => {
                event.preventDefault();
                const card = elements.getElement(CardElement);

                try {
                    const {paymentMethod, error} = await stripe.createPaymentMethod({
                        type: 'card',
                        card: card,
                    });
                    // console.log(paymentMethod)
    
                    if (error) {
                        setError(error.response.data)
                    }
                    else {
                        // TODO: change this to more sustainable solution in near future
                        // implemnted first approach to seperate onetime payments and subs
                        if(plan.includes("sp")) {
                            ApiService.saveStripeInfo({email, payment_method_id: paymentMethod.id, sub_plan: plan}).then(response => {
                                // console.log(response.data);
                                setShow(true);

                                setTimeout(() => {
                                  setShow(false);
                                  history.push('/user');
                                }, 4000);
                            }).catch(error => {
                                console.log(error)
                                setShow(false);
                            })
                        } else {
                            ApiService.payOnceDioptify({email, payment_method_id: paymentMethod.id, pay_plan: plan}).then(response => {
                                // console.log(response.data);
                                setShow(true);

                                setTimeout(() => {
                                  setShow(false);
                                  history.push('/user');
                                }, 4000);
                            }).catch(error => {
                                console.log(error)
                                setShow(false);
                            })
                        }
                    }
                } catch {
                    console.log("error")
                }
            }
        ;

        return (
            <>
            {show ? (
            <Alert
                variant="success" // warning
                style={{ position: "fixed", left: "50%", transform: "translateX(-50%)", top: "36px", zIndex: "1040" }}
            >
                {d("price.success")}
            </Alert>
            ) : (
            ""
            )}
            
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                }}
                className={`${style.financialSection} ${daynight ? style.nightMode : ""}`}
            >
                <div class="container">
                <form onSubmit={handleSubmit} className={`${style.stripeForm}`}>
                    <div className={`${style.formRow}`}>
                        <label htmlFor="email">
                            {d("cta.email")}
                        </label>
                        {/*
                        <input
                            className={`${style.formInput}`}
                            id="email"
                            name="name"
                            type="email"
                            placeholder={d("price.mockmail")}
                            required
                            value={email}
                            onChange={(event) => {
                                setEmail(event.target.value);
                            }}
                        />
                        */}
                        <p>{loggedIn}</p>
                    </div>
                    <div className={`${style.formRow}`}>
                        <label htmlFor="card-element">
                            {d("price.creditcard")}
                        </label>

                        <CardElement
                            id="card-element"
                            onChange={handleChange}
                            className={`${style.cardElement}`}
                        />
                        <div className={`${style.cardErrors}`} role="alert">{error}</div>
                    </div>
                    <button type="submit" className={`${style.submitBtn}`}>{d("price.pay")}</button>
                </form>
                </div>
            </div>
            </>
        );

    }
;

export default CheckoutForm;