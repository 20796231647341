import React, { useState, useEffect } from 'react';
import axiosInstance, { baseMLURL } from '../../axios';
import { Link } from 'react-router-dom';
import { Alert } from "react-bootstrap";
import CanvasDrawEditor from '../editor/canvasDrawEditor';

import style from "../../styles/uploadSectionEditor.module.css";
import ImageEditor from "../ImageEditor";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { setImage } from "../../redux/action/image";
import { SERVICES } from './Services';
import axios from 'axios';
import Loader from "../../components/loaderComponent/Loader";
import HowItWorksWithEditor from "../servicesComponents/uploadServiceSectionTasksWithEditorComponent/HowItWorksWithEditor";


export default function UploadServiceSectionTasksWithEditor(props) {
	const daynight = useSelector((state) => state.data.dayNight);

	let resSuccess = false;
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(false);

	const d = props.data;
	const service = props.service;

	const dispatch = useDispatch();
	const onImageChange = async(image, maskImage, prompt, pbeImage) => {
		// if (event.target.files && event.target.files[0]) {
		if (true) {
			// let img = event.target.files[0];
			let img;
			let mask;
			let pbe_img;

			await fetch(image)
				.then(res => res.blob())
				.then(data => {
					img = data;
			})
				.then(() => {
				console.log("img: ", img);
			});

			await fetch(maskImage)
				.then(res => res.blob())
				.then(data => {
					mask = data;
			})
				.then(() => {
				console.log("mask: ", mask);
			});

			// TODO: add here proper mechanism for catching nonetype pbeImages due to usage of II or SD services
			console.log("pbeImage: ", pbeImage);
			if (pbeImage !== null) {
				await fetch(pbeImage)
					.then(res => res.blob())
					.then(data => {
						pbe_img = data;
				})
					.then(() => {
					console.log("pbe_img: ", pbe_img);
				});
			}

			// TODO: get correct filename!
			// TODO: check if good behavior regarding regex
			// https://stackoverflow.com/a/6582227
			let imgFile = await dataUrlToFile(image, "testFileName." + img.type.match(/\/[0-9a-z]+$/i)[0].substring(1), img.type);
			let maskFile = await dataUrlToFile(maskImage, "testFileNameMask." + mask.type.match(/\/[0-9a-z]+$/i)[0].substring(1), mask.type);
			// TODO: add here proper mechanism for catching nonetype pbeImages due to usage of II or SD services
			let pbeImgFile = null;
			if (pbeImage !== null) {
				pbeImgFile = await dataUrlToFile(pbeImage, "testFileNamePBE." + pbe_img.type.match(/\/[0-9a-z]+$/i)[0].substring(1), pbe_img.type);
			}

			// let img = image;
			let slug = uuidv4();
			
			let user = 'anon';
			if(localStorage.getItem('user') !== null) {
				user = localStorage.getItem('user');
			} else {
				if(localStorage.getItem('temp_user') === null) {
					user = uuidv4();
					localStorage.setItem('temp_user', user);
				} else {
					user = localStorage.getItem('temp_user');
				}
			}

			// event.preventDefault();
			let formData = new FormData();
			formData.append('title', uuidv4());
			formData.append('slug', slug);
			formData.append('service', service);
			formData.append('owner', user);
			formData.append('excerpt', uuidv4());
			formData.append('content', uuidv4());
			formData.append('new_model', checked);
			formData.append('image', imgFile);
			formData.append('mask', maskFile);
			formData.append('prompt', prompt);
			// TODO: add here proper mechanism for catching nonetype pbeImages due to usage of II or SD services
			if (pbeImage !== null) {
				formData.append('paint_by_example_image', pbeImgFile);
			}
			
            setLoading(true); // to show the loader svg

			const getStatus = async(taskID) => {
				await axiosInstance.get(`createeditorimage/${taskID}/`).then((res) => {
				  const taskStatus = res.data.task_status;
				  setShow(false);
			  
				  if (taskStatus === 'SUCCESS' ) getProcessedImage();
				  if (taskStatus === 'SUCCESS' || taskStatus === 'FAILURE') return taskStatus;
				  setTimeout(function() {
					getStatus(res.data.task_id);
				  }, 1000); // this recalls the function recursive to see if the tasks has ended
		  
				})
				.catch((err) => {
				  console.log("Unable to get at the moment: " + err);
				  // TODO: add here some toast or other message to show an error 
				  setLoading(false);

				  setShow(true);
				  setTimeout(() => {
					setShow(false);
				  }, 4000);
				});
			}
		
			const getProcessedImage = async() => {
				axiosInstance.get('editorservicepost/' + slug).then((res) => {
					resSuccess = true;

					setLoading(false); // to disable the loader svg
					setShow(false);

					// TODO: change to more mature logic here
					// tests due to some services overwrite original files
					if(service !== "rb" && service !== "hdr" && service !== "cl" && service !== "ds" && service !== "rf") {
						if(!(res.data.paid)) {
							dispatch(setImage({ image: res.data.preview_image, id: uuidv4(), slug: res?.data?.slug  }));	
						} else {
							dispatch(setImage({ image: res.data.image, id: uuidv4(), slug: res?.data?.slug  }));
						}
					} else {
						if(!(res.data.paid)) {
							// TODO: check if this correct for all services
							// dispatch(setImage({ image: res.data.preview_image, id: uuidv4() }));	
							dispatch(setImage({ image: res.data.original_image, id: uuidv4(), slug: res?.data?.slug  }));
						} else {
							dispatch(setImage({ image: res.data.original_image, id: uuidv4(), slug: res?.data?.slug  }));
						}
					}
					
					// to be dynamic when all the credits are gone it needs to update the cost
					getChipAmount();
				})
				.catch((err) => {
					setLoading(false); // to disable the loader svg
					// alert("Unable to upload your image at the moment: " + err);
					setShow(true);
					setTimeout(() => {
					  setShow(false);
					}, 4000);
				});
			}

			await axiosInstance.post(`createeditorimage/`, formData).then((res) => {
				let taskStatus = getStatus(res.data.task_id); // TESTs
				setShow(false);

				// this way don't work because the taskStatus is pending and will not get set to success
				// console.log("dddddddddddddddddddddd taskStatus: ", taskStatus);
				// if (taskStatus === 'SUCCESS') getProcessedImage();

				resSuccess = true;
			})
			.catch((err) => {
				resSuccess = false;
                setLoading(false); // to disable the loader svg
				
				setShow(true);
				setTimeout(() => {
				  setShow(false);
				}, 4000);
			});
		}
	};
	const isImagePick = useSelector((state) => state.data.image.length);

	let [chipData, updateChipData] = useState(0);
	const [freePLan, setPlan] = useState(false);

	const getChipAmount = async(event) => {    
		let formData2 = new FormData();
		formData2.append('email', uuidv4());
		formData2.append('username', uuidv4());
	
		await axiosInstance.get(`user/creditscore/`, formData2).then((res) => {
		  updateChipData(res.data.credit_score);
		  // updateChipData is not working in time
		  chipData = res.data.credit_score;

		  if(chipData > 0) {
			  setPlan(false);
		  } else {
			  setPlan(true);
		  }
		})
		.catch((err) => {
		  console.log("Unable to upload your file at the moment: " + err);
		});
	};	
	
	useEffect(() => {
		const fetchUser = async () => {
		  if(localStorage.getItem('user') !== null) {
			getChipAmount();
		  } else {
			setPlan(true);
		  }
		};
		fetchUser();	
	}, []);
	
	const [checked, setChecked] = useState(true);
	const handleChange = () => {
		setChecked(!checked);
	};

	const handleChildCallback = async(img, mask, prompt, pbe_image) =>{
		// console.log("SPEAKING OF CHILDREN CALLBACK TO PARENT IMAGE: ", img);
		// console.log("SPEAKING OF CHILDREN CALLBACK TO PARENT MASK: ", mask);
		// console.log("SPEAKING OF CHILDREN CALLBACK TO PARENT PBE_IMAGE: ", pbe_image);

		onImageChange(img, mask, prompt, pbe_image);
	}

	// https://stackoverflow.com/a/62432112
	async function dataUrlToFile(dataUrl, fileName, fileType) {

		const res = await fetch(dataUrl);
		const blob = await res.blob();
		return new File([blob], fileName, { type: fileType });
	}	
	  
	return (
		<>
			{show ? (
			<Alert
				variant="danger" // warning
				style={{ position: "fixed", left: "50%", transform: "translateX(-50%)", top: "36px", zIndex: "1040" }}
			>
				{d("error.errorUpload")}
			</Alert>
			) : (
			""
			)}
			<section 
				className={`${style.heroSection} ${daynight ? style.nightMode : ""}`}
			>
				<div className="col-lg-12">
					{isImagePick !== 0 ? (
						<>
						<div className={`${style.btn_container}`}>
							<div className={`dropdown ${style.dropdown}`}>
							<Link
								className={`${style.buttonAdventureWithoutDarkmode} btn btn-outline-secondary`}
								onClick={(e) => {}}
								id="dropdownMenuButton"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								to="#"
								>
								{d("heroSection.uploadText.text11")} <i class="fa fa-angle-down" aria-hidden="true"></i>
							</Link>
								<div className={`dropdown-menu ${style.dropdownMenu}`} aria-labelledby="dropdownMenuButton">
									<Link
										to="/rbg"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-camera-retro fa-lg"></i>
										<>&nbsp;</>{d("serviceRB")}
									</Link>
									<br/>
									<Link
										to="/sr"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-balance-scale fa-lg"></i>
										<>&nbsp;</>{d("serviceSR")}
									</Link>
									<br/>
									<Link
										to="/db"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-eye fa-lg"></i>
										<>&nbsp;</>{d("serviceDB")}
									</Link>
									<br />
									<Link
										to="/hdr"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-database fa-lg"></i>
										<>&nbsp;</>{d("serviceHDR")}
									</Link>
									<br/>
									<Link
										to="/sns"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-gift fa-lg"></i>
										<>&nbsp;</>{d("serviceNS")}
									</Link>
									<br/>
									<Link
										to="/cl"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-paint-brush fa-lg"></i>
										<>&nbsp;</>{d("serviceCL")}
									</Link>
									<br/>
									<Link
										to="/ds"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-bullseye fa-lg"></i>
										<>&nbsp;</>{d("serviceDS")}
									</Link>
									<br/>
									<Link
										to="/rf"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-cubes fa-lg"></i>
										<>&nbsp;</>{d("serviceRF")}
									</Link>
									<br/>
									<Link
										to="/fb"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-user fa-lg"></i>
										<>&nbsp;</>{d("serviceFB")}
									</Link>
									<br/>
									<Link
										to="/ii"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-bath fa-lg"></i>
										<>&nbsp;</>{d("serviceII")}
									</Link>
									<br/>
									<Link
										to="/sd"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-id-badge fa-lg"></i>
										<>&nbsp;</>{d("serviceSD")}
									</Link>
									<br/>
									<Link
										to="/pe"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-barcode fa-lg"></i>
										<>&nbsp;</>{d("servicePE")}
									</Link>									
								</div>
							</div>						
						</div>
						
						<div className="">
							<div className={style.heroContentSide}>
							{/*
							<div className={style.uploadImageCard}>
								<div className={style.uploadImageCardBodySmall}>
								<label
									for="uploadImage"
									className={`btn btn-secondary ${style.btn_primary}`}
									href="/"
									>
									<i className="fas fa-cloud-upload-alt"></i> {d("heroSection.upload")}
									</label>
									<input
									className="d-none"
									type="file"
									id="uploadImage"
									accept="image/*"
									onChange={onImageChange}
								/>
								<div className={`${style.mainText} mainText text-center`}>{d("subHeroSection.drop")}</div>
								{loading ?  (
									<div className="text-center">
										<Loader></Loader>
									</div>
								) : ""}
								</div>
							</div>
							*/}
							
							<b className={`${style.expText}`}>Service: </b>
							{service == SERVICES.II ?  (
								<p className={`${style.expText}`}>Image Inpainting</p>
							) : ""}
							{service == SERVICES.SD ?  (
								<p className={`${style.expText}`}>Stable Diffusion</p>
							) : ""}
							{service == SERVICES.PE ?  (
								<p className={`${style.expText}`}>Paint By Example</p>
							) : ""}
							<b className={`${style.expText}`}>{d("heroSection.uploadText.text10")}: </b>
							{freePLan ?  (
								<p className={`${style.expText}`}>{d("heroSection.uploadText.text8")}</p>
							) : 
								<p className={`${style.expText}`}>{d("heroSection.uploadText.text9")}</p>
							}

							{(service == SERVICES.SR) || (service == SERVICES.RB) ?  (
								<>
									<b className={`${style.expText}`}>{d("heroSection.uploadText.text12")}: </b>
									<input
										type="checkbox"
										name=""
										id=""
										className={`${style.checkBoxChecked} mr-2`}
										checked={checked}
										onChange={handleChange}
									/>
								</>
							) : ""}
							
							<div className={`${style.smText} smText`}>
								{d("heroSection.uploadText.text1")}
								<a href="/termsofservice"> {d("heroSection.uploadText.text2")} </a>
							</div>

							</div>
						</div>
						</>
					) : (
						<div className="container">
    
                        {service == SERVICES.II ?  (
                            <h1 className={`${style.mainHeading} mainHeading text-center`}>
                                {d("uploadImageII")}
                            </h1>
                        ) : ""}
						{service == SERVICES.SD ?  (
                            <h1 className={`${style.mainHeading} mainHeading text-center`}>
                                {d("uploadImageSD")}
                            </h1>
                        ) : ""}
						{service == SERVICES.PE ?  (
                            <h1 className={`${style.mainHeading} mainHeading text-center`}>
                                {d("uploadImagePE")}
                            </h1>
                        ) : ""}

						<div className={`${style.btn_container}`}>
							<div className={`dropdown ${style.dropdown}`}>
							<Link
								className={`${style.buttonAdventureWithoutDarkmode} btn btn-outline-secondary`}
								onClick={(e) => {}}
								id="dropdownMenuButton"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								to="#"
								>
								{d("heroSection.uploadText.text11")} <i class="fa fa-angle-down" aria-hidden="true"></i>
							</Link>
								<div className={`dropdown-menu ${style.dropdownMenu}`} aria-labelledby="dropdownMenuButton">
									<Link
										to="/rbg"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-camera-retro fa-lg"></i>
										<>&nbsp;</>{d("serviceRB")}
									</Link>
									<br/>
									<Link
										to="/sr"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-balance-scale fa-lg"></i>
										<>&nbsp;</>{d("serviceSR")}
									</Link>
									<br/>
									<Link
										to="/db"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-eye fa-lg"></i>
										<>&nbsp;</>{d("serviceDB")}
									</Link>
									<br />
									<Link
										to="/hdr"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-database fa-lg"></i>
										<>&nbsp;</>{d("serviceHDR")}
									</Link>
									<br/>
									<Link
										to="/sns"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-gift fa-lg"></i>
										<>&nbsp;</>{d("serviceNS")}
									</Link>
									<br/>
									<Link
										to="/cl"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-paint-brush fa-lg"></i>
										<>&nbsp;</>{d("serviceCL")}
									</Link>
									<br/>
									<Link
										to="/ds"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-bullseye fa-lg"></i>
										<>&nbsp;</>{d("serviceDS")}
									</Link>
									<br/>
									<Link
										to="/rf"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-cubes fa-lg"></i>
										<>&nbsp;</>{d("serviceRF")}
									</Link>
									<br/>
									<Link
										to="/fb"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-user fa-lg"></i>
										<>&nbsp;</>{d("serviceFB")}
									</Link>
									<br/>
									<Link
										to="/ii"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-bath fa-lg"></i>
										<>&nbsp;</>{d("serviceII")}
									</Link>
									<br/>
									<Link
										to="/sd"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-id-badge fa-lg"></i>
										<>&nbsp;</>{d("serviceSD")}
									</Link>
									<br/>
									<Link
										to="/pe"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-barcode fa-lg"></i>
										<>&nbsp;</>{d("servicePE")}
									</Link>
								</div>
							</div>						
						</div>

							<div className="row">
								<div className={`col-xl-6`}>
									<div
										className={style.heroContentSide}
										style={{ width: "100%", maxWidth: "420px", marginTop: "3em" }}
									>
										{/*
										<div className={style.uploadImageCard}>
										<label
											for="uploadImage"
											className={`${style.uploadImageCardBody} w-100`}
										>
											<img src="/images/logo-and-icons/new3small.svg" alt="" />
											<label
											for="uploadImage"
											className={`btn btn-secondary ${style.btn_primary}`}
											href="/"
											>
											<i className="fas fa-upload"></i>{" "}
											{d("heroSection.upload")}
											</label>
											<input
											className="d-none"
											type="file"
											id="uploadImage"
											accept="image/*"
											onChange={onImageChange}
											/>
											<div className={`${style.mainText} mainText`}>
											</div>
										</label>
										{loading ?  (
											<div className="text-center">
												<Loader></Loader>
												<br/><br/>
											</div>
										) : ""}
										</div>
										*/}

										<b className={`${style.expText}`}>Service: </b>
										{service == SERVICES.II ?  (
											<p className={`${style.expText}`}>Image Inpainting</p>
										) : ""}
										{service == SERVICES.SD ?  (
											<p className={`${style.expText}`}>Stable Diffusion</p>
										) : ""}
										{service == SERVICES.PE ?  (
											<p className={`${style.expText}`}>Paint By Example</p>
										) : ""}
										<b className={`${style.expText}`}>{d("heroSection.uploadText.text10")}: </b>
										{freePLan ?  (
											<p className={`${style.expText}`}>{d("heroSection.uploadText.text8")}</p>
										) : 
											<p className={`${style.expText}`}>{d("heroSection.uploadText.text9")}</p>
										}

										{(service == SERVICES.SR) || (service == SERVICES.RB) ?  (
											<>
												<b className={`${style.expText}`}>{d("heroSection.uploadText.text12")}: </b>
												<input
													type="checkbox"
													name=""
													id=""
													className={`${style.checkBoxChecked} mr-2`}
													checked={checked}
													onChange={handleChange}
												/>
											</>
										) : ""}

										<div className={`${style.smText} smText`}>
											{d("heroSection.uploadText.text1")}
											<a href="/termsofservice"> {d("heroSection.uploadText.text2")} </a>
										</div>
									</div>

								</div>

								<HowItWorksWithEditor data={d} service={service}/>
							</div>
						</div>
					)}
				</div>
			</section>

			<section className={`${daynight ? style.nightMode : ""}`}>
				<div className={`${style.modalBorder} ${daynight ? style.nightMode : ""} container`}>
					<CanvasDrawEditor parentCallback={handleChildCallback} data={d} service={service}/>
				</div>
			</section>

			{isImagePick !== 0 ? <ImageEditor /> : ""}
		</>
	);
}
