/** External Dependencies */
import styled from "styled-components";
import Button from "@scaleflex/ui/core/button";
import IconButton from "@scaleflex/ui/core/icon-button";

const StyledButtonWrapper = styled.div`
  height: 42px;
  display: flex;
  align-items: center;
  margin-right: 4px;
  font-family:"Open Sans", sans-serif
  font-weight: 700;
  font-size: 0.9em;
`;

const StyledMainButton = styled(Button)`
  // padding: 0.575rem 1.15rem;
  margin-right: 0.9em;
  border-radius: 20px;
  border: 2px solid var(--greenColor); !Important;
  flex-grow: 1;
  justify-content: center;
  background-color: var(--greenColor);;
  color: white;
  align-items: center;
  font-family:"Open Sans", sans-serif !important;

  span {
    font-family:"Open Sans", sans-serif !important
    line-height: 14px !important;
    font-size:20px !important;
  }
  &:active {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
  &:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
`;

const StyledMenuButton = styled(IconButton)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 1px;
  height: 100%;
  padding: 4px 8px;
  span {
    font-family:"Open Sans", sans-serif
    line-height: 14px !important;
  }
  svg {
    transform: rotate(-90deg);
    width: 10px;
    margin-top: -4px;
  }
 
`;

export { StyledButtonWrapper, StyledMainButton, StyledMenuButton };
