import React, { useState, useEffect } from "react";
import axiosInstance from "../../../axios";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";

import style from "../../../styles/multiUploadSection.module.css";
import ImageEditor from "../../ImageEditor";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { setImage } from "../../../redux/action/image";
import { SERVICES } from "../Services";
import Loader from "../../../components/loaderComponent/Loader";
import HowItWorks from "../../servicesComponents/uploadServiceSectionTasksComponent/HowItWorks";
import { useDropzone } from "react-dropzone";
import MultiStepProgressBar from "../../MultiStepProgressBar/MultiStepProgressBar";

export default function UploadMultiServiceSectionTasks(props) {
  const daynight = useSelector((state) => state.data.dayNight);

  let resSuccess = false;
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const d = props.data;
  const services = props.services;
  const service = services[0][1];
  const [files, setFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [count, setCount] = useState(0);
  const [errorMessage, showErrorMessage] = useState();
  const [showProgressBar, setShowProgressBar] = useState(true);
  const dispatch = useDispatch();
  const onImageChange = async (files) => {
    resetUploadingProcess();
    if (Array.isArray(files)) {
      if (files.length > 10) {
        showErrorMessage("error.uploadImageMaxLimitExceded");
        setShow(true);
        setShowProgressBar(false);
        return;
      }

      setFiles(files);
      for (const [index, file] of files.entries()) {
        try {
          const res = await uploadImage(file, index, files);
          setCount((count) => count + 1);
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const uploadImage = async (file, index, files) => {
    let img = file;
    let slug = uuidv4();

    let user = "anon";
    if (localStorage.getItem("user") !== null) {
      user = localStorage.getItem("user");
    } else {
      if (localStorage.getItem("temp_user") === null) {
        user = uuidv4();
        localStorage.setItem("temp_user", user);
      } else {
        user = localStorage.getItem("temp_user");
      }
    }

    //  event.preventDefault();
    let formData = new FormData();
    formData.append("title", uuidv4());
    formData.append("slug", slug);
    formData.append("service1", services[0][1]);

    services[1]
      ? formData.append("service2", services[1][1])
      : formData.append("service2", "NONE");
    services[2]
      ? formData.append("service3", services[2][1])
      : formData.append("service3", "NONE");

    formData.append("owner", user);
    formData.append("excerpt", uuidv4());
    formData.append("content", uuidv4());
    formData.append("new_model", checked);
    formData.append("image", img);

    setLoading(true); // to show the loader svg

    const getStatus = async (taskID) => {
      await axiosInstance
        .get(`createmultiimage/${taskID}/`)
        .then((res) => {
          const taskStatus = res.data.task_status;
          setShow(false);

          if (taskStatus === "SUCCESS") getProcessedImage(index, files);
          if (taskStatus === "SUCCESS" || taskStatus === "FAILURE")
            return taskStatus;
          setTimeout(function () {
            getStatus(res.data.task_id);
          }, 1000); // this recalls the function recursive to see if the tasks has ended
        })
        .catch((err) => {
          console.log("Unable to get at the moment: " + err);

          // TODO: add here some toast or other message to show an error
          setLoading(false);
          // setRejectedFiles(rejectedFiles => [...rejectedFiles,index] )
          setShow(true);
          setTimeout(() => {
            setShow(false);
          }, 4000);
        });
    };

    const getProcessedImage = async (index, files) => {
      axiosInstance
        .get("multiservicepost/" + slug)
        .then((res) => {
          resSuccess = true;

          if (files.length > 1) {
            setUploadedFiles((uploadedFiles) => [...uploadedFiles, index]);
          } else {
            setLoading(false);
          }
      //    setLoading(false); // to disable the loader svg
          setShow(false);

          // TODO: change to more mature logic here
          // tests due to some services overwrite original files
          if (
            service !== "rb" &&
            service !== "hdr" &&
            service !== "cl" &&
            service !== "ds" &&
            service !== "rf"
          ) {
			if(!(res.data.paid)) {
				dispatch(setImage({ image: res.data.preview_image, service:res?.data?.service, originalImage:res?.data?.preview_image, paid:false, id: uuidv4() }));	
			} else {
				dispatch(setImage({ image: res.data.image, service:res?.data?.service, originalImage:res?.data?.original_image, paid:true, id: uuidv4() }));
			}
          } else {
			if(!(res.data.paid)) {
				// TODO: check if this correct for all services
				// dispatch(setImage({ image: res.data.preview_image, id: uuidv4() }));	
				dispatch(setImage({ image: res?.data?.original_image, originalImage:res?.data?.image, service:res?.data?.service, paid:true, id: uuidv4() }));
			} else {
				dispatch(setImage({ image: res?.data?.original_image, originalImage:res?.data?.image, service:res?.data?.service, paid:false, id: uuidv4() }));
			}
          }

          // to be dynamic when all the credits are gone it needs to update the cost
          getChipAmount();
        })
        .catch((err) => {
          setLoading(false); // to disable the loader svg
          // alert("Unable to upload your image at the moment: " + err);
          setShow(true);
          setRejectedFiles((rejectedFiles) => [...rejectedFiles, index]);
          setTimeout(() => {
            setShow(false);
          }, 4000);
        });
    };

    await axiosInstance
      .post(`createmultiimage/`, formData)
      .then((res) => {
        let taskStatus = getStatus(res.data.task_id); // TESTs
        setShow(false);

        // this way don't work because the taskStatus is pending and will not get set to success
        // console.log("dddddddddddddddddddddd taskStatus: ", taskStatus);
        // if (taskStatus === 'SUCCESS') getProcessedImage();

        resSuccess = true;
      })
      .catch((err) => {
        resSuccess = false;
        setLoading(false); // to disable the loader svg
        setRejectedFiles((rejectedFiles) => [...rejectedFiles, index]);

        setShow(true);
        setTimeout(() => {
          setShow(false);
        }, 4000);
      });
  };
  const isImagePick = useSelector((state) => state.data.image.length);

  let [chipData, updateChipData] = useState(0);
  const [freePLan, setPlan] = useState(false);

  const getChipAmount = async (event) => {
    let formData2 = new FormData();
    formData2.append("email", uuidv4());
    formData2.append("username", uuidv4());

    await axiosInstance
      .get(`user/creditscore/`, formData2)
      .then((res) => {
        updateChipData(res.data.credit_score);
        // updateChipData is not working in time
        chipData = res.data.credit_score;

        if (chipData > 0) {
          setPlan(false);
        } else {
          setPlan(true);
        }
      })
      .catch((err) => {
        console.log("Unable to upload your file at the moment: " + err);
      });
  };

  useEffect(() => {
    const fetchUser = async () => {
      if (localStorage.getItem("user") !== null) {
        getChipAmount();
      } else {
        setPlan(true);
      }
    };
    fetchUser();
  }, []);

  const [checked, setChecked] = useState(true);
  const handleChange = () => {
    setChecked(!checked);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onImageChange,
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
    multiple: true,
  });

  const resetUploadingProcess = () => {
    setShowProgressBar(true);
    setCount(0);
    setRejectedFiles([]);
    setUploadedFiles([]);
  };

  const resetUploadingBar = () => {
    setLoading(false);
  };

  return (
    <>
      {show ? (
        <Alert
          variant="danger" // warning
          style={{
            position: "fixed",
            left: "50%",
            transform: "translateX(-50%)",
            top: "36px",
            zIndex: "1040",
          }}
        >
          {errorMessage ? d(errorMessage) : d("error.errorUpload")}
        </Alert>
      ) : (
        ""
      )}

      <section
        className={`${style.heroSection} ${daynight ? style.nightMode : ""}`}
      >
        <div className="col-lg-12">
          {isImagePick !== 0 ? (
            <>
              <div className="">
                <div className={style.heroContentSide}>
                  <div  className={`${style.uploadImageCard } ${
                            isDragActive ? style.active : ""
                          }`}>
                    <div className={style.uploadImageCardBodySmall}>
                      <section>
                        <div {...getRootProps({ className: "dropzone" })}>
                          <label
                            for="uploadImage"
                            className={`btn btn-secondary ${style.btn_primary}`}
                            href="/"
                          >
                            <i className="fas fa-cloud-upload-alt"></i>{" "}
                            {d("heroSection.upload")}
                          </label>
                          <input {...getInputProps()} />
                          <div
                            className={`${style.mainText} mainText text-center`}
                          >
                            {d("subHeroSection.drop")}
                          </div>
                        </div>

                        {loading ? (
                          <div className="text-center">
                            <Loader></Loader>
                          </div>
                        ) : (
                          ""
                        )}
                      </section>
                    </div>
                    {files?.length > 1 && showProgressBar ? (
                      <MultiStepProgressBar
                        files={files}
                        count={count}
                        rejectedFiles={rejectedFiles}
                        uploadedFiles={uploadedFiles}
                        resetUploadingBar={resetUploadingBar}
                      />
                    ) : (
                      ""
                    )}
                  </div>

                  <b className={`${style.expText}`}>Services: </b>
                  <ol>
                    {services.map((service) => (
                      <>
                        <p className={`${style.expText}`}> - {service[0]}</p>
                      </>
                    ))}
                  </ol>

                  <b className={`${style.expText}`}>
                    {d("heroSection.uploadText.text10")}:{" "}
                  </b>
                  {freePLan ? (
                    <p className={`${style.expText}`}>
                      {d("heroSection.uploadText.text8")}
                    </p>
                  ) : (
                    <p className={`${style.expText}`}>
                      {d("heroSection.uploadText.text9")}
                    </p>
                  )}

                  {service == SERVICES.SR || service == SERVICES.RB ? (
                    <>
                      <b className={`${style.expText}`}>
                        {d("heroSection.uploadText.text12")}:{" "}
                      </b>
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        className={`${style.checkBoxChecked} mr-2`}
                        checked={checked}
                        onChange={handleChange}
                      />
                    </>
                  ) : (
                    ""
                  )}

                  <div className={`${style.smText} smText`}>
                    {d("heroSection.uploadText.text1")}
                    <a href="/termsofservice">
                      {" "}
                      {d("heroSection.uploadText.text2")}{" "}
                    </a>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="container">
              <div className="row">
                <div className={`col-lg-6 mt-3 mt-lg-5 pr-3 pr-md-5`}>
                  {/*<HowItWorks data={d} service={service}/>*/}
                  <h1 className={`mainHeading ${style.mainHeading}`}>
                    {d("subHeroSectionGeneric.title")}
                  </h1>
                  <div className={`mainText ${style.mainText}`}>
                    {d("subHeroSectionGeneric.description")}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className={style.heroContentSide}>
                    <div className={`${style.uploadImageCard } ${
                            isDragActive ? style.active : ""
                          }`}>
                      <div className={style.uploadImageCardBodySmall}>
                        <section>
                          <div {...getRootProps({ className: "dropzone" })}>
                            <label
                              for="uploadImage"
                              className={`btn btn-secondary ${style.btn_primary}`}
                              href="/"
                            >
                              <i className="fas fa-cloud-upload-alt"></i>{" "}
                              {d("heroSection.upload")}
                            </label>
                            <input {...getInputProps()} />

                            <div
                              className={`${style.mainText} mainText text-center`}
                            >
                              {d("subHeroSection.drop")}
                            </div>
                          </div>

                          {loading ? (
                            <div className="text-center">
                              <Loader></Loader>
                            </div>
                          ) : (
                            ""
                          )}
                        </section>
                      </div>
					  {files?.length > 1 && showProgressBar ? (
                      <MultiStepProgressBar
                        files={files}
                        count={count}
                        rejectedFiles={rejectedFiles}
                        uploadedFiles={uploadedFiles}
                        resetUploadingBar={resetUploadingBar}
                      />
                    ) : (
                      ""
                    )}
                    </div>

                    <b className={`${style.expText}`}>Services: </b>
                    <ol>
                      {services.map((service) => (
                        <>
                          <p className={`${style.expText}`}> - {service[0]}</p>
                        </>
                      ))}
                    </ol>

                    <b className={`${style.expText}`}>
                      {d("heroSection.uploadText.text10")}:{" "}
                    </b>
                    {freePLan ? (
                      <p className={`${style.expText}`}>
                        {d("heroSection.uploadText.text8")}
                      </p>
                    ) : (
                      <p className={`${style.expText}`}>
                        {d("heroSection.uploadText.text9")}
                      </p>
                    )}

                    {service == SERVICES.SR || service == SERVICES.RB ? (
                      <>
                        <b className={`${style.expText}`}>
                          {d("heroSection.uploadText.text12")}:{" "}
                        </b>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          className={`${style.checkBoxChecked} mr-2`}
                          checked={checked}
                          onChange={handleChange}
                        />
                      </>
                    ) : (
                      ""
                    )}

                    <div className={`${style.smText} smText`}>
                      {d("heroSection.uploadText.text1")}
                      <a href="/termsofservice">
                        {" "}
                        {d("heroSection.uploadText.text2")}{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      {isImagePick !== 0 ? <ImageEditor /> : ""}
    </>
  );
}
