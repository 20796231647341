import { useFormControls } from "./ContactFormControls";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField } from "@material-ui/core";
import React from "react";

import style from "../../styles/contactForm.module.css";


export default function ContactForm(props) {
  const daynight = useSelector((state) => state.data.dayNight);
  const d = props.data;

  const inputFieldValues = [
    {
      name: "company",
      label: d("contact.company"),
      id: "my-company"
    },
    {
      name: "fullName",
      label: d("contact.name"),
      id: "my-name"
    },
    {
      name: "email",
      label: "E-Mail*",
      id: "my-email"
    },
    {
      name: "subject",
      label: d("contact.subject"),
      id: "my-subject"
    },
    {
      name: "message",
      label: d("contact.message"),
      id: "my-message",
      multiline: true,
      rows: 20,
      variant: "filled"
    }
  ];

  const {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors
  } = useFormControls();

  return (
    <section className={`${style.teamSection} ${daynight ? style.nightMode : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className={`mt-5 ${style.teamCarousel}`}>
              <div
                id="teamSlider"
                className={`carousel slide logoCarousel mt-3 ${style.teamSlider}`}
                data-ride="carousel"
              >
                <form autoComplete="off" onSubmit={handleFormSubmit}>
                  {inputFieldValues.map((inputFieldValue, index) => {
                    return (
                      <>
                      <TextField
                        key={index}
                        onChange={handleInputValue}
                        onBlur={handleInputValue}
                        name={inputFieldValue.name}
                        label={inputFieldValue.label}
                        error={errors[inputFieldValue.name]}
                        multiline={inputFieldValue.multiline ?? false}
                        fullWidth
                        rows={inputFieldValue.rows ?? 1}
                        variant={inputFieldValue.variant ?? "outlined"}
                        autoComplete="none"
                        {...(errors[inputFieldValue.name] && {
                          error: true,
                          helperText: d("contact.error")
                        })}
                      />
                      <br/><br/>    
                      </>
                    );
                  })}
                  
                  {/*TODO: https://stackoverflow.com/questions/55586626/react-material-ui-how-to-give-a-button-a-custom-color-when-disabled*/}
                  {/*TODO: connect with backend*/}
                  {/*color="secondary"*/}
                  {/*onClick={() => { console.log('sent'); }}*/}
                  {/*onClick={() => { handleSubmit(); }}*/}
                  <Button
                    variant="contained"
                    type="submit"
                    style={{ backgroundColor: "var(--greenColor)", disabledBackground: "#DDDDDD" }}
                    disabled={!formIsValid()}
                    
                  >
                    {d("contact.send")}
                  </Button>
                </form>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
