import React, { useEffect, useState } from "react";
import style from "../styles/cookies.module.css";
import CookiesTable from "../components/CookiesTable";
import data from "../data/cookiesTableData.json";
import { useSelector } from "react-redux";
import CookiesModal from "../components/CookiesModal";
import CookiesPolicyModal from "../components/CookiesPolicyModal";
import { useTranslation } from "react-multi-lang";

export default function Imprint() {
  const [show, setShow] = useState(false);
  const daynight = useSelector((state) => state.data.dayNight);
  const cookiesModal = useSelector((state) => state.data.cookiesModal);
  const d = useTranslation();

  useEffect(() => {
		document.title = `${d("imprint.title")} - dioptify.com`;
	});
  
  const handleShow = () => setShow(true);

  return (
    <>
      {cookiesModal ? <CookiesModal /> : ""}
      <div className={`${style.cookiesSection} ${daynight ? style.nightMode : ""}`}>
        <div class="col-lg-12 mx-auto">
          <div className={style.heroSection}>
            <h1 class="mainHeading mb-1">{d("imprint.title")}</h1>
            <h4 className="commonText">{d("imprint.subtitle")}</h4>
          </div>
          <div className="mx-2">
            <div className={style.card}>
              <p>
                <strong>dioptify UG (haftungsbeschränkt)</strong><br/>
                Tulpenweg 1a<br/>
                31303 Burgdorf, Deutschland<br/>
                HRB 208738 Amtsgericht Hildesheim
              </p>

              <p>
                <strong>E-Mail:</strong> <a href="mailto:contact@dioptify.com">contact@dioptify.com</a><br/>
                <strong>{d("imprint.phone")}:</strong> <a href="tel:+491772930783">+49 177 2930 783</a>
              </p>

              <p>
                <strong>{d("imprint.life")}:</strong> B. Sc. Kevin Peivareh / Dipl.-Ing. Adrian Peivareh<br/>
                {/*VAT ID: ------------<br/>*/}
              </p>

              <p>
                <strong>{d("imprint.dispute")}</strong>
                <br />
                {d("imprint.dispute1")}
                <br />
                <p>
                  <strong>Allgemeine Verbraucherschlichtungsstelle des Zentrums für Schlichtung e. V.</strong><br/>
                  Straßburger Str. 8<br/>
                  77694 Kehl<br/>
                  <a href="https://www.verbraucher-schlichter.de">https://www.verbraucher-schlichter.de</a>
                </p>                
              </p>

              <p>
                <strong>{d("imprint.copyright")}</strong>
                <br />
                {d("imprint.copyright1")}
                <br />
                {d("imprint.copyright2")}
              </p>

            </div>
          </div>
        </div>
      </div>

      <CookiesPolicyModal show={show} setShow={setShow} />
    </>
  );
}
